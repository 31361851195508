.button-submit-ly-shop {
    text-align: right;
}

.percetange-loyatly-product {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 30px;
    color: #fff;
    /* border: 1px solid #fff; */
    background-color: #f92a5e;
    text-align: center;
    border-radius: 50%;
    position: relative;
    font-size: 13px;
}

.percetange-loyatly-product:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border: 2px solid #3d2767;
    border-radius: 50%;
}

.loyalty-mobile-banner {
    display: none;
}

.unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.image-loyalty-top-counter img {
    width: 100%;
}

.loyalty-table-content td {
    padding: 0px 10px;
}

.table-row-no-padding-top>td {
    border-top: 0;
}

.loyalty-table-content th {
    font-size: 14px;
    padding: 0px 10px;
    border-top: 0px;
    font-weight: 600;
}

.table-loyalty>tbody>tr>td {
    vertical-align: middle;
    font-size: 13px;
}

.loyaltyOldPrice {
    white-space: nowrap;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    line-height: 1;
    position: relative;
}

.loyaltyOldPrice:after {
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    height: 1px;
    width: 60px;
    background: #efb836;
    transform: rotate(-13deg);
}

.mask-captcha-loyalty {
    background: #fff;
    opacity: 0;
    width: 100%;
    height: 51px;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1;
}

.img-loyalty-product {
    padding: 5px;
}

.count-clock-loyalty {
    position: absolute;
    left: 50%;
    top: 400px;
    transform: translate(-50%, 0);
}

.custom-name-title span {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.custom-name-title td {
    padding: 25px 10px 10px;
    /* border-bottom: 2px solid #ddd; */
}

.special-icon-loyalty-for-company {
    max-width: 60px;
}

.info-icons-action {
    margin-top: 16px;
    border: 1px solid #b4b4b4;
    padding: 5px 25px;
    border-radius: 12px;
    font-weight: 700;
}

.smallBadge {
    max-width: 60px;
}

.body-modal-loyalty-specification {
    max-height: 65vh;
    overflow-y: auto;
    /* padding-right: 0; */
}

.body-modal-loyalty-specification .product-detail-tab-specification {
    padding: 0px;
}

.loyalty-detail-name-click {
    cursor: pointer;
}

.loyalty-detail-name-click:hover {
    text-decoration: underline;
}

.body-modal-loyalty-specification-h3 {
    font-size: 20px;
}

.loyalty-modal-image {
    max-width: 100%;
}

.image-content-loyalty-modal {
    padding: 0px 100px;
}

.modal-loyalty-header-item {
    flex: 0 100%;
}

.bottom-divider {
    padding: 0px 60px;
}

.timer-counter-banner-holder {
    display: flex;
    width: 50%;
    text-align: center;
    margin: 0 auto;
}

.timer-holder-item {
    flex: 1;
    font-size: 75px;
}

.total-sum-percent {
    text-align: center;
    font-size: 45px;
    color: #000000;
    font-weight: bold;
}

.scala-pointer {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-9px);
    transition: left 600ms ease-out;
    max-width: 14px;
}

.scala-holder .shop-zoom {
    position: relative;
}

.scala-holder {
    padding-top: 17px;
    margin-bottom: 7px;
    position: relative;
}

.stat-percent {
    width: 100%;
    font-weight: 300;
    font-size: 80px;
    line-height: 1;
    color: #c91031;
    font-weight: 500;
}

.timer-item-text {
    font-size: 30px;
    color: #efb836;
}

.timer-holder-item-dots {
    font-weight: bold;
    color: #efb836;
    flex: 0;
    line-height: 1.2;
}

.timer-item-number {
    font-weight: bold;
    font-size: 100px;
    line-height: 1;
}

.stat-item span {
    display: inline-block;
    width: 100%;
}

.shop-target-description {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
}

.loyalty-upper-desc {
    margin-top: 10px;
    font-size: 24px;
}

.stat-target.red {
    color: #fff;
}

.stat-target {
    border: 1px solid #fff;
    border-radius: 30px;
    padding: 5px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

.stat-target.green {
    color: #fff;
}

.item-loyalty-top-settings {
    border-left: 1px solid #b4b4b4;
}

.loyalty-table-content {
    padding-bottom: 65px;
}

.modal-header h4 {
    margin-bottom: 0px;
}

.loyalty-captcha-image-code {
    margin-bottom: 0px;
    position: relative;
}

.FlipClock .flipUnitContainer .flipCard {
    width: 120px !important;
    height: 50px !important;
}

.FlipClock .flipUnitContainer {
    width: 120px;
    height: 100px;
}

.FlipClock .flipUnitContainer .lowerCard span,
.FlipClock .flipUnitContainer .upperCard span {
    font-size: 4.8em !important;
}

.FlipClock .flipUnitContainer .flipCard span {
    font-size: 4.8em !important;
}

.FlipClock .flipUnitContainer .ContainerTitle {
    top: -23px;
    font-size: 17px;
}

.FlipClock .flipUnitContainer {
    width: 120px !important;
    height: 100px !important;
}

/* loyalty slider */
.loyalty-slider .slider-special-action-image {
    padding-top: 0px;
}

.loyalty-slider .action-title-name {
    font-size: 20px;
    min-height: 60px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.loyalty-slider .loyalty-bages-holder {
    position: absolute;
    top: -1px;
    left: 0px;
}

.loyalty-slider-content .loyalty-discount-holder {
    position: absolute;
    right: 10px;
    top: 4px;
}

.loyalty-slider .slider-special-action-detail-res {
    font-size: 17px;
}

.loyalty-slider .slider-special-action-detail-price {
    font-size: 17px;
}


.loyalty-slider-content .action-block-special-slider-title-main h2 {
    text-transform: none;
    font-size: 32px;
    padding-bottom: 0px;
    font-weight: bold;
    margin-bottom: 5px;
}

.loyalty-slider .spec-action-qty {
    flex: none;
}

.loyalty-slider-content .loyalty-btn-holder {
    padding-top: 15px;
    text-align: center;
    /* position: absolute; */
    /* bottom: 0px; */
    margin-left: auto;
    margin-right: auto;
    /* left: 15px; */
    /* right: 15px; */
}

.action-block-special-slider-title-main span {
    color: red;
    font-size: 35px;
}

.loyalty-slider-content .loyalty-block-btn {
    border: 2px solid black;
    background: black;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    border-radius: 8px;
}

#form-loyalty-shop {
    overflow-x: auto;
}

.loyalty-header-info-products {
    background: #3d2767;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    padding: 3px 18px;
    border-radius: 20px;
}

.banner-loyalty-image-conter {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    border-radius: 15px;
}

.banner-counter-holder-loyaty {
    flex: 2;
}

.banner-logo-holder-loyaty {
    flex: 1;
}

.title-counter-banner-main {
    font-weight: bold;
    font-size: 34px;
}

.banner-counter-holder-main {
    text-align: center;
    color: #fff;
    margin-top: 80px;
}

.loyaty-holder-info-user-box {
    margin-top: 20px;
    background: #efb836;
    padding: 20px 35px;
    border-radius: 15px;
    color: #3d2767;
    margin-bottom: 20px;
    position: relative;
    display: flex;
}

.loyalty-info-box-item {
    flex: 1;
    text-align: center;
    border-right: 2px solid #3d2767;
    padding: 0px 40px;
}

.scala-holder-loyalty {
    height: 23px;
    width: 100%;
    background: #fff;
    border-radius: 25px;
    position: relative;
}

.loyalty-info-box-item:nth-child(3) {
    border-right: 0;
}

.scala-holder-loyatly-left {
    width: calc(50% + 17px);
    background: #3d2767;
    height: 100%;
    border-radius: 25px;
}

.scala-holder-loyalty-circle {
    background: #efb836;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 50%;
    top: 0;
    border-radius: 23px;
    border: 1px solid #3d2767;
}

.total-sum-percent-loyalty {
    color: #fff;
    font-size: 85px;
    font-weight: bold;
    line-height: 90px;
}

.loyalty-info-box-item h1 {
    margin-bottom: 0px;
}

.scala-holder-numers {
    width: 100%;
    color: #fff;
    position: relative;
}

.scala-holder-number-item {
    position: absolute;
    top: 0%;
    left: 0%;
}

.scala-holder-number-item-25 {
    left: 25%;
}

.scala-holder-number-item-50 {
    left: 50%;
}

.scala-holder-number-item-75 {
    left: 75%;
}

.scala-holder-number-item-100 {
    left: 97%;
}

.shop-target-description-loyalty {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0px;
    font-weight: 600;
}

/* .alert-info-loyalty {
    margin-top: 15px;
} */

.sold-out {
    opacity: 0.5;
    background: #fafafa;
    position: relative;
}

.sold-out .productImage:after {
    content: 'RASPRODATO';
    color: #ff0427;
    position: absolute;
    top: 30%;
    left: 10px;
    font-size: 16px;
    letter-spacing: 5px;
    font-weight: 600;
    transform: rotate(-15deg);
}

.action-block-special-slider-main-slider {
    /* height: 100%; */
}

/* .loyalty-block-content .special-block-title-main {
    height: fit-content;
} */
@media (max-width: 1681px) {

    .loyalty-slider .slider-special-action-detail-res,
    .loyalty-slider .slider-special-action-detail-price {
        font-size: 14px;
    }
}

@media (max-width: 1400px) {
    .loyalty-slider .action-title-name {
        font-size: 15px;
    }

    .loyalty-slider .loyalty-block-btn {
        font-size: 12px;
    }

    .loyalty-slider .loyalty-btn-holder {
        left: 9px;
        right: 9px;
    }
}

@media (max-width: 1920px) {
    .count-clock-loyalty {
        top: 290px !important;
    }
}

@media (max-width: 1680px) {
    .count-clock-loyalty {
        top: 250px !important;
    }

    .bottom-divider {
        padding: 0px 25px;
    }

    .timer-counter-banner-holder {
        width: 60%;
    }
}

@media (max-width: 1600px) {
    .count-clock-loyalty {
        top: 220px !important;
    }

    .bottom-divider {
        padding: 0px 5px;
    }

    /* .action-block-special-banner {
        width: 450px;
        height: 450px;
    } */
}

@media (max-width: 1440px) {
    .count-clock-loyalty {
        top: 185px !important;
    }

    .bottom-divider {
        padding: 0px;
    }

    .loyalty-upper-desc {
        font-size: 22px;
    }

    .total-sum-percent-loyalty {
        font-size: 70px;
    }

    .banner-counter-holder-main {
        margin-top: 60px;
    }
}

@media (max-width: 1366px) {
    .timer-counter-banner-holder {
        width: 70%;
    }

    .loyalty-info-box-item {
        padding: 0px 30px;
    }
}

@media (max-width: 1280px) {
    .loyalty-info-box-item {
        padding: 0px 23px;
    }

    .total-sum-percent-loyalty {
        font-size: 55px;
    }

    .loyalty-info-box-item h1 {
        margin-bottom: 0px;
        font-size: 30px;
    }
}

@media (max-width: 1024px) {
    .count-clock-loyalty {
        top: 130px !important;
    }

    .FlipClock .flipUnitContainer {
        width: 90px !important;
        height: 70px !important;
    }

    .FlipClock .flipUnitContainer .lowerCard span,
    .FlipClock .flipUnitContainer .upperCard span {
        font-size: 3.4em !important;
    }

    .FlipClock .flipUnitContainer .flipCard {
        width: 90px !important;
        height: 40px !important;
    }

    .FlipClock .flipUnitContainer .flipCard span {
        font-size: 3.4em !important;
    }

    .timer-item-number {
        font-size: 60px;
    }

    .logo-image-loyalty-banner img {
        width: 250px;
    }

    .timer-holder-item-dots {
        line-height: 0.6;
    }
}

@media (max-width: 450px) {
    .count-clock-loyalty {
        top: 220px !important;
        color: #fff !important;
    }

    .FlipClock .flipUnitContainer {
        width: 75px !important;
        height: 60px !important;
    }

    .FlipClock .flipUnitContainer .lowerCard span,
    .FlipClock .flipUnitContainer .upperCard span {
        font-size: 3em !important;
    }

    .FlipClock .flipUnitContainer .flipCard span {
        font-size: 3em !important;
    }

    .FlipClock .flipUnitContainer .flipCard {
        width: 75px !important;
        height: 35px !important;
    }

    .FlipClock .flipUnitContainer .ContainerTitle {
        font-size: 14px !important;
        font-weight: 600 !important;
        top: -20px !important;
    }

    .alert-info-loyalty {
        margin-top: 15px;
    }

    .loyalty-mobile-banner {
        display: block;
    }

    .loyalty-desktop-banner {
        display: none;
    }

    .mobile-column-no-padding {
        padding: 0px;
    }

    .img-loyalty-product {
        max-width: 70px;
    }

    .bottom-divider {
        padding: 0px;
    }

    .loyalty-upper-desc {
        margin-top: 15px;
    }

    .loyalty-input-value {
        width: 60px;
    }

    .image-content-loyalty-modal {
        padding: 0px 25px;
    }

    .banner-loyalty-image-conter {
        flex-direction: column-reverse;
        margin-top: 20px;
    }

    .loyaty-holder-info-user-box {
        flex-direction: column;
    }

    .loyalty-info-box-item:nth-child(3) {
        border-bottom: 0;
    }

    .loyalty-info-box-item {
        border-right: 0;
        border-bottom: 2px solid #3d2767;
        padding-bottom: 10px;
    }

    .loyalty-info-box-item {
        padding-left: 0px;
        padding-right: 0px;
    }

    .timer-counter-banner-holder {
        width: 100%;
    }

    .timer-item-number {
        font-size: 34px;
    }

    .timer-holder-item {
        font-size: 27px;
    }

    .timer-item-text {
        font-size: 18px;
    }

    .logo-image-loyalty-banner {
        text-align: center;
    }

    .banner-counter-holder-main {
        margin-top: 10px;
        padding-bottom: 40px;
    }

    .title-counter-banner-main {
        font-size: 23px;
    }

    .shop-target-description-loyalty-second {
        margin-top: 0px;
    }

    .timer-holder-item-dots {
        line-height: 1.2;
    }

}

/* RESPONSIVE - HOMEPAGE LOYALTY SLIDER*/
@media (max-width: 1440px) and (min-width: 1366px) {
    .special-block-image-mask-name {
        font-size: 22px;
        line-height: 22px;
    }

    .action-block-special-banner {
        width: 400px;
        height: 400px;
    }

    .loyalty-slider-content .action-block-special-slider-title-main h2 {
        font-size: 22px;
    }

    .action-block-special-slider-title-main span {
        font-size: 24px;
    }

    .action-block-special-slider-item-image img {
        width: 80%;
    }

    .action-block-special-slider-item-price-content {
        font-size: 12px;
    }

    .price-content-item.price-value {
        font-size: 17px;
        line-height: 17px;
    }

    .action-block-special-slider-main-slider-item {
        height: 350px;
    }
}

@media (max-width: 1366px) and (min-width: 1280px) {
    .special-block-image-mask-name {
        font-size: 20px;
        line-height: 20px;
    }

    .action-block-special-banner {
        width: 380px;
        height: 380px;
    }

    .loyalty-slider-content .action-block-special-slider-title-main h2 {
        font-size: 22px;
    }

    .action-block-special-slider-title-main span {
        font-size: 24px;
    }

    .action-block-special-slider-item-image img {
        width: 80%;
    }

    .action-block-special-slider-item-price-content {
        font-size: 12px;
    }

    .price-content-item.price-value {
        font-size: 14px;
        line-height: 14px;
    }

    .action-block-special-slider-main-slider-item {
        height: 335px;
    }
}

@media (max-width: 1280px) and (min-width: 1024px) {
    .special-block-image-mask-name {
        font-size: 20px;
        line-height: 20px;
    }

    .action-block-special-banner {
        width: 380px;
        height: 380px;
    }

}

@media (max-width: 1024px) {
    .special-block-image-mask-name {
        font-size: 20px;
        line-height: 20px;
    }

    .action-block-special-banner {
        width: 100%;
        height: 266px;
        text-align: center;
    }

    .homepage-content-special-image-block {
        width: 100%;
        height: 266px;
        text-align: center;
    }

    .action-block-special-content {
        grid-template-columns: 100%;
        grid-template-areas: "banner"
            "slider";
    }

    .homepage-content-body-special-block {
        grid-template-columns: 100%;
        grid-template-areas: "banner"
            "slider";
    }

    .action-block-special-banner img {
        height: 100%;
        padding-top: 15px;
    }

    .loyalty-slider-content .action-block-special-slider-title-main h2 {
        font-size: 22px;
        padding-top: 15px;
    }

    .action-block-special-slider-title-main span {
        font-size: 22px;
    }
}