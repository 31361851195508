.invalid-feedback {
    display: none;
    width: 100%;
    margin-bottom: 0.25rem !important;
    font-size: 80%;
    color: #dc3545;
    margin-top: 0.25rem !important;
}

.invalid-feedback-absolute {
    position: relative !important;
}

#fullpage-wrapper .form-control {
    display: block;
    width: 100%;
    height: 38px;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.42857143;
    color: #000000;
    background-color: #dddddd;
    /* background-image: none; */
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    box-shadow: inset 0 1px 1px transparent;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    margin-bottom: 10px;
    margin-top: 5px;
}