/*---CSS---*/
@font-face {
	font-family: abuget;
	/* src: url(../../public/css/fonts/abuget/Abuget.ttf); */
}

#fullpage-wrapper body {
	font-family: 'Open Sans', sans-serif;
}

#fullpage-wrapper h1 {
	color: #5a5a5a;
	font-weight: 700;
	font-size: 3.6vmin;
	margin-bottom: 1em;
}

#fullpage-wrapper h2 {
	color: #d90202;
	font-weight: 700;
	font-size: 1.85vw;
	margin-bottom: 25px;
}

#fullpage-wrapper h3 {
	color: #d90202;
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 15px;
}

#fullpage-header header {
	position: fixed;
	z-index: 9;
	width: 100%;
}

#fullpage-header .header-container {
	background: #000;
	color: #fff;
	z-index: 9;
	width: 100%;
	padding-top: 15px;
	padding-bottom: 14px;
}

#fullpage-header .header-logo {
	position: absolute;
	top: 80px;
	right: 50px;
	display: block;
	color: #000;
}

.header-logo.white {
	color: #fff;
}

#fullpage-header .ewe-logo {
	width: 130px;
	fill: currentColor;
}

#fullpage-header .header-logo-m {
	display: block;
	color: #000;
}

#fullpage-header .ewe-logo-m {
	height: 30px;
	fill: #fff;
	margin-right: 25px;
	margin-top: -5px;
	margin-bottom: -9px;
	float: right;
}

#fullpage-header .header-menu {
	list-style: none;
	display: block;
	margin: 0;
	margin-right: 50px;
	float: right;
}

#fullpage-header .header-menu li {
	display: block;
	float: left;
	text-transform: uppercase;
	margin-left: 30px;
	font-size: 15px;
	font-weight: 300;
}

#fullpage-header .header-menu li:last-of-type {
	font-weight: 700;
}

#fullpage-header .header-menu li>a {
	font-size: 14px;
	text-transform: uppercase;
	position: relative;
	display: block;
	/* line-height: 1; */
	color: #c3c3c3;
	text-decoration: none;
}

.header-menu li>a:hover {
	color: #67cd11;
}

.page-holder {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.page-alignment-holder {
	overflow: hidden;
	height: 100%;
	position: relative;
	height: calc(100% - 50px);
	top: 50px;
	/* height: 100%; */
}

.brands-alignment-holder {
	overflow: hidden;
	height: 100%;
	position: relative;
	height: calc(100% - 50px);
	top: 50px;
	/* height: 100%; */
	padding: 20px 40px;
}

/* .map-map img {
	width: 600px;
} */
.alignment-holder-top {}

.alignment-holder-bottom {}

.sato-page {
	background-color: #ebebeb;
	background-image: url(https://resource.ewe.rs/media/intro/shadow.png);
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: 50%;
}

.map-page {
	background: #ebebeb;
}

.map-page::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	background: #bc0d36;
	transform: skewX(160deg);
	left: 50%;
}

.portfolio-page {
	background: #ebebeb;
}

.numbers-page {
	background: #ebebeb;
}





.intro-page {
	opacity: 0;
}

.intro-page-fade-in {
	-webkit-transition: opacity 1000ms ease-in;
	-moz-transition: opacity 1000ms ease-in;
	-o-transition: opacity 1000ms ease-in;
	transition: opacity 1000ms ease-in;
	opacity: 1;
}

.background-mask {
	display: block;
	position: relative;
	height: 100%;
	background-image: url(https://resource.ewe.rs/media/intro/background-mask.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
}

.background-city {
	position: absolute;
	display: block;
	top: 34%;
	height: 32%;
	width: 70%;
	background-image: url(https://resource.ewe.rs/media/intro/city.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.background-city-ani {
	animation-name: city;
	animation-duration: 7000ms;
	animation-fill-mode: forwards;
	animation-delay: 3500ms;
	animation-iteration-count: infinite;
}

@keyframes city {
	0 {
		transform: translateX(0);
	}

	50% {
		transform: translateX(30vw);
	}

	50% {
		transform: translateX(30vw);
	}

	100% {
		transform: translateX(0);
	}
}

.home-text {
	position: absolute;
	display: block;
	overflow: hidden;
	font-size: 2.2vw;
	font-weight: 300;
	white-space: nowrap;
	text-transform: uppercase;
	top: 70%;
	left: 0;
	width: 100%;
	letter-spacing: 3px;
	text-align: center;
}

.home-text b {
	font-weight: 600;
}

.home-text-cover {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	content: '';
	background: #fff;
}

.home-text-ani {
	animation-name: typing;
	animation-duration: 5000ms;
	animation-fill-mode: forwards;
	animation-delay: 1000ms;
}

@keyframes typing {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(100%);
	}
}

.home-scroll {
	position: absolute;
	width: 50px;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	cursor: pointer;
}

.home-scroll-text {
	text-align: center;
	font-size: 10px;
	color: #666;
	margin-bottom: 10px;
}

.home-scroll-line {
	position: relative;
	width: 1px;
	height: 40px;
	left: 50%;
	background-color: #999;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.home-scroll-line::after {
	content: '';
	display: block;
	width: 3px;
	height: 100%;
	position: absolute;
	left: -1px;
	bottom: 0;
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: left bottom;
	transform-origin: left bottom;
	background-color: #666;
	transition: opacity .6s cubic-bezier(.52, .08, .18, 1), -webkit-transform .6s cubic-bezier(.52, .08, .18, 1);
	transition: transform .6s cubic-bezier(.52, .08, .18, 1), opacity .6s cubic-bezier(.52, .08, .18, 1);
	transition: transform .6s cubic-bezier(.52, .08, .18, 1), opacity .6s cubic-bezier(.52, .08, .18, 1), -webkit-transform .6s cubic-bezier(.52, .08, .18, 1);
	opacity: 0;
}

.home-scroll:hover .home-scroll-line::after {
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	opacity: 1;
}

.sato-page-cover {
	position: absolute;
	width: 230%;
	height: 100%;
	top: 0;
	transform: skewX(30deg) translateX(-65vw);
}

.cover-red {
	background-color: #bc0d36;
}

.cover-grey {
	background-color: #e7e7e7;
}

.cover-white {
	background-color: #fff;
}

.cover-red-ani {
	animation-name: sato-cover;
	animation-duration: 1200ms;
	animation-fill-mode: forwards;
}

.cover-grey-ani {
	animation-name: sato-cover;
	animation-duration: 1200ms;
	animation-fill-mode: forwards;
	animation-delay: 400ms;
}

.cover-white-ani {
	animation-name: sato-cover;
	animation-duration: 1200ms;
	animation-fill-mode: forwards;
	animation-delay: 500ms;
}

@keyframes sato-cover {
	from {
		transform: skewX(30deg) translateX(-65vw);
	}

	to {
		transform: skewX(30deg) translateX(160vw);
	}
}

.sato-holder {
	position: absolute;
	bottom: 0;
	height: 85%;
	width: 45%;
	background-image: url(https://resource.ewe.rs/media/intro/roger.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center bottom;
	transform: translateX(-45vw);
}

.sato-holder-ani {
	animation-name: sato-holder;
	animation-duration: 750ms;
	animation-fill-mode: forwards;
	animation-delay: 750ms;
}

@keyframes sato-holder {
	from {
		transform: translateX(-45vw);
	}

	to {
		transform: none;
	}
}

.sato-text-holder {
	position: absolute;
	top: 50%;
	left: 9%;
	width: 45%;
	transform: translateY(-50%) translateX(36vw);
	opacity: 0;
}

.sato-text-holder h2 {
	color: #5a5a5a !important;
	font-weight: 700 !important;
	font-size: 3.6vmin !important;
	margin-bottom: 1em !important;
}

.sato-text-holder-ani {
	animation-name: sato-text;
	animation-duration: 750ms;
	animation-delay: 1250ms;
	animation-fill-mode: forwards;
}

@keyframes sato-text {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.sato-text-holder p {
	font-size: 1.35vw;
	line-height: 1.4;
	font-weight: 300;
}

.partner-signature {
	margin-top: 20px;
	line-height: 1;
	float: right;
}

.partner-signature div:first-of-type {
	font-family: abuget, times;
	font-size: 8vmin;
	text-align: center;
}

.partner-signature div:last-of-type {
	font-size: 1.4vmin;
	text-align: center;
}

.signature {
	margin-top: 10px;
	line-height: 1;
	float: right;
}

.signature div:first-of-type {
	font-size: 1.4vmin;
	line-height: 5vmin;
	float: left;
}

.signature div:last-of-type {
	font-family: abuget, times;
	font-size: 6vmin;
	margin-left: 20px;
	float: right;
}

.page-3 {
	background: #fafafa;
	height: 100%;
	width: 100%;
	padding-top: 4%;
	padding-bottom: 4%;
	padding-left: 4%;
	padding-right: 4%;
}

.about-item {
	position: relative;
	height: 33.33333333%;
	height: 25%;
	width: calc(100% - 30px);
	margin-left: 15px;
	margin-right: 15px;
	padding: 20px;
	float: left;
	/*	border-bottom: 1px solid #959595; */
	transform: none;
	box-shadow: 1px 2px 10px transparent;
	transition: transform 250ms, box-shadow 250ms;
}

.about-item:hover {
	transform: scale(1.01);
	box-shadow: 1px 2px 10px #ccc;
}

.about-item-button-holder {
	position: relative;
	height: 33.33333333%;
	width: calc(100% - 30px);
	margin-left: 15px;
	margin-right: 15px;
	padding: 20px;
	float: left;
	transform: none;
}

.test .about-item {
	position: relative;
	height: 33.33333333%;
	width: 100%;
	float: left;
	/*	border-bottom: 1px solid #959595; */
	transform: none;
	box-shadow: 1px 2px 10px transparent;
	transition: transform 250ms, box-shadow 250ms;
}

.test .about-item:hover {
	transform: translateX(15px);
	box-shadow: 1px 2px 10px #ccc;
}

.about-logo-holder {
	position: relative;
	float: left;
	width: 10%;
	height: 100%;
}

.about-logo-holder div {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	padding: 10px;
}

.about-text-holder {
	float: left;
	width: 80%;
	position: absolute;
	left: 15%;
	top: 50%;
	transform: translateY(-50%);
}

.about-text-holder p {
	font-size: 15px;
	margin-top: auto;
	font-weight: inherit;
	letter-spacing: inherit;
}





.map-text {
	position: absolute;
	width: 40%;
	top: 50%;
	left: 5%;
	transform: translateY(-50%);
	/*	transform: translate(-40vw, -50%);	*/
	opacity: 0;
}

.map-text-ani {
	animation-name: map-text;
	animation-duration: 750ms;
	animation-delay: 750ms;
	animation-fill-mode: forwards;
}

@keyframes map-text {

	/*	from {transform: translate(-40vw, -50%);}
	to {transform: translate(10vw, -50%);}	*/
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.map-text p {
	font-size: 2.2vmin;
	line-height: 1.4;
	font-weight: 300;
	margin-bottom: 1em;
}

.map-text img {
	max-width: 85%;
}

.map-map {
	position: absolute;
	width: 28%;
	left: 60%;
	top: 50%;
	transform: translateY(-50%);
}

.map-gif::after {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: silver;
	top: 0;
	left: 0;
	background-image: url(https://resource.ewe.rs/media/intro/mapa-tackice7.gif);
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

.map-map-ani {
	animation-name: map-map;
	animation-duration: 700ms;
	animation-delay: 2100ms;
	animation-fill-mode: forwards;
}

@keyframes map-map {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.portfolio-circle-holder {
	position: absolute;
	width: 35%;
	left: 12%;
	top: 50%;
	transform: translateY(-50%);
}

.portfolio-title {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 3.8vmin;
	transform: translate(-50%, -50%);
	/* opacity: 0; */
	-webkit-transition: opacity 750ms ease;
	-moz-transition: opacity 750ms ease;
	-o-transition: opacity 750ms ease;
	transition: opacity 750ms ease;
}

.visible {
	opacity: 1;
}

.portfolio-brands-holder {
	position: absolute;
	width: 30%;
	left: 55%;
	height: 100%;
}

.portfolio-brands-holder img {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.logos {
	opacity: 0;
	-webkit-transition: opacity 750ms ease;
	-moz-transition: opacity 750ms ease;
	-o-transition: opacity 750ms ease;
	transition: opacity 750ms ease;
}

.opacity1 {
	opacity: 1;
}

.numbers-text-holder {
	position: absolute;
	width: 50%;
	height: 100%;
}

.numbers-text-holder-m {
	position: absolute;
	width: 100%;
	height: 50%;
}

.numbers-text-m {
	position: absolute;
	width: 80%;
	top: 50%;
	transform: translateY(-50%) translateX(-80vw);
}

.numbers-text-m p {
	text-align: right;
	font-size: 20px;
}

.numbers-text-holder::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100vw;
	background: #ebebeb;
	transform: skewX(160deg);
	left: -100%;
}

.numbers-text {
	position: absolute;
	width: 70%;
	top: 50%;
	left: 10%;
	transform: translateY(-50%);
	opacity: 0;
	/*    transform: translateY(-50%) translateX(-60vw);	*/
}

.numbers-text-ani {
	animation-name: numbers-text;
	animation-delay: 750ms;
	animation-duration: 750ms;
	animation-fill-mode: forwards;
}

@keyframes numbers-text {

	/*	from {transform: translateY(-50%) translateX(-60vw);}
	to {transform: translateY(-50%) translateX(10vw);}	*/
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.numbers-text p {
	font-size: 1.1vw;
	line-height: 1.4;
	font-weight: 300;
	margin-bottom: 25px;
}

.numbers-numbers-holder {
	position: absolute;
	height: 100%;
	width: 65%;
	background-image: url(https://resource.ewe.rs/media/intro/numbers-background.jpg);
	background-position: right center;
	top: 0;
	right: 0;
}

.numbers-numbers {
	font-size: 40px;
	color: #fff;
	position: absolute;
	top: 50%;
	left: 60%;
	transform: translate(-50%, -50%);
	text-align: center;
	opacity: .8;
}

.numbers-holder-mask {
	font-size: 180px;
	font-weight: 700;
	line-height: 200px;
	height: 200px;
	overflow: hidden;
}

.numbers-holder-content {
	white-space: nowrap;
	transform: translateY(200px);
}

.numbers-holder-content-ani {
	animation-name: brojici;
	animation-duration: 3000ms;
	animation-delay: 500ms;
	animation-fill-mode: forwards;
}

@keyframes brojici {
	0% {
		transform: translateY(200px);
	}

	10% {
		transform: translateY(0px);
	}

	45% {
		transform: translateY(0px);
	}

	55% {
		transform: translateY(-200px);
	}

	90% {
		transform: translateY(-200px);
	}

	100% {
		transform: translateY(-400px);
	}
}

.text-holder-mask {
	font-size: 30px;
	font-weight: 300;
	line-height: 40px;
	height: 40px;
	overflow: hidden;
}

.text-holder-content {
	white-space: nowrap;
	transform: translateY(40px);
}

.text-holder-content-ani {
	animation-name: slovca;
	animation-duration: 3000ms;
	animation-delay: 1000ms;
	animation-fill-mode: forwards;
}

@keyframes slovca {
	0% {
		transform: translateY(40px);
	}

	10% {
		transform: translateY(0px);
	}

	45% {
		transform: translateY(0px);
	}

	55% {
		transform: translateY(-40px);
	}

	90% {
		transform: translateY(-40px);
	}

	100% {
		transform: translateY(-80px);
	}
}


.contact-page {
	background: #ebebeb;
}

.contact-info-holder {
	position: absolute;
	width: 30%;
	top: 50%;
	left: 5%;
	transform: translateY(-50%);
}

.contact-info-holder p {
	font-size: 1.2vw;
	font-weight: 700;
	color: #5a5a5a;
	padding-bottom: 30px;
	border-bottom: 2px solid #9d9d9d;
}

.contact-info-item {
	float: left;
	width: 100%;
	font-size: 1vw;
	background: #fff;
	margin-top: 40px;
}

.contact-info-item:last-of-type span {
	/* display: inline-block; */
	/* width: 100%; */
	/* text-align: center; */
}

.contact-info-left {
	background: #af0c32;
	color: #fff;
	float: left;
	width: 58%;
	padding: 20px;
	font-weight: 600;
}

.contact-info-right {
	float: left;
	width: 42%;
	padding: 20px;
}

.contact-info-right span {
	display: inline-block;
	width: 100%;
	text-align: center;
	font-weight: 300;
}


.contact-form-holder {
	position: absolute;
	width: 30%;
	top: 50%;
	left: 40%;
	transform: translateY(-50%);
	padding-left: 4%;
	border-left: 1px solid #898989;
}

.contact-form-holder p {
	font-weight: 300;
	color: #333;
	font-size: 16px;
}


.contact-map-holder {
	position: absolute;
	width: 33%;
	height: 100%;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.contact-map-cover {
	position: absolute;
	background-color: #ebebeb;
	height: 100%;
	width: 20%;
	transform: skewX(163deg);
	right: 25%;
	border-right: 6px solid #af0c32;

}


/*---FULL PAGE MOD START---*/
#fp-nav {
	z-index: 1;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
	height: 6px;
	width: 6px;
	background: #cbcbcb;
	margin: -3px 0 0 -3px;
}

/*---FULL PAGE MOD END---*/

/*---SVG PORTFOLIO START---*/

.element {
	cursor: pointer;
	fill: #afb1b4;
	fill-opacity: 1;
	fill-rule: nonzero;
	stroke: none;
	-webkit-transition: fill 500ms ease;
	-moz-transition: fill 500ms ease;
	-o-transition: fill 500ms ease;
	transition: fill 500ms ease;
}

.element:hover {
	fill: #ed1c24;
}

.mobile:hover .mob {
	color: #bc0d36;
}

/*---SVG PORTFOLIO END---*/

.btn-sato {
	color: #fff;
	background-color: #dc3231;
	border-color: #dc3231;
	border-radius: 0;
	font-size: 16px;
	background-image: url(https://resource.ewe.rs/media/intro/right-arrow.png);
	background-repeat: no-repeat;
	background-position: 92% 50%;
	padding: 8px 70px 8px 20px;
	margin-top: 30px;
}

.btn-sato:hover {
	color: #fff;
	background-color: #bc0d36;
	border-color: #bc0d36;
}

.btn-sato:active,
.btn-sato.active {
	background-image: url(https://resource.ewe.rs/media/intro/right-arrow.png);
	outline: 0;
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}




#fullpage-wrapper .form-control {
	display: block;
	width: 100%;
	height: 38px;
	padding: 6px 12px;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.42857143;
	color: #000000;
	background-color: #dddddd;
	/* background-image: none; */
	border: 1px solid #d7d7d7;
	border-radius: 6px;
	-webkit-box-shadow: inset 0 1px 1px transparent;
	box-shadow: inset 0 1px 1px transparent;
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

	margin-bottom: 10px;
}

#fullpage-wrapper textarea.form-control {
	height: 124px;
}

#fullpage-wrapper .form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #2a2a2a;
}

#fullpage-wrapper .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #2a2a2a;
}

#fullpage-wrapper .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #2a2a2a;
}

#fullpage-wrapper .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #2a2a2a;
}

#fullpage-wrapper .btn-form {
	color: #fff;
	background-color: #898989;
	border-color: #898989;
	font-size: 16px;
	font-weight: 300;
	border-radius: 6px;
	padding: 5px 20px;
}

#fullpage-wrapper .btn-form:hover {
	color: #fff;
	background-color: #757575;
	border-color: #757575;
}

#fullpage-wrapper .button-next {
	width: 44px;
	height: 44px;
	padding: 10px;
	border-radius: 50%;
	position: absolute;
	bottom: 40px;
	right: 50px;
	cursor: pointer;
	border: 2px solid;
	border-color: #dc0032;
	color: #dc0032;
	opacity: 1;
	transition: opacity 1s;
}

.button-next.white {
	border-color: #fff;
	color: #fff;
}

.last-slide {
	opacity: 0;
	pointer-events: none;
}

.button-next svg {
	fill: currentColor;
	;
}

.page-top {
	position: absolute;
	bottom: 30px;
	left: 50%;
	cursor: pointer;
	transform: translateX(-50%);
}

.page-top-text {
	text-align: center;
	font-size: 10px;
	color: #000;
	margin-top: 8px;
}

.page-top-arrow {
	height: 16px;
	width: 60px;
	color: #dc0032;
	transition: transform .45s cubic-bezier(.37, .16, .12, 1), -webkit-transform .45s cubic-bezier(.37, .16, .12, 1);
}

.page-top-arrow svg {
	fill: currentColor;
}

.page-top:hover .page-top-arrow {
	transform: translateY(-6px);
}





.up-in {
	transform: translate(25%, -100%) scale(.75);
}

.up-in-ani {
	animation-name: up-in;
	animation-duration: 1200ms;
	animation-delay: 0ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}

@keyframes up-in {
	0% {
		transform: translate(25%, -100%) scale(.75);
	}

	60% {
		transform: translate(0, 0) scale(.75);
	}

	100% {
		transform: translate(0, 0) scale(1);
	}
}

.up-out {
	transform: translate(0, 0) scale(1);
}

.up-out-ani {
	animation-name: up-out;
	animation-duration: 1200ms;
	animation-delay: 0ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}

@keyframes up-out {
	0% {
		transform: translate(0, 0) scale(1);
	}

	25% {
		transform: translate(-25%, 0) scale(.75);
	}

	100% {
		transform: translate(-25%, 100%) scale(.75);
	}
}

.down-in {
	transform: translate(-25%, 100%) scale(.75);
}

.down-in-ani {
	animation-name: down-in;
	animation-duration: 1200ms;
	animation-delay: 0ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}

@keyframes down-in {
	0% {
		transform: translate(-25%, 100%) scale(.75);
	}

	60% {
		transform: translate(0, 0) scale(.75);
	}

	100% {
		transform: translate(0, 0) scale(1);
	}
}

.down-out {
	transform: translate(0, 0) scale(1);
}

.down-out-ani {
	animation-name: down-out;
	animation-duration: 1200ms;
	animation-delay: 0ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}

@keyframes down-out {
	0% {
		transform: translate(0, 0) scale(1);
	}

	25% {
		transform: translate(25%, 0) scale(.75);
	}

	100% {
		transform: translate(25%, 100%) scale(.75);
	}
}


/*---MOBILE MENU START---*/
.hamburger-menu {
	height: 20px;
	width: 20px;
	margin-left: 20px;
	float: left;
	background-position: center;
	background-size: 100%;
	background-color: transparent;
	border: none;
	background-image: url(https://resource.ewe.rs/media/intro/menu.png);
}

.hamburger-menu:focus {
	outline: none;
}

.cross-menu {
	background-image: url(https://resource.ewe.rs/media/intro/cross.png);
}

.mobile-menu {
	display: block;
	position: absolute;
	background: red;

	display: block;
	position: fixed;
	width: 100%;
	top: 50px;
	left: 0px;
	height: calc(100vh - 50px);
	background: #fff;
	z-index: 199;
	padding: 20px;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
}

.mobile-menu ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.mobile-menu ul>li>a {
	display: inline-block;
	color: #666;
	width: 100%;
	line-height: 1.42857143;
	vertical-align: middle;
	padding: 10px 20px;
	border-bottom: 1px dotted #ccc;
}

/*---MOBILE MENU END---*/

.button-holder {
	position: absolute;
	bottom: 20px;
	right: 40px;
}

.section {
	background-color: #fff !important;
}

@media (max-width: 1531px) {
	.about-small-text-item {
		height: 34% !important;
	}

	.about-big-text-item {
		height: 23% !important;
	}

	.brands-alignment-holder .signature {
		margin-top: 0;
	}

	.section {
		background-color: #fff !important;
	}
}

@media (max-width: 1189px) {
	.about-small-text-item {
		height: 34% !important;
	}

	.section {
		background-color: #fff !important;
	}
}

@media (max-width: 1069px) {
	.about-small-text-item {
		height: 35% !important;
	}

	.brands-alignment-holder .about-text-holder p {
		margin-bottom: 0;
	}

	.section {
		background-color: #fff !important;
	}
}

@media (min-width: 991px) {
	.map-text .text-center {
		text-align: unset !important;
	}
}

@media (max-width: 991px) {
	.section {
		background-color: #fff !important;
	}

	.background-mask {
		display: block;
		position: relative;
		height: 100%;
		background-image: url(https://resource.ewe.rs/media/intro/background-mask-mobile.png);
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
	}

	.background-city {
		position: absolute;
		display: block;
		top: 40%;
		height: 20%;
		width: 80%;
		background-image: url(https://resource.ewe.rs/media/intro/city.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	@keyframes city {
		0 {
			transform: translateX(0);
		}

		50% {
			transform: translateX(20vw);
		}

		50% {
			transform: translateX(20vw);
		}

		100% {
			transform: translateX(0);
		}
	}

	.numbers-numbers-holder {
		position: absolute;
		height: 100%;
		width: 100%;
		background-image: url(https://resource.ewe.rs/media/intro/numbers-background.jpg);
		background-position: right center;
		top: 0;
		left: 0;
	}

	.numbers-text-holder {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	.numbers-text {
		position: absolute;
		width: 90%;
		top: 50%;
		left: 5%;
		transform: translateY(-50%);
		opacity: 0;
	}

	.numbers-numbers {
		font-size: 40px;
		color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		opacity: .8;
	}

	/* .numbers-text p {
		font-size: 2.2vmin;
		line-height: 1.4;
		font-weight: 300;
		margin-bottom: 1em;
	} */
	.sato-holder {
		position: absolute;
		bottom: 0;
		height: 45%;
		width: 100%;
		background-image: url(https://resource.ewe.rs/media/intro/roger.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center bottom;
		transform: translateX(-45vw);
	}

	/* .sato-text-holder {
	    position: absolute;
		height: 50%;
	    width: 80%;
		top: 30px;
		left: 10%;
	    transform: translateY(-50%) translateX(45vw);
		transform: none;
	    opacity: 0;
	}
	.sato-text-holder p {
	    font-size: 3.5vmin;
	    line-height: 1.4;
	    font-weight: 300;
	} */
	/* .portfolio-circle-holder {
	    position: absolute;
	    width: 80%;
	    height: 35%;
	    left: 10%;
	    top: 5%;
	    transform: none;
	} */
	/* .portfolio-brands-holder {
	    position: absolute;
	    width: 70%;
	    left: 25%;
	    height: 60%;
		top: 40%;
	} */

	.img-responsive {
		max-height: 100%;
	}

	.map-page:before {
		background: transparent;
	}

	.map-page .alignment-holder-bottom {
		background-color: #bc0d36;
	}

	.map-text {
		position: absolute;
		width: 90%;
		top: 50%;
		left: 5%;
		transform: translateY(-50%);
		/* transform: translate(-40vw, -50%); */
		opacity: 0;
	}

	.map-text img {
		max-width: 100%;
	}

	.map-map {
		position: absolute;
		width: 100%;
		height: 90%;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}


	/* .alignment-holder-top {
		position: absolute;
		height: 60%;
		width: 100%;
		top: 0;
		left: 0;
	}
	.alignment-holder-bottom {
		position: absolute;
		height: 40%;
		width: 100%;
		bottom: 0;
		left: 0;
	} */
	.contact-info-holder {
		position: absolute;
		width: 90%;
		top: 45%;
		left: 5%;
		transform: translateY(-50%);
	}

	.contact-form-holder {
		position: absolute;
		width: 90%;
		top: 35%;
		left: 5%;
		transform: translateY(-50%);
		padding-left: 0;
		border: none;
	}

	.contact-map-holder {
		display: none;
	}

	.contact-map-cover {
		display: none;
	}

	#fullpage-wrapper .form-control {
		display: block;
		width: 100%;
		height: initial;
		padding: 4px 10px;
		font-size: 2vmax;
		font-weight: 300;
		line-height: 1.42857143;
		color: rgb(0, 0, 0);
		background-color: #d7d7d7;
		background-image: none;
		border: 1px solid #d7d7d7;
		border-radius: 6px;
		-webkit-box-shadow: inset 0 1px 1px transparent;
		box-shadow: inset 0 1px 1px transparent;
		-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
		-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		margin-bottom: 10px;
	}

	#fullpage-wrapper textarea.form-control {
		height: 48px;
	}

	.btn-form {
		color: #fff;
		background-color: #898989;
		border-color: #898989;
		font-size: 2vmax;
		font-weight: 300;
		border-radius: 6px;
		padding: 2px 16px 4px;
	}

	.contact-info-item {
		float: left;
		width: 100%;
		font-size: 3vmin;
		background: #fff;
		margin-top: 12px;
	}

	.contact-info-left,
	.contact-info-right {
		padding: 10px;
	}

	.contact-info-holder p {
		font-size: 2vmax;
		padding-bottom: 4px;
	}

	.contact-form-holder p {
		font-weight: 300;
		color: #333;
		font-size: 3vmin;
		margin-bottom: 4px;
	}

	.partner-signature div:first-of-type {
		font-size: 10vmin;
	}

	.partner-signature div:last-of-type {
		font-size: 3vmin;
	}

	.btn-sato {
		color: #fff;
		background-color: #dc3231;
		border-color: #dc3231;
		border-radius: 0;
		font-size: 17px;
		background-image: url(https://resource.ewe.rs/media/intro/right-arrow.png);
		background-repeat: no-repeat;
		background-position: 92% 50%;
		padding: 8px 70px 8px 20px;
		margin-top: 20px;
	}

	.button-next {
		bottom: 25px;
		right: 25px;
	}

	.about-text-holder p {
		font-size: 13px;
	}

	.brands-alignment-holder {
		padding: 10px;
	}

	.about-small-text-item {
		height: 32% !important;
	}

	.about-big-text-item {
		height: 23%;
	}

	.about-item {
		position: relative;
		height: 33.33333333%;
		height: 25%;
		width: 100%;
		padding: 0;
		margin: 0;
		float: left;
		transform: none;
		box-shadow: 1px 2px 10px transparent;
		transition: transform 250ms, box-shadow 250ms;
	}

	.about-logo-holder {
		position: relative;
		float: left;
		width: 15%;
		left: 2%;
		height: 100%;
	}

	.about-text-holder {
		float: left;
		width: 78%;
		position: absolute;
		left: 20%;
		top: 50%;
		transform: translateY(-50%);
	}

	.signature {
		margin-top: 0;
		line-height: 1;
		text-align: center;
		float: none;
	}

	.signature div:first-of-type {
		font-size: 13px;
		line-height: 1.5;
		float: none;
	}

	.signature div:last-of-type {
		font-family: abuget, times;
		font-size: 40px;
		margin-left: 0;
		float: none;
	}

	.home-text {
		top: 62%;
	}

	.about-logo-holder div {
		padding: 0;
	}
}

@media (max-width: 784px) {
	.about-small-text-item {
		height: 33% !important;
	}

	.signature div:last-of-type {
		font-size: 30px;
	}

	.section {
		background-color: #fff !important;
	}
}

@media (max-width: 991px) and (min-width: 451px) {
	.numbers-text p {
		font-size: 2.2vmin;
		line-height: 1.4;
		font-weight: 300;
		margin-bottom: 1em;
	}

	.portfolio-circle-holder {
		position: absolute;
		width: 80%;
		height: 35%;
		left: 10%;
		top: 5%;
		transform: none;
	}

	.portfolio-brands-holder {
		position: absolute;
		width: 70%;
		left: 25%;
		height: 60%;
		top: 40%;
	}

	.sato-text-holder {
		position: absolute;
		height: 50%;
		width: 80%;
		top: 30px;
		left: 10%;
		transform: translateY(-50%) translateX(45vw);
		transform: none;
		opacity: 0;
	}

	.sato-text-holder p {
		font-size: 3.5vmin;
		line-height: 1.4;
		font-weight: 300;
	}

	.alignment-holder-top {
		position: relative;
		height: 60%;
		width: 100%;
		top: 0;
		left: 0;
	}

	.alignment-holder-bottom {
		position: absolute;
		height: 40%;
		width: 100%;
		bottom: 0;
		left: 0;
	}
}

@media (max-width: 375px) {
	.contact-form-holder {
		top: 35%!important;
	}
	.contact-info-data {
		display: none;
	}
}
@media (max-width: 450px) {
	.section {
		background-color: #fff !important;
	}

	.about-small-text-item {
		height: 35% !important;
	}

	.about-big-text-item {
		height: 21%;
	}

	.about-text-holder p {
		font-size: 8px !important;
	}

	.signature div:last-of-type,
	.beko-signature {
		font-size: 22px !important;
	}

	.signature div:first-of-type {
		font-size: 9px;
	}

	.signature {
		margin-top: 5px !important;
	}

	#fullpage-wrapper h1 {
		color: #5a5a5a;
		font-weight: 700;
		font-size: 18px;
		margin-bottom: 1em;
	}

	.numbers-text {
		top: 53%;
	}

	.numbers-text p {
		font-size: 13px;
		line-height: 1.3;
		font-weight: 300;
		margin-bottom: 1em;
	}

	.numbers-holder-mask {
		font-size: 100px;
	}

	.text-holder-mask {
		font-size: 25px;
	}

	.map-text {
		top: 63%;
	}

	.map-text p {
		font-size: 13px;
		line-height: 1.3;
	}

	.portfolio-circle-holder {
		position: absolute;
		width: 80%;
		height: 35%;
		left: 10%;
		top: 5%;
		transform: none;
	}

	.portfolio-brands-holder {
		position: absolute;
		width: 70%;
		left: 15%;
		height: 60%;
		top: 40%;
	}

	.sato-text-holder {
		position: absolute;
		height: 50%;
		width: 80%;
		top: 50px;
		left: 10%;
		transform: translateY(-50%) translateX(45vw);
		transform: none;
		opacity: 0;
	}

	.sato-text-holder p {
		font-size: 15px;
		line-height: 1.3;
		font-weight: 300;
	}

	.alignment-holder-top {
		position: relative;
		height: 50%;
		width: 100%;
		top: 0;
		left: 0;
	}

	.alignment-holder-bottom {
		position: absolute;
		height: 50%;
		width: 100%;
		bottom: 0;
		left: 0;
	}

	.map-holder-img {
		height: 40%;
	}

	.btn-sato {
		font-size: 12px;
	}

	.partner-signature div:first-of-type {
		font-size: 50px;
	}

	.partner-signature div:last-of-type {
		font-size: 12px;
	}

	.contact-form-holder p {
		font-size: 13px;
	}

	.contact-info-holder {
		position: relative;
		width: 90%;
		margin-top: 70px;
		left: 5%;
		/* transform: translateY(-50%); */
	}

	.contact-form-holder {
		position: relative;
		width: 90%;
		top: 25%;
		left: 5%;
		/* transform: translateY(-50%); */
		padding-left: 0;
		border: none;
	}

}

@media (min-width: 768px) {
	.header-container {
		padding-top: 18px;
		padding-bottom: 18px;
	}
}

.cookie-holder-block {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, .85);
	color: #fff;
	padding-top: 35px;
	padding-bottom: 35px;
	z-index: 9;
	transform: translateY(100%);
	transition-duration: 1s;
}

.cookie-holder-block p {
	margin: 0;
	line-height: 24px;
}

.cookie-holder-block.show {
	transform: translateY(0);
}

.language-ewe {
	color: #67cd11;
	font-size: 14px;
	/* line-height: 1; */
	position: relative;
	bottom: 2px;
}

.language-ewe>a {
	font-weight: 300;
	padding: 0px;
	color: #c3c3c3;
	font-size: 14px;

}

.language-ewe>a:hover {
	color: #67cd11;
	text-decoration: underline;

}

.mobile-menu ul>li>.language-ewe {
	display: inline-block;
	color: #666;
	width: 100%;
	line-height: 1.42857143;
	vertical-align: middle;
	padding: 10px 20px;
	border-bottom: 1px dotted #ccc;

}

.mobile-menu ul>li>.language-ewe a:hover {
	color: #666;
}