@font-face {
    font-family: 'Axiforma';
    src: url('./fonts/axiforma/Axiforma-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('./fonts/axiforma/Axiforma-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

.main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background-color: #000;
    color: #5b5555;
    font-family: 'Axiforma';
    font-weight: bold;
}

.custom-container-fluid {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fffbed;
    justify-content: center;
    align-items: center;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-size: 50%;
    background-position: top left;
    background-repeat: no-repeat;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ewe-go-cart-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ewe-go-cart-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 100%;
    max-width: 100%; */

}

.ewe-go-cart-img-bottom {
    align-self: center;
    margin-top: auto;
    /* width: 100%; */
}

.ewe-go-cart-formula1-img {
    width: 65%;
}

.ewe-go-cart-formula2-img {
    margin-top: 10%;
    width: 100%;
}

.ranking-list p {
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
}

.ewe-go-carting-your-coins {
    text-align: -webkit-center;
}

.button-go-carting-your-coins {
    flex: 1;
    text-align: center;
    background: transparent;
    border-radius: 15px;
    border: 1px solid #DC0000;
    padding: 5px 0px;
    cursor: pointer;
    color: #111111;
    width: 500px;
    font-family: 'Axiforma';
}

.button-go-carting-your-coins:hover {
    border: 1px solid #111111;
    color: #DC0000;
}

.ranking-list {
    margin-top: 20px;
    margin-bottom: 40px;
}

.ranking-item {
    display: flex;
    margin-bottom: 5px;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
}

.ranking-number {
    font-size: 17px;
    font-weight: bold;
    color: #DC0000;
    margin-right: 10px;
    width: 30px;
    text-align: right;
    flex-shrink: 0;
}

.emphasize {
    background-color: #ff000012
}

/* .ranking-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #DC0000;
    display: inline-block;
    background: white;
    margin-right: 10px;
    margin-left: 10px;
    flex-shrink: 0;
} */

.ranking-alias {
    font-size: 16px;
    flex-grow: 1;
    padding: 5px;
}

.ranking-list-img-holder {
    display: flex;
    align-items: center;
}

.ewe-go-brzina1-holder {
    position: absolute;
    top: 20%;
    left: 10%;
}

.ewe-go-brzina2-holder {
    position: absolute;
    bottom: 10%;
    right: 5%;
}

.ewe-go-cart-img-top,
.ewe-go-cart-img-bottom {
    width: 100%;
}

.ewe-go-cart-formula1-img {
    margin-left: 20%;
    margin-top: 8%;
    margin-bottom: 2%;
}


@media(max-width: 500px) {
    .ewe-go-cart-formula1-img {
        display: none;
    }

    .ewe-go-cart-formula2-img {
        display: none;
    }

    .ewe-go-brzina2-img {
        display: none;
    }

    .ewe-go-brzina1-img {
        display: none;
    }

    .ewe-go-brzina2-holder,
    .ewe-go-brzina1-holder {
        display: none;
    }

    .ewe-go-cart-img {
        width: 75%;
    }

    .button-go-carting-your-coins {
        width: 300px;
    }

    .ewe-go-cart-img-top {
        margin-top: 65px;
    }

    .ewe-go-carting-your-coins {
        margin-bottom: 20px;
    }

    .ranking-list {
        margin-bottom: 20px;
    }

    .ewe-go-cart-img {
        margin-top: 10px;
    }
}

@media(min-width: 500px) and (max-width:649px) {
    .ewe-go-cart-formula1-img {
        display: none;
    }

    .ewe-go-cart-formula2-img {
        display: none;
    }

    .ewe-go-brzina2-img {
        display: none;
    }

    .ewe-go-brzina1-img {
        display: none;
    }

    .ewe-go-brzina2-holder,
    .ewe-go-brzina1-holder {
        display: none;
    }

    .ewe-go-cart-img {
        width: 75%;
    }

    .button-go-carting-your-coins {
        width: 300px;
    }

    .ewe-go-carting-your-coins {
        margin-bottom: 20px;
    }

    .ewe-go-cart-img-top {
        margin-top: 65px;
    }

}

@media(min-width: 650px) and (max-width:991px) {
    /* .ewe-go-cart-formula1-img {
        display: none;
    }

    .ewe-go-cart-formula2-img {
        display: none;
    } */

    .ewe-go-brzina2-img {
        display: none;
    }

    .ewe-go-cart-formula2-img {
        width: 90%;
    }

    .ewe-go-brzina1-img {
        display: none;
    }

    .ewe-go-brzina2-holder,
    .ewe-go-brzina1-holder {
        display: none;
    }

    .ewe-go-cart-img {
        width: 100%;
    }

    .button-go-carting-your-coins {
        width: 300px;
    }

    .ewe-go-cart-img-top {
        margin-top: 65px;
    }
}


@media(min-width: 992px) and (max-width:1023px) {

    /* .ewe-go-cart-formula1-img {
        display: none;
    }

    .ewe-go-cart-formula2-img {
        display: none;
    } */

    .ewe-go-cart-formula2-img {
        width: 90%;
    }

    /* .ewe-go-brzina2-img {
        display: none;
    }

    .ewe-go-brzina1-img {
        display: none;
    } */

    .ewe-go-brzina2-holder,
    .ewe-go-brzina1-holder {
        display: none;
    }

    .ewe-go-cart-img {
        width: 100%;
    }

    .button-go-carting-your-coins {
        width: 300px;
    }

    .ewe-go-cart-img-top {
        margin-top: 65px;
    }

}

@media(min-width: 1024px) and (max-width:1537px) {

    .ewe-go-cart-img {
        width: 70%;
    }
}

@media (min-width:1920px) {
    .ewe-go-cart-img {
        width: 68%;
    }
}


@media(min-width: 1537px) {
    .ewe-go-cart-img {
        width: 70%;
    }
}