@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  /* font-family: Roboto, sans-serif;   */
}

@font-face {
  font-family: 'Roboto';
  src: url(/static/media/Roboto-Regular.a8d6ac03.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoBlack';
  src: url(/static/media/Roboto-Black.2a428584.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Abuget';
  src: url(/static/media/Abuget.dba51afc.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeProLight';
  src: url(/static/media/SegoePro-Light.bcf121f0.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NetProximaNova';
  src: url(/static/media/FontsFree-Net-ProximaNova-Regular.2a9ce1ea.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NetAxiforma';
  src: url(/static/media/FontsFree-Net-Axiforma3woff2.a3b2db35.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sigmar One';
  src: url(/static/media/SigmarOne-Regular.4162062e.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #000;
}

.flex-05 {
  flex: 0.5 1;
}

.flex-1 {
  flex: 1 1;
}

.flex-2 {
  flex: 2 1;
}

.flex-3 {
  flex: 3 1;
}

.color-icon-green {
  color: green;
}

.color-icon-red {
  color: red;
}

.color-icon-orange {
  color: orange;
}

.td-row-80 {
  width: 80px;
}

.td-row-100 {
  width: 100px;
}

.td-row-120 {
  width: 120px;
}

.td-row-140 {
  width: 140px;
}

.td-row-160 {
  width: 160px;
}

.td-row-180 {
  width: 180px;
}

.td-row-200 {
  width: 200px;
}

.padding-left-10 {
  padding-left: 10px;
}

/* width */
.modal-root ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.modal-root ::-webkit-scrollbar-track {
  background: #646464;
  border-radius: 50px;
}

/* Handle */
.modal-root ::-webkit-scrollbar-thumb {
  background: #969c9c;
  border-radius: 50px;
}

/* Handle on hover */
.modal-root ::-webkit-scrollbar-thumb:hover {
  background: #969c9c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#content {
  width: 100%;
  padding: 0px;
  min-height: 100vh;
  transition: all 0.3s;
  display: flex;
  margin-left: 200px;
}

.wrapper {
  display: flex;
  align-items: stretch;
  /* display: grid;
  grid-template-columns: 0fr 0fr 1fr; */
}

.body-container-main {
  margin-top: 20px;
}

/* main manu sidebar */
/* #sidebar {
  min-width: 200px;
  max-width: 200px;
  background: #2d2d2f;
  color: #fff;
  transition: all 0.3s;
  height: 100vh;
  position: fixed;
  text-align: center;
} */
#sidebar {
  min-width: 200px;
  max-width: 200px;
  background: #2d2d2f;
  color: #fff;
  transition: all 0.3s;
  height: 100vh;
  text-align: center;
  float: left;
}

#sidebar.active {
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}

.customImageLogo {
  color: #fff;
  margin-top: 20px;
  height: 55px;
}

#sidebarCollapse {
  position: absolute;
  left: 10px;
  top: 400px;
}

.homepage-slider-special-action-image img {
  padding: 15px;
}

#sidebar-mobile {
  min-width: 200px;
  max-width: 200px;
  background: #2d2d2f;
  color: #fff;
  transition: all 0.3s;
  position: absolute;
  height: 100vh;
  left: -200px;
  z-index: 2;
}

#sidebar-mobile.active {
  min-width: 100vw;
  max-width: 100vw;
  text-align: center;
  left: 0;
}

.sidebar-active {
  margin-left: 80px !important;
}

.content-main {
  background-color: #f6f6f6;
  min-height: 100vh;
  width: 100%;
  margin-left: 270px;
}

.navbar {
  /* background: #fff !important; */
  padding: 0px 1rem !important;
}

.main-container-content {
  margin: 20px 30px 0px 30px;
}

.footer-sidebar-logout {
  position: absolute;
  bottom: 0px;
  background: #000;
  width: 100%;
  padding: 20px 0px;
  text-align: left;
  padding-left: 15px;
  font-size: 17px;
  color: #fff;
}

.active-sidebar-logout-btn {
  padding-left: 15px;
}

.footer-sidebar-logout span {
  vertical-align: top;
  font-size: 18px;
  margin-left: 5px;
}

.footer-sidebar-logout svg {
  font-size: 28px;
}

.footer-sidebar-logout:hover {
  background: #424242;
  cursor: pointer;
}

.active-sidebar-image-info {
  left: 10px;
}

/* category sidebar */

.navbar-category {
  min-width: 280px;
  max-width: 280px;
  background-color: #fff;
  float: left;
  height: 100vh;
  box-shadow: 0 2px 20px rgb(0 0 0 / 7%);
  /* position: fixed; */
  /* overflow-x: auto; */
}

.content-category-boxes {
  overflow-y: auto;
  height: calc(100vh - 232px);
}

.category-menu-item {
  cursor: pointer;
  list-style-type: none;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.brand-menu-item {
  cursor: pointer;
  list-style-type: none;
  padding: 5px 15px;
  font-size: 14px;
}

.search-brands-block-content .line-h5-filter {
  margin-left: 15px;
}

.search-categories-block-content .line-h5-filter {
  margin-left: 15px;
}

.subcategory-menu-item {
  cursor: pointer;
  list-style-type: none;
  padding: 10px 20px;
}

.icon-expand-menu-items {
  float: right;
}

.menu-items {
  margin-top: 20px;
  margin-left: 0px;
  padding-left: 0px;
  padding: 0px 0px;
}

.search-box {
  padding: 0px 25px;
  margin-top: 30px;
  position: relative;
}

.search-box input {
  border-radius: 20px;
  background: #e1e1e1;
  color: #b9b9bb;
}

.item-menu-category {
  font-size: 14px;
  /* padding: 7px 15px; */
  padding: 0px 0px;
}

.submenu-items {
  /* padding: 0px 20px 0px 30px; */
  padding-left: 0px;
  margin-top: 4px;
  background: #f6f6f6;
}

.submenu-items-virtual {
  margin-top: 0px;
}

.subsubmenu-items {
  padding-left: 60px;
  margin-top: 4px;
  background: #f6f6f6;
  list-style: circle;
}

.submenu-items a {
  color: #000;
}

.submenu-items li {
  padding: 6px 0px;
}

.category-main-no-sub {
  color: #000;
}

.category-type-item {
  display: inline-block;
  border-bottom: 3px solid #62c900;
  background: #e8e8e8;
  padding: 3px 30px;
  color: #a0a0a0;
  cursor: pointer;
  width: 50%;
  font-size: 15px;
}

.category-type-item.active {
  background: #62c900;
  color: #fff;
  padding-top: 8px;
  font-weight: 700;
}

.category-menu-item.active {
  background: #f6f6f6;
  color: #000;
  font-weight: bold;
  box-shadow: 0 4px 4px rgb(0 0 0 / 7%);
}

.brand-menu-item.active {
  background: #f6f6f6;
  color: #000;
  font-weight: bold;
  box-shadow: 0 4px 4px rgb(0 0 0 / 7%);
}

.brand-main-no-sub {
  color: #000;
}

.subcategory-menu-item.active {
  background: #f6f6f6;
  color: #000;
  box-shadow: 0 4px 4px rgb(0 0 0 / 7%);
}

.category-expanded-li {
  background: #62c900;
  color: #fff;
  font-size: 21px;
  padding: 1px 3px;
  border-radius: 2px;
}

/* catalog */
.catalog-item-name {
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 55px;
  text-align: left;
}

.catalog-item-name a {
  /* text-decoration: none; */
}

.catalog-item-name a {
  color: #000;
}

.catalog-item-name a:hover,
.catalog-item-block-name a:hover,
td.catalog-table-item-name a:hover {
  color: #337ab7;
}

.catalog-item-other {
  /* margin-top: 22px; */
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  height: 125px;
}

.catalog-item-main {
  border: 1px solid #ccc;
  background: #fff;
  text-align: center;
  padding: 18px 16px;
  margin-bottom: 30px;
  border-radius: 15px;
  min-height: 440px;
  /* width: 260px; */
  position: relative;
  transition: box-shadow .3s;
}

.catalog-item-main:hover {
  /* box-shadow: 0px 1px 10px -4px #000; */
  box-shadow: 4px 4px 12px 0px rgba(0, 6, 22, 0.1);
}

.catalog-block-list-item:hover {
  /* box-shadow: 0px 1px 10px -4px #000; */
  box-shadow: 4px 4px 12px 0px rgba(0, 6, 22, 0.1);
}

.table-catalog-list .pretty-custom-global {
  margin-left: 3px;
  /* width: 33%;
  min-width: 33%; */
}

.pretty-custom-global .p-on img {
  height: 12px;
}

.pretty-custom-global .p-off img {
  height: 12px;
}

.table.table-catalog-list tr:hover {
  box-shadow: 4px 4px 12px 0px rgba(0, 6, 22, 0.1);
}

.catalog-item-main {
  margin-bottom: 15px;
}

.catalog-item-ident-reserve {
  display: flex;
  justify-content: space-between;
}

.catalog-item-reserved-box {
  display: flex;
  justify-content: space-between;
}

.catalog-item-qty-price {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  /* margin-top: 10px; */
}

.finish-cart-icons img {
  width: 25px;
  margin-bottom: 3px;
  margin-left: -5px;
}

.small-cart-finish-icon img {
  width: 25px;
  margin-left: -4px;
  margin-right: 6px;
  margin-bottom: 5px;
}

.arrival-stock-item {
  color: #4d7bff;
  text-decoration: underline;
  font-weight: 500;
}

.catalog-item-buttons {
  display: flex;
  justify-content: space-between;
  /* margin-top: 33px; */
  margin-top: auto;
}

.catalog-item-buttons-version2 {
  /* margin-top: 44px; */
}

.catalog-qty-button {
  width: 50%;
  margin-right: 15px;
}

.navbar-brand {
  flex: 3 1;
}

.catalog-item-add-button {
  width: 100%;
  margin-left: 10px;
}

.old-price-item-catalog {
  color: #666;
}


.block-item-empty {
  width: 100%;
  height: 250px;
  background-color: #ccc;
  text-align: center;
  margin-top: 40px;
}

.block-item {
  width: 100%;
  /* height: 250px;
  background-color: #ccc;
  text-align: center;
  margin-bottom: 40px; */
}

.search-icon {
  position: absolute;
  top: 6px;
  right: 40px;
  color: #b9b9bb;
}

.category-sidebar-types {
  text-align: center;
  margin-top: 20px;
}

/* filteri */
.filters-body-catalog {
  padding: 15px 17px;
}

.filters-body-catalog h5 {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 0px;
  margin-bottom: 5px !important;
  cursor: pointer;
  display: flex;
}

.filter-title-holder {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1 1;
}

.line-h5-filter {
  height: 2px;
  background: #62c900;
  width: 70px;
  margin-bottom: 8px;
}

.more-filter-load {
  text-decoration: underline;
  color: #62c900;
  cursor: pointer;
  padding-left: 20px;
}

.more-filter-load:hover {
  color: #397500;
}

.filters-body-catalog .form-check {
  margin-bottom: 0px;
}

.filters-body-catalog label {
  font-size: 14px;
  cursor: pointer;
  width: 100%;
}

.sidebar-submenu-holder {
  padding: 0px 20px 0px 30px;
  display: flex;
  justify-content: space-between;
}

.total-count-filter {
  float: right;
}

li.category-menu-item:hover {
  background: #e1e1e1;
  border-left: 5px solid #62c900;
}

li.brand-menu-item:hover {
  background: #e1e1e1;
  border-left: 5px solid #62c900;
}

li.subcategory-menu-item:hover {
  background: #e1e1e1;
  border-left: 5px solid #62c900;
}

.subcategory-menu-item:hover .fa-chevron-right {
  color: #62c900;
}

.category-menu-item:hover .fa-chevron-right {
  color: #62c900;
}

.brand-menu-item:hover .fa-chevron-right {
  color: #62c900;
}

span.toggle-icon-filters-show {
  background: #62c900;
  padding: 4px 7px;
  font-size: 12px;
  color: #fff;
  border-radius: 3px;
  float: right;
  cursor: pointer;
}

.hide-filters {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.show-filters {
  opacity: 1;
  max-height: 1200px;
  visibility: visible;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.filter-hide-item {
  display: none;
}

.filter-show-item {
  display: block;
}

@media (max-width: 576px) {
  #content {
    margin-left: 0px;
  }

  .content-main {
    margin-left: 0px;
  }
}

@media (max-width: 1020px) {
  .container-content {
    margin-top: 50px;
  }
}

.wrapper-content {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.container-content {
  width: 100%;
  padding: 25px 30px;
}

.sidebar-content {
  width: 200px;
  flex: none;
  transition: all 0.3s;
}

.sidebar-content.active {
  width: 60px;
}

.sidebar-fixed-menu {
  position: fixed;
}

.collapsed-sidebar {
  min-width: 60px !important;
  max-width: 60px !important;
}

.collapsed-sidebar-block {
  width: 60px;
}

.category-content-sidebar {
  width: 280px;
  flex: none;
}

.category-sidebar-item-radio-name {
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
}

.category-sidebar-item-radio-buttons {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.logo-content-sidebar {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.sidebar-items-list {
  margin-top: 20px;
}

.item-list-sidebar {
  width: 100%;
  font-size: 15px;
}

.item-list-sidebar svg {
  font-size: 24px;
}

.item-name {
  padding: 11px 0px;
  display: flex;
  padding-left: 20px;
  min-height: 40px;
  line-height: 15px;
}

.item-name.active {
  background: #515151;
}

.item-name span {
  margin-top: 6px;
  margin-left: 25px;
  font-size: 15px;
  text-align: left;
  color: #fff;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.3;
}

.order-status-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.order-status-item {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sidebar-items-second-line {
  background: #3a3a3c;
}

.item-name:hover {
  background: #515151;
  cursor: pointer;
  font-weight: 600;
}

.hide-submenu {
  display: none;
}

.show-submenu {
  display: block;
}

.icon-item-sidebar {
  position: relative;
  /* width: 15px; */
}

.icon-item-sidebar i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 23px;
  color: #fff;
}

.display-hide-block {
  display: none;
}

.display-show-block {
  display: block;
}

.second-item-sidebar {
  text-align: left;
  margin-left: 60px;
  cursor: pointer;
  padding: 4px 0px;
}

/* login page */
.login-block-image {
  height: 100vh;
  background-image: url(https://www.ewe.rs/media/intro/numbers-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.left-side-login-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-block-login {
  /* position: relative; */
  /* height: 100vh; */
  /* background: #fafafa; */
}

.container-login-form input {
  border-radius: 20px;
}

.container-login-form h2 {
  margin-top: 60px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}

.password-input-container {
  position: relative;
}

.password-input-container input {
  padding-right: 40px;
  /* Dodaj padding desno da se izbegne preklapanje sa ikonicom */
}

.custom-table-container {
  width: 100%;
  /* overflow-x: auto; */
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-wrapper {
  flex-grow: 1;
  overflow-y: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.custom-table th,
.custom-table td {
  padding: 8px;
  text-align: left;
}

.custom-table th {
  background-color: #ffffff;
  color: #333;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  font-weight: 500;
  border-bottom: 1px solid #e0e0e0;
}

.custom-table-divider {
  border-bottom: solid rgba(224, 224, 224, 1)
}

.custom-table tr:nth-child(even) {
  background-color: #fafafa;
}

.custom-table tr:hover {
  background-color: #f1f1f1;
}

.custom-table input {
  /* width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif; */
}

.search-icon-order-status {
  font-size: 16px;
}

.custom-table .action-buttons {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.custom-table .action-buttons .fa {
  cursor: pointer;
  color: #007bff;
  font-size: 16px;
}

.custom-table .action-buttons .fa:hover {
  color: #0056b3;
}

.custom-table .sort-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  margin-left: 5px;
}

.order-status-table-column {
  font-size: 0.875rem !important;
}

.custom-table .sort-icon:hover {
  color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
}

.pagination .order-status-pagination-button {
  cursor: pointer;
  background-color: #f8f9fa;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50%;
}

.pagination .order-status-pagination-button:hover {
  background-color: #f0f0f0;
}

.order-status-pagination-button:disabled,
.order-status-pagination-button[disabled] {
  color: rgba(0, 0, 0, 0.38);
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
  font-size: 14px;
}

.filter-reset-button {
  float: right;
  margin: 2px 0;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.5rem;
}

.filter-reset-button:hover {
  color: #fe0406;
  cursor: pointer;
}

.search-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.search-container input {
  width: 300px;
  padding-right: 30px;
}

.search-container .clear-icon {
  position: relative;
  right: 25px;
  cursor: pointer;
  color: #ccc;
}

.search-container .clear-icon:hover {
  color: #333;
}

.cargo-id-item {
  cursor: pointer;
}

.cargo-id-item:hover {
  color: #62c900;
}

.order-status-row {
  cursor: pointer;
}

.password-input-container .fas {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #686868;
}

.password-input-container .fas:hover {
  color: #333;
}

.link-item-text-content {
  text-align: center;
  color: #0c0c0d;
}

.container-login-form input::placeholder {
  font-style: italic !important;
}

.login-container-holder {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #000000;
}

.forgot-password-block {
  background-color: white;
}

.forgot-password-img {
  width: 50%;
  margin-right: 80px;
}

.login-holder-block-form {
  flex: 1.5 1;
  justify-content: center;
  display: flex;
}

.login-holder-block-image {
  flex: 3 1;
}

.login-holder-block-image img {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.container-login-form {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 10%; */
  /* transform: translate(0%, -50%); */
  width: 450px;
  border: 1px solid #fafafa;
  padding: 80px;
  /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 0%), 0 2px 1px -1px rgb(0 0 0 / 0%); */
  background: #fff;
  border-radius: 35px;
  box-shadow: 0 0em 2em rgba(0, 0, 0, 0.1), 0 0 0 0px rgb(255, 255, 255), 0em 0.3em 2em rgba(0, 0, 0, 0.3);
}

.logo-login-image {
  text-align: center;
  margin-bottom: 20px;
}

.logo-login-image img {
  max-width: 250px;
}

.login-page-container {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-position: center;
}

.info-image-sidebar {
  border-radius: 50px;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  background-size: cover;
  margin-top: 12px;
  margin-left: 15px;
  border: 2px solid #fff;
  flex: none;
}

.sidebar-info-user {
  display: flex;
  position: absolute;
  bottom: 90px;
  padding-top: 0px;
  width: 100%;
  flex-direction: column;
}

.sidebar-user-info-holder {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
}

.sidebar-user-info-holder-upper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.user-info-hover {
  position: fixed;
  bottom: 90px;
  z-index: 99999999999999;
  left: 62px;
  /* min-width: 100px; */
  border-radius: 2px;
  padding: 30px 40px 30px 10px;
  font-size: 15px;
  color: white;
  background-color: #2d2d2f;
  overflow-wrap: break-word;
  font-weight: 400;
  transition: visibility 0s, opacity 0.2s linear;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  text-transform: uppercase; */
}

.user-info-hover-label {
  font-size: 12px;
}

.user-info-hover-text {
  font-weight: 600;
}

.user-info-top-border {
  border-top: 1px solid #62c900;
}

.info-user-detail-sidebar {
  padding-top: 10px;
  padding-right: 10px;
  text-align: left;
  font-size: 11px;
  margin-left: 10px;
  font-weight: 600;
  width: 100%;
}

.content-mobile-user-info {
  display: flex;
  border-bottom: 1px dotted #ccc;
  padding: 15px;
  color: #f1f1f1;
}

.user-info-email-mobile {
  font-size: 15px;
  margin-top: 0px;
  font-weight: 400;
  color: #f1f1f1;
}

.user-info-email-mobile a {
  color: #f1f1f1;
}

.menu-list-sidebar-mobile li {
  color: #f1f1f1;
  padding: 4px 0px;
  cursor: pointer;
}

.content-user-info-image {
  /* flex: 1; */
}

.info-user-detail-sidebar-mobile {
  flex: 4 1;
}

.menu-list-sidebar-mobile ul {
  list-style: none;
  padding-left: 15px;
  margin-top: 15px;
}

.menu-list-sidebar-mobile {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.content-mobile-welcome {
  padding: 12px 15px;
  border-bottom: 1px solid #e6e6e6;
  color: white;
  font-size: 18px;
}

.info-user-detail-sidebar-mobile .user-info-sector-mobile {
  font-size: 13px;
}

.info-user-detail-sidebar-mobile {
  padding-top: 0px;
  text-align: left;
  font-size: 11px;
  margin-left: 10px;
  font-weight: 600;
}

.info-image-sidebar-mobile {
  border-radius: 50px;
  background-repeat: no-repeat;
  height: 60px;
  width: 60px;
  background-size: cover;
}

.info-user-detail-sidebar-mobile .loggedin-username {
  margin-top: 3px;
  font-size: 22px;
  padding-top: 0px;
  margin-bottom: 7px;
}

.loggedin-username {
  font-size: 16px;
  line-height: 15px;
  word-break: break-word;
}

.user-detail-info-text {
  font-size: 14px;
  line-height: 15px;
  word-break: break-word;
}

.user-detail-info-text a {
  color: white;
  text-decoration: none;
}

.user-detail-info-text a:hover {
  color: #62c900;
  cursor: pointer;
}

.info-user-detail-label {
  margin-top: 5px;
  font-weight: 400;
  color: rgba(255, 255, 255, .8);
}

.user-detail-info-separator {
  height: 15px;
  /* margin-right: 40px; */
  border-bottom: 1px solid #62c900;
}

.content-slider-image-product .slider-content-main {
  background: antiquewhite;
  max-width: 100%;
}

.content-slider-image-product .slick-slider-item-cust {
  background: #5f9ea0;
  color: #fff;
  font-size: 36px;
  line-height: 100px;
  margin: 10px;
  padding: 2%;
  position: relative;
  text-align: center;
}

.content-slider-image-product .center .slick-center {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}

.center h3 {
  opacity: .8;
  transition: all .3s ease;
}

.h3-cust {
  color: #fff;
  font-size: 36px;
  line-height: 100px;
  margin: 10px;
  padding: 2%;
  position: relative;
  text-align: center;
  height: 650px;
}

.h3-cust-video {
  height: 440px;
  padding: 0px;
  padding-top: 100px;
  margin: 0px;
}

.product-detail-content-box .h3-cust {
  cursor: pointer;
}

/* /////////////////////////////////// */
/* //////////POVEZANA PRODAJA///////// */
/* /////////////////////////////////// */
.related-sale-products {
  padding: 0 60px 50px;
}

.related-sale-products .show-more-specification-product-detail-btn {
  margin-top: 40px;
}

.related-sale-product-img {
  max-width: 200px;
  margin: auto;
}

.related-sale-header {
  border-top: 1px solid #cecece;
  padding: 20px 20px 0;
}

#related-sale-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
}

.related-sale-item {
  padding: 20px;
}

.related-sale-products p {
  margin-top: auto;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: inherit;
}

.related-sale-product-price {
  opacity: 0.8;
}

.related-sale-product-new-price {
  float: left;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.related-sale-products .slider-special-action-button {
  padding: 0;
  margin-top: 20px;
  position: inherit;
}

.related-sale-products .add-btn-spec-action {
  width: 100px;
}

.disclaimer {
  margin: 50px;
  margin-bottom: 25px;
  font-size: 14px;
  text-align: center;
  color: #5a5e63;
}

@media (max-width: 2000px) {
  .related-sale-product-new-price {
    float: none;
  }

  .related-sale-products .add-btn-spec-action {
    float: none;
  }
}

@media (max-width: 1920px) and (min-width: 1640px) {
  .related-sale-products {
    padding: 0 40px 50px;
  }

  .related-sale-product-img {
    padding-top: 20px;
  }

}

@media (max-width: 1639px) and (min-width: 1500px) {
  .product-detail-content-box {
    overflow: hidden;
  }

  .related-sale-products {
    padding: 0 30px 50px;
  }

  .related-sale-product-img {
    padding-top: 30px;
  }

  .related-sale-item {
    padding: 20px 0;
  }
}

@media (max-width: 1499px) and (min-width: 1200px) {
  .product-detail-content-box {
    overflow: hidden;
  }

  .related-sale-products {
    padding: 0 40px 50px;
  }

  .related-sale-item {
    padding: 20px 0;
  }

  .related-sale-product-img {
    padding-top: 15px;
    max-width: 180px;
  }

  .slider-logout-page-item-body {
    margin-right: 20px;
  }

  .we-present-page-item-body {
    margin-right: 20px;
  }

  .slider-logout-page-item-image {
    margin-right: 20px;
  }

  .we-present-page-item-image {
    margin-right: 20px;
  }

  .slider-logout-page-content .slick-next {
    left: -13% !important;
  }

  /* .related-sale-products p {
    font-size: 15px;
  }
  .related-sale-product-new-price {
    margin-top: 2px !important;
    font-size: 18px !important;
  }
  .related-sale-products .add-btn-spec-action {
    width: 110px;
  } */
}

@media (max-width: 1199px) and (min-width: 1021px) {
  .product-detail-content-box {
    overflow: hidden;
  }

  .related-sale-products {
    padding: 0 20px 50px;
  }

  .related-sale-item {
    padding: 20px 0;
  }

  .related-sale-products p {
    font-size: 15px;
  }

  .related-sale-product-new-price {
    font-size: 18px !important;
  }

  .related-sale-product-img {
    padding-top: 15px;
    max-width: 170px;
  }

  .filters-body-catalog label {
    font-size: 13px;
  }
}

@media (max-width: 1020px) and (min-width: 840px) {
  .related-sale-products {
    padding: 0 60px 50px;
  }

  .related-sale-item {
    padding: 20px 0;
  }

  .related-sale-products p {
    font-size: 15px;
  }

  .related-sale-product-new-price {
    font-size: 18px !important;
  }

  .related-sale-product-img {
    padding-top: 30px;
    max-width: 170px;
  }
}

@media (max-width: 839px) and (min-width: 768px) {
  .related-sale-products {
    padding: 0 40px 50px;
  }

  .related-sale-item {
    padding: 20px 0;
  }

  .related-sale-products p {
    font-size: 15px;
  }

  .related-sale-product-new-price {
    font-size: 18px !important;
  }

  .related-sale-product-img {
    padding-top: 30px;
    max-width: 150px;
  }
}

@media (max-width: 767px) and (min-width: 630px) {
  .related-sale-products {
    padding: 0 60px 50px;
  }

  .related-sale-item {
    padding: 20px 30px;
  }

  .related-sale-product-new-price {
    float: left;
  }

  .related-sale-products .add-btn-spec-action {
    float: right;
  }

  .related-sale-product-img {
    max-width: 170px;
  }
}

@media (max-width: 629px) {
  .related-sale-products {
    padding: 0 15px 50px;
  }

  .related-sale-header {
    padding: 20px 10px 0;
  }

  .related-sale-product-img {
    margin-bottom: 40px;
  }

  .related-sale-product-img {
    max-width: 200px;
  }

  .related-sale-product-new-price {
    float: left;
  }

  .related-sale-products .add-btn-spec-action {
    float: right;
  }
}

/* /////////////////////////////////// */
/* //////////PROIZVODI NEULOGOVANI//// */
/* /////////////////////////////////// */
.products-logout .search-box input {
  padding: 26px;
  border-radius: 32px;
  font-size: 19px;
}

.products-logout .search-icon {
  top: 9px;
  font-size: 23px;
  right: 25px;
}

.logout-page-text {
  padding: 25px 0;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

@media screen and (max-width: 1024px) {
  .logout-page-text {
    font-size: 36px;
  }

}

.logout-page-text span {
  display: block;
  font-size: 18px;
  margin-top: 15px;
}

.logout-page-search {
  margin: auto;
  max-width: 600px;
}

.logout-page-products {
  max-width: 1300px;
  margin: auto;
  padding-top: 25px;
  margin-bottom: 40px;
}

.logout-page-items {
  background-color: #ECECEC;
  border-radius: 10px;
  padding: 20px 15px;
  /* padding-left: 30px; */
  /* padding-right: 0; */
  display: block;
  color: #696969;
  /* margin-bottom: 20px; */
  /* max-width: 295px; */
  height: 100%;
  transition: .2s ease-in all;
}

div.logout-page-products>div.row>div.col-md-3.col-sm-6,
div.logout-page-products>div.row>div.col-md-4.col-sm-6 {
  margin-bottom: 15px;
}

.logout-page-items:hover {
  color: #fff;
  background-color: #17a2b8;
}

.logout-page-items:hover h2 {
  color: #fff;
}

.logout-page-items:hover p {
  color: #fff;
}

.logout-page-subitems:hover {
  background: #57c7d5;
  color: #696969;
}

.logout-page-products-info {
  /* position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  left: auto; */
  flex: 1 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.content-products-item-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 1920px) and (min-width: 1560px) {
  .content-products-item-holder img {
    max-width: 70px;
  }

  .logout-page-products-info h2,
  .logout-page-products-info p {
    font-size: 14px;
  }
}

.logout-page-products-info-outlet {
  position: absolute;
  top: 50%;
  right: 0;
  width: 70%;
  left: auto;
  transform: translate(0, -50%);
}

.logout-page-products-info h2 {
  font-family: unset;
  color: #000;
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 5px;
}

.logout-page-products-info h2,
.logout-page-products-info p {
  font-size: 15px;
  transition: .1s ease-in all;
}

.logout-page-products-info span,
.logout-page-subitems .logout-page-products-info span {
  font-family: unset;
  color: #555;
  font-size: 15px;
}

.logout-page-items img {
  /* margin-left: -25px; */
  /* max-width: 100px; */
  /* height: 90px; */
  /* float: none; */
}

.logout-page-no-img {
  font-size: 35px;
  margin-left: -10px;
  padding: 20px 0;
}

.logout-page-subitems {
  background-color: #ECECEC;
  border-radius: 25px;
  padding: 15px;
  padding-right: 30px;
  padding-left: 0;
  display: block;
  color: #696969;
  margin-bottom: 20px;
  max-width: 280px;
}

.logout-page-subitems .logout-page-products-info {
  position: absolute;
  top: 0;
  left: 8%;
  width: 65%;
}

.logout-page-subitems .logout-page-products-info h2 {
  font-family: unset;
  color: #000;
  font-weight: 700;
  font-size: 19px;
}

.logout-page-subitems img {
  margin-right: -25px;
  max-width: 220px;
  float: right;
}

.logout-page-subitems .logout-page-no-img {
  margin-right: -25px;
  float: right;
}

/* /////////////////////////////////// */
/* ///////////SLIDERI///////////////// */
/* /////////////////////////////////// */
.slider-special-action-button .button-div-item-qty .plus-item-qty {
  padding: 0 7px;
}

.add-btn-spec-action .add-button {
  height: 23px;
  position: relative;
}

.slider-special-action-detail-res {
  /* margin-top: 15px; */
  display: flex;
  font-size: 16px;
}

.content-body-special-block {
  box-shadow: 0px 3px 35px 0px #cecece;
  border-radius: 15px;
  padding: 20px 15px;
  display: flex;
  height: 520px;
}

.product-detail-content-box .content-body-special-block {
  box-shadow: 0px 0px 6px 0px #cecece;
}

.action-title-name {
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  height: 63px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.product-detail-content-box .spec-action-price,
.homepage-special-block-items .spec-action-price {
  position: absolute;
  right: 18px;
  bottom: 50px;
}


@media (max-width: 1920px) and (min-width: 1681px) {

  /* .slider-special-action-detail-res, .slider-special-action-detail-price {
    font-size: 16px;
  } */
  .homepage-content-body-special-block .slick-prev,
  .loyalty-slider-content .slick-prev {
    left: -20px;
    z-index: 1;
  }

  .slider-special-action-image img,
  .homepage-special-block-items img,
  .action-block-special-slider-item-image img {
    margin: 0 auto;
    max-height: 200px;
    width: unset !important;
  }

  .btn-call-actions img {
    margin: 0;
    width: 19px !important;
    padding-bottom: 4px !important;
  }
}

@media (max-width: 1680px) and (min-width: 1537px) {
  .content-body-special-block {
    height: 500px;
  }

  /* .product-detail-content-box .slider-special-action-detail-res, .product-detail-content-box .slider-special-action-detail-price {
    font-size: 16px;
  } */
  .weekly-slider .slider-special-action-detail-res,
  .weekly-slider .slider-special-action-detail-price {
    font-size: 15px;
  }

  .product-detail-content-box .spec-action-price,
  .homepage-special-block-items .spec-action-price {
    font-size: 22px;
  }

  .product-detail-content-box .add-btn-spec-action .add-button {
    padding: 2px 0;
    height: 25px;
  }

  .action-title-name {
    font-size: 17px;
    -webkit-line-clamp: 2;
    height: auto;
    line-height: 1.3;
  }

  .homepage-content-body-special-block .slick-prev,
  .loyalty-slider-content .slick-prev {
    left: -20px;
    z-index: 1;
  }

  .slider-special-action-image img,
  .homepage-special-block-items img,
  .action-block-special-slider-item-image img {
    margin: 0 auto;
    max-height: 200px;
    width: unset !important;
  }

  .btn-call-actions img {
    margin: 0;
    width: 19px !important;
    padding-bottom: 4px !important;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;
  }
}

@media (max-width: 1536px) and (min-width: 1367px) {

  .slider-special-action-image img,
  .homepage-special-block-items img,
  .action-block-special-slider-item-image img {
    margin: 0 auto;
    max-height: 180px;
    width: unset !important;
  }

  .btn-call-actions img {
    margin: 0;
    width: 19px !important;
    padding-bottom: 4px !important;
  }
}

@media (max-width: 1366px) and (min-width: 1150px) {

  .slider-special-action-image img,
  .homepage-special-block-items img,
  .action-block-special-slider-item-image img {
    margin: 0 auto;
    max-height: 150px;
    width: unset !important;
  }

  .btn-call-actions img {
    margin: 0;
    width: 19px !important;
    padding-bottom: 4px !important;
  }
}

@media (max-width: 1536px) and (min-width: 1150px) {
  .product-detail-content-box .content-body-special-block {
    height: 480px;
  }

  /* .product-detail-content-box .slider-special-action-detail-res, .product-detail-content-box .slider-special-action-detail-price {
    font-size: 16px;
  } */

  .product-detail-content-box .spec-action-price {
    font-size: 22px;
  }

  .product-detail-content-box .add-btn-spec-action .add-button {
    padding: 2px 0;
    height: 25px;
  }

  .action-title-name {
    font-size: 17px;
    -webkit-line-clamp: 2;
    height: auto;
    line-height: 1.3;
  }

  .homepage-content-body-special-block .slick-prev:before,
  .homepage-content-body-special-block .slick-next:before,
  .loyalty-slider-content .slick-prev:before,
  .loyalty-slider-content .slick-next:before {
    font-size: 30px;
  }

  .homepage-content-body-special-block .slick-prev,
  .loyalty-slider-content .slick-prev {
    left: -22px;
    z-index: 1;
  }

  .slider-special-action-button {
    bottom: 0;
  }

  .product-detail-content-box .content-slider-special-action .slick-prev {
    left: -30px;
    z-index: 1;
  }

  .product-detail-content-box .slider-special-action-detail-res,
  .product-detail-content-box .slider-special-action-detail-price {
    font-size: inherit;
  }
}

@media (max-width: 1149px) and (min-width: 1021px) {
  .product-detail-content-box .content-body-special-block {
    height: 520px;
  }

  .product-detail-content-box .content-slider-special-action .slick-prev:before,
  .product-detail-content-box .content-slider-special-action .slick-next:before {
    font-size: 30px;
  }

  .product-detail-content-box .content-slider-special-action .slick-prev {
    left: -18px;
    z-index: 1;
  }

  .product-detail-content-box .content-slider-special-action .slick-next {
    right: -17px;
    z-index: 1;
  }

  .product-detail-content-box .item-slider-special-action {
    padding: 0px 15px;
  }

  .product-detail-content-box .slider-special-action-detail-res {
    font-size: 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  .product-detail-content-box .slider-special-action-detail-price {
    flex-direction: column;
  }

  .product-detail-content-box .slider-special-action-button {
    padding: 0px 10px;
  }

  .product-detail-content-box .spec-action-price {
    font-size: 22px;
  }

  .product-detail-content-box .spec-action-price-old {
    font-size: 15px;
  }

  .product-detail-content-box .add-btn-spec-action .add-button {
    padding: 2px 0;
  }

  .action-title-name {
    font-size: 17px;
    -webkit-line-clamp: 2;
    height: auto;
    line-height: 1.3;
  }
}

@media (max-width: 1020px) and (min-width: 851px) {
  .product-detail-content-box .add-btn-spec-action .add-button {
    padding: 2px 0;
  }
}

@media (max-width: 880px) {
  .weekly-slider .slider-special-action-image {
    max-width: 270px;
    margin: auto;
  }
}

@media (max-width: 850px) and (min-width: 600px) {
  .product-detail-content-box .content-body-special-block {
    height: 550px;
  }

  .product-detail-content-box .slider-special-action-detail-res,
  .product-detail-content-box .slider-special-action-detail-price {
    font-size: 15px;
  }

  .product-detail-content-box .spec-action-price {
    font-size: 22px;
  }

  .action-title-name {
    font-size: 16px;
    -webkit-line-clamp: 2;
    height: auto;
    line-height: 1.3;
  }
}

@media (max-width: 599px) {
  .product-detail-content-box .content-body-special-block {
    height: 550px;
  }

  .product-detail-content-box .add-btn-spec-action .add-button {
    padding: 2px 0;
  }

  .action-title-name {
    font-size: 16px;
    -webkit-line-clamp: 2;
    height: auto;
    line-height: 1.3;
  }
}

/* /////////////////////////////////// */
/* //////////3D MODAL///////////////// */
/* /////////////////////////////////// */
.custom-modal-preview-image .content-slider-image-product {
  margin-top: auto;
}

.custom-modal-preview-image {
  max-width: 65vw;
}

.custom-modal-preview-image .h3-cust {
  height: 800px;
  max-height: 80vh;

}

.custom-modal-preview-image .h3-cust img {
  /* width: 450px !important; */
  width: auto !important;
}

.custom-modal-preview-image .slick-dots {
  top: 50px;
  bottom: auto;
}

.custom-modal-preview-image .slick-list {
  margin-top: 80px;
}

@media (min-width: 1920px) and (max-width: 2316px) {
  .custom-modal-preview-image {
    max-width: 1200px;
  }

  .custom-modal-preview-image .h3-cust {
    height: 700px;
  }
}

/* .modal-body .content-slider-image-product .mini-images-slider-product{
  height: 50px;
} */
.embeded-video {
  /* position: relative;
  padding-bottom: 56.25%;
  margin-top: 60px;
  height: 0;
  overflow: hidden;
  max-width: 100%; */
}

.embeded-video iframe {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* width: 100%; */
  /* height: 100%; */
  width: 100%;
  margin-top: 80px;
  padding: 0px 60px;
  height: 700px;
}


@media (max-width: 1700px) and (min-width: 1400px) {
  .custom-modal-preview-image {
    max-width: 1300px;
  }

  .custom-modal-preview-image .h3-cust {
    height: 700px;
    max-height: 70vh;
  }

  .custom-modal-preview-image .slick-list {
    margin-top: 100px;
  }
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .custom-modal-preview-image {
    max-width: 1100px;
  }

  .custom-modal-preview-image .h3-cust {
    height: 60vh;
  }

  .custom-modal-preview-image .slick-list {
    margin-top: 120px;
  }

  .content-slider-image-product .slick-prev,
  .content-slider-image-product .slick-next {
    top: 60%;
  }

  .embeded-video iframe {
    margin-top: 20px;
    height: 60vh;
  }

  .custom-modal-preview-image .h3-cust img {
    width: 450px !important;
  }
}

@media (max-width: 1199px) and (min-width: 1000px) {
  .custom-modal-preview-image {
    max-width: 900px;
  }

  .custom-modal-preview-image .h3-cust {
    height: 60vh;
  }

  .custom-modal-preview-image .slick-list {
    margin-top: 120px;
  }

  .content-slider-image-product .slick-prev,
  .content-slider-image-product .slick-next {
    top: 60%;
  }

  .embeded-video iframe {
    margin-top: 20px;
    height: 60vh;
  }

  .modal-body .product-detail-item-3d {
    width: 75px !important;
    height: 65px !important;
  }
}

@media (max-width: 999px) and (min-width: 700px) {
  .custom-modal-preview-image {
    max-width: 720px;
  }

  .custom-modal-preview-image .h3-cust {
    height: 60vh;
  }

  .custom-modal-preview-image .slick-list {
    margin-top: 120px;
  }

  .embeded-video {
    margin-top: 15px;
  }

  .embeded-video iframe {
    margin-top: 20px;
    height: 50vh;
    padding: 0 30px;
  }

  .modal-body .content-slider-image-product .slick-prev,
  .modal-body .content-slider-image-product .slick-next {
    top: 60%;
  }
}

@media (max-width: 699px) and (min-width: 481px) {
  .custom-modal-preview-image {
    max-width: 500px;
  }

  .custom-modal-preview-image .h3-cust {
    height: 60vh;
  }

  .custom-modal-preview-image .slick-list {
    margin-top: 50px;
  }

  /* .modal-body .content-slider-image-product .mini-images-slider-product{
    height: 40px;
  } */
  .custom-modal-preview-image .slick-dots li,
  .modal-body .mini-images-slider-product-video {
    width: 60px !important;
    height: 55px !important;
  }

  .embeded-video {
    margin-top: 15px;
  }

  .embeded-video iframe {
    margin-top: 20px;
    height: 50vh;
    padding: 0 30px;
  }

  .modal-body .content-slider-image-product .slick-prev,
  .modal-body .content-slider-image-product .slick-next {
    top: 60%;
  }

  .modal-body .item-mini-images-product img.img-logo-360 {
    left: 13px !important;
    top: 3px !important;
  }
}

@media (max-width: 480px) {
  .custom-modal-preview-image {
    max-width: 400px;
  }

  .custom-modal-preview-image .h3-cust {
    height: 50vh;
  }

  .custom-modal-preview-image .slick-list {
    margin-top: 60px;
  }

  /* .modal-body .content-slider-image-product .mini-images-slider-product{
    height: 30px;
  } */
  .custom-modal-preview-image .slick-dots li,
  .modal-body .mini-images-slider-product-video {
    /* width: 45px !important; */
    /* height: 43px !important; */
  }

  .embeded-video {
    margin-top: 15px;
  }

  .embeded-video iframe {
    margin-top: 20px;
    height: 50vh;
    padding: 0 30px;
  }

  .modal-body .content-slider-image-product .slick-prev,
  .modal-body .content-slider-image-product .slick-next {
    top: 60%;
  }

  .modal-body .item-mini-images-product img.img-logo-360 {
    left: 15px !important;
    top: 2px !important;
  }
}


/* .h3-cust :hover + .zoom-image-product-detail {
  display: block;
} */
.zoom-image-product-detail {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute !important;
  -webkit-user-select: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  opacity: .5;
  transition: opacity .1s;
  user-select: none;
  background-color: rgba(0, 0, 0, .8);
  z-index: 1000;
  height: 60px;
  font-size: 21px;
  width: 60px;
  /* display: none; */
}

.zoom-image-product-detail i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-product-detail-gallery-zoom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100vh;
  display: none;
}

.modal-product-detail-gallery-zoom.show-modal-prodcut-detail {
  display: block;
}

.modal-product-detail-gallery-zoom .modal-content-zoom-product {
  background: #fff;
  opacity: 0.95;
  width: 100%;
  height: 100%;
}

.modal-product-detail-gallery-zoom img {
  z-index: 999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}

.slick-slider {
  max-width: 100%;
}

.content-slider-image-product .slick-slider {
  max-width: 100%;
  display: grid;
  margin-top: -50px;
}

.content-slider-image-product .slick-track {
  margin: 0 auto;
}

.content-slider-image-product button.slick-arrow.slick-prev {
  background: red;
}

.content-slider-image-product button.slick-arrow.slick-next {
  background: red;
}

.content-slider-image-product .content-slider-image-product {
  padding: 10px 10px;
}

.content-slider-image-product .slick-slide img {
  width: 70%;
  transition: all .3s ease;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: zoom-in;
}

.content-slider-image-product .slick-center img {
  /* width: 95%; */
  width: 100%;
  transition: all .3s ease;
  margin: 0 auto;
  cursor: zoom-in;
}

.product-content-detail-main {
  background-color: #f6f6f6;
  padding: 0px 60px 10px;
}

.product-table-margin .input-group-text {
  padding-top: 0px;
  padding-bottom: 0px;
}

.product-compare-action-line {
  display: flex;
  justify-content: space-between;
}

.product-action-detail-items {
  position: relative;
  display: inline-block;
  margin-top: 6px;
}

.product-action-item {
  display: inline;
  color: #fff;
  padding: 3px 15px;
  margin-right: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.product-action-item.product-ident-action {
  background-color: #000;
}

.product-action-item.product-new-action {
  background-color: #f7395d;
}

.product-detail-name {
  /* margin-top: 15px; */
  /* display: flex; */
  /* float: left; */
}

.product-detail-name h1 {
  font-size: 30px;
  font-weight: 400;
  /* padding-top: 0px; */
  /* padding-left: 15px; */
}

.brand-logo-product-detail {
  /* width: 450px; */
  position: relative;
  z-index: 2;
}

.product-detail-name img {
  /* float: left; */
  margin-right: 15px;
}

.product-icon-item {
  margin-top: 25px;
}

.product-icon-item img {
  margin-right: 10px;
  margin-bottom: 10px;
}

.product-table-content {
  background: #fff;
  border-radius: 15px;
  box-shadow: 9px 4px 18px 1px #cecece;
  padding: 25px 40px;
}

.product-margin-block {
  background: #fff;
  border-radius: 15px;
  box-shadow: 9px 4px 18px 1px #cecece;
  padding: 25px 40px;
}

.product-table-content td {
  border-bottom: 1px solid #cecece;
  padding: 2px 10px;
}

.td-product-value {
  text-align: right;
  font-weight: bold;
}

.product-total-price {
  color: #f7395d;
  text-align: right;
  font-size: 27px;
  font-weight: 700;
  /* line-height: 0; */
}

.product-total-old-price {
  font-size: 18px;
  text-align: right;
  text-decoration: line-through;
}

.btn-product-add-to-cart {
  margin-top: 10px
}

.btn-select-product {
  display: inline;
}

.btn-add-to-cart-product {
  display: inline;
}

.product-action-item-buttons {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-product-wishlist {
  border: 1px solid #cccccc;
  padding: 2px 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.btn-product-compare {
  border: 1px solid #cccccc;
  padding: 2px 10px;
  border-radius: 5px;
}

.btn-product-wishlist:hover {
  background: #e6e6e6;
}

.btn-product-compare:hover {
  background: #e6e6e6;
}

.productIcon {
  background: #e2e3e7;
}

.small-items-product-slider .h3-cust {
  height: 50px;
}

.small-items-product-slider {
  width: 400px;
  margin: 0 auto;
}

.custom-modal-preview-image .mini-images-slider-product {
  height: 46px !important;
}

.mini-images-slider-product {
  /* width: 100% !important; */
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
  height: 70px;
  width: auto !important;
}

.content-slider-image-product .slick-dots li {
  border: 2px solid #ebebeb;
  width: 75px;
  height: 65px;
  border-radius: 6px;
  margin-bottom: 6px;
  background-color: #fff;
}

.item-mini-images-product {
  padding: 6px;
  position: relative;
}

.second-mini-slider .product-detail-item-3d {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: none;
  padding-bottom: 6px;
  padding-right: 5px;
}

.second-mini-slider .img-logo-360 {
  width: 50% !important;
  top: 48% !important;
  left: 50% !important;
}

.second-mini-slider {
  width: 450px !important;
  margin: 0 auto;
  margin-top: 10px !important;
}

.second-mini-slider-1 {
  width: 120px !important;
}

.second-mini-slider-2 {
  width: 225px !important;
}

.second-mini-slider-3 {
  width: 338px !important;
}

.second-mini-slider .item-mini-images-product {
  border: 2px solid #ebebeb;
  border-radius: 6px;
  margin-bottom: 6px;
  background-color: #fff;
  margin-right: 5px;
}

.second-mini-slider .slick-current .item-mini-images-product {
  border-color: #62c900;
}

.item-list-slider-product {
  position: relative;
}

.header-content-top {
  width: 100%;
  position: fixed;
  z-index: 101;
  top: -1px;
}

.bg-dark {
  background-color: #2d2d2f !important;
}

.header-content-top+.container-content {
  margin-top: 80px;
}

.banner-first-block-home img {
  max-height: 430px;
  border-radius: 25px;
  width: 100%;
  box-shadow: 3px 3px 12px 0 rgb(0 6 22 / 10%);
}

.banner-first-block-home {
  /* background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
  height: 100%; */
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  bottom: 0px;
  margin: 0 auto;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 15px;

}

.banner-second-block-home {
  background-image: url(https://www.ewe.rs/media/banners/2021/07/2021-07-08923752.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
  height: 100%;
}

.block-home-notices {
  box-shadow: 9px 4px 18px 1px #cecece;
  padding: 20px 20px;
  min-height: 430px;
  max-height: 430px;
  border-radius: 10px;
  position: relative;
  width: 100%;
}

.footer-notices-name {
  background: #fe9402;
  width: 100%;
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 7px 10px;
}

.detail-footer-notice {
  background: #fff;
  border-radius: 10px;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  font-weight: 700;
  cursor: pointer;
}

.header-notices-name {
  font-size: 20px;
  padding-bottom: 6px;
}

.body-notices-name {
  border-top: 2px solid #eaebef;
  padding-top: 10px;
  display: table;
  width: 100%;
  padding-bottom: 10px;
}

.body-item-notice {
  display: table-row;
}

.time-notice-item {
  display: table-cell;
}

.time-item-cell {
  color: #fff;
  background: #fe9402;
  border-radius: 13px;
  text-align: center;
}

.text-notice-item {
  display: table-cell;
  padding-left: 20px;
}

.icon-notice-item {
  display: table-cell;
}

.text-title-notice-item {
  font-weight: bold;
  margin-bottom: -6px;
}

.text-desc-notice-item {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.icon-notice-item {
  font-size: 20px;
  color: #d5d5d5;
}


.content-slider-image-product li.slick-active {
  border-color: #62c900;
}

@media (max-width: 1366px) and (min-width: 700px) {}

@media(max-width: 1920px) {
  .h3-cust {
    height: 350px;
  }
}

iframe {
  border: none;
}

.link-item-text {
  cursor: pointer;
  color: #0c0c0d;
}

.link-item-text:hover {
  text-decoration: underline;
}

.link-item-text-content {
  margin-top: 20px;
}

.block-slider-home-banner {
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

.custom-button-login:active:focus {
  box-shadow: 0 0 0 .2rem rgb(252, 57, 85) !important;
}

.custom-button-login {
  background-color: #fc3955 !important;
  color: #fff;
  border-color: #fc3955 !important;
  border-radius: 20px;
  font-weight: 600;
  font-size: 18px;
  padding: 8px 24px;
}

.block-slider-home-banner .slick-track {
  border-radius: 10px;
}

.catalog-list-sidebar-mobile .menu-items {
  margin-top: 0px;
}

.catalog-list-sidebar-mobile .item-menu-category {
  border-bottom: 1px solid #f6f6f6;
}


@media (max-width: 576px) {
  .container-login-form {
    width: 100%;
    left: 0%;
    box-shadow: none;
    transform: none;
    top: 0;
    /* height: 100vh; */
    /* border-radius: 0px; */
    padding: 20px 40px 10px;
  }

  .login-container-holder {
    flex-direction: column;
    height: auto;
    padding-top: 80px;
  }

  .logo-login-image img {
    max-width: 150px;
  }

  .forgot-password-img {
    width: 80%;
    margin: auto;
    margin-top: 25px;
  }

  .container-login-form h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 24px;
  }

  .login-holder-block-image {
    margin-top: 25px;
  }

  .login-holder-block-image img {
    height: auto;
  }

  .form-block-login {
    height: auto;
  }
}

.col-xl-8-custom {
  margin-bottom: 20px;
}

.col-xl-4-custom {
  margin-bottom: 20px;
}

.block-slider-home-banner ul.slick-dots {
  position: absolute;
  right: 10px;
  bottom: 20px;
  text-align: right;
}

.block-slider-home-banner .slick-dots li button {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 15px;
  width: 15px;
}

.block-slider-home-banner .slick-active button {
  border-color: #62c900 !important;
  height: 18px !important;
  width: 18px !important;
}

.block-slider-home-banner .slick-dots li button:before {
  color: transparent !important;
}

.content-slider-special-action-banner ul.slick-dots {
  position: absolute;
  bottom: 5px;
  text-align: center;
}

.content-slider-special-action-banner .slick-dots li button {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.content-slider-special-action-banner .slick-active button {
  border-color: #62c900 !important;
  height: 10px !important;
  width: 10px !important;
}

.content-slider-special-action-banner .slick-dots li button:before {
  color: transparent !important;
}

.slick-slider-item-banner {
  width: 100%;
  height: 20vw;
  /* box-sizing: border-box;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 480px; */
  /* position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  bottom: 0px;
  margin: 0 auto;
  background-position: 50%; */
}

.home-page-blocks {
  /* color: #fff; */
  width: 100%;
  /* display: inline-block; */
}

.timer-item {
  font-size: 30px;
  margin-top: 15px;
}

.timer-item span {
  /* background: #e5e5e5;
  padding: 4px 10px;
  border-radius: 5px; */
}

.action-block-content-body img {
  /* margin-bottom: 18px; */
  /* width: 150px; */
  width: 50px;
  /* margin-left: -8px; */
}

.shop-item-btn-action {
  background: #eb0e4c !important;
  color: #fff;
  float: right;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none;

  display: inline-block;
  background: #e3e3e3;
  height: 73px;
  width: 90px;
  font-size: 45px;
  text-align: center;
  border-radius: 8px;
}

.body-spec-action p {
  font-size: 19px;
  margin-bottom: 10px;
}

.action-block-price {
  background: #eb0e4c;
  color: #fff;
  font-size: 21px;
  border-radius: 5px;
  padding: 8px;
  margin-top: 0px;
  position: relative;
}

.action-block-price-action {
  background-color: #e1e1e1;
  color: #000;
}

.action-block-price-advance {
  margin-top: 5px;
}

.short-desc-action-block {
  font-size: 13px;
  margin-top: 15px;
  font-weight: 100;
}

.action-block-content-body h1 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 0px;
  line-height: 1.3;
}

.action-block-content-body h3 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 0px;
  line-height: 1.3;
}

.action-block-content-body p {
  font-size: 22px;
  font-weight: 400;
  margin-top: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price-item-value-action {
  font-weight: bold;
  font-size: 25px;
  position: absolute;
  top: 3px;
  right: 10px;
}

.price-item-action {
  position: relative;
  padding: 8px;
  font-size: 19px;
  color: #9b9b9b;
  font-weight: 500;
}

.slider-banner {
  width: 500px;
  height: 500px;
  float: left;
  /* background-color: red; */
  margin-bottom: 20px;
}

.action-block {
  /* background-color: green; */
  width: calc(100% - 500px);
  height: 500px;
  float: left;
  margin-bottom: 20px;
}

.action-block .loyalty-msg-holder {
  padding: 0 30px;
}

.news-block {
  /* background-color: blue; */
  width: 500px;
  height: 500px;
  float: left;
  margin-bottom: 20px;
  box-shadow: 0px 3px 35px 0px #cecece;
  border-radius: 15px;
  position: relative;
}

#news-item-holder {
  border: 2px solid #62C900 !important;
}

.news-item {
  border-top: 3px solid #ebecf1;
  padding-top: 10px;
  display: flex;
  padding-bottom: 10px;
}

.news-date-item {
  flex: 1 1;
  justify-content: center;
  align-items: center;
}

.news-date-item span {
  border-radius: 20px;
  padding: 2px 15px;
  margin-right: 15px;
  color: #000;
}

.news-body-item {
  flex: 5 1;
}

.news-notification-item i {
  color: #c6c6c6;
}

.news-notification-item {
  flex: 2 1;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  padding-right: 5px;
}

.news-notification-item span {
  background: #fe0406;
  color: #fff;
  padding: 0px 18px;
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 20px;
  font-size: 15px;
}

.price-item-action .price-item-value-action {
  color: #9b9b9b;
  text-decoration: line-through;
}

.news-body-item h4 {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-box-item-button-link {
  margin-top: 20px;
}

.news-text-body {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.news-block-content {
  padding: 25px 20px;
}

.news-block-footer {
  width: 100%;
  background: #ff9304;
  position: absolute;
  bottom: 0;
  padding: 8px 20px;
  text-align: right;
  font-weight: 500;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 18px;
}

.news-block-title h3 {
  font-size: 30px;
  color: #808080;
}

.big-banner-block {
  /* background-color: purple; */
  width: calc(100% - 525px);
  height: 500px;
  float: left;
  position: relative;
  margin-bottom: 20px;
  margin-right: 25px;
}

.big-banner-block img {
  width: 100%;
}

.block-slider-home-banner img {
  width: 100%;
  border-radius: 15px;
}

.banner-block-home-item img {
  width: 100%;
  border-radius: 15px;
}

.home-page-blocks .slick-arrow {
  display: none !important;
}

.body-spec-action h1 {
  font-size: 26px;
  margin-bottom: 0px;
  font-weight: bold;
}

.special-block-action {
  padding: 15px;
  box-shadow: 9px 4px 18px 1px #cecece;
  margin-left: 10px;
  border-radius: 15px;
}

.action-block-content {
  box-shadow: 0px 3px 35px 0px #cecece;
  height: 100%;
  border-radius: 15px;
  margin-left: 22px;
  padding: 30px 27px;
  display: flex;
}

.action-block-content-image img {
  height: 100%;
  border-radius: 10px;
}

.action-block-content-image {
  flex: 1.2 1;
}

.action-block-content-body {
  flex: 1 1;
  margin-left: 20px;
  position: relative;
}

.action-block-content-no-content {
  justify-content: center;
}

.action-block-content-no-content img {
  border-radius: 15px !important;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  object-fit: cover;
}

.timer-action-block {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.timer-item div {
  display: inline-block;
  background: #e3e3e3;
  height: 73px;
  width: 73px;
  font-size: 45px;
  text-align: center;
  border-radius: 8px;
}

/* @media (max-width: 1280px) {
  .navbar-category {
    min-width: 200px;
    max-width: 200px;
  }
  .category-content-sidebar {
    width: 200px;
  }
  .category-type-item {
    padding: 3px 10px;
  }
} */
.main-top-banner-home {
  display: none;
}

.special-block-image img {
  border-radius: 15px;
  width: 100%;
}

.special-block-image {
  border-radius: 15px;
  height: 100%;
  width: 100%;
  background-size: cover;
  bottom: 0px;
  margin: 0 auto;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 15px;
}

.content-special-image-block {
  flex: 1 1;
  position: relative;
}

.special-block-items {
  flex: 1.9 1;
  padding: 0px 30px;
}

.special-block-title-main h2 {
  text-transform: uppercase;
  font-size: 26px;
  border-bottom: 2px solid #eb0e4c;
  padding-bottom: 5px;
  font-weight: bold;
  /* color: #e6264b; */
}

.item-slider-special-action {
  border-right: 1px solid #cacaca;
  padding: 0px 20px;
  height: 100%;
  position: relative;
}

.action-title-brand {
  margin-bottom: 0px;
  font-size: 0.7vw;
}


.special-action-block {
  margin-bottom: 25px;
  width: 100%;
}

.slick-next:before,
.slick-prev:before {
  color: #cacaca;
  font-size: 40px;
}

.content-slider-image-product .slick-prev {
  width: 40px !important;
  height: 40px !important;
  z-index: 1;
}

.content-slider-image-product .slick-next {
  width: 40px !important;
  height: 40px !important;
}

.slick-next,
.slick-prev {
  width: 30px !important;
  height: 30px !important;
}

.spec-action-price {
  font-size: 22px;
}

.spec-action-price {
  color: #e6264b;
  font-weight: bold;
}

.spec-action-price {
  flex: 2 1;
  text-align: right;
}

.spec-action-price-offline {
  font-size: 22px;
  text-align: left;
  color: #e6264b;
  font-weight: bold;
}

.spec-action-price-old {
  font-size: 0.8vw;
  text-decoration: line-through;
  -webkit-text-decoration-color: #e6264b;
          text-decoration-color: #e6264b;
}

.spec-action-price-old {
  font-size: 18px;
  color: #666;
  font-weight: bold;
}

.spec-action-price-old {
  flex: 2 1;
  text-align: right;
}

.spec-action-text-right {
  flex: 2 1;
  text-align: right;
}

.slider-special-action-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.spec-action-ident {
  flex: 1 1;
}

.spec-action-res {
  flex: 2 1;
  text-align: right;
}

.slider-special-action-detail-price {
  display: flex;
  font-size: 16px;
}

.spec-action-qty {
  flex: 1 1;
}

.slider-special-action-button {
  width: 100%;
}

.add-btn-spec-input-qty {
  /* flex: 1; */
  width: calc(50% - 15px);
  float: left;
  position: relative;
}

.minus-item-qty {
  position: absolute;
  height: 100%;
  padding: 0px 7px;
  cursor: pointer;
  font-weight: bold;
}

.product-add-to-cart-price .add-btn-spec-input-qty {
  width: 100%;
  float: left;
  position: relative;
}

.product-add-to-cart-price .minus-item-qty {
  position: absolute;
  height: 100%;
  padding: 5px 7px;
  cursor: pointer;
  font-weight: bold;
}

.plus-item-qty {
  position: absolute;
  height: 100%;
  padding: 0px 5px;
  right: 0;
  cursor: pointer;
  font-weight: bold;
}

.button-div-item-qty .plus-item-qty:hover,
.button-div-item-qty .minus-item-qty:hover {
  background-color: #e6e6e6;
  border-radius: 5px;
}

.product-add-to-cart-price .plus-item-qty {
  position: absolute;
  height: 100%;
  padding: 5px 5px;
  right: 0;
  cursor: pointer;
  font-weight: bold;
}

.product-add-to-cart-price .button-div-item-qty .plus-item-qty,
.product-add-to-cart-price .button-div-item-qty .minus-item-qty {
  top: 0;
}

.btn-add-product-detail {
  background: #f7395d;
  border: 1px solid #f7395d;
  color: #fff;
  width: 145px;
  padding: 6px 0px 5px 0px;
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
  cursor: pointer;
  font-size: 14px;
}

.btn-add-product-detail:hover {
  background: #fff;
  color: #f7395d;
  border: 1px solid #f7395d;
}

.btn-add-product-detail svg {
  fill: #ffff;
  width: 20px;
  margin-bottom: 5px;
  margin-right: -3px;

}

.btn-add-product-detail:hover svg {
  fill: #f7395d;
}


.add-btn-spec-action {
  float: right;
  width: 50%;
}

.add-btn-spec-input-qty input {
  width: 100%;
  text-align: center;
  padding: 0px 20px;
}

.btn-spec-action {
  text-align: center;
  width: 100%;
  background: #e6264b;
  border-radius: 7px;
  color: #fff;
  padding: 4px 0px;
  cursor: pointer;
}

.btn-spec-action:hover {
  background-color: #fff;
  border: 1px solid #e6264b;
  color: #e6264b;
}

.btn-sm svg {
  fill: white;
  width: 20px;
  margin-left: -5px;
  margin-right: -3px;
  margin-bottom: 4px;
}

.slider-item-spec:hover {
  box-shadow: 0 0 12px 10px rgba(0, 6, 22, 0.1);
}

.action-title-name a:hover {
  color: #337ab7;
}

.slider-special-action-image {
  padding: 20px;
  position: relative;
}

.content-slider-special-action {
  height: 100%;
  padding-top: 50px;
}

.content-slider-special-action .slick-slider {
  max-width: 100%;
  display: grid;
  height: 100%;
}

.content-slider-special-action .slick-track {
  height: 100%;
}

.content-slider-special-action .slick-slide {
  height: 100%;
}

.content-slider-special-action .slick-slide>div {
  height: 100%;
}

.content-slider-special-action-banner {
  height: 100%;
}

.content-slider-special-action-banner .slick-slider {
  max-width: 100%;
  display: grid;
  height: 100%;
}

.content-slider-special-action-banner .slick-track {
  height: 100%;
}

.content-slider-special-action-banner .slick-slide {
  height: 100%;
}

.content-slider-special-action-banner .slick-slide>div {
  height: 100%;
}

.special-block-title-main {
  height: 0px;
}

.slider-item-spec {
  height: 100%;
}

.catalog-banner-product-content {
  box-shadow: 0px 3px 35px 0px #cecece;
  border-radius: 15px;
  margin-bottom: 20px;
  display: flex;
  height: 230px;
  border-bottom: 19px solid #e6264b;
}

.catalog-product-image img {
  height: 100%;
  padding-right: 8px;
}

.catalog-product-image {
  flex: 1 1;
  text-align: right;
  padding: 12px 0px;
}

.catalog-product-body {
  flex: 3 1;
}

.catalog-product-body-content-text {
  display: flex;
  padding: 0px 30px;
  margin-top: 10px;
}

.title-catalog-product-action {
  font-size: 27px;
  line-height: 1;
}

.catalog-product-body-text {
  flex: 2 1;
  padding: 12px 4px;
}

.catalog-product-body-price {
  flex: 1 1;
  padding: 12px 0px;
  padding-top: 0px;
  padding-left: 30px;
}

table.table-spec-product-action {
  margin: 0 0 auto auto;
  /* width: 250px; */
  font-size: 18px;
  width: 100%;
}

.price-row-action {
  color: #e6264b;
  font-size: 25px;
  font-weight: bold;
}

.qty-bottom-price-product-action span {
  font-weight: bold;
  padding-top: 10px;
}

.old-price-catalog-price {
  text-decoration: line-through;
  /* text-decoration-color: #e6264b; */
  color: #797979;
  font-size: 15px;
}

.catalog-price-item .new-price-catalog-price {
  font-weight: bold;
  /* color: #e6264b; */
  font-size: 17px;
}

.price-catalog-red {
  color: #eb0e4c;
}

.price-catalog-red-table {
  color: #eb0e4c;
  font-weight: bold;
}

.catalog-price-item {
  text-align: right;
}

.qty-bottom-price-product-action {
  text-align: right;
  padding-bottom: 10px;
}

td.price-table-row-product-action {
  text-align: right;
}

.price-row-old {
  text-decoration: line-through;
}

.catalog-product-image .img-logo-brand-product {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 80px;
  height: 80px;
}

.img-product-action {
  border-right: 2px solid #e6264b;
}

.content-span-product-badges {
  display: flex;
  margin-left: 50px;
}

.badge-item-product-action {
  /* max-width: 150px; */
  margin-right: 15px;
  padding: 4px 8px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #fff;
  text-align: center;
}

.badge-item-product-action.action-badge {
  background: #f7395f;
}

.badge-item-product-action.new-badge {
  background: #6ec53a;
}

.badge-item-product-action.ident-badge {
  background: #2d2d2f;
  font-size: 15px;
}

.specification-catalog-product-action {
  font-size: 15px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.input-special-product-action {
  width: 100%;
}

.btn-add-special-product-action {
  background: #e6264b;
  color: #fff;
  font-size: 16px;
  line-height: 10px;
  border-radius: 3px;
  text-align: center;
  padding: 7px 0px;
  cursor: pointer;
  border: 1px solid #e6264b;
}

.btn-add-special-product-action:hover {
  color: #e6264b;
  background-color: #fff;
}

.btn-add-special-product-action:hover svg {
  fill: #e6264b;
}

.btn-add-special-product-action svg {
  height: 100%;
  fill: #ffffff;
}

.menu-mobile-sidebar {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  margin: 0 0 0 100%;
  z-index: 9997;
  transition: all .5s ease-in-out;
}

.menu-mobile-sidebar.toggle-mob-menu {
  margin-left: 0;
  padding: 0;
}

.menu-open-modal {
  z-index: 9999;
  /* flex: 0.2; */
  width: 35px;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 5px;
}

.menu-item-icon {
  display: block;
  width: 33px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;

  background: #000;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: all .3s ease-in-out;

  /* display: block;
  background: #0c0c0d;
  width: 80%;
  height: 3px;
  margin: 2px auto;
  transition: all .3s ease-in-out;
  text-align: right;
  float: right; */
}

.logo-mobile-header-sidebar .navbar {
  border-bottom: 1px solid #e6e6e6;
}

.opened-sidebar-mobile .bar1 {
  transform: rotate(45deg) translate(7px, 6px);
  background: #0c0c0d;
}

.opened-sidebar-mobile .bar2 {
  opacity: 0;
}

.opened-sidebar-mobile .bar3 {
  transform: rotate(-45deg) translate(6px, -6px);
  background: #0c0c0d;
}

.title-catalog-sidebar {
  text-transform: uppercase;
  background: #62c900;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}

.mobile-content-menu {
  height: calc(100vh - 77px);
  overflow-y: auto;
}

.catalog-list-sidebar-mobile {
  padding-bottom: 40px;
  margin-bottom: 40px;
  margin-top: 10px;
}

.top-navigation-mobile {
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
  background-color: #fff;
}

.intro-header .bottom-navigation-mobile {
  background-color: #000;
}

.intro-header .header-menu a {
  display: inline-block;
  color: #f1f1f1;
  width: 100%;
  line-height: 1.42857143;
  vertical-align: middle;
  padding: 10px 20px;
  border-bottom: 1px dotted #ccc;
}

.intro-header .menu-list-sidebar-mobile ul {
  margin-top: 15px;
}

.intro-header .menu-list-sidebar-mobile li {
  padding: 0;
}

.header-logo-m {
  background: #000;
}

.intro-header .menu-item-icon {
  background: #fff;
}

.intro-header .cart-shop-icon-user-login,
.intro-header .cart-shop-icon-search-mobile {
  color: #fff;
}

.ewe-logo-m {
  fill: #fff;
}

.bottom-navigation-mobile {
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
  background-color: #fff;
  padding-top: 0px;
  margin-top: -1px;
}

.search-mobile-box input {
  width: 100%;
}

.search-mobile-box {
  flex: 3 1;
  position: relative;
}

.mobile-search-box {
  display: none;
}

.mobile-search-box.active {
  display: block;
  padding-bottom: 10px !important;
}

.cart-shop-icon-search-mobile {
  font-size: 25px;
  margin-right: 10px;
}

.search-mobile-box span {
  position: absolute;
  right: 11px;
  top: 5px;
  font-size: 18px;
  color: #666;
}

.product-catalog-badge-image-360 {
  background-color: #666;
  border-radius: 4px;
  padding: 2px;
  font-size: 0px !important;
}

.product-catalog-badge-image-360 img {
  width: 100%;
}

.input-search-box-mobile {
  border-radius: 20px;
  background: #e1e1e1;
  color: #666;
  border: 1px solid #ced4da;
  padding: 2px 12px;
  height: calc(1.5em + .75rem + 0px);
}

.menu-open-modal.opened-sidebar-mobile {
  /* top: 20px;
  position: absolute;
  right: 16px;
  height: 50px;
  width: 50px; */
}

.opened-sidebar-mobile .menu-item-icon {
  width: 35px;
  margin-bottom: 15px;
  transform-origin: 4px 1px;
}

.cart-shop-icon {
  font-size: 25px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  width: 30px;
}

.cart-shop-icon-user-login {
  font-size: 25px;
  position: relative;
  cursor: pointer;
  color: #212529;
  margin-right: 10px;
}

.cart-shop-icon-favorite {
  font-size: 25px;
  cursor: pointer;
  margin-right: 10px;
  width: 28px;
}

.cart-shop-icon span {
  position: absolute;
  top: 0;
  right: -10px;
  background: red;
  color: #fff;
  font-size: 11px;
  border-radius: 30px;
  height: 17px;
  width: 17px;
  text-align: center;
  font-weight: bold;
}

.container-login-form .alert {
  margin-top: 15px;
  display: none;
}

.container-coins-reg-form .alert {
  margin-top: 15px;
  display: none;
}

.show-error-message {
  display: block !important;
}

.login-button-home-page {
  color: #fff;
}

.login-button-home-page:hover {
  color: #fff;
}

.login-mobile-link-header {
  color: #212529;
}

[data-rmiz-wrap="hidden"] {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
}


[data-rmiz-wrap="visible"] {
  position: initial !important;
  display: flex !important;
  align-items: flex-start !important;
}

.product-detail-content-box {
  padding: 0px 0px !important;
}

.search-mobile-box .search-box {
  padding: 0px 0px;
  margin-top: 0px;
}

.modal-close-product-detail {
  position: absolute;
  top: 0px;
  right: 25px;
  font-size: 40px;
  cursor: pointer;
}

table.table-shor-desc {
  width: 100%;
}

.table-short-description-items {
  margin-top: 5px;
  border-top: 2px solid #e2e3e7;
  padding-top: 10px;
  border-bottom: 2px solid #e2e3e7;
  padding-bottom: 10px;
}

.product-detail-margine-block-box {
  margin-top: 5px;
}

.td-name-short-desc {
  width: 200px;
  border-right: 2px solid #e2e3e7;
  padding-right: 15px;
  vertical-align: initial;
}

.td-value-short-desc {
  padding-left: 30px;
  vertical-align: initial;
}

.button-export-product-detail {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  float: right;
}

.button-export-product-detail svg {
  width: 18px;
  fill: #fff;
  margin-right: 5px;
  margin-top: -2px;
}

.button-export-product-detail a {
  margin-right: 1px !important;
  margin-top: 10px;
}

.btn-product-exp {
  padding: 4px 12px;
  border-radius: 5px;
}

.btn-offer-product-detail {
  margin-right: 10px;
  color: #fff;
  background-color: #39d0bf;
  border: 1px solid #39d0bf;
  cursor: pointer;
}

.btn-offer-product-detail:hover {
  border: 1px solid #31b8a8;
  color: #fff;
  background-color: #31b8a8;
}

.btn-pdf-product-detail {
  color: #fff;
  background-color: #1ea8c5;
  border: 1px solid #1ea8c5;
  cursor: pointer;
}

.btn-pdf-product-detail:hover {
  border: 1px solid #1ea8c5;
  color: #fff;
  background-color: #1b95ad;
}

.top-holder-margin {
  display: flex;
  border-bottom: 2px solid #e2e3e7;
  padding-bottom: 3px;
}

.advance-radio-btn-product-detail {
  flex: 7 1;
}

.margin-radio-btn-product-detail {
  flex: 1 1;
  margin-right: 25px;
}

.margine-radio-btn-product-detail {
  flex: 1 1;
}

.product-table-margin .form-group {
  margin-bottom: 0rem;
}

/* .product-margin-box {
  display: flex;
} */

.product-margin-block-content {
  margin-top: 15px;
}

.close-action-modal {
  position: absolute;
  right: 0;
  top: -70px;
  color: #fff;
  font-size: 50px;
  cursor: pointer;
}

.action-modal-popup .action-block {
  width: 100%;
  margin-bottom: 0px;
}

.action-modal-popup .action-block-content {
  margin-left: 0px;
  padding: 10px;
  box-shadow: none;
}

.action-modal-popup .modal-content {
  border-radius: 15px;
}

.menu-modal-hide {
  /* display: none; */
  visibility: hidden !important;
  opacity: 0 !important;
  transition: visibility 0s, opacity 0.2s linear !important;
}

.menu-modal-show {
  /* display: block; */
  visibility: visible !important;
  opacity: 1 !important;
}

.modal-root {
  position: fixed;
  background: #2d2d2ffa;
  width: 900px;
  height: 100vh;
  z-index: 23;
  left: 200px;
  border-left: 2px solid #fff;
  color: #fff;
  padding: 85px 40px 0px 40px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
}

.collapsed-sidebar-modal {
  left: 60px !important;
}

.active-sidebar-image-info .info-image-sidebar {
  margin-left: 5px;
}

.menu-modal-item-header {
  font-size: 35px;
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
  flex-direction: row;
  width: 100%;
}

.cart-name-modal-menu {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: 15px;
  flex: 1 1;
}

.block-action-slider-cart-button svg {
  margin-left: 4px;
  margin-top: 2px;
  margin-right: -2px;
  margin-bottom: 3px;

}

.menu-modal-item-header-button {
  width: 190px;
  margin-right: 20px;
}

.menu-modal-item-header-button svg {
  width: 20px;
  fill: white;
  margin-left: 5px;
}

.menu-modal-item-header-button:hover svg {
  fill: #111;
}

.menu-modal-list-links ul {
  margin-top: 40px;
  font-size: 30px;
  cursor: pointer;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  max-height: 675px;
  overflow-y: auto;
}

.menu-modal-list-links a {
  color: #fff;
  font-weight: 400;
}

.close-menu-modal-popup {
  position: absolute;
  top: 20px;
  right: 50px;
  font-size: 35px;
  cursor: pointer;
}

.close-menu-modal-popup:hover {
  transform: scale(1.2);
  color: #e6264b;
}

.settings-cart-button {
  font-size: 17px;
  border: 1px solid #fff;
  padding: 4px 13px;
  border-radius: 12px;
  cursor: pointer;
  float: right;
  margin-top: 10px;
  color: #fff;
}

.shopping-cart-items-list ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 15px;
  max-height: 780px;
  overflow-y: auto;
  padding-right: 10px;
}

.shopping-cart-items-list li {
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #474745;
}

.shopping-cart-items-list .empty-cart {
  color: #fff;
}

.cart-item-image-list {
  flex: 1.2 1;
}

.cart-item-name-list {
  flex: 7 1;
}

.cart-item-qty-list {
  flex: 2 1;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 12px;
}

.cart-item-price-list {
  flex: 2 1;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-right: 10px;
}

.img-cart-list-item {
  width: 60px;
  background: #fff;
  border-radius: 8px;
  padding: 5px;
}

.item-cart-name {
  font-weight: 600;
  line-height: 16px;
  color: #fff;
}

.cart-item-ident-list {
  font-size: 12px;
}

.qty-button-cart-item input {
  width: 100%;
  text-align: center;
  padding: 0px 20px;
  background-color: #2d2d2f;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 5px;
}

.qty-button-cart-item {
  position: relative;
  width: 80px;
}

.remove-wish-list-item {
  position: absolute;
  color: #eb0e4c;
  font-size: 15px;
  top: 22px;
  opacity: 0.9;
  cursor: pointer;
}

.wish-list-row-table {
  position: relative;
}

.remove-cart-item {
  position: absolute;
  color: #eb0e4c;
  font-size: 15px;
  top: -5px;
  right: 0px;
  opacity: 0.9;
  cursor: pointer;
}

.cart-item-price-value {
  font-weight: 500;
}

.modal-root {
  /* opacity: .98; */
}

.shopping-cart-modal-total-sum {
  margin-top: 40px;
  padding: 12px 0px;
  text-align: right;
  border-top: 1px solid #e6264b;
  border-bottom: 1px solid #e6264b;
}

.price-cart-modal {
  font-size: 17px;
  text-transform: uppercase;
  color: #c5c5c5;
}

.price-cart-modal span {
  font-size: 22px;
}

.total-price-cart-modal {
  font-size: 20px;
  text-transform: uppercase;
  margin-top: -5px;
  font-weight: 700;
}

.total-price-cart-modal span {
  font-size: 23px;
}

.btn-cart-modal-finish {
  background: #e6264b;
  padding: 5px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.button-finish-cart-modal {
  margin-top: 13px;
  margin-bottom: 10px;
}

.button-finish-cart-modal a {
  color: #fff;
}

.button-finish-cart-modal a:hover {
  text-decoration: none;
}

.dashboard-block-content {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
}

.delivery-boxes-content {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
}

.financial-boxes-content {
  display: flex;
  position: relative;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.dashboard-item-content:first-child {
  border-bottom: 2px solid #fff;
}

.dashboard-item-content {
  padding-bottom: 25px;
  margin-top: 40px;
}

.dashboard-box-info {
  background: #272a31;
  padding: 27px 10px;
  width: 30%;
  /* border-bottom: 5px solid #3390dd; */
  /* border-bottom: 5px solid #959595; */
  border-radius: 10px;
  display: flex;
  box-shadow: 5px 6px 8px 0px #0e1016;
  margin-bottom: 20px;
}

.dashboard-img-ly-title img {
  max-width: 150px;
}

.dashboard-box-financial {
  background: #272a31;
  padding: 27px 10px;
  width: 30%;
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  box-shadow: 5px 6px 8px 0px #0e1016;
  margin-right: 30px;
}

.menu-financial-boxes {
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 2px solid #fff;
}

.dashboard-box-delivery {
  background: #272a31;
  padding: 5px 10px;
  width: 30%;
  /* border-bottom: 5px solid #959595; */
  border-radius: 10px;
  box-shadow: 5px 6px 8px 0px #0e1016;
  text-align: center;
  color: #fff;
}

.delivery-item-box-menu {
  background: #272a31;
  padding: 5px 10px;
  width: 30%;
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow: 5px 6px 8px 0px #0e1016;
  text-align: center;
}

.icon-dashboard-info {
  background: #3b3e45;
  font-size: 24px;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 40px;
  box-shadow: 0px 3px 5px 0px #0e1016;
}

.dashboard-box-info-icon {
  flex: 1 1;
}

.dashboard-box-info-text {
  flex: 3 1;
  text-align: center;
  line-height: 23px;
  padding: 0px 8px;
  color: #fff;
}

.dashboard-box-info-name {
  font-weight: 500;
  border-bottom: 1px solid #fff;
  margin-bottom: 4px;
}

.icon-dashboard-info i {
  margin-top: 12px;
  color: #fff;
}

.dashboard-box-red {
  border-color: #d21e5b;
}

.dashboard-box-blue {
  border-color: #458bc7;
}

.dashboard-box-purple {
  border-color: #653aa6;
}

.dashboard-box-silver {
  border-color: #7d7d7d;
}

.dashboard-box-green {
  border-color: #85c202;
}

.dashboard-box-color-green {
  color: #85c202;
}

.dashboard-box-color-red {
  color: #d21e5b;
}

.dashboard-box-icon-color-red {
  background-color: #d21e5b;
}

.dashboard-box-lightblue {
  border-color: #6de7da;
}

.dashboard-box-info-value {
  font-weight: bold;
  font-size: 24px;
}

.menu-dashboard-content {
  margin-top: 25px;
  padding-bottom: 40px;
}

.dashboard-value-item {
  line-height: 20px;
  margin-right: 23px;
}

.dashboard-value-item-2 {
  line-height: 20px;
}

.dashboard-value-price {
  line-height: 20px;
}

.dashboard-item-radio-name {
  font-size: 15px;
  text-align: center;
  margin-top: 15px;
}

.cart-modal-item-radio-name {
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
}

.dashboard-menu-header {
  align-items: center;
  display: flex;
}

.dashboard-title-menu {
  flex: 1 1;
}

.dashboard-item-radio-buttons {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.dashboard-item-radio-buttons label {
  cursor: pointer;
}

.empty-cart-modal {
  margin-top: 25px;
  font-size: 20px;
}

.dashboard-box-deliver-title {
  font-weight: 700;
  /* border-bottom: 1px solid #f7055b; */
  /* border-bottom: 1px solid #959595; */
  font-size: 15px;
}

.dashboard-box-deliver-subtitle {
  font-size: 14px;
  line-height: 15px;
  margin-top: 5px;
}

.dashboard-box-deliver-value {
  font-weight: 700;
  font-size: 30px;
  margin: -5px 0px;
}

.dashboard-box-deliver-info-text {
  /* color: #f7055b; */
  color: #959595;
  font-size: 12px;
  font-weight: 500;
}

.dashboard-item-loaylty-content {
  margin-top: 25px;
  border-bottom: 1px solid #fff;
  padding-bottom: 25px;
}

.trend-dashboard-content {
  padding: 0px 7px;
}

.line-ly-separate {
  width: 100%;
  height: 2px;
  background: #fff;
  margin-left: 15px;
}

.dashboard-img-ly-title {
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
}

.dashboard-loyalty-items {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.dashboadr-item-loyalty {
  flex: 1 1;
  text-align: center;
  border-right: 1px solid #f7055b;
}

.dashboadr-item-loyalty:last-child {
  border-right: none;
}

.dashboard-item-loyalty-title {
  font-size: 18px;
  color: #efb836;
  font-weight: 500;
}

.dashboard-item-loyalty-value-big {
  font-size: 75px;
  font-weight: bold;
}

.dashboard-item-loyalty-value-big .fa-check {
  color: green;
}

.dashboard-item-loyatly-per {
  font-weight: bold;
  color: #f7055b;
  color: #1f1538;
  font-size: 40px;
}

.loyalty-info-box-item .fa-check {
  font-size: 80px;
  color: green;
  margin-top: 20px;
}

.loyalty-info-box-item p {
  font-size: 30px;
  font-weight: 500;
}

.dashboard-item-loyalty-desc {
  font-size: 12px;
}

.dashboard-ly-name {
  color: #f7055b;
  font-weight: 500;
}

.dashboard-ly-value {
  font-weight: bold;
  font-size: 26px;
}

.dashboard-loyalty-target {
  line-height: 25px;
  margin-bottom: 14px;
}

.dashboard-loyalty-traffic {
  line-height: 25px;
}

.menu-modal-list-links li {
  border-bottom: 2px solid #626262;
  font-size: 20px;
  padding: 8px 8px;
}

.menu-modal-list-links li:hover {
  background: #ffffff1a;
}

.menu-modal-list-links a:hover {
  text-decoration: none;
}

.menu-delivery-boxes {
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 2px solid #fff;
}

.item-list-sidebar a:hover {
  text-decoration: none;
}

.item-list-sidebar a:hover::after,
.shopping-cart-menu-item::after {
  /* content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%; */
}

.col-sm-4-cust {
  /* width: 260px; */
  width: 264px;
  padding: 0px;
  margin-right: 25px;
}

.col-sm-4-cust.collapsed-sidebar-catalog {
  width: 284px;
}

.catalog-banner-padding {
  padding: 0px;
}

.container-catalog-content {
  padding: 30px;
  min-height: 100vh;
}

.container-fluid-catalog-content {
  /* height: 100%; */
}

.container-custom-page-content {
  padding: 0px !important;
}

.container-terms-content {
  padding: 30px;
}

.container-terms-content .banner-image-on-top-page {
  margin-bottom: 10px;
}

.container-main-content {
  padding: 30px;
}

.container-main-cart-content {
  padding: 30px;
}

.catalog-content-box {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: left; */
  width: 100%;
  position: relative;
}

.catalog-ident-box {
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-top: 20px;
  padding-bottom: 4px;
}

.catalog-box-compare-wish {
  display: flex;
  flex: 2 1;
  font-size: 11px;
}

.catalog-content-compare {
  flex: 1 1;
}

.catalog-box-compare-wish .form-group:hover {
  background-color: #e6e6e6;
}

.catalog-box-compare-wish .form-group:hover {
  background-color: #e6e6e6;
}

.compare-button-catalog:hover {
  background-color: #e6e6e6;
}

.wishlist-button-catalog:hover {
  background-color: #e6e6e6;
}


.catalog-content-wish {
  flex: 1 1;
}

.catalog-ident-item-block {
  font-size: 16px;
  font-weight: 500;
}

.catalog-box-compare-wish .form-group {
  margin-bottom: 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 4px;
  margin-top: 0px;
  margin-left: 5px;
}

.news-content-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
}

.news-item-box {
  width: 360px;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin: 0px 30px 30px 0px;
}

.news-item-box2 {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin: 0px 30px 30px 0px;
}

.news-container-content {
  padding: 35px 60px;
}

.news-content-center {
  display: flex;
}

.news-box-item-image {
  height: 260px;
  background: #fafafa;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  border-bottom: 1px solid #ccc;
}

.news-box-item-image2 {
  flex: 1.5 1;
  /* height: 260px; */
  background: #fff;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  /* border-bottom: 1px solid #ccc; */
  align-self: center;
}

.news-box-item-badge {
  position: absolute;
  bottom: -13px;
  left: 20px;
  background: red;
  color: #fff;
  padding: 0px 16px;
  border-radius: 20px;
  font-size: 18px;
}

.news-box-item-badge2 {
  left: 20px;
  background: red;
  color: #fff;
  padding: 0px 16px;
  border-radius: 20px;
  font-size: 18px;
}

.news-box-item-body {
  padding: 15px 20px 0px 20px;
  flex: 1 1;
}

.news-box-item-body2 {
  padding: 20px;
  flex: 3 1;
}

.news-box-item-date {
  font-size: 14px;
}

.news-box-item-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.news-box-item-description {
  /* line-height: 20px; */
  font-size: 16px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical; */
  /* min-height: 100px; */
}

.news-box-item-button {
  padding: 5px 20px 18px 20px;
  text-align: center;
}

.news-btn-box-item {
  border-top: 1px solid #ccc;
  margin-top: 15px;
  cursor: pointer;
  text-align: center;
  padding-bottom: 20px;
}

.news-block-footer a:hover {
  text-decoration: none;
}

.news-block-footer a {
  color: #000;
  text-decoration: none;
}

.editing-cart-item-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.wish-list-table .editing-cart-items-table {
  width: 100%;
  max-height: 100%;
  overflow: auto;
}

.editing-cart-items-table-custom {
  overflow: auto;
}

.editing-cart-items-table {
  width: 100%;
}

.MTableToolbar-searchField-10 {
  padding-left: 0 !important;
}

/* .MuiToolbar-gutters {
  padding-left: 0 !important;
} */
.MuiTableRow-hover:hover>td:first-child {
  text-decoration: underline;
  color: #337ab7 !important;
}

.material-icons {
  font-size: 0.9rem !important;
}

.main-cart-item-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.main-cart-items-table {
  width: 100%;
  /* max-height: 800px; */
  /* max-height: 35vh; */
  /* overflow: hidden; */
}

.main-cart-items-table-custom {
  overflow: auto;
  max-height: 30vh;
}

.item-detail-cart-forms {
  width: 100%;
}

.main-cart-item-image a {
  position: relative;
}

.main-cart-item-image .percetange-loyatly-product {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -16px;
  right: -10px;
  background-color: #138496;
}

td.main-cart-item-image.main-cart-item-value img {
  width: 63px;
}

.main-cart-items-table tbody td {
  vertical-align: middle;
  font-size: 13px;
}

td.main-cart-item-name.main-cart-item-value {
  font-weight: 600;
  line-height: 21px;
}

.main-cart-items-table th {
  text-transform: uppercase;
  padding: 6px 8px;
  font-size: 13px;
  border-top: none;
}

.main-cart-items-table td {
  padding: 2px 8px;
}

.main-cart-items-table tbody {
  font-size: 14px;
}

td.main-cart-item-qty {
  max-width: 100px;
  min-width: 100px;
}

/* td.main-cart-item-price.main-cart-item-value {
  width: 135px;
}
td.main-cart-item-total.main-cart-item-value {
  width: 135px;
}
td.main-cart-item-tax.main-cart-item-value {
  width: 135px;
}
td.main-cart-item-total.main-cart-item-value {
  width: 135px;
}
td.main-cart-item-sum-total.main-cart-item-value {
  width: 135px;
}
td.main-cart-item-voucher-value.main-cart-item-value {
  width: 135px;
} */
/* td.main-cart-item-name.main-cart-item-value .item-cart-td {
  min-width: 250px;
} */
.btn-voucher-editing-cart {
  display: inline-block;
}

.main-cart-total-sum-block {
  margin-top: 20px;
}

.main-cart-total-sum-block td {
  border-top: none;
  padding: 0px;
}

.modal-body .table-cart-sum table {
  max-width: 100%;
}

.table-cart-sum table {
  max-width: 370px;
  font-size: 20px;
  float: right;
}

td.main-cart-table-sum-value {
  font-weight: 700;
  text-align: right;
}

.total-sum-cart-table-row {
  /* border-top: 1px solid #666; */
}

.main-cart-table-sum-name {
  color: #666;
}

.total-sum-cart-main-td {
  border-top: 1px solid #ddd !important;
}

.small-info-promo-code {
  line-height: 14px;
  margin-bottom: 8px;
  color: #9b9b9b;
}

.empty-cart {
  margin-top: 50px;
  color: #9B9B9B;
  line-height: 0.5;
}

.empty-cart img {
  margin-bottom: 10px;
}

.button-remove-all-item {
  background: orange;
  color: #fff;
  padding: 5px 13px;
  border-radius: 6px;
  cursor: pointer;
}

.promo-code-content label {
  font-weight: bold;
}

.promo-code-button-check {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.promo-code-content {
  border: 1px solid #ccc;
  padding: 15px 20px;
  padding-bottom: 15px;
  border-radius: 5px;
  padding-bottom: 15px;
}

.btn-remove-all-cart {
  margin-top: 5px;
  text-align: right;
}

.cart-info-box-info {
  background: #efefef;
  margin-bottom: 10px;
  padding: 10px 12px;
  color: #666;
  font-weight: 500;
  border-radius: 5px;
  max-width: 500px;
  display: flex;
  /* align-items: center; */
}

.cart-info-box-info i {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  padding-top: 5px;
  margin-right: 10px;
}

.cart-info-box-info .success-icon {
  border: 2px solid green;
  color: green;
}

.cart-info-box-info .danger-icon {
  border: 2px solid red;
  color: red;
}

.cart-info-box-info .info-icon {
  border: 2px solid orange;
  color: orange;
}
.cart-info-box-info .info2-icon {
  border: 2px solid #11548b;
  color: #11548b;
}

.main-cart-delivery-content {
  border: 1px solid #ccc;
  padding: 15px 20px;
  border-radius: 5px;
  padding-bottom: 15px;
}

.main-cart-address-info-content {
  border: 1px solid #efefef;
  padding: 31px 20px;
  border-radius: 5px;
  background: #efefef;
}

.delivery-box-cart-main .form-group label {
  font-weight: bold;
}

.address-box-cart-main .form-group label {
  font-weight: bold;
}

.main-cart-items-table tbody tr:last-child>td {
  border-bottom: 1px solid #dee2e6;
}

.remove-main-cart-item {
  top: -5px;
  right: 0px;
  position: relative;
}

.catalog-filters-content {
  width: 100%;
  background: #fafafa;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
}

.catalog-filters-top-title {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.catalog-filters-top-title i {
  float: right;
  font-size: 25px;
  color: #666;
}

.catalog-filters-top-items.active {
  display: flex;
}

.catalog-filters-top-items {
  display: none;
  flex-wrap: wrap;
  justify-content: left;
}

.catalog-filter-top-item {
  background: #eff4f8;
  color: #000;
  margin: 0px 5px 5px 0px;
  padding: 4px 12px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.catalog-filter-top-item.active {
  background: #46c8d6;
  color: #fff;
}

.button-filters-top-submit.active {
  display: block;
}

.button-filters-top-submit {
  text-align: right;
  margin-top: 15px;
  display: none;
}

.btn-filter-submit {
  background: #46c8d6;
  color: #fff;
  padding: 4px 14px;
  float: right;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.catalog-selected-filters-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.catalog-selected-filters-items {
  flex: 2 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  margin-left: 10px;
}

.reset-btn-filters-catalog {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2px 10px;
  cursor: pointer;
  font-size: 14px;
}

.reset-btn-filters-catalog i {
  color: red;
}

.catalog-sort-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 0px;
  /* border-bottom: 1px solid #ccc; */
}

.catalog-sort-box-input {
  flex: 2 1;
}

.catalog-sort-select-input {
  width: 150px;
  margin-left: 10px;
}

.catalog-sort-box-title,
.catalog-selected-filters-title {
  font-weight: 500;
  font-size: 14px
}

.catalog-sort-box-view {
  margin-right: 10px;
  color: #666;
  display: flex;
}

.catalog-sort-box-view div.active svg {
  fill: grey;
}




/* .catalog-sort-box-view i {
  margin-right: 0px;
  font-size: 16px;
  padding: 4px;
  border-radius: 2px;
  cursor: pointer;
} */
.catalog-sort-box-export i {
  width: 25px;
  border: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  margin-top: -5px;
  margin-left: 5px;
}

.catalog-sort-box-export span svg {
  width: 29px;
  padding: 4px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  fill: green;
}

.catalog-sort-box-export span {
  width: 29px;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}

.catalog-sort-box-export span svg:hover {
  background: green;
  fill: #fff;
  border: 1px solid green;
  border-radius: 6px;
}

.catalog-sort-box-export a {
  width: 29px;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}

.catalog-sort-box-export a svg {
  width: 29px;
  border: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  fill: #FF0000;
}

.catalog-sort-box-export a svg:hover {
  background: #FF0000;
  fill: #fff;
  border: 1px solid #FF0000;
  border-radius: 6px;
}

.create-your-offer-button {
  margin-left: 20px;
  color: #FF0000;
  border-color: #FF0000;
}

.create-your-offer-button:hover {
  color: white;
  border-color: #FF0000;
  background-color: #FF0000;
}

.compare-page-icon {
  width: 100px;
  border: 1px solid #FF0000;
  padding: 4px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  color: #FF0000;
  fill: #FF0000;
}

.compare-page-icon svg {
  fill: #FF0000;
  margin-right: 2px;
  margin-left: 2px;
}

.compare-page-icon:hover svg {
  fill: #fff;
}

.compare-page-icon:hover {
  background-color: #FF0000;
  border: 1px solid #FF0000;
  border-radius: 6px;
}

.weekly-title {
  /* display: flex;
  justify-content: space-between; */
  /* align-items: center; */
  width: 100%;
  /* text-align: center; */
  border-bottom: 1px solid #ccc;
  margin: 0;
}

.weekly-title h1 {
  font-size: 40px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.weekly-title h1 span {
  font-weight: 400;
}

.weekly-title p {
  font-size: 18px;
  font-weight: 400;
  padding: 0 9rem;
}

.main-category-title {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ccc;
  margin: 0;
}

.main-category-title h1 {
  font-size: 40px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.main-category-title h1 span {
  font-weight: 400;
}

.main-category-title p {
  font-size: 18px;
  font-weight: 400;
  padding: 0 9rem;
}

@media screen and (max-width: 1024px) {
  .weekly-title p {
    padding: 0;
  }

  .main-category-title p {
    padding: 0;
  }

}

.back-button {
  font-size: 14px;
  background-color: transparent;
  border-top: transparent;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 5px 20px;
  transition: .1s ease-in all;
}

.back-button i {
  font-size: 12px;
}

.back-button:hover {
  color: #fff;
  background-color: #17a2b8;
  border-top: transparent;
  border-left: 1px solid #117a8b;
  border-right: 1px solid #117a8b;
  border-bottom: 1px solid #117a8b;
  /* border-color: #117a8b; */
}

.weekly-block-page {
  width: 100%;
}

.outlet-page .logout-page-products {
  /* max-width: 1100px; */
}

.outlet-links-wrapper {
  margin-top: 20px;
}

.outlet-desc,
.outlet-page-item h2 {
  font-size: 1rem;
  font-weight: 650;
  letter-spacing: -1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #40474D;
  /* height: 120px; */
}

.outlet-page-item h2 {
  color: #949494;
}

.outlet-page-item img {
  /* margin-bottom: -33px; */
  /* padding-top: 10px; */
}

/* .outlet-links img {
  transition: transform .1s ease-in-out;
  left: 0;
  position: absolute;
} */
.outlet-links {
  border-bottom: 7px solid;
  margin: 15px 5px;
  max-width: 190px;
  transition: border-color 0.3s ease, color 0.3s ease;
}

.outlet-img-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.outlet-links a:hover {
  color: #000;
  text-decoration: none;
}

.outlet-links:hover p {
  color: #000;
}

.outlet-links:hover {
  border-color: #40474D !important;
}

/* .outlet-links a:hover img {
  transform: translate3d(0px, -15px, 0px);
} */

/* .outlet-page-holder {
  border: 1px solid #ccc;
  padding-top: 20px;
  margin: 20px 0;
} */
.outlet-page-item {
  background-color: #ECECEC;
  border-radius: 25px;
  display: flex;
  color: #696969;
  margin-bottom: 40px;
  padding: 0 25px;
  overflow: hidden;
  min-height: 130px;
  border: 1px solid transparent;
  transition: box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
}

.outlet-page-item:hover {
  border: 1px solid #ebebeb;
  background-color: #f0f0f0;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 12px 20px 0px;
  color: #111;
  text-decoration: none;
}

/* .outlet-page-item:hover {
  background-color: #80ccdc;
  color: #fff;
} */

/* .outlet-page-item img {
  float: right;
  margin-right: -15px;
} */

/* .outlet-page-item h2 {
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 0;
  font-size: 22px;
  width: 45%;
  float: left;
  position: absolute;
  top: 10px;
  left: 25px;
  font-weight: 300;
  margin-top: 0;
} */

.fa-file-excel {
  color: green;
}

.fa-file-excel:hover {
  color: green;
}

.fa-file-pdf {
  color: red;
}

.fa-file-pdf:hover {
  color: red;
}

.btn-pdf-product-detail .fa-file-pdf {
  color: #fff;
}

.search-box-modal {
  position: fixed;
  left: 485px;
  width: 650px;
  background: #fff;
  height: 100vh;
  top: 0;
  z-index: 3;
  padding: 30px;
  box-shadow: 5px 0px 8px 0px #ccc;
  display: none;
}

.search-box-modal.active-sidebar-collapsed {
  left: 341px;
}

.search-box-catbrends-title {
  font-size: 20px;
  color: #62c900;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1px;
  font-weight: 500;
}

.search-box-category-content {
  flex: 1 1;
  margin-right: 25px;
}

.search-box-category-brands {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.search-box-brands-content {
  flex: 1 1;
  margin-left: 25px;
}

.search-box-carbrands-list ul {
  padding: 0px;
  padding-left: 20px;
  margin-top: 10px;
  font-size: 13px;
  flex: 1 1;
}

.search-box-carbrands-list {
  display: flex;
  max-height: 185px;
  overflow-y: auto;
}

.search-box-list-items {
  position: relative;
}

.search-box-item-result {
  float: left;
  width: 50%;
  padding: 15px 0px;
  display: flex;
  border-bottom: 1px solid #ccc;
}

.search-box-item-result-name a {
  text-decoration: none;
  color: #000;
}

.search-box-item-result-name {
  line-height: 15px;
  font-weight: 700;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.search-box-item-result-body {
  padding-left: 13px;
  border-right: 1px solid #ccc;
  flex: 2 1;
  padding-right: 10px;
  height: 70px;
}

.search-box-item-result-image {
  padding-left: 10px;
  flex: 1 1;
}

.search-box-item-result-ident {
  font-size: 12px;
  padding-bottom: 3px;
  color: #888;
  font-weight: 400;
}

.search-box-item-result:nth-child(2n)>.search-box-item-result-body {
  border-right: none;
}

.search-box-modal.active {
  display: block;
}

.clear-search-box {
  cursor: pointer;
}

.table-view-badge {
  position: relative;
}

.product-table-view-badge {
  color: #fff;
  background: #e6264b;
  max-width: 30px;
  margin: 2px auto;
  position: relative;
  border-radius: 2px;
  padding: 1px 4px;
  font-size: 11px;
}

.table-action-info {
  position: absolute;
  top: 0;
  z-index: 9;
  left: 17px;
  min-width: 100px;
  border-radius: 2px;
  padding: 1px 5px 1px 1px;
  font-size: 11px;
  background-color: #e6264b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  text-transform: uppercase;
}

.products-table-list-action-holder {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

.product-catalog-badge {
  background: #e6264b;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 15px;
  cursor: help;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 6px;
  font-size: 11px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.catalog-item-block-body .product-table-view-badge {
  padding: 3px 5px;
  font-size: 11px;
}

.catalog-item-block-body .table-action-info {
  padding: 3px 5px 3px 1px;
  font-size: 11px;
  left: 22px;
  width: 120px;
}

.prodcut-badges-holder {
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 2;
}

.catalog-item-main .prodcut-badges-holder {
  top: 15px;
}

.list-action-badges-table .product-catalog-badge-image {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

/* .export-catalog-page {
  text-align: left!important;
} */

.list-action-badges-table {
  max-width: 110px;
  display: flex;
  flex-wrap: wrap;
}

.product-catalog-badge-image {
  margin-top: 5px;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.product-catalog-badge-image-2 {
  margin-top: 5px;
  cursor: pointer;
  position: relative;
  text-align: center;
  background-image: linear-gradient(to left, #d40114, #a10023, #6c0b23, #380f1a, #2b0b0b);
  width: 35px;
  height: 35px;
  border-radius: 4px;
}

.products-list-table-item-holder {
  position: relative;
}

.prodcut-badges-images-holder {
  position: absolute;
  right: 0;
  bottom: 0;
}

.banner-block-home-item {
  background: aliceblue;
  height: 100%;
  border-radius: 15px;
}

.banner-item-block-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  bottom: 0px;
  margin: 0 auto;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 15px;
}

.action-item-image-block {
  height: 100%;
  /* width: 450px; */
  /* width: 100%; */
  background-size: cover;
  bottom: 0px;
  margin: 0 auto;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 15px;
}

.catalog-content-list-box {
  display: none;
  flex-wrap: wrap;
  justify-content: left;
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 20px;
}

.catalog-content-list-box.active {
  display: flex;
}

.catalog-table-item-name a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-width: 200px;
  color: #337ab7;
  font-weight: 500;
}

.table-view-price {
  white-space: nowrap;
}

.table-catalog-list th {
  border-top: 0;
  padding: 4px 12px;
  font-size: 14px;
  text-align: center;
}

table.table.table-catalog-list {
  width: 100%;
}

.catalog-content-list-table {
  display: none;
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 20px;
}

.catalog-content-list-table.active {
  display: block;
  overflow-x: auto;
}

.catalog-content-list-block {
  display: none;
  flex-wrap: wrap;
  justify-content: left;
  margin-top: 30px;

  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 20px;
}

.catalog-content-list-block.active {
  display: flex;
}

.catalog-table-item-img {
  width: 60px;
}

.table-catalog-list tbody td {
  vertical-align: middle;
  font-size: 14px;
  padding: 4px 12px;
  text-align: left;
}

td.catalog-table-item-name {
  line-height: 17px;
  font-weight: 600;
  text-align: center;
}

.product-catalog-table-badge {
  background: #e6264b;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  height: 30px;
  font-size: 19px;
}

.incoming-catalog-item-td {
  width: 90px;
}

.button-div-item-qty .minus-item-qty {
  top: 0px;
  background: transparent;
  border: none;
  padding: 0px 10px;
}

.button-div-item-qty .plus-item-qty {
  top: 0px;
  background: transparent;
  border: none;
  padding: 0px 10px;
}

.button-div-item-qty {
  position: relative;
}

input.input-qty-add-item {
  width: 100%;
  text-align: center;
  padding: 4px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

input.input-qty-add-item:focus {
  outline: none;
}

td.text-center.qty-table-td {
  min-width: 110px;
  max-width: 110px;
  text-align: center;
}

.catalog-block-list-item {
  /* width: 370px; */
  width: 23.5%;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* padding: 8px 12px; */
  margin-right: 38px;
  margin-bottom: 30px;
}

.catalog-block-list-item.collapsed-sidebar-catalog {
  /* width: 398px; */
  width: 23.6%;
}

.catalog-block-list-item-header {
  padding: 8px 12px;
  position: relative;
}

.catalog-item-block-header {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 7px;
}

.catalog-item-block-name {
  flex: 1 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 10px;
}

.catalog-item-block-body {
  display: flex;
  position: relative;
}

.catalog-item-block-body .product-catalog-badge {
  top: -7px;
  font-size: 14px;
}

.catalog-item-block-body-image {
  flex: 1 1;
  margin-right: 15px;
  position: relative;
}

.product-catalog-badge-image img {
  width: 35px;
}

.catalog-item-block-body-content {
  flex: 2 1;
}

.catalog-item-block-footer {
  display: flex;
  padding: 8px 12px;
  min-height: 76px;
}

.catalog-item-block-price {
  flex: 1 1;
  font-size: 14px;
  text-align: right;
  border-right: 1px solid #6ec53a;
  padding-right: 10px;
  line-height: 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.catalog-item-block-stock {
  flex: 0.8 1;
  font-size: 14px;
  text-align: right;
  border-right: 1px solid #6ec53a;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.catalog-item-block-qty .btn-success {
  margin-top: 3px;
}

.catalog-item-block-qty {
  flex: 1 1;
}

.catalog-item-block-qty button {
  margin-top: 5px;
}

.catalog-item-block-price-value-old {
  font-weight: 500;
  text-decoration: line-through;
  color: #666;
  text-align: center;
}

.catalog-item-block-price-value-price {
  font-weight: bold;
  text-align: center;
}

.catalog-item-block-price-value-mp {
  text-align: left;
  font-size: 13px;
}

.catalog-item-block-stock-value {
  text-align: right;
}

.catalog-item-block-reserved-value {
  text-align: right;
}

.catalog-item-block-qty .input-qty-add-item {
  padding: 0px 20px;
}

.catalog-item-block-qty .button-div-item-qty .plus-item-qty {
  top: -6px;
  padding: 0px 10px;
}

.catalog-item-block-qty .button-div-item-qty .minus-item-qty {
  top: -6px;
  padding: 0px 10px;
}

.catalog-block-list-item-footer {
  background: #f2f2f2;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  min-height: 76px;
}

table.table-item-catalog-block td {
  padding: 0px;
  font-size: 12px;
}

.catalog-block-item-value-table-td {
  text-align: left;
  font-weight: 700;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

table.table-item-catalog-block {
  width: 100%;
  line-height: 15px;
}

.compare-button-catalog {
  border: 1px solid #ccc;
  padding: 1px 4px;
  font-size: 11px;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 3px;
}

.wishlist-button-catalog {
  border: 1px solid #ccc;
  padding: 1px 4px;
  font-size: 11px;
  border-radius: 3px;
  text-align: center;
}

.subfilters-list-content-items ul {
  list-style: none;
  padding-left: 0px;
}

.checkbox-filters-top-modal {
  margin-right: 7px;
}

.subfilters-list-content-items {
  display: none;
  position: absolute;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #666;
  padding: 7px;
  top: 30px;
  z-index: 3;
  width: 200px;
  height: 200px;
  overflow-y: auto;
}

.subfilters-list-content-items.active {
  display: block;
}

.filter-top-item-content {
  position: relative;
}

.product-detail-item-3d {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  width: 75px;
  height: 65px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}

/* THUMBNAIL 3D PRIKAZ IKONICE*/
.item-mini-images-product img.img-logo-360 {
  width: 55% !important;
  left: 17px;
  top: 5px;
}

img.img-logo-360 {
  position: absolute;
  width: 90% !important;
}

.product-detail-item-3d img {
  width: 45px;
  margin-top: 9px;
}

.body-3d-modal-product-detail {
  height: 700px;
}

.content-slider-image-product .slick-dots li:first-child {
  /* margin-left: 60px; */
}

.modal-custom-product-3d.active {
  width: 90%;
  max-width: none !important;
}

.compare-content-container {
  background: #00000091;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #fff;
  padding: 20px 50px;
  display: flex;
  z-index: 1;
  box-shadow: 0 8px 13px 0 rgb(0 0 0 / 15%);
}

.compare-content-item {
  border: 1px dashed #fff;
  margin-right: 20px;
  padding: 10px;
  position: relative;
}

.close-compare-item-detail {
  position: absolute;
  top: 0;
  right: 5px;
  color: #666;
  cursor: pointer;
}

.compare-item-body {
  width: 20%;
}

.compare-item-detail {
  display: flex;
}

.compare-item-image {
  flex: 1 1;
}

.close-compare-item-detail.hidden {
  display: none;
}

.compare-content-item.active .close-compare-item-detail {
  display: block;
}

.compare-content-item.active {
  background-color: #fff;
  color: #000;
}

.compare-item-name {
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  flex: 4 1;
}

.img-compare-item {
  width: 40px;
  border: 1px solid #ccc;
  padding: 3px;
  margin-left: 5px;
  margin-top: 3px;
}

.comapre-item-number {
  font-size: 11px;
}

.comapre-button-footer-submit {
  background: #fff;
  color: #000;
  border-radius: 20px;
  padding: 4px 12px;
  width: 180px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.comapre-button-footer-clear:hover {
  text-decoration: underline;
}

.comapre-button-footer-clear {
  text-transform: uppercase;
  font-size: 14px;
  width: 180px;
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
}

.compare-item-container-body {
  overflow: hidden;
  max-width: 1634px;
  margin: auto;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
}

.compare-content-container.hidden {
  display: none;
}

.compare-page-table-content {
  width: 100%;
}

.compare-page-table-content table thead td {
  border-top: 0;
  text-align: center;
  position: relative;
}

.compare-page-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  line-height: 20px;
}

.compare-page-item-ident {
  color: #b2b2b2;
  font-size: 15px;
  font-weight: 500;
}

.compare-page-item-price {
  font-size: 30px;
  font-weight: bold;
  margin-top: 5px;
}

.compare-page-table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.compare-page-table tbody tr:nth-child(even) {
  background-color: #fff;
}

.compare-page-table {
  border-collapse: separate;
  border-spacing: 8px 0;
  table-layout: fixed;
}

.compare-page-table tbody {
  font-size: 14px;
  font-weight: 500;
}

.compare-page-table-content tbody td {
  border-top: 0;
}

td.compare-name-table-main {
  font-weight: 700;
}

.remove-compare-item-page {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}

.back-to-top {
  padding: 12px 16px;
  background: #eb0e4dd2;
  position: fixed;
  bottom: 110px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 0;
  color: #fff;
  z-index: 3;
  transition: all .1s ease-in-out;
  display: none;
}

.back-to-top.active {
  display: block;
  opacity: 1;
  transition: all .2s ease-in-out;
}

.back-to-top i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.finish-order-button-cart {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

button.finish-btn-order {
  border: 0;
  background: #62c900;
  color: #fff;
  border-radius: 8px;
  padding: 6px 12px;
}

.header-top-content {
  background: #000;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 56px;
  z-index: 1;
}

.mask-div-image-360 {
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.product-table-margin-left {
  margin-bottom: 20px;
}

table.product-table-margin {
  width: 100%;
  /* flex: 2.5; */
  /* margin-left: 15px; */
}

.product-detail-price-block {
  max-width: 610px;
  float: right;
}

.product-detail-price-block {
  max-width: 610px;
  float: right;
  width: 100%;
}

.product-add-cart-price-items {
  padding: 0px 15px;
  display: flex;
  width: 100%;
  align-items: center;
}

.product-add-cart-qty {
  flex: 1 1;
}

.product-add-cart-btn {
  flex: 1 1;
}

.product-add-cart-price {
  flex: 3 1;
}

.icon-btn-item {
  /* background: #e9ecef; */
  padding: 2px 8px;
  border-radius: 3px;
  color: #959595;
  border: 1px solid #ced4da;
  background: rgb(217, 217, 217);
  background: linear-gradient(0deg, rgba(217, 217, 217, 1) 0%, rgba(217, 217, 217, 1) 0%, rgba(255, 255, 255, 1) 100%);
  cursor: pointer;
}

.product-table-margin .input-group .fas {
  font-size: 14px;
  color: #959595;
}

.custom-select {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.product-table-margin .second-row-mp-price td {
  padding-top: 7px;
}

.product-table-margin .input-group-text {
  background: rgb(217, 217, 217);
  background: linear-gradient(0deg, rgba(217, 217, 217, 1) 0%, rgba(217, 217, 217, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.title-margine-table {
  text-align: right;
  padding-right: 8px;
  /* width: 108px; */
}

.title-margine-table-left {
  padding-right: 8px;
}

.blank-row {
  height: 42px !important;
  /* overwrites any other rules */
  background-color: #FFFFFF;
}

.td-left-row-percetange {
  text-align: right;
  padding-top: 11px;
}

.product-detail-tabs-content-container {
  margin-top: 0;
  padding: 0px 60px;
}

.product-detail-tabs-list {
  display: inline-block;
}

.product-detail-tab-item {
  cursor: pointer;
  display: inline-block;
  background: #fff;
  color: #000;
  margin-right: 10px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding: 14px 30px;
  text-transform: uppercase;
  font-weight: 500;
  box-shadow: 2px 1px 1px 0px #cecece;
  font-size: 14px;
  font-weight: 600;
}

.product-detail-tab-item.active {
  background: #8bbe28;
  color: #fff;
}

.content-tabs-sector {
  border-bottom: 2px solid #8bbe28;
  padding: 0px;
  background: #f6f6f6;
}

.product-detail-tabs-body {
  /* margin-bottom: 100px; */
  width: 100%;
}

.product-detail-tab-specification {
  padding: 0px 140px;
  display: none;
  color: #3b424c;
}

.product-detail-tab-desc {
  display: none;
}

.product-detail-tab-desc img {
  width: 100%;
}

.product-detail-tab-desc.active {
  display: block;
}

.product-detail-tab-specification.active {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 50px; */
  margin-top: 35px;
}

.product-detail-specification-title {
  font-size: 31px;
  /* font-size: 21px; */
  font-weight: bold;
  border-bottom: 2px solid #000;
}

.product-detail-specification-table td {
  border: 0;
  padding: 5px 0px;
  font-weight: 500;
  width: 50%;
}

.row-spec-td-name {
  font-weight: 700 !important;
}

.product-detail-specification-table {
  /* font-size: 17px; */
  font-size: 15px;
}

.product-detail-specification-item {
  /* margin-bottom: 30px; */
  margin-bottom: 0;
}

.show-more-specification-product-detail-btn {
  text-align: center;
}

.btn-show-more-spec {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.btn-show-more-spec i {
  border-radius: 100%;
  border: 2px solid #3b424c;
  height: 35px;
  width: 35px;
  font-size: 20px;
  padding-top: 5px;
}

.green {
  color: darken(#99DBB4, 20%);
}

.red {
  color: darken(#EF8D9C, 10%);
}

.alert {
  font-weight: 700;
  /* letter-spacing: 5px; */
}

button,
.dot {
  cursor: pointer;
}

#success-box.show {
  transform: translateX(0%);
}

#success-box {
  position: fixed;
  width: 300px;
  /* background: linear-gradient(to bottom right, #B0DB7D 40%, #99DBB4 100%); */
  background-color: #90bb44;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 5px 5px 20px rgba('#CBCDD3', 10%);
  perspective: 40px;
  height: 50px;
  right: 0;
  display: flex;
  top: 200px;
  z-index: 99;
  transition: all 0.5s;
  transform: translateX(100%);
}

#error-box.show {
  transform: translateX(0%);
  transition: all 0.5s;
}

#error-box {
  position: fixed;
  /* min-width: 300px; */
  /* background: linear-gradient(to bottom right, #B0DB7D 40%, #99DBB4 100%); */
  background-color: #f04460;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 5px 5px 20px rgba('#CBCDD3', 10%);
  perspective: 40px;
  height: 50px;
  right: 0;
  display: flex;
  top: 200px;
  z-index: 99;
  transition: all 0.5s;
  transform: translateX(100%);
}

.loader-mask-full.show {
  /* display: block; */
}

.loader-mask-full {
  /* position: fixed;
  width: 100%;
  height: 100vh;
  background: #ffffff91;
  z-index: 2;
  display: none; */
}

.btn-add-to-cart-catalog {
  background: #62c900;
  color: #fff;
  border-radius: 5px;
  padding: 4px 0px;
  cursor: pointer;
  border: 1px solid #62c900;
  min-width: 30px;
}

.btn-add-to-cart-catalog:hover {
  background-color: #fff;
  border: 1px solid #62c900;
  color: #62c900;
}

.catalog-content-list-box input.input-qty-add-item {
  padding: 3px 20px;
}

.catalog-content-list-box button.plus-item-qty,
.catalog-content-list-box button.minus-item-qty {
  top: 0;
  height: 100%;
}

.catalog-content-list-table button.plus-item-qty,
.catalog-content-list-table button.minus-item-qty {
  top: 0;
  height: 97%;
}

.catalog-content-list-box button.plus-item-qty,
.catalog-content-list-table button.plus-item-qty,
.product-add-to-cart-price .plus-item-qty {
  padding: 0 8px;
}

/* #error-box {
  position: absolute;
  width: 35%;
  height: 100%;
  right: 12%;
  background: linear-gradient(to bottom left, #EF8D9C 40%, #FFC39E 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba('#CBCDD3', 10%);
  display: flex;
} */

.dot {
  width: 8px;
  height: 8px;
  background: #FCFCFC;
  border-radius: 50%;
  position: absolute;
  top: 4%;
  right: 6%;
  /* &:hover {
    background: darken(#FCFCFC, 20%);
  } */
}

.two {
  right: 12%;
  opacity: .5;
}

.face {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #FCFCFC;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 15px;
  left: 16px;
  z-index: 2;
  animation: bounce 1s ease-in infinite;
}

.face2 {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #FCFCFC;
  border-radius: 50%;
  border: 1px solid #777777;
  top: -5px;
  left: 16px;
  z-index: 2;
  animation: roll 3s ease-in-out infinite;
}

.eye {
  position: absolute;
  width: 4px;
  height: 4px;
  background: #777777;
  border-radius: 50%;
  top: 10px;
  left: 5px;
}

.right {
  left: 19px;
}

.mouth {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.happy {
  border: 2px solid;
  border-color: transparent #777777 #777777 transparent;
  transform: rotate(45deg);
}

.sad {
  top: 49%;
  border: 2px solid;
  border-color: #777777 transparent transparent #777777;
  transform: rotate(45deg);
}

.message-error-icon .shadow {
  position: absolute;
  width: 23px;
  height: 5px;
  opacity: .5;
  background: #777777;
  left: 22px;
  top: 28px;
  border-radius: 50%;
  z-index: 1;
}

.message-succes-icon .shadow {
  position: absolute;
  width: 23px;
  height: 5px;
  opacity: .5;
  background: #777777;
  left: 22px;
  top: 45px;
  border-radius: 50%;
  z-index: 1;
}

.img-compare-no-image {
  max-width: 180px;
}

.scale {
  animation: scale 1s ease-in infinite;
}

.move {
  animation: move 3s ease-in-out infinite;
}

.red-background .message {
  padding-left: 80px;
}

.green-background .message {
  padding-left: 60px;
}

.message {
  width: 100%;
  text-align: right;
  font-size: 15px;
  color: #fff;
  margin-top: 0px;
  padding-right: 5px;
}

.message-succes-icon {
  flex: 1 1;
}

.message-succes-text {
  flex: 5 1;
}

.message-error-icon {
  /* flex: 2; */
  position: relative;
  width: 85px;
}

.message-error-text {
  /* flex: 5; */
  /* padding-right: 15px; */
}

.green-background {
  background-color: #90bb44 !important;
  color: #fff !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  /* width: 320px; */
  /* margin: 0 0 0 auto; */
}

.Toastify__toast-container {
  width: auto !important;
}

.red-background {
  background-color: #f04460 !important;
  color: #fff !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.Toastify__toast-container--top-right {
  right: 0em !important;
}

.Toastify__toast-container {
  padding-right: 0px !important;
}

.green-background .Toastify__progress-bar-theme--light {
  background: #609208 !important;
}

.red-background .Toastify__progress-bar-theme--light {
  background: #bd001f !important;
}

.button-box {
  position: absolute;
  background: #FCFCFC;
  width: 50%;
  height: 15%;
  border-radius: 20px;
  top: 73%;
  left: 25%;
  outline: 0;
  border: none;
  box-shadow: 2px 2px 10px rgba(#777777, .5);
  transition: all .5s ease-in-out;
  /* &:hover {
    background: darken(#FCFCFC, 5%);
    transform: scale(1.05);
    transition: all .3s ease-in-out;
  } */
}

.show-more {
  font-size: 18px;
  color: #212529;
  transition: color 0.3s;
  cursor: pointer;
}

.show-more:hover {
  color: #5f5f5f;
}

.show-more i.fas.fa-chevron-down {
  color: #212529;
  padding-left: 4px;
}

.show-more:hover i.fas.fa-chevron-down {
  color: #5f5f5f;
}

.show-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}

@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 15%;
  }

  50% {
    left: 60%;
    transform: rotate(168deg);
  }

  100% {
    transform: rotate(0deg);
    left: 15%;
  }
}

@keyframes move {
  0% {
    left: 25%;
  }

  50% {
    left: 60%;
  }

  100% {
    left: 25%;
  }
}

table.table.editing-cart-table thead th {
  background: #ebebeb;
  border-top: 0;
  border-bottom: 0;
  text-align: center;
  text-transform: uppercase;
}

.buttons-editing-cart-all {
  display: inline-block;
  padding-left: 30px;
  padding-right: 20px;
  float: right;
}

/* .btn-custom-editing-cart {
  display: inline-block;
  cursor: pointer;
} */
.box-create-new-order-button {
  cursor: pointer;
}

.btn-custom-editing-cart-text {
  margin-left: 5px;
  color: #fff;
  border-radius: 5px;
  padding: 3px 12px;
}

.blue-btn-editing-cart .btn-custom-editing-cart-text {
  background-color: #21a7c4;
  border: 1px solid #21a7c4;
}

.green-btn-editing-cart .btn-custom-editing-cart-text {
  background-color: #62c900;
  border: 1px solid #62c900;
}

.orange-btn-editing-cart .btn-custom-editing-cart-text {
  background-color: #ff9432;
  border: 1px solid #ff9432;
}

.red-btn-editing-cart .btn-custom-editing-cart-text {
  background-color: #f7395f;
  border: 1px solid #f7395f;
}

/* .editing-cart-table .orange-btn-editing-cart:hover>.btn-custom-editing-cart-text {
  background-color: #fff;
  color: #ff9432;
  border-color: #ff9432;
} */

/* .editing-cart-table .blue-btn-editing-cart:hover>.btn-custom-editing-cart-text {
  background-color: #fff;
  color: #21a7c4;
  border-color: #21a7c4;
} */

/* .editing-cart-table .green-btn-editing-cart:hover>.btn-custom-editing-cart-text {
  background-color: #fff;
  color: #62c900;
  border-color: #62c900;
}

.editing-cart-table .red-btn-editing-cart:hover>.btn-custom-editing-cart-text {
  background-color: #fff;
  color: #f7395f;
  border-color: #f7395f;
} */

.detail-status-order-table-modal th {
  border-top: none;
}

.fa-icon-note-status {
  color: #21a7c4;
  cursor: help;
}

.table-padding-item-border-both {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.table-padding-item-border-right {
  border-right: 1px solid #ccc;
}

.modal-status-order-item {
  cursor: pointer;
}

.modal-status-order-item:hover {
  text-decoration: underline;
}

.table-padding-item-border-left {
  border-left: 1px solid #ccc;
}

.table-row-editing-cart-item-name {
  padding-right: 10px;
}

.table-row-editing-cart-item-name:hover svg {
  fill: #007bff;
}

.table-row-editing-cart-item-name svg {
  width: 12px;
  margin-left: 8px;
}

.editing-cart-table-header-buttons {
  width: 525px;
}

.editing-cart-table-header-qty {
  width: 100px;
}

.editing-cart-table-header-price {
  width: 170px;
}

table.table.editing-cart-table tbody td {
  padding: 10px 0px;
}

.editing-cart-table tbody tr:last-child>td {
  border-bottom: 1px solid #dee2e6;
}

.box-create-item {
  display: inline-block;
}

.delivery-status-order-row {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.box-create-new-order-input {
  width: 300px;
}

.box-create-item.box-create-new-order-title {
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 10px;
}

.box-crete-new-order {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 12px;
}

.editing-cart-item-content h4 {
  font-weight: bold;
}

.container-content.container-editing-cart-content {
  min-height: 100vh;
  background-color: #fff !important;
}

.show-more-specification-product-detail {
  display: none !important;
}

.show-more-specification-product-detail.active {
  display: grid !important;
  width: 100%;
}

.large-item {
  display: block;
}

.small-item {
  display: none;
}

.block-profile-data {
  background: #fbfbfb;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 15px 30px;
  margin-bottom: 40px;
}

.profile-page-title {
  padding: 30px;
  display: flex;
  align-content: center;
  align-items: center;
}

.profile-page-title .far {
  font-size: 30px;
  margin-right: 20px;
}

.profile-page-title-section {
  line-height: 0.5;
}

.admin-tab-buttons {
  margin-right: 45px;
  margin-left: 5px;
}

.user-section {
  padding: 15px 30px;
  margin-bottom: 40px;
  /* align-items: center; */
}

.global-info-user h5 {
  font-size: 14px;
  font-weight: unset;
  color: #939393;
  margin-bottom: 3px;
}

.global-info-user-data {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.clerk-info {
  width: 100%;
  border: 1px solid #fafafa;
  padding: 20px;
  border-radius: 35px;
  box-shadow: 3px 3px 12px 0 rgb(0 6 22 / 10%);
  transition: all .3s ease 0s;
}

.clerk-info:hover {
  box-shadow: 0 0em 2em rgba(0, 0, 0, 0.1), 0 0 0 0px rgb(255, 255, 255), 0em 0.3em 2em rgba(0, 0, 0, 0.3);
}

.clerk-info img {
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 0 4px #eaedf1;
}

.clerk-info h5 {
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.clerk-info-position {
  font-weight: 600;
  line-height: 1.2;
  font-size: 15px;
}

.clerk-info-data {
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
}

.clerk-info-social {
  height: 24px;
  width: 24px;
  font-size: 20px;
  margin: 0 auto;
}

.user-links {
  margin: 15px 5px;
  max-width: 150px;
  text-align: center;
  cursor: pointer;
}

.user-links-active .global-info-user-data {
  border-bottom: 2px solid #62c900;
}

.user-links-not-active .global-info-user-data::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width .3s;
  border-bottom: 2px solid #62c900;
}

.user-links-not-active .global-info-user-data:hover::after {
  width: 100%;
}

.user-info-select {
  margin-top: 40px;
}

.user-info-select-input {
  max-width: 150px;
  margin: 0 auto;
  margin-top: 10px;
}

.user-info-profacture-input {
  max-width: 150px;
  margin-top: 10px;
}

.user-info-margin {
  margin: 82px 0;
}

.btn-submit-email {
  background: #62c900;
  color: #fff;
}

.homepage-newsletter {
  width: 45px;
}

.home-page-exports {
  background: #E6E6E6;
  padding: 20px 0;
  align-items: center;
  box-shadow: 0px 3px 35px 0px #cecece;
  border-radius: 15px;
}

.home-page-exports .form-group {
  margin-bottom: 0;
}

.export-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.export-buttons .loader-mask-line {
  max-width: 100px;
  margin-right: 30px;
}

.export-buttons .loader-mask-line img {
  margin-top: 5px;
}

.export-buttons .loader-mask-line:nth-child(2n) {
  margin-right: 0;
}

.export-buttons .btn-newsletter {
  margin-right: 30px;
}

.export-buttons .btn-newsletter:nth-child(2n) {
  margin-right: 0;
}

.block-profile-data-image-item .delivery-img-preview {
  background: white !important;
  margin-top: 0;
}

h4.title-block-profile-data {
  border-bottom: 1px solid #dbdbdb;
  color: #62c900;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 700;
  padding-bottom: 5px;
}

.block-profile-data-content-type {
  margin: 31px 0px;
}

.block-profile-data-content-type span {
  background: #000;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  padding: 3px 15px;
  border-radius: 13px;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.block-profile-data-content-price-value {
  margin: 62px 0px;
}

.block-profile-data-content-price-value .dashboard-value-item {
  text-align: center;
  margin-right: 0px;
}

.block-profile-data-content-price-value .dashboard-value-price {
  text-align: center;
}

.buttons-create-login-user .custom-control {
  margin-left: 19px;
  font-size: 20px;
  font-weight: bold;
}

.buttons-create-login-user {
  display: flex;
}

.title-buttons-create-login-user {
  margin-right: 20px;
}

.block-profile-data-content-user {
  margin: 27px 0px;
}

.block-profile-data-image-item div {
  float: left;
  background: #00a8c7;
  color: #fff;
  margin-left: 20px;
  padding: 5px 40px;
  margin-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.block-profile-data-image-item {
  display: flex;
}

.table.user-settings-profile-table th {
  border-top: 0;
  padding: 5px 10px;
  border-bottom: 0px;
}

.table.user-settings-profile-table td {
  border-top: 0px;
  padding: 5px 10px;
}

.table.user-settings-profile-table i {
  color: red;
  font-size: 20px;
  cursor: pointer;
}

.add-item-table-settings-profile-user {
  border: 2px dashed #dbdbdb;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  color: #959595;
}

.social-contact-user-box {
  display: flex;
  margin-top: 7px;
}

.social-contact-user-box-item {
  /* flex: 1; */
  background: #fff;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  font-size: 13px;
  color: #666;
  padding-right: 4px;
  padding-left: 4px;
}

.footer-sidebar-logout svg {
  border: 2px solid #fff;
  border-radius: 100%;
  padding: 5px;
}

.footer-sidebar-logout img {
  width: 25px;
  margin-right: 10px;
  margin-top: -4px;
}

.sidebar-menu-item-image img {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.sidebar-menu-item-image {
  text-align: left;
  margin-top: 5px;
}

.special-block-image-mask {
  position: absolute;
  height: 140px;
  width: 100%;
  bottom: 0;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.695098107602416) 0%, rgba(0, 0, 0, 0.13207289751838236) 75%, rgba(255, 255, 255, 0) 100%);
}

.special-block-image-mask-content {
  display: flex;
  position: absolute;
  bottom: 25px;
  width: 100%;
  padding: 0px 20px;
}

.special-block-image-mask-name {
  flex: 2 1;
  color: #fff;
  font-weight: bold;
  font-size: 34px;
  line-height: 30px;
}


.special-block-image-mask-btn {
  flex: 1 1;
  /* position: relative; */
}

.special-block-image-mask-btn-item {
  background: #fff;
  text-align: center;
  width: 115px;
  font-weight: bold;
  border-radius: 8px;
  padding: 10px;
  font-size: 15px;
  position: absolute;
  bottom: 0;
  right: 20px;
  border: 1px solid #fff;
  cursor: pointer;
}

.special-block-image-mask-btn-item a {
  color: #000;
}

/* .special-block-image-mask-btn-item:hover {
  background-color: transparent;
  color: #fff;
} */
.icon-image-item-sidebar {
  width: 30px;
}

.cart-dispInfo-holder {
  border: 1px solid #ccc;
  padding: 15px 20px;
  border-radius: 5px;
  padding-bottom: 15px;
  margin-top: 10px;
}

.item-name.item-name-icon-image {
  padding-top: 5px;
  padding-bottom: 5px;
}

.item-name-icon-image .display-show-block {
  margin-left: 10px;
  margin-top: 7px;
}

.block-logo-item {
  text-align: center;
}

.delivery-form-info {
  border: 1px solid #efefef;
  background: #efefef;
  border-radius: 5px;
  padding: 20px;
}

/* /////////////////////////////////// */
/* //////////UPOREDI PROIZVODE//////// */
/* /////////////////////////////////// */
.compare-page .compare-product-th {
  width: 180px;
}

@media (max-width: 1572px) and (min-width: 1400px) {
  .compare-page-item-price {
    font-size: 27px;
    white-space: nowrap;
  }

  .compare-page-item-name {
    -webkit-line-clamp: 3;
    min-width: 150px;
  }
}

@media (max-width: 1399px) and (min-width: 1250px) {
  .compare-page-item-price {
    font-size: 24px;
    white-space: nowrap;
  }

  .compare-page-item-name {
    -webkit-line-clamp: 3;
    min-width: 150px;
  }
}

@media (max-width: 1249px) and (min-width: 1021px) {
  .compare-page {
    overflow: hidden;
  }

  .compare-page-item-price {
    font-size: 27px;
    white-space: nowrap;
  }

  .compare-page-table {
    border-collapse: collapse;
    max-width: 100%;
    border-spacing: inherit;
    table-layout: inherit;
  }

  .compare-page-table-content {
    overflow: visible;
    overflow-x: scroll;
    height: calc(100vh - 40px);
  }

  .compare-page-table tbody tr td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
  }

  .compare-product-head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #fff;
  }

  .compare-product-th {
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0;
    z-index: 4;
    background-color: #fff;
  }

  .compare-product-row {
    position: -webkit-sticky;
    position: sticky;
    left: 12px;
    z-index: 2;
  }

  .compare-page-table tbody tr:nth-child(odd) td:first-child {
    background-color: #f2f2f2;
  }

  .compare-page-table tbody tr:nth-child(even) td:first-child {
    background-color: #fff;
  }

}

/* @media (max-width: 1249px) and (min-width: 1100px) {
  .compare-page-item-price {
    font-size: 20px;
    white-space: nowrap;
  }
  .compare-page-item-name {
    -webkit-line-clamp: 3;
  }
}
@media (max-width: 1099px) and (min-width: 1021px) {
  .compare-page-item-price {
    font-size: 18px;
    white-space: nowrap;
  }
  .compare-page-item-name {
    -webkit-line-clamp: 3;
  }
} */
@media (max-width: 1020px) and (min-width: 880px) {
  .compare-page-item-price {
    font-size: 24px;
    white-space: nowrap;
  }

  .compare-page-item-name {
    -webkit-line-clamp: 3;
    min-width: 150px;
  }
}

/* @media (max-width: 879px) and (min-width: 770px) {
  .compare-page-item-price {
    font-size: 20px;
    white-space: nowrap;
  }
  .compare-page-item-name {
    -webkit-line-clamp: 3;
    font-size: 15px;
  }
} */
@media (max-width: 879px) and (min-width: 451px) {
  .compare-page-item-price {
    font-size: 27px;
    white-space: nowrap;
  }

  .compare-page-table {
    border-collapse: collapse;
    max-width: 100%;
    border-spacing: inherit;
    table-layout: inherit;
  }

  .compare-page-table-content {
    overflow: visible;
    overflow-x: scroll;
    height: calc(100vh - 84px);
  }

  .compare-page-table tbody tr td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
  }

  /* .compare-product-head {
    position: sticky;
    top: 0; 
    z-index: 3; 
    background-color: #fff;
  } */
  .compare-product-th {
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0;
    z-index: 4;
    background-color: #fff;
  }

  .compare-product-row {
    position: -webkit-sticky;
    position: sticky;
    left: 12px;
    z-index: 2;
  }

  .compare-page-table tbody tr:nth-child(odd) td:first-child {
    background-color: #f2f2f2;
  }

  .compare-page-table tbody tr:nth-child(even) td:first-child {
    background-color: #fff;
  }

}

@media (max-width: 450px) {
  .compare-page-table-content table thead td {
    padding: 6px;
  }

  .compare-page-item-name {
    font-size: 14px;
  }

  .compare-page-item-ident {
    font-size: 13px;
  }

  .compare-page-item-price {
    font-size: 16px;
    white-space: nowrap;
  }

  .compare-page-table {
    border-collapse: collapse;
    max-width: 100%;
    border-spacing: inherit;
    table-layout: inherit;
  }

  .compare-page-table-content {
    overflow: visible;
    overflow-x: scroll;
    height: calc(100vh - 84px);
  }

  .compare-page-table tbody {
    font-size: 13px;
  }

  .compare-page-table-content tbody td {
    padding: 6px;
  }

  .compare-page-table-content tbody th {
    padding-left: 0;
  }

  .compare-page-table tbody tr td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
  }

  .compare-product-head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #fff;
  }

  .compare-product-th {
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0;
    z-index: 4;
    background-color: #fff;
  }

  .compare-product-row {
    position: -webkit-sticky;
    position: sticky;
    left: 5px;
    z-index: 2;
  }

  .compare-page-table tbody tr:nth-child(odd) td:first-child {
    background-color: #f2f2f2;
  }

  .compare-page-table tbody tr:nth-child(even) td:first-child {
    background-color: #fff;
  }

  .show-more {
    margin-bottom: 16px;
  }

  /* .catalog-sort-content-header{
    justify-content: center!important;
  } */
}

/* /////////////////////////////////// */
/* /////////PREGLED NEWSLETTERA/////// */
/* /////////////////////////////////// */
.newsletter-item {
  margin-bottom: 15px;
  border: 1px solid #898989;
  max-width: 300px;
}

.newsletter-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 50px;
  align-items: center;
  background: #EBEBEB;
}

.btn-newsletter {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.btn-newsletter:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}

.newsletter-img-preview {
  max-height: 65vh;
  overflow-y: auto;
}

@media (max-width: 1440px) and (min-width: 1280px) {
  .newsletter-buttons .btn {
    font-size: 12px;
    padding: 5px;
  }
}

@media (max-width: 1024px) and (min-width: 451px) {
  .newsletter-buttons .btn {
    font-size: 12px;
    padding: 4px;
  }
}

@media (max-width: 450px) {
  .newsletter-item {
    margin: 15px auto;
  }

  .newsletter-content .contact-desc,
  .customer-care-page .contact-desc {
    margin-top: 10px;
  }
}

/* /////////////////////////////////// */
/* //////////BENEFIT COINS//////////// */
/* /////////////////////////////////// */
@font-face {
  font-family: 'GtestiProRegular';
  src: url(/static/media/gt-esti-pro-display-regular.435396af.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GtestiProLight';
  src: url(/static/media/gt-esti-pro-display-light.c77844d5.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GtestiProBold';
  src: url(/static/media/gt-esti-pro-display-bold.98af5af2.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GtestiProUltraBold';
  src: url(/static/media/gt-esti-pro-display-ultrabold.9bde7d49.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

.benefit-content {
  font-family: 'GtestiProRegular';
  background: #0D0906;
  height: 100vh;
  display: flex;
  align-items: center;
}

.benefit-content-row {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.benefit-content-header {
  font-family: 'GtestiProUltraBold';
  color: white;
  font-size: 55px;
  letter-spacing: 30px;
  /* font-weight: 100; */
}

.sencor-content-header {
  font-family: 'GtestiProUltraBold';
  color: rgb(183, 56, 106);
  font-size: 55px;
  letter-spacing: 30px;
  /* font-weight: 100; */
}

.benefit-user {
  color: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid white;
  padding: 10px 0 10px 30px;
}

.benefit-user-sencor {
  color: rgb(183, 56, 106);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F5E0ED;
  padding: 10px 0 10px 30px;
}

.benefit-user span {
  font-size: 30px;
  letter-spacing: 5px;
  font-weight: 100;
  text-transform: uppercase;
}

.benefit-user-sencor span {
  font-size: 30px;
  letter-spacing: 5px;
  font-weight: 100;
  text-transform: uppercase;
}

.benefit-user-img {
  border-radius: 50%;
  border: 2px solid rgb(183, 56, 106);
  width: 40px;
  height: 40px;
  margin: 0 20px;
}

.benefit-number {
  color: rgb(183, 56, 106);
  width: 80px;
}

.benefit-number-sencor {
  color: rgb(183, 56, 106);
  width: 80px;
}

/* @media (max-width: 1680px) and (min-width: 1440px) {
  .benefit-content-header {
    font-size: 45px;
  }

  .benefit-user span {
    font-size: 25px;
  }
} */

/* /////////////////////////////////// */
/* /////ISPORUKA KRAJNJEM KUPCU/////// */
/* /////////////////////////////////// */
.delivery-form-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 25px;
}

.form-body-holder-delivery {
  border: 1px solid #c9c9c9;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.delivery-form-item {
  display: flex;
  border-bottom: 2px solid #fff;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.delivery-form-buttons {
  margin-left: 20px;
  font-size: 17px;
}

.global-info-delivery-content h4 {
  font-size: 19px;
  font-weight: 600;
}

.info-delivery-content-item {
  color: #939393;
}

.info-delivery-type-content {
  margin-bottom: -10px;
}

.global-info-delivery-content {
  border-bottom: 1px solid #939393;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.info-payment-address {
  text-align: center;
}

.delivery-form-name-payment {
  display: inline-block;
}

.delivery-form-buttons-payment {
  display: inline-block;
  margin-left: 20px;
}

.delivery-form-page .info-payment-address-content {
  padding-bottom: 15px;
}

.check-option-payment {
  text-align: center;
  background: #f7395e;
  color: #fff;
  padding: 10px 0px;
  font-weight: bold;
  border-radius: 5px;
}

.payment-more-option-content {
  border: 1px solid #c9c9c9;
  margin-top: 30px;
  border-radius: 5px;
  padding: 15px 20px 20px 20px;
}

.input-custom-delivery {
  margin-top: 15px;
}

.delivery-file-att-content {
  text-align: center;
  background: #efefef;
  color: #000;
  font-weight: 600;
  padding: 20px 0px;
  margin-top: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-end;
}

.delivery-label {
  background: #1ea8c5;
  padding: 6px 40px;
  color: #fff;
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
}

.delete-label-profile {
  background: #ffffff;
  padding: 6px 40px;
  color: #111111;
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
}

.delete-label-profile:hover {
  background: red;
  color: white;
}

.recommended-img-size {
  color: #a3a3a3;
  font-size: 73%;
}

.delivery-img-name {
  font-weight: 400;
  margin-left: 10px;
}

.delivery-file-att-item label {
  font-weight: 600;
  margin-top: 10px;
  width: -webkit-max-content;
  width: max-content;
}

.delivery-file-att-item input {
  display: none;
}

.delivery-img-preview {
  margin-top: 20px;
  margin-left: 20px;
  max-width: 400px;
}

.delivery-img-preview button {
  background: #1ea8c5;
  padding: 6px 10px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  border: none;
  margin: 5px;
}

.delivery-file-att-item:nth-child(1) {
  margin-bottom: 20px;
}

.delivery-form-submit-button {
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.btn-sbmit-delviery {
  background: #62c900;
  width: 200px;
  display: inline-block;
  color: #fff;
  padding: 5px 0px;
  border-radius: 5px;
  cursor: pointer;
}

.check-option-payment-info input {
  margin-left: 10px;
  cursor: pointer;
}

.modal-detail-user-info {
  text-align: left;
  padding: 0px 10px;
  padding-bottom: 20px;
}

.modal-table-detail-user td {
  border-bottom: 1px solid #bddcc3;
  padding: 5px;
}

.modal-table-detail-user {
  margin: 0 auto;
  width: 100%;
}

.modal-left-side-info-detail {
  width: 270px;
  padding-right: 20px;
  font-weight: bold;
  padding-bottom: 5px;
}

.modal-detail-order-info {
  text-align: left;
  padding: 0px 10px;
}

.modal-table-order {
  width: 100%;
  border-collapse: collapse;
}

.modal-table-order thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  text-align: left;
}

.custom-offer-table thead th {
  text-align: left;
}

.modal-table-order tbody tr {
  border-bottom: 1px solid #dee2e6;
}

.options-payment {
  padding-top: 15px;
}

.delivery-file-preview {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.delivery-preview img {
  max-width: 300px;
  margin-top: 20px;
}

.delivery-preview span {
  display: block;
}

/* /////////////////////////////////// */
/* //////////STATUSI PORUDZBINA//////// */
/* /////////////////////////////////// */
.status-holder-box {
  background: #f6f6f6;
  border-radius: 10px;
  padding: 10px 25px;
}

.status-holder-box-trend {
  background: #f6f6f6;
  border-radius: 10px;
  padding: 10px 25px;
}

.status-box-diagram {
  display: flex;
  border-left: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  position: relative;
}

.status-box-diagram-item {
  flex: 1 1;
  height: 200px;
  border-right: 1px dashed #c9c9c9;
}

.status-box-diagram-value {
  display: flex;
}

.status-box-diagram-item-value {
  flex: 1 1;
  margin-left: -10px;
}

.status-diagram-line {
  position: absolute;
  background: #fe285c;
  color: #fff;
  margin-top: 5px;
  border-radius: 15px;
  text-align: left;
  padding: 1px 10px;
  left: 0;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: help;
}

.status-diagram-line:nth-child(1) {
  top: 0%;
}

.status-diagram-line:nth-child(2) {
  top: 20%;
}

.status-diagram-line:nth-child(3) {
  top: 40%;
}

.status-diagram-line:nth-child(4) {
  top: 60%;
}

.status-diagram-line:nth-child(5) {
  top: 80%;
}

.status-diagram-lines {
  width: 100%;
  position: absolute;
  height: 100%;
}

.status-box-trend-value {
  text-align: center;
  font-size: 70px;
  color: #fe285c;
  font-weight: 600;
  padding: 30px 0px;
}

.order-status-page-table .clear-all-filters {
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  bottom: -102px;
  z-index: 13;
  right: 0;
}

@media (max-width: 1280px) {
  .status-box-trend-value {
    font-size: 32px;
    padding: 5px 0px;
  }

  .status-holder-box-trend {
    padding: 15px 10px;
  }

}

@media (max-width: 1680px) and (min-width: 1280px) {
  .status-box-trend-value {
    font-size: 45px;
    padding: 5px 0px;
  }

  .status-holder-box-trend {
    padding: 15px 25px;
  }

  .news-box-item-image2 img {
    margin: 15px 0 0 15px;
  }

  .item-name {
    min-height: 30px;
    padding: 0;
    padding-left: 15px;
    align-items: center;
  }

  .item-name img {
    height: 18px !important;
    width: 18px !important;
    align-self: center;
  }

  .customImageLogo {
    height: 45px;
  }

  .footer-sidebar-logout {
    padding: 10px 0 10px 15px;
  }

  .margin-bottom-20 {
    margin-bottom: 8px;
  }

  .collapse-button-sidebar {
    margin-top: 4px !important;
  }

  .collapse-button-sidebar {
    padding: .175rem .75rem !important;
  }

  .sidebar-items-list {
    margin-top: 0px;
  }

  .loggedin-username {
    font-size: 12px;
  }

  .sidebar-info-user {
    bottom: 52px !important;
  }

  .shopping-cart-item-name span {
    top: -5px !important;
  }

  .user-detail-info-separator {
    height: 8px;
  }

  .menu-modal-show {
    overflow: auto;
  }

}

.status-box-trend-desc {
  font-size: 12px;
  text-align: center;
  color: #666;
  line-height: 15px;
  padding-bottom: 15px;
}

.status-diagram-line span {
  font-weight: bold;
}

.status-box-diagram-item-value span {
  /* float: right; */
  position: absolute;
  right: 20px;
}

.status-order-box-title {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

/* .stats-order-content-holder h1 {
  margin-bottom: 30px;
  border-bottom: 1px solid #cacaca;
  padding-bottom: 5px;
  font-size: 28px;
  font-weight: bold;
} */
.status-order-filter-line {
  display: flex;
  margin-top: 30px;
  border-top: 1px solid #c9c9c9;
  padding: 7px 0px;
  border-bottom: 1px solid #c9c9c9;
}

.invoice-filter-line {
  display: flex;
  margin-top: 30px;
  border-top: 1px solid #c9c9c9;
  padding: 7px 0px;
  border-bottom: 1px solid #c9c9c9;
}

.status-order-filter-line .form-inline {
  width: 100%;
}

.status-order-filter-items {
  width: 100%;
  display: flex;
}

.status-order-table table.table.editing-cart-table thead th {
  background: #fff;
  text-align: center;
  padding: 8px;
  font-size: 14px;
  border-top: none;
}

.status-order-table {
  margin-top: 30px;
  /* margin-top: 0px; */
}

.status-order-table .buttons-editing-cart-all {
  padding-left: 0px;
}

.wish-list-table table.table.editing-cart-table thead th {
  background: #fff;
  text-align: left;
  padding: 8px;
  font-size: 14px;
  border-top: none;
}

.wish-list-table .buttons-editing-cart-all {
  padding-left: 0px;
}

.target-box-table table.table.editing-cart-table thead th {
  background: #fff;
  text-align: center;
  padding: 2px;
  font-size: 14px;
  border-top: none;
}

.target-box-table .buttons-editing-cart-all {
  padding-left: 0px;
}

.text-left-padding {
  text-align: left;
  padding-left: 15px;
}

.repeat-icon-order-status {
  background-color: #62c900;
  color: #fff;
  border-radius: 4px;
  padding: 5px 8px;
  cursor: pointer;
}

.repeat-icon-order-status-disabled {
  background-color: #d0d0d0;
  color: #fff;
  border-radius: 4px;
  padding: 5px 8px;
  cursor: not-allowed;
}

/* .repeat-icon-order-status svg {
  fill: #fff;
  width:19px;
  position: relative;
  padding-bottom: 8px;
  padding-right: 2px;
} */

.repeat-icon-order-status:hover {
  background-color: #54ae00;
  border-color: #54ae00;
}

.order-status-text-status {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.banner-image-from-page {
  width: 100%;
}

.delivery-schedule-content .stats-order-content-holder {
  margin-top: 20px;
}

.target-box-title {
  background-color: #e3e3e3;
  border-radius: 15px;
  margin-bottom: 0px;
  padding: 5px 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.target-box-holder {
  margin-bottom: 50px;
}

/* /////////////////////////////////// */
/* //////////STATUS REKLAMACIJA/////// */
/* /////////////////////////////////// */
.reclamation-status-content .stats-order-content-holder h1 {
  border-bottom: none;
  margin-bottom: 0px;
}

.reclamation-status-content .status-order-filter-line {
  margin-top: 0px;
}

.status-order-filter-search-input {
  position: relative;
  margin-left: auto;
}

.status-order-filter-search-input i {
  position: absolute;
  right: 10px;
  top: 7px;
  color: #8a8a8c;
}

.status-filter-search-input {
  border-radius: 15px;
  background: #e1e1e1;
  min-width: 240px;
}

.col-width-300 {
  max-width: 300px;
}

.text-overflow-custom-1 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-overflow-custom-2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-overflow-custom-3 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.delivery-schedule-content .status-order-filter-line {
  margin-top: 0px;
}

.delivery-schedule-content .stats-order-content-holder h1 {
  border-bottom: 0px;
  margin-bottom: 0px;
}

.delivery-schedule-filter-search-input input {
  width: 500px !important;
}

.delivery-schedule-filter-search-input {
  position: relative;
  margin: auto;
}

.delivery-schedule-filter-search-input i {
  position: absolute;
  right: 10px;
  top: 7px;
  color: #8a8a8c;
}

.delivery-schedule-filter-search-input {
  border-radius: 15px;
  background: #e1e1e1;
  min-width: 240px;
}

.currency-content .stats-order-content-holder h1 {
  border-bottom: 0px;
  margin-bottom: 0px;
}

.currency-box-list {
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  padding: 20px 0px;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.currency-box-list-item {
  width: 30%;
}

.currency-box-list-item-content {
  background-color: #f6f6f6;
  color: #000;
  border-radius: 10px;
  padding: 15px 0px;
}

.currency-box-list-item-title {
  border-bottom: 1px solid #ccc;
  width: 50%;
  text-align: center;
  margin: 0 auto;
  font-size: 17px;
}

.currency-box-list-item-value {
  font-weight: bold;
  font-size: 27px;
  line-height: 30px;
}

.debit-info-content-diagram {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 15px;
}

.debit-content-info {
  margin-top: 20px;
}

.debit-content-info-rows-block {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.debit-info-box-footer span {
  padding: 3px 30px;
  border-radius: 20px;
  font-weight: bold;
}

@media (max-width: 610px) {
  .debit-info-box-footer-amount-red {
    padding: 3px 30px;
    border-radius: 20px;
    font-weight: bold;
    border: 1px solid #f7395e;
    color: #f7395e;
  }

  .debit-info-box-footer-amount-green {
    padding: 3px 30px;
    border-radius: 20px;
    font-weight: bold;
    border: 1px solid #8bbe28;
    color: #8bbe28;
  }

  .debit-info-content-diagram {
    margin-top: 50px;
  }

  .currency-box-list-item {
    width: 100%;
    margin-bottom: 15px;
  }
}

.debit-content-box {
  background: #f6f6f6;
  border-radius: 15px;
  padding: 15px 20px;
}

.debit-content-info-row {
  display: flex;
  margin-bottom: 3px;
}

.debit-content-info-row-item-left-side {
  border-right: 1px dashed #ccc;
}

.debit-content-info-row-item-right-side {
  border-left: 1px dashed #ccc;
}

.debit-content-info-row-item-center-side {
  text-align: center;
  font-size: 14px;
  margin: 0px 5px;
}

.debit-content-line-box {
  text-align: right;
  margin-right: 20px;
  position: relative;
  width: 100%;
  display: flex;
}

.debit-info-box-footer {
  text-align: center;
  margin-bottom: 10px;
}

.debit-box-value {
  margin-left: auto;
}

.debit-content-line-box-left .debit-box-item-diagram {
  margin-left: 10px;
}

.debit-content-line-box-right .debit-box-item-diagram {
  margin-right: 10px;
}

.debit-box-item-diagram {
  height: 20px;
  width: 100%;
  margin-top: 4px;
  border-radius: 20px;
}

.analytic-content {
  overflow: hidden;
}

.analytic-content h1 {
  margin-bottom: 0px;
}

.debit-box-red {
  background: #f7395e;
}

.debit-box-green {
  background: #8bbe28;
}

.debit-info-box-footer-red span {
  border: 1px solid #f7395e;
  color: #f7395e;
}

.debit-info-box-footer-green span {
  border: 1px solid #8bbe28;
  color: #8bbe28;
}

.analytic-info-box-footer-red span {
  border: 1px solid #f7395e;
  color: #f7395e;
}

.analytic-info-box-footer-green span {
  border: 1px solid #8bbe28;
  color: #8bbe28;
}

.debit-content-line-box-right .debit-box-value {
  margin-right: auto;
  margin-left: 0;
}

.analytic-content .stats-order-content-holder h1,
.reservation-content .stats-order-content-holder h1,
.contact-page-content .stats-order-content-holder h1,
.customer-care-page .stats-order-content-holder h1,
.newsletter-content .stats-order-content-holder h1 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.analytic-box-options {
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.analytic-box-info-filter-items {
  margin-left: auto;
  display: flex;
}

.analytic-box-info-filter {
  /* border-bottom: 1px solid #ccc; */
  margin-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.analytic-box-info-filter-item.analytic-info-box-footer-green {
  margin-left: 40px;
}

.analytic-box-info-filter-item span {
  border-radius: 15px;
  padding: 2px 20px;
  font-weight: bold;
}

.analytic-box-info-filter-inputs {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.analytic-box-info-filter-items-inputs {
  display: flex;
}

.analytic-box-info-filter-input-item:nth-child(1) {
  margin-right: 15px;
}

.analytic-box-info-filter-input-item:nth-child(2) {
  margin-right: 15px;
}

.analytic-box-info-filter-input-item:nth-child(4) {
  margin-left: auto;
  margin-right: 15px;
}

.analytic-box-info-filter-input-item i {
  border: 1px solid #ccc;
  padding: 4px;
  width: 32px;
  text-align: center;
  margin-left: 4px;
  cursor: pointer;
}

.analytic-content .status-order-table {
  margin-top: 5px;
}

/* /////////////////////////////////// */
/* //////////AUKCIJE/////////////////// */
/* /////////////////////////////////// */
.auticon-content {
  background-color: #071113;
  min-height: 100vh;
}

.auction-info-block {
  cursor: pointer;
}

.auticon-content-holder img {
  width: 100%;
}

.auticon-content-holder {
  width: 100%;
  position: relative;
}

.auciton-title-description {
  position: relative;
  bottom: 50px;
  color: #fff;
  text-align: center;
  margin: 0px 75px;
  border-bottom: 1px solid;
  padding-bottom: 20px;
}

.auction-title {
  font-size: 40px;
  font-weight: bold;
}

.auticon-content {
  padding: 0px;
}

.auction-content-items {
  /* margin-top: 50px; */
  margin-bottom: 50px;
  display: flex;
  padding: 0px 60px;
  flex-wrap: wrap;
  justify-content: center;
}

.auction-content-item {
  background: #fff;
  width: 290px;
  margin-right: 34px;
  padding: 15px 35px;
  border-radius: 20px;
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#auction-holder {
  position: absolute;
  top: 40%;
}

.auticon-item-price {
  text-align: center;
  border-bottom: 1px solid #c9c9c9;
}

.auction-price-title {
  text-transform: uppercase;
}

.auction-price-value {
  font-weight: bold;
  font-size: 29px;
  margin-top: 0;
  color: #e6264b;
}

.auticon-item-image img {
  width: 100%;
  padding: 15px;
}

.auction-detail-block {
  margin-top: -100px;
  position: relative;
}

.title-action-text {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}

.auction-time-value .timer-item div {
  display: inline-block;
  background: #e3e3e3;
  height: 65px;
  width: 65px;
  font-size: 42px;
  text-align: center;
  border-radius: 8px;
}

.auticon-item-time {
  margin-top: 15px;
  text-align: center;
}

.auticon-item-image {
  margin-top: 10px;
}

.auction-time-title {
  font-size: 14px;
  text-align: center;
  margin-bottom: -10px;
}

.auticon-item-buttons {
  margin-top: 20px;
  display: flex;
}

.button-bid-list {
  flex: 1 1;
  text-align: center;
  background: #e3e3e3;
  border-radius: 15px;
  padding: 5px 0px;
  cursor: pointer;
}

.button-bid-quote {
  flex: 1 1;
  text-align: center;
  color: #fff;
  background: #e6264b;
  border-radius: 15px;
  padding: 5px 0px;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 5px;
  cursor: pointer;
}

.auction-image-block-image img {
  width: 100%;
}

.auction-body-detail {
  background: #fff;
  border-radius: 30px;
  padding: 40px;
  display: flex;
  margin-bottom: 50px;
  align-items: center;
}

.auction-big-image-block {
  flex: 1 1;
  padding: 20px 40px;
}

.auction-list-block {
  flex: 1 1;
  border-left: 1px solid #2d2d2f;
  padding: 20px 40px;
}

.auction-list-block .auticon-item-buttons {
  align-items: center;
}

.auction-list-block .button-bid-list {
  border-radius: 25px;
  font-size: 25px;
}

.auction-detail-holder {
  padding: 0px 75px;
}

.auction-image-block-title {
  text-align: center;
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
}

.auction-image-block-title p {
  text-align: center;
  font-weight: 300;
  color: #111;
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.auction-image-block-price {
  display: inline-block;
  border-bottom: 1px solid #c9c9c9;
  text-align: center;
  width: 100%;
  padding-bottom: 5px;
  margin: 15px 0;
}

.auction-image-block-price-title {
  display: inline-block;
  font-size: 20px;
  margin-right: 10px;
  text-transform: uppercase;
}

.auction-image-block-price-value {
  display: inline-block;
  font-size: 50px;
  line-height: 0px;
  font-weight: bold;
  color: #e6264b;
}

.auction-image-block-time {
  text-align: center;
}

.auction-list-block-table ul {
  list-style: none;
  padding-left: 0px;
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  border-radius: 20px;
  height: 440px;
  overflow-y: auto;
}

.auction-list-block-table li {
  border-bottom: 1px solid #666;
  padding: 3px 5px;

}

.auction-list-block-table-li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.auction-list-block-table-li span {
  font-size: 22px;
}

.offer-text-auction {
  display: flex;
  flex-direction: column;
}

.offer-text-auction span {
  font-size: 15px;
  color: #666;
  font-weight: 300;
}

.auction-list-block-title {
  margin-bottom: 5px;
  text-align: right;
  font-weight: 600;
  font-size: 20px;
  padding-right: 15px;
}

.auction-list-block-title span {
  font-size: 40px;
  margin-left: 10px;
  color: #e6264b;
  font-weight: bold;
}

.auction-list-block-bid-input-box {
  display: flex;
  border: 1px solid #c9c9c9;
  border-radius: 25px;
  padding: 5px 2px;
  margin-left: 10px;
  flex: 1 2;
}

.auction-list-block-bid-input-box input {
  border: none;
  padding: 0px;
  box-shadow: none !important;
}

.auction-list-block-bid-input {
  margin-left: 20px;
  width: 100%;
}

.auction-list-block-bid-button {
  background: #e6264b;
  margin-right: 6px;
  color: #fff;
  text-transform: uppercase;
  padding: 7px 18px;
  border-radius: 20px;
  padding-top: 7px;
  cursor: pointer;
  border: none;
}

.auction-list-info {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 20px;
  color: #7c7c7c;
  font-weight: bold;
}

.auction-other-items {
  text-align: center;
  color: #fff;
  font-size: 36px;
  border-bottom: 1px solid #fff;
  margin-bottom: 30px;
  padding-bottom: 10px;
  margin: 0px 75px 40px 75px;
  font-weight: 600;
}


/* /////////////////////////////////// */
/* //////////KONTAKT STRANA/////////// */
/* /////////////////////////////////// */
.pickup-return-page {
  overflow: hidden;
}

.banner-image-on-top-page img {
  width: 100%;
}

.banner-image-on-top-page {
  flex: 0 0 100%;
  max-width: 100%;
}

.btn-add-article-pickupreturn {
  background: #1ea8c5;
  color: #fff;
  width: 180px;
  text-align: center;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  text-transform: uppercase;
}

.pickup-return-title {
  font-weight: bold;
  font-size: 26px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.contact-title {
  font-weight: bold;
  font-size: 26px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.pickup-return-desc {
  margin-bottom: 30px;
  font-size: 16px;
}

.contact-desc {
  margin-bottom: 30px;
  font-size: 16px;
}

.pickup-return-form-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.contact-form-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.form-body-holder-pickupreturn {
  border: 1px solid #c9c9c9;
  padding: 20px 15px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.form-body-holder-contact {
  border: 1px solid #c9c9c9;
  padding: 20px 15px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.form-contact-holder {
  line-height: normal;
}

.form-contact-holder p {
  margin-left: 15px;
  /* font-size: 14px; */
}

.contact-form-col-title {
  margin-left: 15px;
  margin-bottom: 25px;
  font-size: 20px;
}

.contact-form {
  width: 1200px;
  margin: 0 auto;
}

.button-add-holder-article {
  margin-top: 40px;
}

.contact-page-content {
  overflow: hidden;
}

.contact-form22 {
  margin-top: 20px;
}

.contact-item {
  background: #fff;
  padding: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  box-shadow: 0 0 10px rgb(0 0 0 / 7%);
  margin-bottom: 30px;
  height: 150px;
}

.contact-item:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.contact-item h4 {
  margin-top: 12px;
  margin-bottom: 5px;
  color: #33495F;
  font-weight: bold;
}

.contact-info {
  align-items: center;
}

.contact-item a {
  color: #C1D549;
}

/* .contact-item img {
  padding-left: 15px;
} */

.contact-item-text {
  /* padding-left: 25px; */
  margin-top: -10px;
}

.contact-map {
  /* margin: 100px 0; */
  /* padding: 0 50px; */
  padding: 0;
  margin-top: 25px;
}

.contact-map iframe {
  height: 400px !important;
}

@media (min-width: 2400px) {
  .contact-title {
    margin-top: 70px;
  }

  .news-items-list {}
}

@media (max-width: 1750px) {
  .contact-form {
    width: 100%;
  }

  .button-add-holder-article {
    margin-top: 20px;
  }
}

@media (max-width: 1680px) and (min-width: 1536px) {
  .contact-item {
    height: 130px;
  }

  .btn-add-special-product-action {
    font-size: 14px;
  }

  .contact-item h4 {
    font-size: 20px;
  }

  .contact-item img {
    padding-left: 0;
  }
}

@media (max-width: 1535px) and (min-width: 1366px) {
  .contact-item {
    height: 120px;
  }

  .contact-item-text {
    margin-left: -25px;
    margin-top: -13px;
    font-size: 15px;
  }

  .contact-item h4 {
    font-size: 20px;
  }

  .contact-item img {
    margin-left: -10px;
  }

}

@media (max-width: 1780px) and (min-width: 1366px) {

  .auticon-item-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .auction-list-block .button-bid-list {
    border-radius: 25px;
    font-size: 25px;
    width: 100%;
    margin: 5px;
  }

  .auction-list-block-bid-input-box {
    display: flex;
    border: 1px solid #c9c9c9;
    border-radius: 25px;
    padding: 5px 2px;
    width: 100%;
    margin-left: 0;
  }

  .button-bid-quote {
    flex: 1 1;
    text-align: center;
    color: #fff;
    background: #e6264b;
    border-radius: 15px;
    padding: git status5px 0px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 5px;
    cursor: pointer;
    width: 100%;
  }

  .button-bid-list {
    flex: 1 2;
    text-align: center;
    background: #e3e3e3;
    border-radius: 15px;
    padding: 5px 0px;
    cursor: pointer;
    margin: 5px;
    width: 100%;
  }
}

@media (max-width: 1280px) {

  .auticon-item-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .auction-list-block .button-bid-list {
    border-radius: 25px;
    font-size: 25px;
    width: 100%;
    margin: 5px;
  }

  .auction-list-block-bid-input-box {
    display: flex;
    border: 1px solid #c9c9c9;
    border-radius: 25px;
    padding: 5px 2px;
    width: 100%;
    margin-left: 0;
  }

  .button-bid-quote {
    flex: 1 1;
    text-align: center;
    color: #fff;
    background: #e6264b;
    border-radius: 15px;
    padding: 5px 0px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 5px;
    cursor: pointer;
    width: 100%;
  }

  .button-bid-list {
    flex: 1 1;
    text-align: center;
    background: #e3e3e3;
    border-radius: 15px;
    padding: 5px 0px;
    cursor: pointer;
    margin: 5px;
    width: 100%;
  }
}

@media (max-width: 1365px) and (min-width: 1280px) {
  .contact-item {
    height: 120px;
  }

  .contact-item-text {
    margin-left: -25px;
    margin-top: -13px;
    font-size: 15px;
  }

  .contact-item h4 {
    font-size: 19px;
  }

  .contact-item img {
    margin-left: -10px;
  }

  .catalog-block-item-value-table-td {
    width: 80px;
  }
}

@media (max-width: 1279px) and (min-width: 768px) {
  .contact-item {
    height: 110px;
  }

  .contact-item-text {
    margin-left: -25px;
    margin-top: -18px;
    font-size: 15px;
  }

  .contact-item h4 {
    font-size: 19px;
  }

  .contact-item img {
    margin-left: -10px;
  }
}

/* @media (max-width: 1600px) and (min-width: 1200px) {
  .contact-form-col-title {
    margin-left: 8px;
    margin-bottom: 25px;
    font-size: 17px;
  }

  .form-contact-holder p {
    margin-left: 8px;
    font-size: 13px;
  }

  .contact-item {
    font-size: 14px;
    height: 130px;
  }

  .contact-item h4 {
    font-size: 15px;
    margin-top: 10px;
  }

  .contact-item img {
    padding-left: 15px;
    width: 100px;
  }

  .contact-map iframe {
    height: 380px !important;
  }

  .contact-map {
    /* margin: 100px 0; 
    padding: 0 25px;
  }
} */

@media (max-width: 1200px) and (min-width: 768px) {
  .contact-form-col-title {
    margin-left: 5px;
    margin-bottom: 25px;
    font-size: 15px;
  }

  .form-contact-holder p {
    margin-left: 5px;
    font-size: 14px;
  }

  /* .contact-item {
    font-size: 12px;
    height: 190px;
  }

  .contact-item h4 {
    font-size: 15px;
    margin-top: 15px;
  }

  .contact-item img {
    padding-left: 20px;
    width: 90px;
  } */

  .contact-map iframe {
    height: 360px !important;
  }

  .contact-map {
    /* margin: 100px 0; */
    padding: 0 0;
  }
}

@media (max-width: 1200px) and (min-width: 1024px) {

  /* .contact-item {
    font-size: 15px;
    height: 220px;
  }

  .contact-item img {
    padding-left: 20px;
    width: 120px;
  }

  .contact-item h4 {
    font-size: 16px;
    margin-top: 10px;
  } */

  /*SEARCH MODAL */
  .search-box-modal {
    left: 340px;
  }

  .search-box-modal.active-sidebar-collapsed {
    left: 260px;
  }


}

@media (max-width: 991px) {
  .contact-form-col-title {
    margin-left: 0;
    margin-bottom: 25px;
    font-size: 16px;
    text-align: center;
  }

  .form-contact-holder p {
    margin-left: 0;
    font-size: 14px;
    text-align: center;
  }

  .contact-map iframe {
    height: 360px !important;
  }

  .contact-map {
    /* margin: 100px 0; */
    padding: 0 0;
    margin-top: 45px;
  }

  .catalog-block-list-item {
    width: 100%;
  }

  .catalog-block-item-value-table-td {
    width: 150px;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog,
  .catalog-block-list-item {
    width: 100%;
    margin-right: 0;
  }

}

@media (max-width: 480px) {
  .contact-item {
    font-size: 15px;
    height: 130px;
  }

  .contact-item-text {
    padding-left: 10px;
    margin-top: -10px;
  }

  .contact-item h4 {
    font-size: 20px;
  }

  .catalog-block-item-value-table-td {
    width: 200px;
  }
}

.pickup-return-form-body-article {
  border: 1px solid #efefef;
  border-radius: 5px;
  background: #efefef;
  padding: 15px 20px;
}

.pickup-return-form-item {
  display: flex;
  border-bottom: 2px solid #fff;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.pickup-return-form-buttons {
  margin-left: 20px;
  font-size: 17px;
}

.pickup-return-import-decs {
  background-color: #cce5ff;
  padding: 10px;
}

.pickup-return-table {
  margin-top: 35px;
}

.button-add-holder-pickupreturn {
  margin-top: 1rem;
}

.reservation-content {
  overflow: hidden;
}

.reservation-content .stats-order-content-holder h1 {
  margin-bottom: 0px;
}

.reservation-content .status-order-filter-line {
  margin-top: 0px;
}

.reservation-box-color {
  height: 12px;
  width: 12px;
  position: relative;
  display: inline-block;
  margin-right: 5px;
  border-radius: 3px;
}

.target-content {
  overflow: hidden;
}

.target-content .stats-order-content-holder h1 {
  margin-bottom: 40px;
  border-bottom: solid 1px #E0E0E0;
  padding-bottom: 10px;
}

.target-box-color {
  height: 18px;
  width: 18px;
  text-align: center;
  font-size: 12px;
  margin-right: 0;
}

.reservation-content .status-order-table table.table.editing-cart-table thead th:nth-child(1) {
  text-align: left;
}

.reservation-content .status-order-table {
  margin-top: 0;
}

.contract-content .status-order-table {
  margin-top: 5px;
}

.contract-content .stats-order-content-holder h1 {
  margin-bottom: 0px;
  border-bottom: 0px;
}

.contract-content .status-order-filter-line {
  margin-top: 5px;
}

.contract-content .status-order-table table.table.editing-cart-table thead th:nth-child(1) {
  text-align: left;
}

.btn-contract-item-button {
  background: #1ea8c5;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.btn-download-document-contract {
  padding: 0px 15px;
}


.page-not-found-container {
  width: 100%;
  /* height: 100vh; */
  position: relative;
  background-color: #fff;
}

.page-not-found-container img {}

.page-banner-top-image img {
  border-radius: 15px;
}

.page-banner-top-image {
  /* height: 350px; */
  margin-bottom: 15px;
}

.custom-banner-element-holder {
  margin-bottom: 25px;
  /* height: 350px; */
  position: relative;
}

.custom-banner-element-body {
  height: 100%;
  width: 100%;
  background-size: cover;
  bottom: 0px;
  margin: 0 auto;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 15px;
}

.custom-banner-elemnt-text {
  position: absolute;
  color: #fff;
  font-size: 40px;
}

.custom-banner-elemnt-text-top-left {
  top: 40px;
  left: 40px;
}

.custom-banner-elemnt-text-top-center {
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
}

.custom-banner-elemnt-text-top-right {
  top: 40px;
  right: 40px;
}

.custom-banner-elemnt-text-center-left {
  top: 50%;
  left: 40px;
  transform: translate(0, -50%);
}

.custom-banner-elemnt-text-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-banner-elemnt-text-center-right {
  top: 50%;
  right: 40px;
  transform: translate(0, -50%);
}

.custom-banner-elemnt-text-bottom-left {
  bottom: 40px;
  left: 40px;
}

.custom-banner-elemnt-text-bottom-center {
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
}

.custom-banner-elemnt-text-bottom-right {
  bottom: 40px;
  right: 40px;
}

/* .loader-mask {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.loader-mask {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%2362c900' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.catalog-content-box .alert {
  margin-top: 0px;
}

.main-title-categroy-item {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 5px;
  position: relative;
  min-height: 30px;
}

.main-title-categroy-item span {
  font-weight: normal;
}

.catalog-breadcrumbs-item {
  font-size: 14px;
  color: #666;
}

.catalog-breadcrumbs-item a {
  color: #666;
}

.shopping-cart-items-list .input-qty-add-item {
  background-color: transparent;
  color: #fff;
}

.shopping-cart-items-list .button-div-item-qty .plus-item-qty {
  color: #fff;
}

.shopping-cart-items-list .button-div-item-qty .minus-item-qty {
  color: #fff;
}

.shopping-cart-menu-item {
  position: relative;
}

.shopping-cart-item-name span {
  height: 17px;
  width: 17px;
  background-color: #eb0e4c;
  position: absolute;
  top: 5px;
  left: -17px;
  text-align: center;
  border-radius: 27px;
  padding-top: 0px;
  font-size: 11px;
}

.clone-cart-name {
  height: 20px;
  margin-top: 6px;
}

.wrapper iframe {
  width: 100%;
  height: 100vh;
}

.content-slider-image-product-one-item {
  width: 40% !important;
}

img.img-fluid.content-slider-image-small {
  width: 65%;
}

.h3-cust-small {
  height: 550px;
  width: 80%;
  margin: 0 auto !important;
}

.search-brands-block-content h3 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 0px;
  margin-bottom: 5px !important;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
}

.search-categories-block-content h3 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 0px;
  margin-bottom: 5px !important;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
}

.search-brands-block-content h5 {
  padding: 5px 20px;
  margin-bottom: 0px;
  font-size: 18px;
}

.search-brands-block-content .menu-items {
  margin-top: 0px;
}

.search-categories-block-content h5 {
  padding: 5px 20px;
  margin-bottom: 0px;
  font-size: 18px;
}

.search-categories-block-content .menu-items {
  margin-top: 0px;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.info-alert-search {
  width: 100%;
  font-size: 24px;
  margin-bottom: 15px;
}

.info-alert-search span {
  font-weight: normal;
}

.search-content-box .catalog-sort-content {
  justify-content: right;
}

.title-cart-order {
  font-size: 28px;
  border-bottom: 2px solid;
  margin-bottom: 20px;
}

.title-cart-order span {
  font-weight: 500;
}

.comapre-content-container-minimize {
  background: #000000ad;
  z-index: 40;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 6px 10px 6px 50px;
  box-shadow: 0 8px 13px 0 rgb(0 0 0 / 15%);
}

.comapre-content-container-minimize:hover {
  /* text-decoration: underline; */
}

.comapre-button-footer-minimize:hover {
  text-decoration: underline;
}

.comapre-button-footer-minimize {
  position: absolute;
  top: 5px;
  right: 15px;
  cursor: pointer;
}

.comapre-button-footer-maximize {
  display: flex;
}

.minimize-counter-products-compare {
  flex: 1 1;
}

.minimize-counter-products-compare {
  flex: 1 1;
  font-size: 19px;
  font-weight: 700;
}

.icon-maximze-content {
  cursor: pointer;
}

.maximize-items-compare {
  display: flex;
}

.maximize-compare-item-btn .comapre-button-footer-clear {
  margin-right: 15px;
}

.maximize-compare-item-btn {
  margin-right: 5px;
}

.maximize-compare-item-btn i {
  font-size: 20px;
}

.comapre-button-footer-minimize i {
  font-size: 20px;
}

.comapre-content-container-minimize.hidden {
  display: none;
}

/* pravi zbrku za tablu u status-porudzbine */
/* .MuiTableCell-root {
  padding: 10px !important;
} */
.price-value-column-modal-status-order {
  width: 150px;
}

.detail-status-order-table-modal td {
  padding: 5px 10px;
}

.catalog-item-block-body-image img {
  max-width: 180px;
}

.catalog-item-image-block-img {
  height: 160px;
}

.catalog-item-image {
  position: relative;
}

.error-input-message {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #FF0000;
}

.range-filter-content {
  /* height: 125px; */
  /* height: 235px; */
  margin-bottom: 15px;
}

.input-range__track--active {
  background: #62c900 !important;
}

.input-range__slider {
  background: #62c900 !important;
  border: 5px solid #e3e3e3 !important;
  height: 24px !important;
  width: 24px !important;
  top: -3px !important;
}

.input-range__track {
  height: 6px !important;
}

.input-range__label--max .input-range__label-container {
  left: 0% !important;
}

.input-range__label {
  color: #212529 !important;
  font-size: 14px !important;
}

.input-range {
  margin: 0 auto !important;
  width: 90% !important;
}

.container-price-block-inputs {
  display: flex;
}

.price-block-input-min-item {
  margin-right: 10px;
}

.custom-range-input {
  background-color: #fff !important;
}

.price-block-input-item {
  color: rgb(34, 34, 34) !important;
  width: 100%;
  margin-top: 10px;
  padding: 0px 20px 5px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #fafafa;
}

.range-slider-values-print small {
  color: rgb(113, 113, 113) !important;
}

.slider-values-print-item {
  margin-top: -5px;
  font-size: 16px;
}

.search-brands-block-content {
  margin-top: 15px;
}

.filter-top-item-modal {
  display: flex;
}

.filter-top-item-modal .total-count-filter {
  float: none;
}

.filter-top-item-modal-text {
  flex: 1 1;
}

.close-top-filter-modal {
  cursor: pointer;
}

.conent-subfilters-holder {
  display: flex;
}

.conent-subfilters-holder ul {
  flex: 1 1;
}

.filter-top-item-modal-text label {
  width: 90%;
}



/**
 * Main wrapper
 */
.select-search {
  width: 300px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2FCC8B;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

#custom-input-demo {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

#custom-input-demo:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

#autocomplete-custom-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

#autocomplete-custom-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.pretty input:checked~.state.p-primary label:after,
.pretty.p-toggle .state.p-primary label:after {
  background-color: #bdc3c7 !important;
}

.filters-mobile-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: #fff;
  overflow-y: auto;
  margin-top: 65px;
}

.filters-mobile-container.active {
  display: block;
}

.close-mobile-filter-modal {
  text-align: right;
  margin: 10px;
}

.mobile-filters-button {
  width: 100%;
}

.close-mobile-filter-modal-item {
  background-color: #17a2b8;
  border-color: #17a2b8;
  padding: 4px 15px;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.search-box-modal .search-modal-close-mobile {
  display: none;
  position: fixed;
  top: 10px;
  right: 15px;
  font-size: 25px;
}

.loyalty-msg-holder {
  /* margin-top: 50px; */
}

.table-voucher-list th {
  border-top: 0px;
}

.container-custom-page-content .menu-modal-item-header {
  border-bottom: 2px solid #626262;
}

.container-custom-page-content .menu-modal-list-links ul {
  margin-top: 0px;
  padding-bottom: 20px;
}

.container-custom-page-content .menu-modal-list-links li {
  border-bottom: 2px solid #626262;
  font-size: 20px;
  padding: 8px 8px;
  color: #626262;
}

.Component-horizontalScrollContainer-12 {
  z-index: 0 !important;
}

.cart-editing-mobile-button {
  display: none;
}

.span-info-cart-box {
  flex: 1 1;
}

.hidden-form-inputs {
  display: none;
}

.form-shop-cart {
  width: 100%;
}

.text-input-required-red {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #FF0000;
}

.is-invalid-autocomplete-input input {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.collapse-button-sidebar {
  /* position: absolute;
  left: calc(50% - 18px);
  bottom: 200px; */
  width: 85%;
  margin-top: 20px;
}

.btn-custom-editing-cart {
  display: inline-block;
  cursor: pointer;
}

.catalog-content-top-items-filter {
  /* display: flex; */
}

.popup-type-line {
  border-top: 1px solid;
  /* margin-top: 60px; */
}

.list-idents-item-popup {
  position: absolute;
  right: 0;
  top: -30px;
  color: #fff;
  display: flex;
}

.ident-item-popup {
  background: #ccc;
  margin-right: 5px;
  padding: 20px 8px 2px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.category-main-no-sub .subcategory-menu-item {
  padding-left: 30px;
}

.new-price-catalog-price.price-catalog-offline {
  font-size: 23px;
}

.catalog-item-buttons-offline {
  margin-top: 51px;
}

.catalog-item-block-qty-offline {
  margin-left: 20px;
}

.link-filtered-search:hover {
  text-decoration: none;
}

.slider-image-home-block {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 500px;
}

.width-100-td {
  width: 100px;
}

.width-120-td {
  width: 120px;
}

.width-150-td {
  width: 150px;
}

.related-products-list {
  margin-top: 30px;
  padding: 0px 45px;
}

.btn-success {
  background-color: #62c900;
  border-color: #62c900;
}

.btn-success:hover {
  background-color: #54ae00;
  border-color: #54ae00;
}

.btn-secondary {
  background-color: #eb0e4c;
  border-color: #eb0e4c;
}

/* .btn-secondary svg{
  margin-left: 9px;
  margin-top: 2px;
  margin-right: -2px;
  margin-bottom: 2px;
} */

.btn-secondary:hover {
  background-color: #bd2946;
  border-color: #bd2946;
}

.btn:enabled:after {
  transition: all .3s ease-out 0s;
  background: rgba(0, 0, 0, .09) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  z-index: 1;
}

.slider-logout-page-content .slick-slider {
  max-width: 100%;
  display: grid;
  height: 100%;
}

.slider-logout-page-content {
  background: #fafafa;
  padding: 40px 60px;
  position: relative;
}

.h2-silder-title-logout-page {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40%;
}


.slider-logout-page-item-image {
  background: #fff;
  margin-right: 30px;
  border-radius: 25px;
  margin-left: 10px;
}

.slider-logout-page-item-image img {
  object-fit: cover;
  width: 100%;
  height: 200px;
  border-radius: 25px;
}

.we-present-page-item-image {
  background: #fff;
  margin-right: 30px;
  border-radius: 25px 25px 0 0;
  margin-left: 10px;
}

.we-present-page-item-image img {
  object-fit: cover;
  width: 100%;
  height: 230px;
  border-radius: 25px 25px 0 0;
}

.slider-logout-page-item-body {
  background: #fff;
  margin-right: 30px;
  margin-top: -30px;
  padding: 45px 20px 20px 20px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.07);
  margin-bottom: 10px;
  margin-left: 10px;
  height: 200px;
}

.we-present-page-item-body {
  background: #fff;
  margin-right: 30px;
  margin-top: -30px;
  padding: 45px 20px 10px 20px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.07);
  margin-bottom: 10px;
  margin-left: 10px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.we-present-page-item-holder {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-wrap: wrap;
}

.button-submit-ly-shop-disabled button {
  cursor: not-allowed;
}

.button-submit-ly-shop-disabled button:hover {
  background-color: #28a745;
  border-color: #28a745;
}

.we-present-page-item-body:hover {
  box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.2);
}

.slider-logout-page-item-body h3 {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 30px;
}

.we-present-page-item-body h3 {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 30px;
}

.slider-logout-page-item-link {
  color: #777;
  position: absolute;
  bottom: 30px;
  margin: 0 auto;
}

.we-present-page-item-link {
  color: #777;
  bottom: 30px;
  margin: 0 auto;
}

.we-present-page-divider {
  width: 80%;
  margin: 0 auto;
  border-top: #e4e4e4 1px solid;
  margin-top: auto;
  margin-bottom: 10px;
}

@media (max-width: 1600px) and (min-width: 1460px) {
  .we-present-page-item-body {
    height: auto;
  }

  .we-present-page-item-image img {
    height: 200px;
  }

  .we-present-page-item-body h3 {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

@media (max-width: 1460px) and (min-width: 1024px) {
  .we-present-page-item-body {
    height: auto;
  }

  .we-present-page-item-image img {
    height: 160px;
  }

  .we-present-page-item-body h3 {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .we-present-page-item {
    padding: 0;
  }
}

@media (max-width: 600px) {
  .we-present-page-item-holder {
    flex-direction: column;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .we-present-page-item-body {
    height: auto;
  }

  .we-present-page-item-image img {
    height: 140px;
  }

  .we-present-page-item-body h3 {
    font-size: 14px;
  }

  .we-present-page-item-link {
    font-size: 14px;
  }
}

.slider-logout-page-content .slick-slider {
  position: unset;
}

.slider-logout-page-content .slick-prev {
  position: absolute;
  top: 60%;
  left: -19%;
}

.slider-logout-page-content .slick-next {
  position: absolute;
  top: 60%;
  left: -13%;
}

.logout-page-brands-list {
  margin-top: 30px;
}

.logout-page-search .search-box {
  padding: 0px;
}

.logout-page-logo-content {
  max-width: 1300px;
  margin: 30px auto auto auto;
  display: flex;
  flex-wrap: wrap;
}

.logo-item-logout-page {
  flex: 0 0 16.66667%;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.logo-item-logout-page img {
  filter: grayscale(100%);
  opacity: .6;
  max-width: 100px;
}

.logo-item-logout-page:hover img {
  filter: none;
  opacity: 1;
}

.logout-page-products-info h2 {
  font-size: 12px;
}

.logout-page-products-info p {
  font-size: 12px;
}

/* /////////////////////////////////// */
/* //////////////ANKETE/////////////// */
/* /////////////////////////////////// */
.poll-block {
  width: 100%;
  margin-bottom: 20px;
  background-color: #eb0e4c;
  color: #fff;
  float: left;
  padding: 12px 20px;
  border-radius: 10px;
}

.poll-block-info h3 {
  font-size: 17px;
  font-weight: 600;
}

.poll-block-info {
  border-right: 1px solid #fff;
  padding-right: 15px;
  height: 100%;
  text-align: right;
}

.poll-block-info-buton button {
  color: #000;
  background: #fff;
  border-radius: 25px;
  font-weight: 6000;
  font-size: 19px;
  padding: 5px 25px;
  margin-top: 3px;
}

.poll-modal .modal-body {
  background: #ccc;
  border-radius: 25px;
}

.poll-modal .modal-content {
  border-radius: 25px;
}

.poll-container-modal {
  background: #fff;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  min-height: 45vh;
  position: relative;
}

.poll-header-info-text {
  background: #f7395d;
  color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 6px 20px;
  text-transform: uppercase;
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  font-size: 21px;
}

.poll-header-info {
  text-align: center;
  margin: 0 auto;
}

.poll-quest-answer-block-range {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.poll-quest-answer-block-range div {
  margin: 0 10px;
}

.poll-quest-answer-block-range .check-box-option {
  margin: 0 30px;
}

.poll-quest-answer-block-range .radio-option {
  position: relative;
  margin-top: 10px;
}

.poll-quest-answer-block-range .radio-option img {
  margin-bottom: 30px;
}

.poll-quest-answer-block-range .radio-option-answer {
  position: absolute;
  bottom: 0;
  left: 55%;
}

.poll-quest-answer-block-range textarea {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #ccc;
  padding: 10px 15px;
  max-height: 200px;
  min-height: 200px;
}

.button-answer-item {
  background: none;
  border: 5px solid #ccc;
  padding: 10px 20px;
  font-size: 19px;
  border-radius: 50px;
  text-transform: uppercase;
  color: #ccc;
  font-weight: bold;
}

.button-answer-item:hover {
  border: 5px solid #f7395d;
  color: #f7395d;
}

.button-checked {
  border: 5px solid #f7395d;
  box-shadow: rgba(0, 0, 0, 0.3) 1.95px 1.95px 2.6px;
  color: #f7395d;
}

.poll-answer-block-range-item {
  flex: 1 1;
  cursor: pointer;
}

.poll-question-answer-block {
  width: 100%;
  padding: 0 80px;
}

.poll-question-answer-block-button {
  margin-bottom: 30px;
}

.poll-question-title h2 {
  margin-bottom: 30px;
}

.poll-question-answer-block-button button {
  background: #f7395d;
  color: #fff;
  border-radius: 23px;
  font-size: 20px;
  padding: 4px 16px;
  font-weight: 600;
}

.poll-question-answer-block-button button:hover {
  color: #fff;
}

.survey-invalid {
  margin-top: 20px;
}

.survey-invalid span {
  color: red;
}

.poll-thank-you-note {
  margin: auto 40px;
}

.poll-header-desc {
  padding: 15px 20px;
  color: #a3a3a3;
  font-weight: 600;
}

.empty-cart .fa-shopping-cart {
  font-size: 24px;
}

.survey-info-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  right: 0;
  padding: 10px 15px;
}

/* ANKETE - responsive*/
@media(max-width: 1366px) and (min-width: 992px) {
  .poll-question-title h2 {
    padding-top: 15px;
  }

  .poll-question-answer-block-button {
    margin-bottom: 20px;
    margin-top: 30px;
  }
}

@media(max-width: 991px) {
  .poll-question-answer-block {
    padding: 0 30px;
  }

  .poll-quest-answer-block-range .check-box-option {
    margin-top: 10px;
  }

  .poll-question-answer-block-button {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .survey-info-time {
    padding: 10px;
    font-size: 14px;
  }

  .poll-header-info-text {
    width: 70%;
    font-size: 18px;
  }
}

@media(max-width: 523px) {
  .poll-header-info-text {
    font-size: 18px;
  }

  .poll-question-answer-block {
    padding: 0 15px;
  }

  .button-answer-item {
    font-size: 15px;
  }

  .poll-quest-answer-block-range .radio-option {
    max-width: 120px;
  }

  .poll-quest-answer-block-range .radio-option-answer {
    left: 61%;
  }

  .poll-question-title h2 {
    font-size: 25px;
    padding-top: 15px;
  }

  .poll-thank-you-note h1 {
    font-size: 25px;
  }

  .poll-block-info-buton button {
    font-size: 15px;
  }

  .survey-info-time {
    padding: 10px 3px;
    right: 2px;
  }
}


.content-category-boxes {
  overflow-x: hidden;
}

.content-category-boxes::-webkit-scrollbar {
  width: 8px;
}

.content-category-boxes::-webkit-scrollbar-track {
  background: #D3D3D3;
}

.content-category-boxes::-webkit-scrollbar-thumb {
  background: #888;
}

.content-category-boxes::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.action-block-special {
  width: 100%;
  margin-bottom: 20px;
}

.action-block-special-content {
  box-shadow: 0px 3px 35px 0px #cecece;
  border-radius: 15px;
  padding: 20px 15px;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.homepage-content-special-image-block {
  height: 480px;
  width: 480px;
  border-radius: 15px;
  position: relative;
}

.homepage-content-special-image-block img {
  height: 480px;
  width: 480px;
  object-fit: cover;
}

.homepage-content-body-special-block {
  box-shadow: 0px 3px 35px 0px #cecece;
  border-radius: 15px;
  padding: 20px 15px;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.action-block-special-banner {
  height: 480px;
  width: 480px;
  border-radius: 15px;
  position: relative;
  /* grid-area: banner; */
}

.action-block-special-banner img {
  border-radius: 15px;
}

.homepage-content-special-image-block img {
  border-radius: 15px;
}

.action-block-special-slider-title-main h2 {
  text-transform: uppercase;
  font-size: 26px;
  padding-bottom: 5px;
  font-weight: bold;
}

.action-block-special-slider-item-image img {
  width: 100%;
  margin: 0 auto;
  padding: 15px;
}

.action-block-special-slider-main-slider-item {
  border-right: 1px solid #b4b4b4;
  height: 410px;
  padding: 10px 20px;
  position: relative;
}

.btn-cart-finish-text {
  text-align: left;
  margin-left: 10px;
  line-height: 1.1;
  font-size: 18px;
}

.btn-cart-finish-text span {
  font-size: 12px;
  text-align: center;
  font-style: italic;
}

.action-block-special-slider-title-main {
  border-bottom: 2px solid #e6264b;
  margin-bottom: 20px;
}

.action-block-special-slider {
  /* margin-left: 20px; */
  width: 100%;
  padding: 0px 20px;
  /* grid-area: slider; */
}

.homepage-special-block-items {
  width: 100%;
  padding: 0px 20px;
}

.action-block-special-slider-main-slider .slick-slider {
  display: grid;
  height: 100%;
}

.action-block-special-slider-main-slider .slick-track {
  height: 100%;
}

.slider-special-action-image img {
  /* width: 100%; */
}

.action-block-special-slider-main-slider .slick-slide>div {
  height: 100%;
}

.action-block-special-slider-item-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.action-block-special-slider-item-image {
  margin-bottom: 15px;
}

.action-block-special-slider-item-button {
  margin-top: auto;
  text-align: center;
}

.button-slider-item-detail {
  background: #212529;
  color: #fff;
  padding: 6px 0px;
  border-radius: 8px;
  cursor: pointer;
}

.action-block-special-slider-item-price-content {
  display: flex;
  margin-top: 10px;
  font-size: 14px;
}

.price-content-item.price-value {
  color: #f92a5e;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
}

.price-content-item.oldprice-value {
  text-decoration: line-through;
}

.action-block-special-slider-item-price-content-left {
  flex: 1 1;
}

.action-block-special-slider-item-price-content-right {
  flex: 1 1;
  text-align: right;
  font-size: 14px;
}

.action-block-special-slider-item-title {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: bold;
  height: 57px;
  line-height: 18px;
}

.main-cart-banner {
  margin-top: 20px;
  width: 100%;
}

.col-page-not-found-block {
  flex: 1 1;
}

.page-not-found-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.content-not-found-block-title {
  font-size: 40px;
  margin-bottom: 9px;
}

.content-not-found-block-subtitle {
  font-weight: bold;
  font-size: 25px;
}

.content-not-found-block-desc {
  font-weight: 500;
  font-size: 21px;
}

.content-not-found-block-button {
  margin-top: 15px;
}

.canvas-holder:hover {
  box-shadow: 0px 1px 10px -4px #000;
  cursor: pointer;
}

.product-detail-document-holder {
  margin-left: 15px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap
}

.document-holder-items {
  display: flex;
  /* padding-left: 40px; */
}

.document-holder-item {
  margin-right: 15px;
  border: 1px solid #ccc;
}

.icons-holder-product-detail img {
  max-width: 180px;
  margin-right: 10px;
  height: 57px;
  width: auto;
}

.product-catalog-badge-image-top {
  max-width: 20px !important;
  margin-top: 0px;
  margin-bottom: 2px;
}

.info-danger-outlet-description i {
  margin-top: 5px;
  margin-right: 5px;
}

.info-danger-outlet-description {
  display: flex;
}

.no-padding-block {
  padding: 0px !important;
}

.icons-holder-product-detail {
  margin-bottom: 20px;
  padding-right: 40px;
  margin-top: 15px;
  display: flex;
}

.empty-search-result-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}

.alert-info-specification {
  margin: 15px 45px 0px 45px;
}

.empty-search-result-image {
  flex: 0 1;
}

.empty-search-result-text {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.search-content-box .page-not-found-container {
  height: 100% !important;
}

.catalog-sort-content-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  width: 100%;
  align-items: center;
}

.catalog-sort-content-header a button svg {
  fill: #FF0000;
  width: 14px;
  margin-right: 8px;
  margin-left: 3px;
}

.catalog-sort-content-header a button:hover svg {
  fill: white;
}


.add-button {
  height: 21px;
}


.content-header-main-category {
  width: 100%;
  margin-bottom: 20px;
}

.user-section .logo-login-image img {
  max-width: 100px;
}

.news-container-content .stats-order-content-holder {
  margin-bottom: 20px;
}

.empty-sortable-div {
  /* flex: 1; */
}

.brand-menu-logo {
  width: 30px;
  margin-right: 5px;
}


.dashboard-box-info.color-background-user-PLAV .icon-dashboard-info {
  background: #4386fc;
  /*! border-bottom: 1px solid red; */
}

.dashboard-box-info.color-background-user-PLAV {
  /*! background: #3390dd; */
  border-bottom: 5px solid #4386fc;
}

.dashboard-box-info.color-background-user-PLAV .dashboard-box-info-value {
  color: #4386fc;
}

.dashboard-box-info.color-background-user-ZELEN .icon-dashboard-info {
  background: #0f9d58;
  /*! border-bottom: 1px solid red; */
}

.dashboard-box-info.color-background-user-ZELEN {
  /*! background: #3390dd; */
  border-bottom: 5px solid #0f9d58;
}

.dashboard-box-info.color-background-user-ZELEN .dashboard-box-info-value {
  color: #0f9d58;
}

.dashboard-box-info.color-background-user-CRVEN .icon-dashboard-info {
  background: #e91e63;
  /*! border-bottom: 1px solid red; */
}

.dashboard-box-info.color-background-user-CRVEN {
  /*! background: #3390dd; */
  border-bottom: 5px solid #e91e63;
}

.dashboard-box-info.color-background-user-CRVEN .dashboard-box-info-value {
  color: #e91e63;
}

.custom-export-catalog-image img {
  max-width: 60px;
}

.column-custom-export-width {
  width: 150px;
}

.description-page-detail {
  padding: 10px 20px;
  margin: 0px 0px 20px;
  font-size: 17.5px;
  border-left: 5px solid rgb(238, 238, 238);
}

.column-no-width {
  width: 0px;
}

.custom-export-icon-remove {
  cursor: pointer;
}

.container-custom-export-input {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.product-detail-name-category-small {
  font-size: 16px;
  color: #626262 !important;
  margin-bottom: 8px;
}

.product-detail-name-category-small a {
  color: #626262 !important;
}

.embed-video-product {
  height: 100% !important;
}

.mini-images-slider-product-video {
  padding: 0px !important;
}

.sold-out-cart>.productImage:after {
  content: 'RASPRODATO';
  color: #ff0427;
  position: absolute;
  top: 30%;
  left: 10px;
  font-size: 16px;
  letter-spacing: 5px;
  font-weight: 600;
  transform: rotate(-15deg);
}

.company-limit-reached {
  opacity: 0.4;
  background: #fafafa;
  position: relative;
}

.sold-out-cart-qty .button-div-item-qty {
  border: 1px solid red;
  border-radius: 5px;
}

.delivery-schedule-content {
  overflow: hidden;
}


/* SLIDER NOVI POCETAK */
.slider-item-detail-container {
  height: 100%;
  border-right: 1px solid #ddd;
}

.slider-item-detail {
  padding: 0px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
}

.slider-item-detail-image img {
  max-height: 180px;
  margin: 0 auto;
  padding: 15px;
}

.slider-item-detail-image {
  flex: 1 1;
}

.relative-for-benefit {
  position: relative;
}

.slider-item-detail-title {
  margin-top: 10px;
  height: 43px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}

.slider-item-detail-content-row-item-full-width {
  width: 100%;
  text-align: right;
}

.slider-item-detail-content {
  flex: 2 1;
  padding-top: 20px;
}

.slider-item-detail-button {
  /* background-color: green; */
  /* position: relative; */
  position: relative;
  flex: 1 1;
  margin-top: 10px;
}

.slider-item-detail-button .slider-special-action-button {
  padding: 0px;
}

.slider-item-detail-content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slider-item-detail-content-price {
  color: #eb0e4c;
  font-weight: 700;
  font-size: 21px;
}

.slider-item-detail-content-old-price {
  font-size: .8vw;
  text-decoration: line-through;
  text-decoration-color: currentcolor;
  -webkit-text-decoration-color: #e6264b;
  text-decoration-color: #e6264b;
  font-size: 16px;
  color: #666;
  font-weight: 700;
}

/* SLIDER NOVI KRAJ */

.beko-page-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.beko-page-item {
  width: 20%;
  text-align: center;
  padding: 0px 20px;
}

.beko-page-item h2 {
  font-family: SohoGothicPro-Bold;
  font-size: 16px;
  color: #0082c6;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.2;
  letter-spacing: -.5px;
  font-weight: bold;
}

.beko-page-item p {
  text-align: center;
  color: #9e9e9e;
  font-size: 14px;
}

.beko-page-item img {
  max-width: 100px;
}

.title-beko-page h1 {
  font-family: SohoGothicPro-Regular;
  font-size: 45px;
  color: #0189cf;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 0;
  line-height: 1.2;
  font-weight: bold;
}

.subtitle-beko-page p {
  font-family: SohoGothicPro-Light;
  font-size: 26px;
  color: #9e9e9e;
  text-align: center;
  margin-top: 5px;
  /* margin-bottom: 30px; */
  line-height: 1.2;
}

.subtitle-beko-page {
  margin-bottom: 30px;
}

.items-dell-block-content {
  padding: 0px 250px;
}

.item-dell-block-main-content {
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-left: 35px;
  padding: 12px 25px;
  box-shadow: -1px 5px 14px -5px #0087cc;
  background: #fff;
  z-index: 2;
  position: relative;
}

.item-dell-block-main-title {
  font-size: 22px;
  text-transform: uppercase;
  color: #0087cc;
  font-weight: bold;
  margin-bottom: 10px;
}

.item-dell-block-main-desc {
  font-size: 16px;
}

.item-dell-block {
  position: relative;
  margin-bottom: 40px;
}

.content-main-center {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.container-dell-page {
  width: 100%;
  max-width: 100%;
}

.item-dell-block-main-desc p {
  margin-bottom: 0px;
}

.item-dell-block-number {
  background: #0087cc;
  color: #fff;
  border-radius: 20px;
  padding: 12px;
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  width: 100%;
  z-index: 0;
  box-shadow: -1px 5px 14px -5px #0087cc;
}

.customer-care-info {
  margin-bottom: 30px;
}

/* .custom-input-check:invalid[focused="true"] ~ .error-input-message {
  display: block;
} */

/* ********************************* */
/* ********************************* */
/* ********************************* */
/* ********************************* */
/* ********************************* */
/* ****RESPONSIVE nemanja*********** */
/* ********************************* */
/* ********************************* */
/* ********************************* */
/* ********************************* */
/* ********************************* */
/* ********************************* */
/* ::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #D3D3D3;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.content-category-boxes {
  overflow-x: hidden;
} */

/* POPUP BLOK RESPONSIVE */
@media (max-width: 1919px) and (min-width: 1830px) {

  .action-item-image-block {
    /* width: 420px; */
  }

  .timer-item div {
    height: 60px;
    width: 60px;
    font-size: 40px;
  }
}

@media (max-width: 1829px) and (min-width: 1790px) {

  .action-item-image-block {
    /* width: 400px; */
  }

  .timer-item div {
    height: 55px;
    width: 55px;
    font-size: 35px;
  }
}

@media (max-width: 1789px) and (min-width: 1690px) {

  .action-item-image-block {
    /* width: 400px; */
  }

  .timer-item div {
    height: 55px;
    width: 55px;
    font-size: 35px;
  }

  .slider-banner {
    width: 400px;
    height: 400px;
  }

  .action-block {
    width: calc(100% - 400px);
    height: 400px;
  }

  .action-block-content-body h1 {
    -webkit-line-clamp: 2;
  }

  .action-block-content-body h3 {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 1689px) and (min-width: 1590px) {

  .action-item-image-block {
    /* width: 350px; */
  }

  .timer-item div {
    height: 45px;
    width: 45px;
    font-size: 30px;
  }

  .slider-banner {
    width: 400px;
    height: 400px;
  }

  .action-block {
    width: calc(100% - 400px);
    height: 400px;
  }

  .action-block-content-body h1 {
    -webkit-line-clamp: 2;
    font-size: 20px;
  }

  .action-block-content-body h3 {
    -webkit-line-clamp: 2;
    font-size: 20px;
  }

  .action-block-content-body p {
    font-size: 17px;
    margin-bottom: 5px;
  }

  .short-desc-action-block {
    margin-top: 5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media (max-width: 1589px) and (min-width: 1506px) {
  .slider-banner {
    width: 350px;
    height: 350px;
  }

  .action-block {
    width: calc(100% - 350px);
    height: 350px;
  }

  .action-item-image-block {
    /* width: 350px; */
  }

  .timer-item div {
    height: 45px;
    width: 45px;
    font-size: 30px;
  }

  .action-block-content-body h1 {
    -webkit-line-clamp: 2;
    font-size: 20px;
    margin-top: 0px;
  }

  .action-block-content-body h3 {
    -webkit-line-clamp: 2;
    font-size: 20px;
    margin-top: 0px;
  }

  .action-block-content-body p {
    font-size: 17px;
    margin-bottom: 0px;
  }

  .action-block-content-body img {
    margin-bottom: 8px;
    width: 50px;
  }

  .short-desc-action-block {
    font-size: 14px;
    margin-top: 3px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .action-block-content {
    padding: 23px 20px;
  }
}

@media (max-width: 1505px) and (min-width: 1441px) {
  .slider-banner {
    width: 350px;
    height: 350px;
  }

  .action-block {
    width: calc(100% - 350px);
    height: 350px;
  }

  .action-item-image-block {
    width: 300px;
  }

  .timer-item div {
    height: 43px;
    width: 43px;
    font-size: 30px;
  }

  .action-block-content-body h1 {
    -webkit-line-clamp: 2;
    font-size: 20px;
    margin-top: 0px;
  }

  .action-block-content-body h3 {
    -webkit-line-clamp: 2;
    font-size: 20px;
    margin-top: 0px;
  }

  .action-block-content-body p {
    font-size: 17px;
    margin-bottom: 0px;
  }

  .action-block-content-body img {
    margin-bottom: 8px;
    width: 50px;
  }

  .short-desc-action-block {
    font-size: 14px;
    margin-top: 3px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .action-block-content {
    padding: 23px 15px;
  }
}

@media (max-width: 1366px) and (min-width: 1024px) {

  .dashboard-item-content {
    padding-bottom: 5px;
  }

  .menu-modal-item-header {
    margin-bottom: 15px;
  }

  .menu-modal-item-header img {
    width: 22px;
  }

  .total-price-cart-modal span {
    font-size: 17px;
  }

  .btn-cart-modal-finish {
    font-size: 13px;
  }

  .menu-modal-list-links li {
    font-size: 18px;
  }

  .auciton-title-description {
    position: relative;
  }

  .auction-price-value {
    font-size: 32px;
  }

  .title-action-text {
    font-size: 14px;
  }

  .auction-price-title {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .timer-item {
    font-size: 22px;
  }

  .item-name.item-name-icon-image {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .user-detail-info-text {
    font-size: 12px;
    line-height: 12px;
    word-break: break-word;
  }

  .collapse-button-sidebar {
    width: 80%;
  }

  .logout-page-products-info h2,
  .logout-page-products-info p {
    font-size: 12px;
  }

  /* .icon-item-sidebar img {
    height: 20px;
    width: 20px;
  }

  .item-name.item-name-icon-image img {
    height: 20px;
    width: 20px;
  } */
}

@media (max-width: 1315px) and (min-width: 1024px) {
  /* .action-block {
    width: 100%;
    margin-top: 0px;
    height: auto;
  }

  .action-item-image-block {
    height: 440px;
  }

  .action-block-content {
    margin-left: 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .slider-banner {
    width: 100%;
    height: auto;
  }

  .action-block-content-image img {
    width: 100%;
  }

  .action-block-content-body {
    margin-left: 0px;
    margin-top: 20px;
  }

  .timer-action-block {
    position: relative;
  } */
}

@media (max-width: 768px) and (min-width: 451px) {
  .action-block-content {
    margin-left: 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    padding: 20px 12px;
  }

  .timer-action-block {
    position: relative;
  }

  .action-item-image-block {
    /* width: 100%; */
  }
}

@media (max-width: 1023px) and (min-width: 451px) {
  .slider-banner {
    width: 100%;
    height: auto;
  }

  .action-block {
    width: 100%;
  }

  .action-block-content {
    margin-left: 0px;
  }

  .timer-item div {
    height: 65px;
    width: 65px;
    font-size: 39px;
  }
}

@media (max-width: 880px) and (min-width: 610px) {

  .action-item-image-block {
    /* width: 400px; */
  }

}

@media (max-width: 880px) and (min-width: 451px) {

  .timer-item div {
    height: 55px;
    width: 55px;
    font-size: 35px;
  }

  /* .slider-banner {
    width: 400px;
    height: 400px;
  } */

  .action-block-content-body h1 {
    -webkit-line-clamp: 2;
  }

  .action-block-content-body h3 {
    -webkit-line-clamp: 2;
  }
}

/* CATALOG TABLE VIEW RESPONSIVE*/
@media (max-width: 1754px) and (min-width: 1575px) {

  .table-catalog-list tbody td,
  .table-catalog-list th {
    font-size: 14px;
    padding: 4px 7px;
  }

  .catalog-table-item-img {
    width: 65px;
  }

  td.text-center.qty-table-td {
    min-width: 80px;
    max-width: 80px;
  }

  .qty-table-td input.input-qty-add-item {
    width: 100%;
  }

  .qty-table-td .button-div-item-qty .plus-item-qty {
    padding: 0 5px;
    right: 2px;
  }

  .qty-table-td .button-div-item-qty .minus-item-qty {
    padding: 0 6px;
  }
}

@media (max-width: 1574px) and (min-width: 1366px) {
  .container-catalog-content {
    overflow: hidden;
  }

  .table-catalog-list tbody td,
  .table-catalog-list th {
    font-size: 13px;
    padding: 4px 6px;
  }

  .catalog-table-item-img {
    width: 50px;
  }

  .table-view-price {
    white-space: inherit;
  }

  .catalog-table-item-name a {
    min-width: 190px;
  }

  td.text-center.qty-table-td {
    min-width: 80px;
    max-width: 80px;
  }

  .qty-table-td input.input-qty-add-item {
    width: 100%;
  }

  .qty-table-td .button-div-item-qty .plus-item-qty {
    padding: 0 5px;
    right: 2px;
  }

  .qty-table-td .button-div-item-qty .minus-item-qty {
    padding: 0 6px;
  }
}

@media (max-width: 1365px) {
  .container-catalog-content {
    overflow: hidden;
  }

  .table-catalog-list {
    overflow: visible;
    overflow-x: scroll;
  }

  .custom-offer-table {
    overflow: visible;
    overflow-x: scroll;
  }

  .custom-offer-table thead th {
    text-align: left;
  }

  .catalog-content-list-table {
    height: calc(100vh - 235px);
  }

  .catalog-table-item-img {
    width: 60px;
  }

  .custom-offer-table .table-catalog-list th,
  .custom-offer-price {
    white-space: nowrap;
    text-align: center;
  }

  .custom-offer-input {
    min-width: 140px;
  }

  .custom-offer-input input {
    font-size: 13px;
  }

  .custom-offer-product-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-width: 150px;
    -webkit-box-orient: vertical;
  }

}

@media (max-width: 450px) {

  .table-catalog-list tbody td,
  .table-catalog-list th {
    font-size: 13px;
    padding: 4px 7px;
  }

  .news-items-list {
    padding-bottom: 30px;
    overflow-y: auto;
    height: 360px;
  }

  .catalog-table-item-img {
    width: 60px;
  }

  .custom-offer-table {
    height: calc(100vh - 290px);
  }

  .custom-offer-table .table-catalog-list thead {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    background-color: white;
    z-index: 1;
  }

  .custom-offer-create {
    margin-top: 10px;
  }
}

/* OUTLET RESPONSIVE */
/* @media (max-width: 1650px) {
  .outlet-page-item h2 {
    font-size: 1.22vw;
  }
} */

@media (min-width: 2250px) {
  /* .col-sm-4-cust {
    max-width: 16.666666%;
    padding: 11px; */
  /* } */

  .col-sm-4-cust:nth-child(7n) {
    margin-right: 0px;
  }

  .news-item-box:nth-child(5n) {
    margin-right: 0px;
  }

  .catalog-block-list-item:nth-child(4n) {
    margin-right: 0px;
  }

  .auction-content-item:nth-child(6n) {
    margin-right: 0px;
  }

  .h2-silder-title-logout-page {
    margin-top: 28%;
  }
}

@media (max-width: 1920px) and (min-width: 1681px) {
  .content-body-special-block {
    /* height: 500px; */
  }

  /* .slider-special-action-detail-res, .slider-special-action-detail-price {
    font-size: 17px;
  } */
  .spec-action-price {
    font-size: 21px;
  }

  .h3-cust {
    /* height: 370px; */
    height: 440px;
    padding: 0px;
    margin: 0px;
  }

  .shopping-cart-items-list ul {
    height: 550px;
  }

  .menu-modal-list-links ul {
    max-height: 770px;
  }

  .modal-root {
    padding: 60px 50px 0px 50px;
  }

  .col-sm-4-cust {
    margin-right: 10px;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 292px;
  }

  .col-sm-4-cust:nth-child(5n) {
    margin-right: 0px;
  }

  .catalog-item-main {
    margin-bottom: 15px;
  }

  .news-item-box {
    width: 400px;
    margin: 0px 41px 41px 0px;
  }

  .news-item-box:nth-child(3n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 60px 70px;
  }

  .catalog-block-list-item {
    width: 434px;
    margin-right: 30px;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 481px;
  }

  .catalog-block-list-item:nth-child(3n) {
    margin-right: 0px;
  }

  /* .product-margin-block, .product-table-content {
    padding: 20px 25px;
  } */
  .auction-content-item:nth-child(3n) {
    margin-right: 0px;
  }

  .auction-content-item {
    width: 400px;
    margin-right: 36px;
  }

  /* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
  .product-detail-name h1 {
    font-size: 28px;
  }

  /* .productIcon {
    width: 50px;
  } */

  .product-table-content {
    padding: 20px 25px;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 4px 15px;
    width: 100%;
  }

  .product-total-price {
    font-size: 19px;
  }

  .product-total-old-price {
    font-size: 14px;
  }

  .large-item {
    display: block;
  }

  .small-item {
    display: none;
  }

  .product-detail-tab-specification {
    padding: 0 220px;
  }
}

/* responsive dimenzije za margin-block*/
@media (max-width: 1920px) and (min-width: 1900px) {
  .product-content-detail-main {
    padding: 0 45px 20px;
  }

  .product-margin-block {
    padding: 25px 40px;
  }
}

@media (max-width: 1899px) and (min-width: 1751px) {
  .product-content-detail-main {
    padding: 0 30px 20px;
  }

  .product-margin-block {
    padding: 25px 15px;
  }
}

@media (max-width: 1750px) and (min-width: 1681px) {
  .product-content-detail-main {
    padding: 0 20px 20px;
  }

  .product-margin-block {
    padding: 25px 12px;
  }

  .product-detail-content-box .input-group-text {
    padding: 7px;
  }
}

@media (max-width: 1680px) and (min-width: 1600px) {
  /* .slider-banner {
    width: 400px;
    height: 400px;
  }

  .action-block {
    width: calc(100% - 400px);
    height: 400px;
  } */

  .news-block {
    width: 400px;
    height: 400px;
  }

  .big-banner-block {
    width: calc(100% - 425px);
    height: 400px;
  }

  /* .action-block-content-body h1 {
    font-size: 23px;
  }

  .action-block-content-body p {
    font-size: 19px;
    margin-bottom: 5px;
  } */

  /* .timer-item div {
    height: 55px;
    width: 55px;
    font-size: 35px;
  } */

  .news-item {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .news-body-item h4 {
    font-size: 17px;
  }

  .news-text-body {
    font-size: 13px;
  }

  .action-block-price {
    font-size: 18px;
    padding: 4px;
  }

  .price-item-action {
    padding: 4px;
    font-size: 18px;
  }

  .price-item-value-action {
    font-size: 19px;
  }

  /* .short-desc-action-block {
    margin-top: 5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } */

  /* .category-content-sidebar {
    width: 270px;
  }
  .navbar-category {
    min-width: 280px;
    max-width: 280px;
  } */
  /* .content-body-special-block {
    height: 450px;
  } */
  /* .slider-special-action-detail-res, .slider-special-action-detail-price {
    font-size: 0.8vw;
  } */
  .spec-action-price-old {
    font-size: 15px;
  }

  .spec-action-price {
    font-size: 19px;
  }

  .item-slider-special-action {
    padding: 0px 15px;
  }

  .slider-special-action-button {
    padding: 0px 15px;
  }

  .add-btn-spec-input-qty {
    width: calc(50% - 10px);
  }

  .content-slider-special-action {
    padding-top: 50px;
  }

  .special-block-title-main h2 {
    font-size: 25px;
  }

  table.table-spec-product-action {
    font-size: 17px;
  }

  .price-row-action {
    font-size: 19px;
  }

  .title-catalog-product-action {
    font-size: 25px;
  }

  .catalog-product-image img {
    margin-top: 20px;
    height: 80%;
  }

  .catalog-product-image .img-logo-brand-product {
    top: 5px;
    left: 40px;
    width: 70px;
    height: 70px;
  }

  .h3-cust {
    height: 350px;
  }

  .col-sm-4-cust {
    margin-right: 31px;
    width: 260px;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 295px;
  }

  .col-sm-4-cust:nth-child(4n) {
    margin-right: 0px;
  }

  .catalog-item-main {
    margin-bottom: 31px;
  }

  .news-item-box {
    margin: 0px 25px 25px 0px;
    width: 354px;
  }

  .news-item-box:nth-child(3n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 40px 40px;
  }

  .modal-root {
    padding: 60px 50px 0px 50px;
  }

  .menu-modal-list-links ul {
    margin-top: 10px;
    max-height: 450px;
  }

  .shopping-cart-items-list ul {
    height: 500px;
  }

  .catalog-block-list-item {
    width: 370px;
    margin-right: 11px;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 411px;
    margin-right: 20px;
  }

  .catalog-block-list-item:nth-child(3n) {
    margin-right: 0px;
  }

  .auction-content-item:nth-child(3n) {
    margin-right: 0px;
  }

  #auction-holder {
    top: 35%;
  }

  .auciton-title-description {
    position: initial;
    margin: -55px 75px 20px;
  }
}

@media (max-width: 1600px) and (min-width: 1560px) {
  /* .slider-banner {
    width: 400px;
    height: 400px;
  } */

  /* .action-block {
    width: calc(100% - 400px);
    height: 400px;
  } */

  .news-block {
    width: 400px;
    height: 370px;
  }

  .big-banner-block {
    width: calc(100% - 425px);
    height: 370px;
  }

  .news-block-title h3 {
    font-size: 25px;
  }

  .news-block-content {
    padding: 20px 15px;
    padding-top: 10px;
  }

  /* .action-block-content-body h1 {
    font-size: 23px;
  }

  .action-block-content-body p {
    font-size: 19px;
    margin-bottom: 5px;
  }

  .timer-item div {
    height: 55px;
    width: 55px;
    font-size: 35px;
  } */

  .news-item {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .news-body-item h4 {
    font-size: 17px;
  }

  .news-text-body {
    font-size: 13px;
  }

  .action-block-price {
    font-size: 18px;
    padding: 4px;
  }

  .price-item-action {
    padding: 4px;
    font-size: 18px;
  }

  .price-item-value-action {
    font-size: 19px;
  }

  /* 
  .short-desc-action-block {
    margin-top: 5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } */

  .spec-action-price-old {
    font-size: 15px;
  }

  .spec-action-price {
    font-size: 19px;
  }

  .item-slider-special-action {
    padding: 0px 15px;
  }

  .slider-special-action-button {
    padding: 0px 15px;
  }

  .add-btn-spec-input-qty {
    width: calc(50% - 10px);
  }

  .content-slider-special-action {
    padding-top: 50px;
  }

  .special-block-title-main h2 {
    font-size: 25px;
  }

  table.table-spec-product-action {
    font-size: 17px;
  }

  .price-row-action {
    font-size: 19px;
  }

  .title-catalog-product-action {
    font-size: 25px;
  }

  .catalog-product-image img {
    margin-top: 20px;
    height: 80%;
  }

  .catalog-product-image .img-logo-brand-product {
    top: 5px;
    left: 40px;
    width: 70px;
    height: 70px;
  }

  .h3-cust {
    height: 300px;
  }

  .col-sm-4-cust {
    margin-right: 17px;
    width: 250px;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 285px;
  }

  .col-sm-4-cust:nth-child(4n) {
    margin-right: 0px;
  }

  .catalog-item-main {
    margin-bottom: 17px;
  }

  .news-item-box {
    margin: 0px 15px 15px 0px;
    width: 341px;
  }

  .news-item-box:nth-child(5n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 30px;
  }

  .modal-root {
    padding: 60px 50px 0px 50px;
  }

  .menu-modal-list-links ul {
    margin-top: 10px;
    max-height: 336px;
  }

  .shopping-cart-items-list ul {
    height: 355px;
  }

  .catalog-block-list-item {
    width: 343px;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 390px;
    margin-right: 11px;
  }

  .catalog-block-list-item:nth-child(3n) {
    margin-right: 0px;
  }
}

@media (max-width: 1680px) and (min-width: 1537px) {

  /* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
  .item-menu-category,
  .menu-items {
    font-size: 15px;
  }

  .news-items-list {
    overflow-x: auto;
    height: 265px;
  }

  .category-content-sidebar {
    width: 270px;
  }

  .navbar-category {
    min-width: 270px;
    max-width: 270px;
  }

  .category-menu-item {
    padding: 10px 15px;
  }

  .product-content-detail-main {
    padding: 0 45px 20px;
  }

  .product-detail-name h1 {
    font-size: 26px;
  }

  .productIcon {
    width: 50px;
  }

  .product-table-content {
    padding: 25px;
    font-size: 16px;
  }

  .product-add-cart-price-items {
    display: block;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;
  }

  .product-add-cart-btn {
    float: right;
    width: 50%;
  }

  .btn-add-product-detail {
    width: 100%;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 5px 20px;
  }

  .product-add-cart-price.small-item {
    margin-bottom: 5px;
    margin-top: -5px;
  }

  .product-total-price {
    font-size: 20px;
  }

  .product-total-old-price {
    font-size: 15px;
  }

  .large-item {
    display: none;
  }

  .small-item {
    display: block;
  }

  .product-margin-block {
    padding: 25px 30px;
  }

  .product-margin-box {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .blank-row {
    display: none;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
  }

  .product-detail-tab-item {
    padding: 14px 25px;
  }

  .product-detail-tab-specification {
    padding: 0 80px;
  }

  .product-detail-specification-title {
    font-size: 30px;
  }

  .product-detail-specification-table {
    font-size: 16px;
  }

}

@media (max-width: 1536px) and (min-width: 1441px) {
  /* .slider-banner {
    width: 350px;
    height: 350px;
  }

  .action-block {
    width: calc(100% - 350px);
    height: 350px;
  } */

  .news-block {
    width: 350px;
    height: 350px;
  }

  .big-banner-block {
    width: calc(100% - 375px);
    height: 350px;
  }

  .category-content-sidebar {
    width: 250px;
  }

  .navbar-category {
    min-width: 250px;
    max-width: 250px;
  }

  .news-items-list {
    overflow-y: auto;
    height: 230px;
  }

  .news-body-item h4 {
    font-size: 16px;
  }

  .news-item {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .news-notification-item span {
    font-size: 12px;
  }

  .news-notification-item {
    font-size: 20px;
  }

  .news-text-body {
    font-size: 13px;
  }

  .news-date-item span {
    padding: 1px 8px;
  }

  .news-block-footer {
    font-size: 16px;
    padding: 8px 15px;
  }

  .news-block-content {
    padding: 16px 15px;
  }

  .category-type-item {
    padding: 3px 22px;
  }

  /* .action-block-content-body h1 {
    font-size: 20px;
  }

  .timer-item div {
    height: 55px;
    width: 55px;
    font-size: 34px;
  }

  .action-block-content-body p {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .action-block-content-body img {
    margin-bottom: 8px;
    width: 50px;
    /* width: 70px;
} */

  .action-block-price {
    font-size: 17px;
    padding: 4px;
  }

  .price-item-action {
    font-size: 17px;
    padding: 4px;
  }

  .price-item-value-action {
    font-size: 18px;
  }

  /* .short-desc-action-block {
    font-size: 14px;
    margin-top: 3px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .action-block-content {
    padding: 23px 20px;
  } */

  /* .content-body-special-block {
    height: 420px;
  } */
  /* .slider-special-action-detail-res, .slider-special-action-detail-price {
    font-size: 0.8vw;
  } */
  .spec-action-price-old {
    font-size: 15px;
  }

  .spec-action-price {
    font-size: 19px;
  }

  .item-slider-special-action {
    padding: 0px 15px;
  }

  .content-slider-special-action {
    padding-top: 50px;
  }

  .special-block-title-main h2 {
    font-size: 25px;
  }

  .col-sm-4-cust {
    max-width: 300px;
    margin-right: 15px;
    width: 300px;
  }

  .catalog-content-list-box.active {
    justify-content: center;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 350px;
    margin-right: 30px;
  }

  .catalog-item-main {
    padding: 18px 12px;
    margin-bottom: 10px;
  }

  .title-catalog-product-action {
    font-size: 23px;
  }

  table.table-spec-product-action {
    font-size: 16px;
  }

  .price-row-action {
    font-size: 17px;
  }

  .catalog-product-image img {
    height: 80%;
    margin-top: 20px;
  }

  .catalog-product-image .img-logo-brand-product {
    top: 0px;
    width: 70px;
    height: 70px;
  }

  .h3-cust {
    height: 250px;
  }

  .second-mini-slider {
    width: 380px !important;
  }

  .second-mini-slider-1 {
    width: 100px !important;
  }

  .second-mini-slider-2 {
    width: 190px !important;
  }

  .second-mini-slider-3 {
    width: 290px !important;
  }

  .col-sm-4-cust:nth-child(3n) {
    margin-right: 0px;
  }

  .news-item-box {
    margin: 0px 35px 35px 0px;
  }

  .news-item-box:nth-child(5n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 60px 70px;
  }

  .modal-root {
    padding: 60px 50px 0px 50px;
  }

  .menu-modal-list-links ul {
    margin-top: 10px;
    max-height: 336px;
  }

  .shopping-cart-items-list ul {
    height: 355px;
  }

  .catalog-block-list-item {
    width: 450px;
    margin-right: 15px;
  }

  .catalog-block-list-item:nth-child(2n) {
    margin-right: 0px;
  }

  .catalog-content-list-block.active {
    justify-content: center;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 450px;
    margin-right: 30px;
  }

  .auction-content-item:nth-child(2n) {
    margin-right: 0px;
  }

  .auction-content-item {
    width: 400px;
    margin-right: 36px;
  }

  #auction-holder {
    top: 35%;
  }

  .auciton-title-description {
    position: initial;
    margin: -55px 75px 20px;
  }

  /* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
  .category-menu-item {
    padding: 10px 15px;
  }

  .menu-items {
    font-size: 14px;
  }

  .product-content-detail-main {
    padding: 0px 30px 20px;
  }

  .product-detail-name h1 {
    font-size: 25px;
  }

  .productIcon {
    width: 45px;
  }

  .product-table-content {
    padding: 25px 20px;
    font-size: 15px;
  }

  .large-item {
    display: none;
  }

  .small-item {
    display: block;
  }

  .product-add-cart-price-items {
    display: block;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;
  }

  .product-add-cart-btn {
    float: right;
    width: 50%;
  }

  .btn-add-product-detail {
    width: 100%;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 5px 20px;
  }

  .product-add-cart-price.small-item {
    margin-bottom: 5px;
    margin-top: -5px;
  }

  .product-total-price {
    font-size: 20px;
  }

  .product-total-old-price {
    font-size: 14px;
  }

  .product-margin-block {
    padding: 25px 20px;
  }

  .product-margin-box {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .blank-row {
    display: none;
  }

  .title-margine-table-left {
    padding-right: 8px;
    width: 108px;
    text-align: right;
  }

  .product-table-margin {
    margin-top: 10px;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
  }

  .product-detail-tab-item {
    padding: 14px 20px;
  }

  .product-detail-tab-specification {
    padding: 0px 70px;
  }

  .product-detail-specification-title {
    font-size: 29px;
  }

  .product-detail-specification-table {
    font-size: 15px;
  }

  .search-box-modal {
    left: 465px;
  }

  .search-box-modal.active-sidebar-collapsed {
    left: 340px;
  }
}

@media (max-width: 1440px) and (min-width: 1367px) {
  .big-banner-block {
    width: 100%;
    height: 350px;
  }

  .banner-first-block-home {
    /* display: none; */
  }

  .main-top-banner-home {
    display: block;
    border-radius: 15px;
  }

  .category-type-item {
    padding: 3px 17px;
  }

  /* .navbar-category {
    min-width: 240px;
    max-width: 240px;
  }
  .category-content-sidebar {
    width: 240px;
  } */
  .news-block {
    width: 440px;
    height: 440px;
  }

  .slider-banner {
    width: 440px;
    height: 440px;
    float: right;
  }

  .news-body-item h4 {
    font-size: 20px;
  }

  .news-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .action-block {
    width: 100%;
  }

  .action-block-content {
    margin-left: 0px;
  }

  .news-block-content {
    padding: 20px;
  }

  .news-block-title h3 {
    font-size: 27px;
  }

  .container-content {
    padding: 30px 40px;
  }

  /* .content-body-special-block {
    height: 470px;
  } */
  /* .action-title-name {
    font-size: 15px;
  } */
  .slider-special-action-detail-res,
  .slider-special-action-detail-price {
    font-size: 15px;
  }

  .spec-action-price {
    font-size: 21px;
  }

  .spec-action-price-old {
    font-size: 17px;
  }

  .item-slider-special-action {
    padding: 0px 18px;
  }

  .col-sm-4-cust {
    width: 280px;
    margin-right: 31px;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 333px;
  }

  .catalog-item-main {
    margin-bottom: 31px;
  }

  .title-catalog-product-action {
    font-size: 23px;
  }

  table.table-spec-product-action {
    font-size: 16px;
  }

  .price-row-action {
    font-size: 17px;
  }

  .catalog-product-image img {
    height: 80%;
    margin-top: 20px;
  }

  .catalog-product-image .img-logo-brand-product {
    top: 0px;
    width: 70px;
    height: 70px;
  }

  .input-special-product-action {
    width: 70px;
  }

  .h3-cust {
    height: 250px;
  }

  .second-mini-slider {
    width: 380px !important;
  }

  .second-mini-slider-1 {
    width: 100px !important;
  }

  .second-mini-slider-2 {
    width: 190px !important;
  }

  .second-mini-slider-3 {
    width: 290px !important;
  }

  .col-sm-4-cust:nth-child(3n) {
    margin-right: 0px;
  }

  .news-item-box {
    margin: 0px 10px 25px 0px;
    width: 307px;
  }

  .news-item-box:nth-child(3n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 20px;
  }

  .news-box-item-image {
    height: 200px;
  }

  .news-box-item-title {
    font-size: 22px;
  }

  .news-box-item-body {
    padding: 20px 15px 0px 15px;
  }

  .modal-root {
    padding: 60px 50px 0px 50px;
    left: 195px;
  }

  .menu-modal-list-links ul {
    margin-top: 10px;
    max-height: 336px;
  }

  .shopping-cart-items-list ul {
    height: 355px;
  }

  .catalog-block-list-item {
    width: 441px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .catalog-block-list-item:nth-child(2n) {
    margin-right: 0px;
  }

  .auction-content-item:nth-child(2n) {
    margin-right: 0px;
  }

  .auction-content-item {
    width: 330px;
    margin-right: 36px;
  }

  #auction-holder {
    top: 30%;
  }

  .auciton-title-description {
    position: initial;
    margin: -55px 75px 20px;
  }

  .auction-image-block-time-value .timer-item div {
    height: 50px;
    width: 50px;
    font-size: 30px;
  }

  .search-box-modal {
    left: 440px;
  }

  .search-box-modal.active-sidebar-collapsed {
    left: 300px;
  }

  /* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
  .sidebar-content {
    width: 195px;
  }

  #sidebar {
    min-width: 195px;
    max-width: 195px;
  }

  .slider-item-detail-image img {
    max-height: 125px;
    padding: 0px;
  }

  .slider-item-detail-content {
    padding-top: 15px;
  }

  .container-login-form {
    padding: 25px 40px;
    width: 350px;
  }

  .category-content-sidebar {
    width: 220px;
  }

  .category-menu-item {
    padding: 10px;
  }

  .navbar-category {
    min-width: 220px;
    max-width: 220px;
  }

  .icon-item-sidebar i {
    font-size: 18px;
  }

  .sidebar-items-list {
    margin-top: 10px;
  }

  .footer-sidebar-logout span {
    font-size: 16px;
  }

  .footer-sidebar-logout svg {
    font-size: 24px;
  }

  .footer-sidebar-logout {
    padding: 12px 16px;
    font-size: 4px;
  }

  .sidebar-info-user {
    bottom: 65px;
  }

  .item-menu-category {
    font-size: 13px;
    width: 220px;
  }

  .item-name span {
    font-size: 13px;
  }

  .item-name {
    padding: 8px 0px;
    min-height: 30px;
    padding-left: 20px;
  }

  .product-content-detail-main {
    padding: 0px 30px 20px;
  }

  .product-action-item {
    font-size: 13px;
    padding: 3px 15px;
  }

  .product-action-detail-items {
    margin-top: 2px;
  }

  .product-action-item-buttons,
  .menu-items {
    font-size: 13px;
  }

  .product-detail-name h1 {
    font-size: 25px;
  }

  .productIcon {
    width: 45px;
  }

  .product-table-content,
  .product-margin-block {
    padding: 25px 20px;
    font-size: 14px;
  }

  .product-total-price {
    font-size: 20px;
  }

  .product-total-old-price {
    font-size: 14px;
  }

  .product-margin-box {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .blank-row {
    display: none;
  }

  .product-table-margin {
    margin-top: 10px;
  }

  .title-margine-table {
    width: 96px;
  }

  .title-margine-table-left {
    padding-right: 8px;
    width: 108px;
    text-align: right;
  }

  .product-detail-tab-specification.active {
    margin-top: 30px;
  }

  .product-detail-tab-specification {
    padding: 0px 60px;
    margin-top: 30px;
  }

  .product-detail-tab-item {
    font-size: 13px;
    padding: 9px 20px;
  }

  .product-detail-specification-title {
    font-size: 25px;
  }

  .product-detail-specification-table {
    font-size: 15px;
  }

  .table-shor-desc,
  .button-export-product-detail {
    font-size: 14px;
  }

  .large-item {
    display: none;
  }

  .small-item {
    display: block;
  }

  .product-add-cart-price-items {
    display: block;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;
  }

  .product-add-cart-btn {
    float: right;
    width: 50%;
  }

  .btn-add-product-detail {
    width: 100%;
    font-size: 13px;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 5px 20px;
  }

  .product-add-cart-price.small-item {
    margin-bottom: 5px;
    margin-top: -5px;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
  }

  .slider-logout-page-item-image,
  .slider-logout-page-item-body {
    margin-right: 20px;
  }

  .we-present-page-item-image,
  .we-present-page-item-body {
    margin-right: 20px;
  }

  .item-cart-td-brand-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-width: 150px;
    -webkit-box-orient: vertical;
  }

}

/* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
@media (max-width: 1366px) and (min-width: 1200px) {
  .modal-root {
    left: 170px;
  }

  .category-type-item {
    padding: 3px 21px;
    font-size: 14px;
  }

  .customImageLogo {
    margin-top: 10px;
    /* width: 100%; */
    width: 150px;
    padding: 0px 15px;
    height: 40px;
  }

  .customImageLogo.active {
    padding: 0px 10px;
  }

  .item-name {
    padding: 3px 0px;
    min-height: 30px;
    padding-left: 20px;
  }

  .icon-item-sidebar i {
    font-size: 18px;
  }

  .sidebar-items-list {
    margin-top: 10px;
  }

  .footer-sidebar-logout span {
    font-size: 16px;
  }

  .footer-sidebar-logout svg {
    font-size: 24px;
  }

  .item-menu-category {
    font-size: 12px;
    width: 210px;
  }

  .menu-items {
    font-size: 12px;
  }

  #sidebar {
    min-width: 170px;
    max-width: 170px;
  }

  .slider-item-detail-content {
    padding-top: 10px;
    font-size: 14px;
  }

  .slider-item-detail-title {
    height: 38px;
    font-size: 18px;
  }

  .slider-item-detail-content-price {
    font-size: 19px;
    margin-top: 8px;
  }

  .slider-item-detail-content-old-price {
    font-size: 16px;
    margin-top: 10px;
  }

  .slider-item-detail-image img {
    padding: 10px;
    max-height: 135px;
  }

  .sidebar-content {
    width: 170px;
  }

  .navbar-category {
    min-width: 210px;
    max-width: 210px;
  }

  .category-content-sidebar {
    width: 210px;
  }

  .category-menu-item {
    padding: 10px 11px;
  }

  .footer-sidebar-logout {
    padding: 12px 16px;
    font-size: 4px;
  }

  .sidebar-info-user {
    bottom: 65px;
  }

  .item-name span {
    font-size: 13px;
  }

  .product-content-detail-main {
    padding: 0px 13px 20px;
  }

  .product-total-price {
    font-size: 20px;
  }

  .product-total-old-price {
    font-size: 14px;
  }

  .product-margin-box {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .blank-row {
    display: none;
  }

  .product-table-margin {
    margin-top: 10px;
  }

  .product-detail-tab-specification.active {
    margin-top: 30px;
  }

  .product-detail-tab-specification {
    padding: 0px 30px;
    margin-top: 30px;
  }

  .product-detail-tab-item {
    font-size: 13px;
    padding: 9px 20px;
  }

  .product-detail-specification-title {
    font-size: 20px;
  }

  .product-detail-specification-table {
    font-size: 13px;
  }

  .product-action-item {
    font-size: 13px;
    padding: 3px 15px;
  }

  .product-action-detail-items {
    margin-top: 2px;
  }

  .product-action-item-buttons {
    font-size: 13px;
  }

  .product-detail-name h1 {
    font-size: 23px;
  }

  .productIcon {
    width: 45px;
  }

  .product-table-content {
    padding: 25px 20px;
    font-size: 13px;
  }

  .product-margin-block {
    padding: 25px 7px;
    font-size: 13px;
  }

  .title-margine-table {
    width: 150px;
  }

  .title-margine-table-left {
    padding-right: 8px;
    width: 108px;
    text-align: right;
  }

  .table-shor-desc,
  .button-export-product-detail {
    font-size: 14px;
  }

  .large-item {
    display: none;
  }

  .small-item {
    display: block;
  }

  .product-add-cart-price-items {
    display: block;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;
  }

  .product-add-cart-btn {
    float: right;
    width: 50%;
  }

  .btn-add-product-detail {
    width: 100%;
    font-size: 13px;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 5px 20px;
  }

  .product-add-cart-price.small-item {
    margin-bottom: 5px;
    margin-top: -5px;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
  }

  .slider-logout-page-content .col-lg-2 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .slider-logout-page-content .col-lg-10 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .slider-logout-page-content .slick-prev {
    left: -49%;
  }

  .slider-logout-page-content .slick-next {
    left: -40% !important;
  }

  .action-block-special-banner {
    width: 420px;
    height: 420px;
  }

  .homepage-content-special-image-block {
    width: 420px;
    height: 420px;
  }

  .action-block-special-slider-main-slider-item {
    height: 350px;
  }

  .action-block-special-slider-item-title {
    -webkit-line-clamp: 2;
    height: 38px;
  }

  .action-block-special-slider-main-slider-item {
    padding: 10px;
  }

  .action-block-special-slider-item-image img {
    width: 90%;
  }

  .news-items-list {
    overflow-y: auto;
    height: 270px;

  }

  /*SEARCH MODAL */
  .search-box-modal {
    left: 380px;
  }

  .search-box-modal.active-sidebar-collapsed {
    left: 295px;
  }

  .filters-body-catalog label {
    font-size: 13px;
  }

}

/* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
@media (max-width: 1199px) and (min-width: 1021px) {
  .search-box {
    padding: 0 10px;
    font-size: 0.75rem;
    margin-top: 20px;
  }

  .search-icon {
    top: 10px;
    right: 25px;
  }

  .sidebar-content {
    width: 145px;
  }

  #sidebar {
    min-width: 145px;
    max-width: 145px;
  }



  .container-login-form {
    padding: 20px 10px;
    width: 250px;
  }

  .logo-login-image img {
    max-width: 160px;
  }

  .container-login-form h2 {
    margin-top: 30px;
  }

  .modal-root {
    left: 145px;
  }

  .customImageLogo {
    margin-top: 10px;
    width: 100%;
    padding: 0px 15px;
    height: 40px;
  }

  .customImageLogo.active {
    padding: 0px 8px;
  }

  .sidebar-items-list {
    margin-top: 10px;
  }

  .icon-item-sidebar i {
    font-size: 18px;
  }

  .sidebar-info-user {
    bottom: 65px;
  }

  .info-image-sidebar {
    margin-left: 5px;
    min-width: 30px;
  }

  .user-info-top-border {
    padding-right: 0;
  }

  .info-user-detail-sidebar {
    margin-left: 8px;
  }

  .footer-sidebar-logout {
    padding: 12px 16px;
    font-size: 4px;
  }

  .footer-sidebar-logout span {
    font-size: 16px;
  }

  .footer-sidebar-logout svg {
    font-size: 24px;
  }

  .item-name {
    padding: 8px 0px;
    min-height: 30px;
    padding-left: 20px;
    font-size: 11px;
  }

  .item-name span {
    font-size: 12px;
    margin-left: 6px;
  }

  .shopping-cart-item-name span {
    left: 6px;
  }

  .category-content-sidebar {
    width: 180px;
  }

  .navbar-category {
    min-width: 180px;
    max-width: 180px;
  }

  .category-type-item {
    padding: 3px 17px;
    font-size: 12px;
  }

  .item-menu-category {
    font-size: 11px;
    width: 180px;
  }

  .menu-items {
    font-size: 11px;
  }

  .category-menu-item {
    padding: 10px 3px;
  }

  .product-content-detail-main {
    padding: 0 0 20px;
  }

  .product-action-detail-items {
    margin-top: -1px;
  }

  .product-action-item {
    font-size: 9px;
    padding: 3px 15px;
  }

  .product-action-item-buttons {
    font-size: 9px;
  }

  #exampleCheck1 {
    margin-top: 1px;
  }

  .product-detail-name h1 {
    font-size: 18px;
  }

  .productIcon {
    width: 37px;
  }

  .product-table-content {
    padding: 15px;
    font-size: 11px;
  }

  .product-add-cart-price-items {
    display: block;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;

  }

  .product-add-cart-btn {
    float: right;
    width: 50%;
  }

  .btn-add-product-detail {
    width: 100%;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 5px 20px;
  }

  .product-add-cart-price.small-item {
    margin-bottom: 5px;
    margin-top: -5px;
  }

  .product-total-price {
    font-size: 15px;
  }

  .product-total-old-price {
    font-size: 12px;
  }

  .large-item {
    display: none;
  }

  .small-item {
    display: block;
  }

  .table-shor-desc {
    font-size: 13px;
  }

  .button-export-product-detail {
    font-size: 12px;
  }

  .product-margin-block {
    padding: 20px 5px;
    font-size: 11px;
  }

  .product-margin-box {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .title-margine-table {
    width: 80px;
    padding-right: 3px;
  }

  .blank-row {
    display: none;
  }

  .smallInput,
  #inputGroupSelect01 {
    font-size: 11px;
  }

  .smallIcon {
    padding: 7px;
  }

  .smallCheck {
    margin-top: 1px;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
  }

  .product-detail-tab-item {
    padding: 7px 13px;
    font-size: 12px;
  }

  .product-detail-tab-specification {
    padding: 0 10px;
    margin-top: 30px;
  }

  .product-detail-specification-title {
    font-size: 20px;
  }

  .product-detail-specification-table {
    font-size: 13px;
  }

  .slider-logout-page-content .col-lg-2 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .slider-logout-page-content .col-lg-10 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .slider-logout-page-item-image,
  .slider-logout-page-item-body {
    margin-right: 10px;
  }

  .we-present-page-item-image,
  .we-present-page-item-body {
    margin-right: 10px;
  }

  .slider-logout-page-content {
    padding: 30px;
  }

  .slider-logout-page-content .slick-prev {
    left: -31%;
  }

  .slider-logout-page-content .slick-next {
    left: -21%;
  }
}

/* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
@media (max-width: 1020px) and (min-width: 768px) {
  .product-content-detail-main {
    padding: 0 20px 20px;
  }

  .product-action-detail-items {
    margin-top: -1px;
  }

  .product-action-item {
    font-size: 12px;
    padding: 3px 15px;
  }

  .product-action-item-buttons {
    font-size: 12px;
    margin-top: 0;
  }

  #exampleCheck1 {
    margin-top: 1px;
  }

  .product-detail-name h1 {
    font-size: 22px;
  }

  .productIcon {
    width: 40px;
  }

  .product-add-cart-price-items {
    display: block;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;
  }

  .product-add-cart-btn {
    float: right;
    width: 50%;
  }

  .btn-add-product-detail {
    width: 100%;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 5px 20px;
  }

  .product-add-cart-price.small-item {
    margin-bottom: 5px;
    margin-top: -5px;
  }

  .product-total-price {
    font-size: 18px;
  }

  .product-total-old-price {
    font-size: 13px;
  }

  .large-item {
    display: none;
  }

  .small-item {
    display: block;
  }

  .table-shor-desc {
    font-size: 15px;
  }

  .button-export-product-detail {
    font-size: 14px;
  }

  .product-margin-box {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .blank-row {
    display: none;
  }

  .call-us-button {
    width: 100% !important;
  }

  .smallCheck {
    margin-top: 1px;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
  }

  .product-detail-tab-item {
    padding: 7px 13px;
    font-size: 14px;
  }

  .product-detail-tab-specification {
    padding: 0 38px;
    margin-top: 30px;
  }

  .product-detail-specification-title {
    font-size: 20px;
  }

  .product-detail-specification-table {
    font-size: 14px;
  }
}

/* responsive dimenzije za margin-block i product-table*/
@media (max-width: 1020px) and (min-width: 900px) {
  .product-table-content {
    padding: 20px;
    font-size: 13px;
  }

  .product-margin-block {
    padding: 20px 13px;
    font-size: 13px;
  }

  .smallInput,
  #inputGroupSelect01 {
    font-size: 13px;
  }

  .title-margine-table {
    width: 90px;
    padding-right: 5px;
  }

  .news-items-list {
    overflow-y: auto;
    height: 360px;
  }

  .news-box-item-image2 img {
    max-width: 100%;
  }
}

@media (max-width: 899px) and (min-width: 768px) {
  .product-table-content {
    padding: 15px 14px;
    font-size: 13px;
  }

  .product-margin-block {
    padding: 20px 12px;
    font-size: 13px;
  }

  .smallInput,
  #inputGroupSelect01 {
    font-size: 13px;
  }

  .smallIcon {
    padding: 7px;
  }

  .title-margine-table {
    width: 72px;
    padding-right: 3px;
  }
}

/* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
@media (max-width: 767px) and (min-width: 680px) {
  .product-content-detail-main {
    padding: 0 60px 20px;
  }

  .product-action-detail-items {
    margin-top: -1px;
  }

  .product-action-item {
    font-size: 16px;
    padding: 5px 15px;
  }

  .product-action-item-buttons {
    font-size: 16px;
    margin-top: -2px;
  }

  #exampleCheck1 {
    margin-top: 1px;
  }

  .product-detail-name h1 {
    font-size: 25px;
  }

  .productIcon {
    width: 47px;
  }

  .product-table-content {
    margin-top: 20px;
    padding: 20px 30px;
    font-size: 16px;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 4px 15px;
    width: 100%;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;
  }

  .product-add-cart-btn {
    float: left;
    width: 50%;
  }

  .product-total-price {
    font-size: 24px;
  }

  .product-total-old-price {
    font-size: 16px;
  }

  .large-item {
    display: block;
  }

  .small-item {
    display: none;
  }

  .table-short-description-items {
    margin-top: 20px;
  }

  .table-shor-desc {
    font-size: 16px;
  }

  .button-export-product-detail {
    font-size: 16px;
  }

  .product-detail-margine-block-box {
    margin-top: 25px;
  }

  .product-margin-block {
    font-size: 16px;
    padding: 25px;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
  }

  .product-detail-tab-item {
    padding: 7px 13px;
    font-size: 16px;
  }

  .product-detail-tab-specification {
    padding: 0 38px;
    margin-top: 30px;
  }

  .product-detail-specification-title {
    font-size: 22px;
  }

  .product-detail-specification-table {
    font-size: 18px;
  }
}

/* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
@media (max-width: 679px) and (min-width: 570px) {
  .product-content-detail-main {
    padding: 0 35px 20px;
  }

  .product-action-detail-items {
    margin-top: -1px;
  }

  .product-action-item {
    font-size: 15px;
    padding: 5px 10px;
  }

  .product-action-item-buttons {
    font-size: 15px;
    margin-top: -2px;
  }

  #exampleCheck1 {
    margin-top: 1px;
  }

  .product-detail-name h1 {
    font-size: 25px;
  }

  .productIcon {
    width: 48px;
  }

  .product-table-content {
    margin-top: 20px;
    padding: 20px 35px;
    font-size: 16px;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 4px 15px;
    width: 100%;
    min-width: 80px;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;
  }

  .product-add-cart-btn {
    float: left;
    width: 50%;
  }

  .product-total-price {
    font-size: 23px;
  }

  .product-total-old-price {
    font-size: 15px;
  }

  .large-item {
    display: block;
  }

  .small-item {
    display: none;
  }

  .table-short-description-items {
    margin-top: 20px;
  }

  .table-shor-desc {
    font-size: 16px;
  }

  .button-export-product-detail {
    font-size: 16px;
  }

  .product-detail-margine-block-box {
    margin-top: 25px;
  }

  .product-margin-block {
    font-size: 16px;
    padding: 25px 15px;
  }

  .smallInput,
  #inputGroupSelect01 {
    font-size: 14px;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
  }

  .product-detail-tab-item {
    padding: 7px 13px;
    font-size: 15px;
  }

  .product-detail-tab-specification {
    padding: 0 38px;
    margin-top: 30px;
  }

  .product-detail-specification-title {
    font-size: 23px;
  }

  .product-detail-specification-table {
    font-size: 16px;
  }
}

/* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
@media (max-width: 569px) and (min-width: 478px) {
  .product-content-detail-main {
    padding: 0 20px 20px;
  }

  .product-action-detail-items {
    margin-top: -1px;
  }

  .product-action-item {
    font-size: 14px;
    padding: 6px;
  }

  .product-action-item-buttons {
    font-size: 14px;
    margin-top: -2px;
  }

  #exampleCheck1 {
    margin-top: 1px;
  }

  .product-detail-name h1 {
    font-size: 24px;
  }

  .productIcon {
    width: 48px;
  }

  .product-table-content {
    margin-top: 20px;
    padding: 20px 10px;
    font-size: 16px;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 5px 15px;
    width: 98%;
  }

  .product-total-price {
    font-size: 20px;
  }

  .product-total-old-price {
    font-size: 15px;
  }

  .large-item {
    display: block;
  }

  .small-item {
    display: none;
  }

  .table-short-description-items {
    margin-top: 20px;
  }

  .table-shor-desc {
    font-size: 16px;
  }

  .button-export-product-detail {
    font-size: 16px;
  }

  .product-detail-margine-block-box {
    margin-top: 25px;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
    padding: 0 45px;
  }

  .product-detail-tab-item {
    padding: 7px 14px;
    font-size: 16px;
  }

  .product-detail-tab-specification {
    padding: 0 38px;
    margin-top: 30px;
  }

  .product-detail-specification-title {
    font-size: 22px;
  }

  .product-detail-specification-table {
    font-size: 16px;
  }

  .product-detail-specification-table td {
    padding-right: 8px;
  }
}

/* responsive dimenzije za margin-block*/
@media (max-width: 569px) and (min-width: 511px) {
  .product-margin-block {
    font-size: 16px;
    padding: 25px 10px;
  }

  .smallInput,
  #inputGroupSelect01 {
    font-size: 14px;
  }
}

@media (max-width: 510px) and (min-width: 478px) {
  .product-margin-block {
    font-size: 16px;
    padding: 25px 30px;
  }

  .product-margin-box {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .blank-row {
    display: none;
  }

}

/* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
@media (max-width: 477px) and (min-width: 360px) {
  .product-content-detail-main {
    padding: 0 5px 20px;
  }

  .product-action-detail-items {
    margin-top: -1px;
  }

  #exampleCheck1 {
    margin-top: 0;
    margin-left: -16px;
    width: 9px;
  }

  .product-detail-name h1 {
    font-size: 22px;
  }

  .brand-logo-product-detail {
    margin-top: 10px;
  }

  /* .h3-cust-top-product-detail {
    padding-top: 80px;
  } */

  .productIcon {
    /* width: 54px; */
  }

  .product-table-content {
    margin-top: 20px;
    padding: 15px;
    font-size: 15px;
  }

  .product-add-cart-price-items {
    display: block;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;
  }

  .product-add-cart-btn {
    float: right;
    width: 50%;
  }

  .btn-add-product-detail {
    width: 100%;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 5px 20px;
  }

  .product-add-cart-price.small-item {
    margin-bottom: 5px;
    margin-top: -5px;
  }

  .product-total-price {
    font-size: 20px;
  }

  .product-total-old-price {
    font-size: 15px;
  }

  .large-item {
    display: none;
  }

  .small-item {
    display: block;
  }

  .table-short-description-items {
    margin-top: 20px;
  }

  .table-shor-desc {
    font-size: 15px;
  }

  .button-export-product-detail {
    font-size: 14px;
  }

  .product-detail-margine-block-box {
    margin-top: 25px;
  }

  .product-margin-block {
    font-size: 15px;
    padding: 20px;
  }

  .product-margin-box {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .blank-row {
    display: none;
  }

  .title-margine-table {
    width: 77px;
  }

  .smallInput,
  #inputGroupSelect01 {
    font-size: 15px;
  }

  .smallCheck {
    margin-top: 5px;
  }

  .smallIcon {
    padding: 7px;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
    padding: 0 30px;
  }

  .product-detail-tab-item {
    padding: 7px 13px;
    font-size: 15px;
  }

  .product-detail-tab-specification.active {
    padding: 0 15px;
    margin-top: 25px;
  }

  .product-detail-specification-title {
    font-size: 18px;
  }

  .product-detail-specification-table {
    font-size: 15px;
  }

  .product-detail-specification-table td {
    padding-right: 6px;
  }

  .related-products-list {
    padding: 0px 30px;
  }
}

@media (max-width: 477px) and (min-width: 401px) {
  .product-action-item {
    font-size: 13px;
    padding: 7px;
    margin-right: 10px;
  }

  .product-action-item-buttons {
    font-size: 13px;
    margin-top: -2px;
  }

  .btn-product-wishlist,
  .btn-product-compare {
    padding: 4px;
  }
}

@media (max-width: 400px) and (min-width: 360px) {
  .product-action-item {
    font-size: 12px;
    padding: 6px;
    margin-right: 10px;
  }

  .product-action-item-buttons {
    font-size: 11px;
    margin-top: 0;
  }

  .btn-product-wishlist,
  .btn-product-compare {
    padding: 3px;
  }
}

/* RESPONSIVE - STRANICA OTVORENOG PROIZVODA*/
@media (max-width: 359px) {
  .product-content-detail-main {
    padding: 0 5px 20px;
  }

  .product-action-detail-items {
    margin-top: -1px;
  }

  .product-action-item {
    font-size: 10px;
    padding: 5px;
    margin-right: 3px;
  }

  .btn-product-wishlist,
  .btn-product-compare {
    padding: 3px;
  }

  .product-action-item-buttons {
    font-size: 10px;
    margin-top: 0;
  }

  #exampleCheck1 {
    margin-top: 0;
    margin-left: -16px;
    width: 9px;
  }

  .product-detail-name h1 {
    font-size: 20px;
  }

  .productIcon {
    width: 46px;
  }

  .product-table-content {
    margin-top: 20px;
    padding: 20px 10px;
    font-size: 13px;
  }

  .product-add-cart-price-items {
    display: block;
  }

  .product-add-cart-qty {
    width: 50%;
    float: left;
  }

  .product-add-cart-btn {
    float: right;
    width: 50%;
  }

  .btn-add-product-detail {
    width: 100%;
  }

  .product-detail-content-box input.input-qty-add-item {
    padding: 5px 20px;
  }

  .product-add-cart-price.small-item {
    margin-bottom: 5px;
    margin-top: -5px;
  }

  .product-total-price {
    font-size: 19px;
  }

  .product-total-old-price {
    font-size: 14px;
  }

  .large-item {
    display: none;
  }

  .small-item {
    display: block;
  }

  .table-short-description-items {
    margin-top: 20px;
  }

  .table-shor-desc {
    font-size: 14px;
  }

  .button-export-product-detail {
    font-size: 13px;
  }

  .product-content-detail-main .btn-product-exp {
    padding: 4px 6px;
  }

  .product-detail-margine-block-box {
    margin-top: 25px;
  }

  .product-margin-block {
    font-size: 13px;
    padding: 25px 10px;
  }

  .product-margin-box {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .blank-row {
    display: none;
  }

  .title-margine-table {
    width: 77px;
  }

  .smallInput,
  #inputGroupSelect01 {
    font-size: 12px;
  }

  .smallCheck {
    margin-top: 2px;
  }

  .smallIcon {
    padding: 5px;
  }

  .product-detail-tabs-content-container {
    margin-top: 0px;
    padding: 0 25px;
  }

  .product-detail-tab-item {
    padding: 7px 13px;
    font-size: 13px;
  }

  .product-detail-tab-specification.active {
    padding: 0 20px;
    margin-top: 25px;
  }

  .product-detail-specification-title {
    font-size: 15px;
  }

  .product-detail-specification-table {
    font-size: 13px;
  }

  .product-detail-specification-table td {
    padding-right: 6px;
  }
}

@media (max-width: 1366px) and (min-width: 1281px) {
  .big-banner-block {
    width: 100%;
    height: 350px;
  }

  .banner-first-block-home {
    /* display: none; */
  }

  .main-top-banner-home {
    display: block;
    border-radius: 15px;
  }

  .news-block {
    width: 400px;
    height: 400px;
    margin-right: 10px;
  }

  .slider-banner {
    width: 400px;
    height: 400px;
    float: right;
  }

  .news-body-item h4 {
    font-size: 18px;
  }

  .news-item {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .news-block-content {
    padding: 15px 15px;
  }

  .news-block-title h3 {
    font-size: 25px;
  }

  .action-block {
    width: 100%;
  }

  .action-block-content {
    margin-left: 0px;
  }

  .news-date-item span {
    padding: 1px 10px;
    margin-right: 10px;
  }

  .news-notification-item {
    font-size: 24px;
  }

  .news-notification-item span {
    font-size: 14px;
    padding: 0px 7px;
  }

  .container-content {
    padding: 30px 40px;
  }

  .items-dell-block-content {
    padding: 0px 150px;
  }

  /* .content-body-special-block {
    height: 420px;
  } */
  /* .action-title-name {
    font-size: 15px;
  } */
  .slider-special-action-detail-res,
  .slider-special-action-detail-price {
    font-size: 14px;
  }

  .spec-action-price {
    font-size: 18px;
  }

  .homepage-slider-special-action-image {
    margin-bottom: 15px;
  }

  .spec-action-price-old {
    font-size: 16px;
  }

  .item-slider-special-action {
    padding: 0px 18px;
  }

  .content-slider-special-action {
    padding-top: 45px;
  }

  .special-block-title-main h2 {
    font-size: 24px;
  }

  .col-sm-4-cust {
    margin-right: 24px;
    width: 280px;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 317px;
  }

  .col-sm-4-cust:nth-child(3n) {
    margin-right: 0px;
  }

  .catalog-item-main {
    margin-bottom: 33px;
  }

  .title-catalog-product-action {
    font-size: 23px;
  }

  table.table-spec-product-action {
    font-size: 16px;
  }

  .price-row-action {
    font-size: 17px;
  }

  .catalog-product-image img {
    height: 75%;
    margin-top: 30px;
  }

  .catalog-product-image .img-logo-brand-product {
    top: -20px;
    width: 70px;
    height: 70px;
  }

  .input-special-product-action {
    width: 80px;
  }

  .h3-cust {
    height: 250px;
  }

  .second-mini-slider {
    width: 380px !important;
  }

  .second-mini-slider-1 {
    width: 100px !important;
  }

  .second-mini-slider-2 {
    width: 190px !important;
  }

  .second-mini-slider-3 {
    width: 290px !important;
  }

  .news-item-box {
    margin: 0px 15px 25px 0px;
    width: 279px;
  }

  .news-item-box:nth-child(3n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 20px;
  }

  .news-box-item-image {
    height: 200px;
  }

  .news-box-item-title {
    font-size: 22px;
  }

  .news-box-item-body {
    padding: 20px 15px 0px 15px;
  }

  .news-box-item-button {
    padding: 0px 20px 10px 20px;
  }

  .modal-root {
    padding: 35px 30px 0px 30px;
  }

  .menu-modal-list-links ul {
    margin-top: 10px;
    height: 480px;
  }

  .shopping-cart-items-list ul {
    height: 284px;
  }

  .dashboard-item-loyalty-value-big {
    font-size: 55px;
  }

  .dashboard-item-loyatly-per {
    font-size: 32px;
  }

  .dashboard-item-loaylty-content {
    margin-top: 5px;
    padding-bottom: 18px;
  }

  .menu-modal-item-header {
    font-size: 20px;
    padding-bottom: 15px;
  }

  .menu-modal-item-header img {
    width: 22px;
  }

  .price-cart-modal {
    font-size: 13px;
  }

  .price-cart-modal span {
    font-size: 15px;
  }

  .total-price-cart-modal {
    font-size: 16px;
  }

  .total-price-cart-modal span {
    font-size: 17px;
  }

  .dashboard-item-radio-buttons {
    font-size: 15px;
  }

  .close-menu-modal-popup {
    top: 6px;
    right: 30px;
    font-size: 25px;
  }

  .dashboard-box-financial {
    padding: 15px 7px;
  }

  .dashboard-box-info-value {
    font-size: 22px;
  }

  .dashboard-box-info {
    padding: 15px 10px;
  }

  .dashboard-item-content {
    margin-top: 25px;
  }

  .dashboard-box-deliver-title {
    font-size: 14px;
  }

  .dashboard-box-deliver-subtitle {
    font-size: 12px;
  }

  .dashboard-box-deliver-value {
    font-size: 24px;
  }

  .settings-cart-button {
    font-size: 11px;
  }

  .container-main-cart-content {
    padding: 20px;
  }

  /* td.main-cart-item-name.main-cart-item-value {
    line-height: 18px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } */
  .cart-info-box-info {
    font-size: 14px;
  }

  .cart-info-box-info i {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }

  .table-cart-sum table {
    font-size: 16px;
  }

  .delivery-box-cart-main .custom-input-form-cart-item {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
  }

  .address-box-cart-main .custom-input-form-cart-item {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
  }

  .main-cart-items-table td {
    padding: 4px 8px;
  }

  /* .footer-sidebar-logout {
    padding: 12px 16px;
    font-size: 4px;
  }
  .sidebar-info-user {
    bottom: 65px;
  }
  .item-name span {
    font-size: 13px;
  } */
  .catalog-block-list-item {
    width: 434px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 489px;
  }

  .catalog-block-list-item:nth-child(2n) {
    margin-right: 0px;
  }

  /* .catalog-content-list-table.active {
    width: calc(100vw - 537px);
  } */
  /* #sidebar {
    min-width: 170px;
    max-width: 170px;
  }
  .sidebar-content {
    width: 170px;
  } */

  .auction-content-item:nth-child(2n) {
    margin-right: 0px;
  }

  .auction-content-item {
    width: 330px;
    margin-right: 36px;
  }

  #auction-holder {
    top: 33%;
  }

  .auciton-title-description {
    position: initial;
    margin: -55px 75px 20px;
  }

  .auction-image-block-time-value .timer-item div {
    height: 40px;
    width: 40px;
    font-size: 25px;
  }

  .auction-body-detail {
    padding: 40px 30px;
  }

  .item-cart-td-brand-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-width: 150px;
    -webkit-box-orient: vertical;
  }

  .info-image-sidebar {
    min-width: 30px;
    margin-left: 10px;
  }

}

@media (max-width: 1280px) and (min-width: 1280px) {
  .auction-content-item:nth-child(2n) {
    margin-right: 0px;
  }

  .auction-content-item {
    width: 330px;
    margin-right: 36px;
  }

  #auction-holder {
    top: 25%;
  }

  .auciton-title-description {
    position: initial;
    margin: -55px 75px 20px;
  }

  .auction-image-block-time-value .timer-item div {
    height: 40px;
    width: 40px;
    font-size: 25px;
  }

  .auction-big-image-block {
    padding: 20px 35px;
  }

  .big-banner-block {
    width: 100%;
    height: 300px;
  }

  .banner-first-block-home {
    /* display: none; */
  }

  .main-top-banner-home {
    display: block;
    border-radius: 15px;
  }

  /* .container-content {
    padding: 20px 30px;
  }
  .navbar-category {
    min-width: 240px;
    max-width: 240px;
  }
  .category-content-sidebar {
    width: 240px;
  }
  .category-type-item {
    padding: 3px 19px;
  } */
  .news-block {
    width: 380px;
    height: 380px;
  }

  .slider-banner {
    width: 380px;
    height: 380px;
    float: right;
  }

  .news-block-content {
    padding: 15px 15px;
  }

  .action-block {
    width: 100%;
  }

  .action-block-content {
    margin-left: 0px;
  }

  .news-body-item h4 {
    font-size: 18px;
  }

  .news-item {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .news-notification-item {
    font-size: 22px;
  }

  .news-notification-item span {
    font-size: 12px;
  }

  .news-block-title h3 {
    font-size: 22px;
  }

  .news-text-body {
    font-size: 15px;
  }

  /* .content-body-special-block {
    height: 420px;
  } */
  /* .action-title-name {
    font-size: 15px;
  } */
  .slider-special-action-detail-res,
  .slider-special-action-detail-price {
    font-size: 14px;
  }

  .spec-action-price {
    font-size: 21px;
  }

  .spec-action-price-old {
    font-size: 15px;
  }

  .item-slider-special-action {
    padding: 0px 10px;
  }

  .special-block-title-main h2 {
    font-size: 23px;
  }

  .content-slider-special-action {
    padding-top: 40px;
  }

  .col-sm-4-cust {
    margin-right: 15px;
    /* margin-right: 6px; u simulatoru na webu je 15 a kada promenim rezoluciju na kompu onda je 6 */
    width: 264px;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 301px;
  }

  .col-sm-4-cust:nth-child(3n) {
    margin-right: 0px;
  }

  .catalog-item-main {
    margin-bottom: 15px;
  }

  .title-catalog-product-action {
    font-size: 23px;
  }

  table.table-spec-product-action {
    font-size: 16px;
  }

  .price-row-action {
    font-size: 17px;
  }

  .catalog-product-image img {
    height: 75%;
    margin-top: 30px;
  }

  .catalog-product-image .img-logo-brand-product {
    top: -20px;
    width: 70px;
    height: 70px;
  }

  .input-special-product-action {
    width: 80px;
  }

  .h3-cust {
    height: 200px;
  }

  .second-mini-slider {
    width: 360px !important;
  }

  .second-mini-slider-1 {
    width: 80px !important;
  }

  .second-mini-slider-2 {
    width: 170px !important;
  }

  .second-mini-slider-3 {
    width: 270px !important;
  }

  .news-item-box {
    margin: 0px 21px 21px 0px;
    width: 240px;
  }

  .news-item-box:nth-child(3n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 30px;
  }

  .news-box-item-image {
    height: 170px;
  }

  .news-box-item-body {
    padding: 15px 15px 0px 15px;
  }

  .news-box-item-title {
    font-size: 19px;
    margin-top: 1px;
    margin-bottom: 5px;
  }

  .news-box-item-description {
    font-size: 14px;
  }

  .news-box-item-button {
    padding: 5px 15px 18px 15px;
  }

  .news-box-item-badge {
    bottom: -12px;
    font-size: 15px;
  }

  .news-btn-box-item {
    margin-top: 20px;
    font-size: 14px;
  }

  .modal-root {
    padding: 35px 30px 0px 30px;
  }

  .menu-modal-list-links ul {
    margin-top: 10px;
    max-height: 288px;
  }

  .shopping-cart-items-list ul {
    height: 284px;
  }

  .dashboard-item-loyalty-value-big {
    font-size: 55px;
  }

  .dashboard-item-loyatly-per {
    font-size: 32px;
  }

  .dashboard-item-loaylty-content {
    margin-top: 12px;
    padding-bottom: 18px;
  }

  .menu-modal-item-header {
    font-size: 26px;
  }

  .dashboard-item-radio-buttons {
    font-size: 15px;
  }

  .close-menu-modal-popup {
    top: 6px;
    right: 30px;
    font-size: 25px;
  }

  .dashboard-box-financial {
    padding: 15px 7px;
  }

  .dashboard-box-info-value {
    font-size: 22px;
  }

  .dashboard-box-info {
    padding: 15px 10px;
  }

  .dashboard-item-content {
    margin-top: 25px;
  }

  .dashboard-box-deliver-title {
    font-size: 14px;
  }

  .dashboard-box-deliver-subtitle {
    font-size: 12px;
  }

  .dashboard-box-deliver-value {
    font-size: 24px;
  }

  .settings-cart-button {
    font-size: 11px;
  }

  .catalog-block-list-item {
    margin-right: 23px;
    margin-bottom: 20px;
    width: 430px;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 455px;
  }

  .catalog-block-list-item:nth-child(2n) {
    margin-right: 0px;
  }

  .item-cart-td-brand-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-width: 150px;
    -webkit-box-orient: vertical;
  }

  .catalog-block-list-item {
    width: 408px;
  }

  .info-image-sidebar {
    min-width: 30px;
    margin-left: 10px;
  }

  .product-detail-content-box .mini-images-slider-product {
    height: 52px;
  }
}


@media (max-width: 1279px) and (min-width: 1024px) {
  .big-banner-block {
    width: 100%;
    height: 300px;
  }

  .banner-first-block-home {
    /* display: none; */
  }

  .main-top-banner-home {
    display: block;
    border-radius: 15px;
  }

  .news-items-list {
    overflow-y: auto;
    height: 260px;
  }

  .container-content {
    padding: 20px 30px;
  }

  .items-dell-block-content {
    padding: 0px 150px;
  }

  /* .navbar-category {
    min-width: 240px;
    max-width: 240px;
  }
  .category-content-sidebar {
    width: 240px;
  }
  .category-type-item {
    padding: 3px 19px;
  } */
  .news-block {
    width: 100%;
    height: 380px;
  }

  .call-us-button {
    width: 100% !important;
  }

  /* .slider-banner {
    width: 100%;
    height: auto;
  } */

  .news-block-content {
    padding: 15px 15px;
  }

  /* 
  .action-block {
    width: 100%;
    margin-top: 0px;
    height: auto;
  }

  .action-item-image-block {
    height: 440px;
  }

  .action-block-content {
    margin-left: 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  } */

  .news-body-item h4 {
    font-size: 18px;
  }

  .news-item {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .news-notification-item {
    font-size: 22px;
  }

  .news-notification-item span {
    font-size: 12px;
  }

  .news-block-title h3 {
    font-size: 22px;
  }

  .news-text-body {
    font-size: 15px;
  }

  /* .action-block-content-image img {
    width: 100%;
  }

  .action-block-content-body {
    margin-left: 0px;
    margin-top: 20px;
  }

  .timer-action-block {
    position: relative;
  } */

  .content-body-special-block {
    flex-direction: column;
    height: 1100px;
  }

  .special-block-items {
    margin-top: 15px;
    padding: 0;
    max-height: 550px;
  }

  /* .action-title-name {
    font-size: 17px;
  } */
  .special-block-title-main h2 {
    font-size: 22px;
  }

  .content-slider-special-action {
    padding-top: 45px;
  }

  .slider-special-action-detail-res,
  .slider-special-action-detail-price {
    font-size: 15px;
  }

  .spec-action-price {
    font-size: 20px;
  }

  .h3-cust {
    height: 250px;
  }

  .second-mini-slider {
    width: 310px !important;
  }

  .second-mini-slider-1 {
    width: 80px !important;
  }

  .second-mini-slider-2 {
    width: 160px !important;
  }

  .second-mini-slider-3 {
    width: 240px !important;
  }

  .second-mini-slider .img-logo-360 {
    top: 46% !important;
    left: 47% !important;
  }

  .modal-root {
    padding: 30px 20px 0px 20px;
  }

  .dashboard-item-loyalty-value-big {
    font-size: 60px;
  }

  .menu-modal-list-links li {
    font-size: 17px;
    padding: 5px 8px;
  }

  .menu-modal-list-links ul {
    margin-top: 10px;
    max-height: 333px;
  }

  .menu-modal-item-header {
    font-size: 20px;
  }

  .dashboard-loyalty-items {
    margin-top: 10px;
  }

  .dashboard-item-content {
    margin-top: 25px;
  }

  .dashboard-item-loaylty-content {
    margin-top: 12px;
  }

  .close-menu-modal-popup {
    position: absolute;
    top: 8px;
    right: 20px;
    font-size: 25px;
  }

  .shopping-cart-items-list li {
    padding: 5px 0px;
  }

  .shopping-cart-items-list ul {
    height: 427px;
  }

  .col-sm-4-cust {
    width: 260px;
    margin-right: 5px;
  }

  .col-sm-4-cust:nth-child(3n) {
    /* margin-right: 0px; */
  }

  .catalog-item-main {
    margin-bottom: 5px;
  }

  .news-item-box {
    margin: 0px 35px 35px 0px;
  }

  .news-item-box:nth-child(5n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 60px 70px;
  }

  .modal-root {
    padding: 35px 30px 0px 30px;
  }

  .menu-modal-list-links ul {
    margin-top: 10px;
    max-height: 288px;
  }

  .shopping-cart-items-list ul {
    height: 284px;
  }

  .dashboard-item-loyalty-value-big {
    font-size: 55px;
  }

  .dashboard-item-loyatly-per {
    font-size: 32px;
  }

  .dashboard-item-loaylty-content {
    margin-top: 12px;
    padding-bottom: 18px;
  }

  .menu-modal-item-header {
    font-size: 26px;
  }

  .dashboard-item-radio-buttons {
    font-size: 15px;
  }

  .close-menu-modal-popup {
    top: 6px;
    right: 30px;
    font-size: 25px;
  }

  .dashboard-box-financial {
    padding: 15px 7px;
  }

  .dashboard-box-info-value {
    font-size: 22px;
  }

  .dashboard-box-info {
    padding: 15px 10px;
  }

  .dashboard-item-content {
    margin-top: 25px;
  }

  .dashboard-box-deliver-title {
    font-size: 14px;
  }

  .dashboard-box-deliver-subtitle {
    font-size: 12px;
  }

  .dashboard-box-deliver-value {
    font-size: 24px;
  }

  .settings-cart-button {
    font-size: 11px;
  }

  .catalog-block-list-item {
    width: 390px;
    margin-right: 7px;
  }

  .auction-content-item {
    width: 280px;
    margin-right: 14px;
  }

  .auction-content-item:nth-child(2n) {
    margin-right: 0px;
  }

  .auction-content-items {
    padding: 0;
  }

  #auction-holder {
    top: 50%;
  }

  .auciton-title-description {
    position: initial;
    margin: -55px 0 20px;
  }

  .auction-body-detail {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    padding: 10px;
  }

  .auction-list-block {
    border-left: inherit;
  }

  .auction-detail-block {
    margin-top: -60px;
  }

  .container-main-cart-content {
    overflow: hidden;
  }

  .main-cart-table thead th,
  .main-cart-item-price,
  .main-cart-item-total,
  .main-cart-item-sum-total,
  .main-cart-item-voucher-name {
    white-space: nowrap;
  }

  .item-cart-td-brand-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-width: 150px;
    -webkit-box-orient: vertical;
  }

  .remove-main-cart-item {
    right: -5px;
  }

  .action-block {
    width: 100%;
  }

  .slider-banner {
    width: 100%;
  }

  .block-slider-home-banner {
    width: 500px;
    margin: 0 auto;
    height: 500px;
  }

  .content-slider-special-action {
    padding-top: 60px;
    padding-bottom: 17px;
  }

  .special-block-title-main h2 {
    margin-top: 13px;
  }

  element.style {}

  .block-action-slider-cart-button {
    font-size: 13px;
  }

  .block-action-slider-cart-button svg {
    margin-top: 2px;
    margin-right: -2px;
    margin-bottom: 5px;
    position: relative;
    width: 18px;
  }
}

@media (max-width: 1024px) and (min-width: 1024px) {
  .catalog-block-item-value-table-td {
    width: 180px;
  }

  .col-sm-4-cust {
    width: 310px;
    margin-right: 7px;
  }

  .col-sm-4-cust:nth-child(2n) {
    margin-right: 0px;
  }

  .catalog-item-main {
    margin-bottom: 7px;
  }

  .news-item-box {
    margin: 0px 21px 21px 0px;
    width: 240px;
  }

  .news-item-box:nth-child(2n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 30px;
  }

  .news-box-item-image {
    height: 170px;
  }

  .news-box-item-body {
    padding: 15px 15px 0px 15px;
  }

  .news-box-item-title {
    font-size: 19px;
    margin-top: 1px;
    margin-bottom: 5px;
  }

  .news-box-item-description {
    font-size: 14px;
  }

  .news-box-item-button {
    padding: 5px 15px 18px 15px;
  }

  .news-box-item-badge {
    bottom: -12px;
    font-size: 15px;
  }

  .news-btn-box-item {
    margin-top: 20px;
    font-size: 14px;
  }

  .modal-root {
    padding: 35px 30px 0px 30px;
    width: 790px;
  }

  .cart-item-ident-list {
    font-size: 11px;
  }

  .cart-item-price-value {
    font-size: 14px;
  }

  .item-cart-name {
    font-size: 13px;
  }

  .menu-modal-list-links ul {
    margin-top: 10px;
    max-height: 288px;
  }

  .shopping-cart-items-list ul {
    height: 305px;
  }

  .dashboard-item-loyalty-value-big {
    font-size: 55px;
  }

  .dashboard-item-loyatly-per {
    font-size: 32px;
  }

  .dashboard-item-loaylty-content {
    margin-top: 12px;
    padding-bottom: 18px;
  }

  .menu-modal-item-header {
    font-size: 26px;
  }

  .dashboard-item-radio-buttons {
    font-size: 15px;
  }

  .close-menu-modal-popup {
    top: 6px;
    right: 30px;
    font-size: 25px;
  }

  .dashboard-box-financial {
    padding: 15px 7px;
  }

  .dashboard-box-info-value {
    font-size: 22px;
  }

  .dashboard-box-info {
    padding: 15px 10px;
  }

  .dashboard-item-content {
    margin-top: 25px;
  }

  .dashboard-box-deliver-title {
    font-size: 14px;
  }

  .dashboard-box-deliver-subtitle {
    font-size: 12px;
  }

  .dashboard-box-deliver-value {
    font-size: 24px;
  }

  .settings-cart-button {
    font-size: 11px;
  }

  .product-detail-item-3d {
    width: 45px;
    height: 35px;
  }

  .catalog-sort-content-header {
    justify-content: right;
  }

  .create-offer-button-1024 {
    font-size: 13px;
  }

  .filters-body-catalog label {
    font-size: 12px;
  }

  .more-filter-load {
    padding-left: 5px;
  }

  .price-block-input-item {
    padding: 0 5px 5px 5px;
    font-size: 14px;
  }

  .price-block-input-min-item {
    margin-right: 5px;
  }

  .slider-values-print-item {
    margin-top: -2px;
    font-size: 13px;
  }

  .editing-cart-items-table {
    white-space: nowrap;
  }

  .container-editing-cart-content {
    overflow: hidden;
  }

  .editing-cart-items-table-custom {
    overflow: visible;
    overflow-x: scroll;
  }

  .table-row-editing-cart-item {
    padding: 0 10px;
  }

  .buttons-editing-cart-all {
    float: none;
  }

  .delivery-schedule-content .stats-order-content-holder h1 {
    font-size: 29px;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog,
  .catalog-block-list-item {
    width: 550px;
  }

  .catalog-content-list-block {
    justify-content: center;
  }

  .catalog-content-list-box.active {
    justify-content: space-evenly;
  }

  .catalog-content-list-box.active .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 320px;
  }

  .create-your-offer-button {
    width: 237px;
    margin: 3px;
  }

  .create-offer-button {
    width: 237px;
    margin: 3px;
  }

  .category-sidebar-item-radio-name {
    font-size: 10px;
    text-align: center;
    margin-top: 15px;
  }

  .btn-add-product-detail {
    font-size: 12px;
  }

}

@media (max-width: 1019px) {
  .customImageLogo {
    margin-top: 0px;
    width: 120px;
  }

  .homepage-slider-special-action-image {
    margin-bottom: 15px;
  }

  .container-terms-content {
    margin-top: 73px;
    padding: 0 30px;
  }

  .container-catalog-content {
    margin-top: 45px;
  }

  .beko-page-item {
    width: 33.33333%;
  }

  .catalog-content-list-table {
    height: calc(100vh - 280px);
  }

  .page-not-found-container {
    flex-direction: column;
    /* margin-top: 80px; */
  }

  .col-page-not-found-block-image {
    margin-top: 80px;
  }

  .content-not-found-block-title {
    font-size: 28px;
    margin-bottom: 9px;
  }

  .content-not-found-block-subtitle {
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
  }

  .content-not-found-block-desc {
    font-weight: 500;
    font-size: 17px;
    margin-top: 9px;
  }

  .second-mini-slider {
    width: 330px !important;
  }

  .second-mini-slider-1 {
    width: 90px !important;
  }

  .second-mini-slider-2 {
    width: 170px !important;
  }

  .second-mini-slider-3 {
    width: 250px !important;
  }

  .second-mini-slider .img-logo-360 {
    top: 46% !important;
    left: 47% !important;
  }

  /* .outlet-page-item h2 {
    font-size: 19px;
  } */
}

@media (max-width: 1019px) and (min-width: 901px) {
  .auction-content-item {
    width: 280px;
    margin-right: 14px;
  }

  .auction-content-item:nth-child(3n) {
    margin-right: 0px;
  }

  .auction-content-items {
    padding: 0;
  }

  #auction-holder {
    top: 40%;
  }

  .auciton-title-description {
    position: initial;
    margin-bottom: 20px;
    margin-top: -55px;
  }

  .auction-body-detail {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    padding: 10px;
  }

  .auction-list-block {
    border-left: inherit;
  }

  .auction-detail-block {
    margin-top: -60px;
  }

  .container-main-cart-content {
    overflow: hidden;
  }

  .main-cart-table thead th,
  .main-cart-item-price,
  .main-cart-item-total,
  .main-cart-item-sum-total,
  .main-cart-item-voucher-name {
    white-space: nowrap;
  }

  .item-cart-td-brand-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-width: 150px;
    -webkit-box-orient: vertical;
  }

  .remove-main-cart-item {
    right: -5px;
  }
}

@media (max-width: 900px) and (min-width: 451px) {

  /* .container-catalog-content {
    margin-top: 45px;
  } */
  .container-custom-page-content {
    margin-top: 100px;
  }

  .big-banner-block {
    width: 100%;
    height: 400px;
  }

  .news-block {
    width: 100%;
  }

  /* .slider-banner {
    width: 100%;
    height: auto;
  }

  .action-block {
    width: 100%;
  }

  .action-block-content {
    margin-left: 0px;
  } */

  /* .timer-item div {
    height: 65px;
    width: 65px;
    font-size: 39px;
  } */

  .header-content-top {
    display: block !important;
  }

  .login-container-holder {
    flex-direction: column;
    height: auto;
    padding-top: 100px;
  }

  .login-holder-block-image {
    margin-top: 25px;
  }

  .login-holder-block-image img {
    height: auto;
  }

  .forgot-password-img {
    width: 80%;
    margin: auto;
    margin-top: 25px;
  }

  /* .customImageLogo {
    margin-top: 0px;
    width: 120px;
  } */
  .app {
    margin-top: 57px;
  }

  .catalog-product-top-action {
    margin-top: 100px;
  }

  .title-catalog-product-action {
    font-size: 23px;
  }

  .catalog-product-image img {
    width: 100%;
    margin-top: 25px;
  }

  .catalog-product-image .img-logo-brand-product {
    position: absolute;
    top: 75px;
    left: 25px;
    width: 65px;
    height: 65px;
  }

  .col-sm-4-cust {
    width: 250px;
    padding: 0px;
    margin-right: 12px;
  }

  .col-sm-4-cust:nth-child(3n) {
    margin-right: 0px;
  }

  .catalog-item-main {
    margin-bottom: 12px;
  }

  .search-page-content-box {
    margin-top: 105px;
  }

  .h3-cust {
    height: 270px;
  }

  .second-mini-slider {
    width: 330px !important;
  }

  .second-mini-slider-1 {
    width: 90px !important;
  }

  .second-mini-slider-2 {
    width: 170px !important;
  }

  .second-mini-slider-3 {
    width: 250px !important;
  }

  .second-mini-slider .img-logo-360 {
    top: 46% !important;
    left: 47% !important;
  }

  /* .product-detail-content-box {
    margin-top: 105px;
  } */

  .news-item-box {
    margin: 0px 35px 35px 0px;
  }

  .news-item-box:nth-child(5n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 60px 70px;
  }

  .h2-silder-title-logout-page {
    margin: 0px 0px 70px 0px;
  }

  .slider-logout-page-content .slick-next {
    position: absolute;
    top: -10%;
    left: 12%;
  }

  .slider-logout-page-content .slick-prev {
    position: absolute;
    top: -10%;
    left: 2%;
  }

  .auction-content-item:nth-child(2n) {
    margin-right: 0px;
  }

  #auction-holder {
    top: 40%;
  }

  .auciton-title-description {
    position: initial;
    margin-bottom: 20px;
  }

  .auction-body-detail {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    padding: 10px;
  }

  .auction-list-block {
    border-left: inherit;
  }

  .auction-detail-block {
    margin-top: -60px;
  }

  .container-main-cart-content {
    overflow: hidden;
  }

  .main-cart-table thead th,
  .main-cart-item-price,
  .main-cart-item-total,
  .main-cart-item-sum-total,
  .main-cart-item-voucher-name {
    white-space: nowrap;
  }

  .item-cart-td-brand-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-width: 150px;
    -webkit-box-orient: vertical;
  }

  .remove-main-cart-item {
    right: -5px;
  }
}

@media (max-width: 450px) {
  .big-banner-block {
    width: 100%;
    height: 265px;
  }

  .action-item-image-block {
    height: 250px;
    width: 100%;
  }

  /* definisano na liniji 9852 
  .container-content {
    padding: 5px 30px;
  } */
  /*.product-detail-content-box {
    padding: 5px 15px;
  }*/
  .action-block {
    width: 100%;
    margin-top: 0px;
    height: auto !important;
  }

  .short-desc-action-block {
    margin-top: 0;
  }

  .slider-banner {
    width: 100%;
    height: auto;
  }

  .news-block {
    width: 100%;
    height: auto;
  }

  .action-block-content {
    margin-left: 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    padding: 20px 12px;
  }

  .timer-action-block {
    position: relative;
  }

  .action-block-content-body {
    margin-top: 20px;
    margin-left: 0px;
  }

  .news-block-content {
    padding: 10px 12px;
  }

  .news-date-item span {
    padding: 0px 7px;
    margin-right: 5px;
    font-size: 15px;
  }

  .news-block-title h3 {
    font-size: 21px;
  }

  .news-item {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .news-body-item h4 {
    font-size: 16px;
  }

  .news-text-body {
    font-size: 14px;
  }

  .news-block-footer {
    font-size: 14px;
  }

  .news-items-list {
    padding-bottom: 30px;
  }

  .timer-item div {
    height: 65px;
    width: 65px;
    font-size: 39px;
  }

  .auction-image-block-time-value .timer-item div {
    height: 50px;
    width: 50px;
    font-size: 32px;
  }

  .auction-list-block-title span {
    font-size: 30px;
  }

  .auction-list-block-bid-input-box input {
    font-size: 13px;
  }

  .auction-list-block-bid-button {
    font-size: 13px;
    padding: 7px;
  }

  .auction-list-info {
    font-size: 17px;
  }

  .auction-list-block-table-li {
    font-size: 15px;
  }

  .content-body-special-block {
    flex-direction: column;
    /* height: 530px; */
    height: 800px;
    padding-bottom: 30px;
  }

  .special-block-items {
    margin-top: 0px;
    padding: 0;
  }

  .slider-special-action-detail-res,
  .slider-special-action-detail-price {
    font-size: 17px;
  }

  .spec-action-price {
    font-size: 21px;
  }

  /* .action-title-name {
    font-size: 19px;
  } */
  .content-slider-special-action .slick-prev {
    left: -15px;
  }

  .content-slider-special-action .slick-next {
    right: 0px;
  }

  .content-slider-special-action {
    padding-top: 50px;
  }

  .special-block-title-main h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  .item-slider-special-action {
    border-right: 0;
  }

  /* .customImageLogo {
    margin-top: 0px;
    width: 120px;
  } */
  .app {
    /* margin-top: 75px; */
    margin-top: 30px;
  }

  .catalog-product-top-action {
    /* margin-top: 120px; */
  }

  .catalog-product-body-content-text {
    flex-direction: column;
  }

  .title-catalog-product-action {
    font-size: 14px;
    font-weight: bold;
  }

  .catalog-banner-product-content {
    height: 100%;
    flex-direction: column;
  }

  .catalog-product-image .img-logo-brand-product {
    /* top: 125px; */
    top: -5px;
    /* left: 20px; */
    left: 11px;
    width: 80px;
    height: 80px;
  }

  .img-product-action {
    border-right: none;
    /* border-bottom: 2px solid #e6264b; */
  }

  .content-span-product-badges {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }

  .catalog-product-image {
    padding-bottom: 0px;
  }

  .catalog-product-body-content-text {
    padding: 0px 8px;
  }

  .catalog-product-body-price {
    padding: 12px 8px;
    padding-top: 0px;
  }

  .specification-catalog-product-action {
    padding: 0px 8px;
  }

  .h3-cust {
    height: 230px;
    padding: 0px;
    margin: 0px;
  }

  .h3-cust-video {
    height: 290px;
    padding-top: 60px;
  }

  .second-mini-slider-1 {
    width: 90px !important;
  }

  .second-mini-slider-2 {
    width: 160px !important;
  }

  .second-mini-slider-3 {
    width: 230px !important;
  }

  .second-mini-slider .img-logo-360 {
    top: 46% !important;
    left: 47% !important;
  }

  .mini-images-slider-product-video .img-logo-360 {
    top: 50% !important;
    left: 50% !important;
  }

  /*.product-content-detail-main {
    padding: 0px 15px 20px;
  }
  .product-compare-action-line {
    flex-direction: column;
  }
  .product-table-content {
    padding: 15px 10px;
    margin-top: 10px;
  }
  .product-icon-item img {
    height: 40px;
  }*/
  .btn-detail-add {
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-top: 10px;
    width: 100%;
  }

  .btn-detail-add button {
    width: 100%;
  }

  /*.product-detail-name h1 {
    font-size: 26px;
  }
  .product-add-to-cart-price .add-btn-spec-input-qty {
    width: 100%;
  }
  .container-content .container-fluid {
    padding: 0px;
  }
  .product-content-detail-main .no-padding {
    padding: 0px;
  }
  .product-content-detail-main .no-padding {
    padding: 0px;
  }*/
  .catalog-list-sidebar-mobile .category-menu-item {
    padding: 10px 15px;
  }

  .catalog-list-sidebar-mobile .sidebar-submenu-holder {
    padding: 0px 20px 0px 25px;
  }

  /* .content-slider-image-product {
    margin-top: 70px;
  } */

  .badge-item-product-action {
    margin-right: 5px;
  }

  .search-mobile-box .search-box {
    padding: 0px 0px;
    margin-top: 0px;
  }

  .search-page-content-box {
    margin-top: 105px;
  }

  /*.product-detail-name {
    margin-top: 0px;
  }
  .product-icon-item {
    margin-top: 15px;
  }*/
  .catalog-item-main {
    padding: 10px 15px;
  }

  .col-sm-4-cust {
    width: 100%;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 100%;
  }

  .col-sm-4-cust:nth-child(1n) {
    margin-right: 0px;
  }

  .content-slider-image-product .slick-center img {
    width: 90%;
  }

  /*.product-content-detail-main {
    margin-top: 15px;
  }*/
  .action-modal-popup .modal-content {
    margin-top: 50px;
  }

  .news-item-box {
    margin: 0px;
    margin-bottom: 20px;
    width: 100%;
  }

  .news-item-box:nth-child(5n) {
    margin-right: 0px;
  }

  .news-container-content {
    padding: 0px 15px;
    margin-top: 75px;
  }

  .news-content-box {
    flex-direction: column;
    align-items: center;
    padding: 0px 15px;
  }

  .news-box-item-image {
    height: 210px;
  }

  .news-box-item-title {
    font-size: 28px;
    margin-top: 0px;
    margin-bottom: 7px;
  }

  .news-box-item-description {
    /* line-height: 16px; */
  }

  .news-btn-box-item {
    margin-top: 20px;
  }

  .news-box-item-body {
    padding: 20px 15px 0px 15px;
  }

  .news-content-center {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .news-box-item-image2 {
    flex: none;
    height: 200px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 15px;
  }

  .catalog-block-list-item {
    width: 100%;
    margin-right: 0px;
  }

  .catalog-item-block-name {
    line-height: 20px;
  }

  .catalog-item-block-body {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .catalog-item-block-body-image {
    text-align: center;
  }

  .content-slider-image-product .slick-dots {
    bottom: -20px;
    height: 68px;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
  }

  .body-3d-modal-product-detail {
    height: 400px;
  }

  .content-slider-image-product .slick-dots li {
    width: 65px;
    height: 55px;
  }

  /*.product-detail-tabs-content-container {
    padding: 0px 20px;
  }
  .product-detail-tab-item {
    padding: 10px 26px;
  }
  .product-detail-tab-specification {
    padding: 0px;
  }
  .product-detail-specification-title {
    font-size: 24px;
  }
  .product-detail-specification-table {
    font-size: 15px;
  }
  .product-detail-tab-specification.active {
    margin-top: 15px;
  }
  .product-margin-box {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }
  .blank-row {
    display: none;
  }
  .product-table-margin {
    margin-top: 10px;
  }
  .product-detail-tab-specification {
    padding: 0px 10px;
  }
  .title-margine-table-left {
    padding-right: 8px;
    width: auto;
    text-align: right;
  }
  .large-item {
    display: none;
  }
  .small-item {
    display: block;
  }
  .product-add-cart-price-items {
    display: block;
  }
  .product-add-cart-qty {
    width: 50%;
    float: left;
  }
  .product-add-cart-btn {
    float: left;
    width: 50%;
  }
  .btn-add-product-detail {
    width: 100%;
  }
  input.input-qty-add-item {
    padding: 5px 20px;
  }
  .product-add-cart-price.small-item {
    margin-bottom: 5px;
    margin-top: -5px;
  }
  .product-detail-tabs-content-container {
    margin-top: 0px;
  }
  .product-margin-block {
    padding: 10px 15px;
  }
  .title-margine-table {
    width: auto;
    font-size: 13px;
  }
  .product-detail-specification-table td {
    padding: 3px 0px;
  }
  .product-detail-specification-item {
    margin-bottom: 15px;
  }*/
  .compare-item-container-body {
    padding: 0px;
  }

  .comapre-item-number {
    font-size: 11px;
    position: absolute;
    top: 0;
    left: 4px;
  }

  .compare-content-container {
    padding: 20px;
    z-index: 4;
  }

  .close-compare-item-detail {
    position: absolute;
    top: -5px;
    right: 2px;
    color: #666;
    cursor: pointer;
  }

  /* .compare-content-item {
    display: none;
  } */
  .compare-item-container-body .compare-content-item:nth-child(3) {
    display: none;
  }

  .compare-item-container-body .compare-content-item:nth-child(4) {
    display: none;
  }

  .comapre-button-footer-submit {
    width: 155px;
    font-size: 13px;
  }

  .comapre-button-footer-clear {
    font-size: 13px;
    width: 155px;
  }

  .container-main-content {
    margin-top: 80px;
  }

  .block-profile-data-content-price-value {
    margin: 20px 0px;
  }

  .social-contact-user-box-item {
    font-size: 16px;
    border: 1px solid #666;
    width: 30px;
    height: 30px;
  }

  .social-contact-user-box-item i {
    margin-top: 5px;
  }

  .special-block-image-mask-name {
    font-size: 25px;
    line-height: 25px;
  }

  .special-block-image-mask-btn-item {
    width: 80px;
    padding: 8px;
    font-size: 14px;
  }

  /* .stats-order-content {
    margin-top: 130px;
  } */
  .status-order-box-title {
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 30px;
  }

  .stats-order-content-holder h1 {
    margin-bottom: 0px;
  }

  .status-box-diagram-item-value {
    font-size: 13px;
  }

  .status-diagram-line {
    font-size: 13px;
  }

  .auction-detail-holder {
    padding: 0px 15px;
  }

  .auction-body-detail {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    padding: 10px;
  }

  .auction-list-block {
    border-left: none;
    padding: 15px;
  }

  .auction-big-image-block {
    border-bottom: 1px solid #2d2d2f;
    padding: 15px 20px;
  }

  .auction-detail-block {
    margin-top: 0px;
  }

  .auticon-content {
    margin-top: 100px;
  }

  .auction-other-items {
    margin: 0px 15px 40px 15px;
    font-size: 30px;
  }

  .auction-content-items {
    padding: 0px;
  }

  .auction-content-item {
    margin-right: 0px;
    width: 100%;
  }

  .auciton-title-description {
    margin: auto;
    position: initial;
    margin-bottom: 20px;
  }

  /* .auction-body-detail {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    padding: 10px;
  }

  .auction-list-block {
    border-left: inherit;
  } */

  #fullpage-header .header-menu {
    margin-right: 5px;
    float: left;
    list-style: none;
    padding-left: 0px;
  }

  .container-content {
    padding: 5px 15px;
  }

  .main-cart-item-content {
    /* margin-top: 63px; */
    margin-top: 15px;
  }

  .item-detail-cart-forms {
    width: auto;
  }

  /* td.main-cart-item-name.main-cart-item-value {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  } */
  .main-cart-items-table {
    max-height: 350px;
  }

  .container-catalog-content {
    margin-top: 65px;
  }

  .container-custom-page-content {
    margin-top: 63px;
  }

  .page-banner-top-image {
    height: 100%;
  }

  .beko-page-item {
    width: 50%;
  }

  .comapre-content-container-minimize {
    padding: 6px 10px 6px 12px;
  }

  .comapre-button-footer-maximize {
    /* flex-direction: column; */
  }

  .minimize-counter-products-compare {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .maximize-btn-compare-click {
    position: absolute;
    top: 10px;
    right: 6px;
    display: none;
  }

  .mobile-compare-button {
    margin-right: 0px;
  }

  .mobile-compare-button .comapre-button-footer-submit {
    width: 80px;
  }

  .mobile-compare-button .comapre-button-footer-clear {
    width: 65px;
    margin-right: 0px;
  }

  .red-background {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .green-background {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .catalog-breadcrumbs-item {
    font-size: 12px;
  }

  .main-title-categroy-item {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 15px;
    position: relative;
    line-height: 25px;
  }

  .catalog-selected-filters-content {
    flex-direction: column;
  }

  .catalog-reset-filters-btn {
    text-align: center;
  }

  .catalog-selected-filters-items {
    margin-left: 0px;
  }

  .catalog-sort-box-view {
    margin-right: 0px;
    /* flex: 1; */
    text-align: center;
  }

  .catalog-sort-select-input {
    width: 135px;
    margin-left: 0px;
    margin-right: 10px;
  }

  .catalog-sort-box-input {
    flex: 1 1;
  }

  .catalog-sort-box-export {
    flex: 1 1;
    text-align: right;
  }

  .container-editing-cart-content {
    margin-top: 110px;
  }

  .delivery-schedule-content {
    padding: 5px 0px;
  }

  .loyalty-shop-content-container {
    margin-top: 58px !important;
    padding: 5px 0px;
  }

  .container-terms-content {
    padding: 0;
  }

  .container-terms-content .banner-image-on-top-page {
    padding: 0px 30px;
  }

  .container-terms-content {
    padding: 0px;
    padding-bottom: 50px;
  }

  .container-registration-content {
    padding: 0px 15px;
  }

  .search-box-modal {
    position: fixed;
    left: 0px;
    width: 100%;
    overflow-y: auto;
    margin-top: 108px;
    height: calc(100vh - 109px);
  }

  .search-box-modal .search-modal-close-mobile {
    display: block;
  }

  .dashboard-box-info {
    width: 100%;
    color: #fff;
  }

  .dashboard-box-delivery {
    width: 100%;
    color: #fff;
    margin-bottom: 20px;
  }

  /* .dashboard-item-radio-buttons {
    text-align: left;
  } */

  .dashboard-value-item {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  /* .dashboard-item-radio-name {
    text-align: left;
  } */

  .dashboard-item-content {
    margin-top: 15px;
  }

  .dashboard-loyalty-items {
    flex-direction: column;
  }

  .dashboadr-item-loyalty {
    border-right: none;
    border-bottom: 1px solid #f7055b;
  }

  .line-ly-separate {
    display: none;
  }

  .dashboadr-item-loyalty {
    padding: 15px 0px;
  }

  .dashboard-item-loyalty-value-big {
    line-height: 70px;
  }

  .dashboard-mobile-background {
    background-color: #2d2d2f;
    color: #fff;
    min-height: calc(100vh - 63px);
  }

  .container-custom-page-content .menu-modal-list-links li {
    color: #fff;
  }

  .container-custom-page-content .menu-modal-item-header {
    margin-top: 10px;
  }

  .cart-editing-mobile-button {
    display: block;
    width: 100%;
    height: 40px;
  }

  .settings-cart-button {
    color: #000;
    border-color: #000;
    font-size: 15px;
    padding: 2px 6px;
    border-radius: 10px;
  }

  .btn-custom-editing-cart-text {
    margin-left: 0px;
  }

  .box-create-new-order-button .btn-custom-editing-cart-text {
    padding: 5px 12px;
  }

  .box-create-new-order-button {
    margin-top: 10px;
    width: 100%;
  }

  .box-create-new-order-input {
    width: 100%;
  }

  .buttons-editing-cart-all {
    display: flex;
    padding-left: 10px;
    padding-right: 0px;
  }

  .buttons-editing-cart-all .btn-custom-editing-cart {
    width: 180px;
    margin-right: 10px;
  }

  .table-row-editing-cart-item-name {
    width: 115px;
  }

  .editing-cart-table .table-padding-item-border-right {
    width: 130px;
  }

  .item-cart-td-brand-name {
    width: 150px;
  }

  .main-cart-items-table .title-cart-order {
    margin-bottom: 0px;
  }

  .main-cart-info-block {
    margin-top: 15px;
  }

  .btn-remove-all-cart {
    margin-top: 15px;
  }

  .span-info-cart-box {
    line-height: 18px;
    flex: 1 1;
  }

  .main-cart-address-info-content {
    margin-top: 20px;
  }

  .remove-main-cart-item,
  .remove-cart-item {
    right: 0px;
  }

  .voucher-btn-cart.btn.btn-info.btn-sm {
    width: 100px;
    margin-right: 9px;
  }

  .item-cart-td-width-mobile {
    width: 80px;
  }

  .item-cart-td-width-mobile-100 {
    width: 100px;
  }

  .product-detail-tabs-body {
    margin-bottom: 30px;
  }

  .stats-order-content-holder {
    padding-left: 15px;
    padding-bottom: 10px;
  }

  .items-dell-block-content {
    padding: 10px;
  }

  .container-dell-page {
    padding: 0px;
  }

  .search-box-modal.active-sidebar-collapsed {
    left: 0px;
    padding: 15px;
  }

  .search-box-category-content {
    margin-right: 0px;
  }

  .container-dell-page-col-12 {
    padding: 0px;
  }

  .action-block-content-body img {
    margin-left: 0px;
    margin-top: -20px;
  }

  .intro-header .menu-list-sidebar-mobile ul {
    padding-left: 0px;
  }

  .special-block-image {
    height: 250px;
  }

  .content-slider-image-product-one-item {
    width: 100% !important;
  }

  .content-slider-image-product .slick-slider {
    margin-top: 0px;
  }

  .custom-modal-preview-image .slick-dots {
    top: -9px;
    bottom: auto;
  }

  .h2-silder-title-logout-page {
    margin: 0px 0px 70px 0px;
  }

  .slider-logout-page-content .slick-next {
    position: absolute;
    top: -10%;
    left: 20%;
  }

  .slider-logout-page-content .slick-prev {
    position: absolute;
    top: -10%;
    left: 2%;
  }

  .slider-logout-page-item-body {
    margin-left: 25px;
    margin-right: 25px;
  }

  .we-present-page-item-body {
    margin-left: 25px;
    margin-right: 25px;
  }

  .slider-logout-page-item-image {
    margin-left: 25px;
    margin-right: 25px;
  }

  .we-present-page-item-image {
    margin-left: 25px;
    margin-right: 25px;
  }

  .slider-logout-page-content {
    padding: 40px;
  }

  .logout-page-items {
    max-width: 100%;
  }

  .products-logout {
    margin-top: 80px;
  }

  .logo-item-logout-page {
    flex: 0 0 33.33333%;
  }

  .products-logout .search-box input {
    padding: 19px;
    border-radius: 30px;
    font-size: 19px;
  }

  .products-logout .search-icon {
    top: 2px;
    font-size: 24px;
    right: 19px;
  }

  .logout-page-subitems {
    max-width: 100%;
  }

  .logo-item-logout-page img {
    width: 80%;
  }

  .logout-page-logo-content {
    margin: auto;
  }

  .catalog-item-block-footer {
    flex-direction: column;
  }

  .catalog-item-block-stock {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .catalog-sort-content-header {
    justify-content: right;
  }

  .create-offer-button {
    margin-top: 10px;
  }

  .catalog-sort-box-export {
    text-align: right;
  }

  .sort-box {
    margin-left: 20px;
  }

  .analytic-content,
  .reservation-content,
  .newsletter-content {
    padding: 5px 0;
  }

  .analytic-content .stats-order-content-holder,
  .reservation-content .stats-order-content-holder,
  .contact-page-content .stats-order-content-holder,
  .customer-care-page .stats-order-content-holder,
  .newsletter-content .stats-order-content-holder {
    padding: 0;
    margin-top: 15px;
  }

  .analytic-content .stats-order-content-holder h1,
  .reservation-content .stats-order-content-holder h1,
  .contact-page-content .stats-order-content-holder h1,
  .customer-care-page .stats-order-content-holder h1,
  .newsletter-content .stats-order-content-holder h1 {
    padding-bottom: 5px;
    font-size: 28px;
    font-weight: bold;
  }

  .analytic-box-info-filter-items {
    flex-direction: column;
  }

  .analytic-box-info-filter-item.analytic-info-box-footer-green {
    margin-left: 0;
    margin-top: 10px;
  }

  .product-compare-action-line {
    justify-content: space-between;
    flex-direction: column-reverse;
  }

  .product-action-detail-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -5px;
  }

  .product-action-item {
    margin-bottom: 3px;
  }

  .product-action-item-buttons {
    height: 25px;
  }

  .action-block-content-image {
    flex: none;
  }

  .action-block-content-body {
    flex: none;
  }

  .price-item-action {
    font-size: 18px;
  }

  .price-item-action .price-item-value-action {
    font-size: 18px;
    top: 9px;
  }

  .timer-action-block {
    bottom: -5px;
  }

  .timer-action-block .timer-item div {
    height: 50px;
    width: 50px;
    font-size: 32px;
  }

  .shop-item-btn-action {
    font-size: 16px !important;
    height: 50px;
    width: 85px;
  }

  .export-buttons {
    justify-content: space-evenly;
    margin-top: 10px;
  }

  .export-buttons .btn-newsletter {
    margin: 0;
  }

  .create-your-offer-mobile {
    width: 100%;
  }

  .create-offer-button {
    color: #FF0000;
    border-color: #FF0000;
    height: 35px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .create-offer-button a svg {
    color: black;
    width: 100%;
    height: 35px;
    fill: black;
    display: flex;
    align-content: flex-start;
  }

  .create-offer-button:hover {
    color: white;
    border-color: #FF0000;
    background-color: #FF0000;
  }

  .content-slider-special-action-banner .slick-dots li button {
    border: 2px solid #fff;
    height: 8px;
    width: 8px;
    padding: 4px;
  }

  .content-slider-special-action-banner .slick-active button {
    border-color: #62c900 !important;
    height: 8px !important;
    width: 8px !important;
  }
}

/* RESPONSIVE - HOMEPAGE LOYALTY SLIDER*/
@media (max-width: 1536px) and (min-width: 1440px) {
  .special-block-image-mask-name {
    font-size: 26px;
    line-height: 26px;
  }

  .action-block-special-banner {
    width: 450px;
    height: 450px;
  }

  .homepage-content-special-image-block {
    width: 450px;
    height: 450px;
  }

  .price-content-item.price-value {
    font-size: 18px;
    line-height: 18px;
  }

  .action-block-special-slider-main-slider-item {
    height: 390px;
  }

  .action-block-special-slider-item-image img {
    width: 90%;
  }

  .news-items-list {
    overflow-y: auto;
    height: 220px;
  }

  .logout-page-products-info h2,
  .logout-page-products-info p {
    font-size: 15px;
  }

  div.logout-page-products-info p {
    margin-bottom: 0;
  }

}

@media (max-width: 1600px) and (min-width: 1536px) {
  .special-block-image-mask-name {
    font-size: 26px;
    line-height: 26px;
  }

  .item-cart-td-brand-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-width: 150px;
    -webkit-box-orient: vertical;
  }

  .news-items-list {
    overflow-y: auto;
    height: 255px;
  }
}

@media (max-width: 1680px) and (min-width: 1600px) {
  .special-block-image-mask-name {
    font-size: 26px;
    line-height: 26px;
  }

  .logout-page-products-info h2 {
    font-size: 13px;
  }

  .logout-page-products-info p {
    font-size: 11px;
  }

  .content-products-item-holder img {
    max-width: 50px;
  }
}

@media (max-width: 1280px) and (min-width: 768px) {
  .action-block-special-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  .action-block-special-banner {
    width: 430px;
    height: 430px;
  }

  .homepage-content-special-image-block {
    width: 430px;
    height: 430px;
  }

  .content-body-special-block {
    display: flex;
    flex-direction: column;
    height: 800px;
  }

  .action-block-special-slider-item-image img {
    width: 80%;
  }

  .homepage-slider-special-action-image a img {
    width: 80%;
    margin: 0 auto;
  }

  .action-block-special-slider-item-price-content {
    font-size: 18px;
  }

  .action-block-special-slider-main-slider-item {
    height: 400px;
  }

  /* .slider-item-spec {
    height: 400px;
  } */

  /* .homepage-special-block-items {
    margin-top: 15px;
  } */
}

@media (max-width: 768px) and (min-width: 480px) {

  .content-body-special-block {
    flex-direction: column;
    height: 800px;
  }

  .special-block-image-mask-name {
    font-size: 25px;
    line-height: 25px;
  }

  .special-block-title-main h2 {
    font-size: 22px;
    margin-top: 20px;
  }

  .special-block-items {
    padding: 0;
  }

  .action-block-special-slider-main-slider-item {
    border-right: none;
  }

  .loyalty-slider-content .action-block-special-slider-title-main h2 {
    font-size: 22px;
  }

  .action-block-special-slider-title-main span {
    font-size: 24px;
  }

  .action-block-special-slider-item-image img {
    width: 80%;
  }

  .action-block-special-slider-item-price-content {
    font-size: 18px;
  }

  .action-block-special-slider-main-slider-item {
    height: 440px;
  }

  .slider-item-spec {
    height: 400px;
  }

  .homepage-slider-special-action-image a img {
    width: 70%;
    margin: 0 auto;
  }

  .homepage-special-block-items {
    margin-top: 15px;
  }

  .news-items-list {
    overflow-y: auto;
    height: 360px;
  }
}

@media (max-width: 480px) and (min-width: 360px) {
  .action-block-special-content {
    display: flex;
    flex-direction: column;
  }

  .action-block-special-banner {
    max-height: 480px;
    max-width: 480px;
    align-items: center;
  }

  .homepage-content-special-image-block {
    max-height: 480px;
    max-width: 480px;
    align-items: center;
  }

  .special-block-image-mask-name {
    font-size: 25px;
    line-height: 25px;
  }

  .content-body-special-block {
    flex-direction: column;
    height: 800px;
  }

  .special-block-title-main h2 {
    font-size: 18px;
    margin-top: 0px;
  }

  .homepage-special-block-items .special-block-title-main h2 {
    font-size: 18px;
    margin-top: 20px;
  }

  .mini-images-slider-product {
    height: 41px !important;
  }

  .custom-modal-preview-image .mini-images-slider-product {
    height: 38px !important;
  }

  .custom-modal-preview-image .mini-images-slider-product-video {
    height: 55px !important;
    width: 65px !important;
  }

  .slider-item-detail-image img {
    padding: 10px;
  }

  .homepage-special-block-items .slider-item-detail-content {
    padding-bottom: 40px;
    padding-top: 5px;
  }

  .homepage-special-block-items .content-slider-special-action .slick-slide {
    padding-bottom: 15px;
  }

  .slider-item-detail-container {
    border: none;
  }

  .special-block-items {
    padding: 0;
  }

  .action-block-special-slider-main-slider-item {
    border-right: none;
  }

  .homepage-special-block-items .slider-item-spec {
    height: 400px;
  }

  .action-block-special-slider-item-image img {
    width: 100%;
  }

  .homepage-slider-special-action-image a img {
    width: 80%;
    margin: 0 auto;
    padding: 0;
  }

  .action-block-special-slider-main-slider .slick-prev {
    z-index: 2;
  }

  .product-detail-content-box .content-slider-special-action .slick-prev:before,
  .product-detail-content-box .content-slider-special-action .slick-next:before,
  .homepage-special-block-items .slick-prev:before,
  .homepage-special-block-items .slick-next:before {
    font-size: 30px;
  }

  .product-detail-content-box .content-slider-special-action .slick-prev,
  .homepage-special-block-items .slick-prev {
    left: -18px;
    z-index: 1;
  }

  .product-detail-content-box .content-slider-special-action .slick-next,
  .homepage-special-block-items .slick-next {
    right: -17px;
    z-index: 1;
  }

  .product-detail-content-box .add-btn-spec-action .add-button {
    height: 26px;
  }

}

@media (max-width: 1440px) and (min-width: 1366px) {
  .homepage-content-special-image-block {
    height: 400px;
    width: 400px;
  }

  .homepage-content-special-image-block img {
    height: 400px;
    width: 400px;
    object-fit: cover;
  }
}

@media (max-width: 1366px) and (min-width: 1025px) {
  .dashboard-img-ly-title img {
    max-width: 110px;
  }

  .homepage-content-special-image-block {
    height: 390px;
    width: 400px;
  }

  .homepage-content-special-image-block img {
    height: 400px;
    width: 400px;
    object-fit: cover;
  }
}

@media (max-width: 1024px) {
  .homepage-content-special-image-block {
    width: 100%;
    height: 266px;
    text-align: center;
  }

  .homepage-content-special-image-block img {
    height: 100%;
  }

  .user-links {
    margin: 5px 3px;
  }

  .customer-care-page .contact-desc {
    margin-bottom: 15px;
  }

  .news-box-item-date {
    margin-bottom: 10px;
  }

  .news-box-item-title {
    margin-bottom: 30px;
  }

  .weekly-title h1 {
    font-size: 36px;
  }

  .main-category-title h1 {
    font-size: 36px;
  }

  .back-button {
    /* font-size: 13px;
    margin-bottom: 10px; */
  }

  f {
    font-size: 13px;
  }
}

/* PREGLED FAKTURA */
.btn-custom-table-text {
  color: #fff;
  border-radius: 5px;
  padding: 3px 12px;
  font-size: 11px;
}

.invoice-table-button-column {
  display: flex;
}

.table-column-button {
  margin-right: 10px;
}

.blue-btn-editing-cart .btn-custom-table-text {
  background-color: #21a7c4;
  border: 1px solid #21a7c4;
}

.orange-btn-editing-cart .btn-custom-table-text {
  background-color: #ff9432;
  border: 1px solid #ff9432;
}

.red-btn-editing-cart .btn-custom-table-text {
  background-color: #f7395f;
  border: 1px solid #f7395f;
}

table.table.editing-cart-table .table-column-btn {
  padding: 10px 5px;
}

table.table.editing-cart-table .table-column-invoice {
  vertical-align: middle;
}

/* PREGLED FAKTURA - RESPONSIVE */
@media (max-width: 1680px) and (min-width: 1600px) {
  .table-column-item {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .status-order-table table.table.editing-cart-table thead th {
    font-size: 15px;
  }

  .btn-custom-table-text {
    padding: 3px 12px;
    font-size: 12px;
  }
}

@media (max-width: 1600px) and (min-width: 1536px) {
  .table-column-item {
    font-size: 14px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .status-order-table table.table.editing-cart-table thead th {
    font-size: 13px;
  }

  .btn-custom-table-text {
    color: #fff;
    border-radius: 5px;
    padding: 2px 11px;
  }

  .btn-custom-table-text {
    padding: 2px 11px;
    font-size: 12px;
  }
}

@media (max-width: 1536px) and (min-width: 1440px) {
  .table-column-item {
    font-size: 13px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .status-order-table table.table.editing-cart-table thead th {
    font-size: 12px;
  }

  .btn-custom-table-text {
    padding: 2px 10px;
    font-size: 11px;
  }

  .table.table.editing-cart-table .table-column-btn {
    padding: 10px 2px;
  }
}

@media (max-width: 1440px) and (min-width: 1366px) {
  .table-column-item {
    font-size: 12px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .btn-custom-table-text {
    padding: 2px 10px;
    font-size: 11px;
  }

  .status-order-table table.table.editing-cart-table thead th {
    font-size: 12px;
  }
}

@media (max-width: 1366px) and (min-width: 1280px) {
  .table-column-item {
    font-size: 11px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .btn-custom-table-text {
    padding: 2px 10px;
    font-size: 11px;
  }

  .container-login-form {
    padding: 25px 40px;
    width: 350px;
  }
}

@media (max-width: 1280px) and (min-width: 1024px) {
  .table-column-item {
    font-size: 11px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .btn-custom-table-text {
    padding: 2px 10px;
    font-size: 11px;
  }

  .editing-cart-items-table {
    font-size: 15px;
  }

  .catalog-banner-product-content {
    flex-direction: column;
    height: auto;
  }

  .catalog-product-image {
    text-align: center;
  }

  .img-product-action {
    border: none;
  }

  .catalog-product-image img {
    height: 500px;
  }

  .catalog-product-body {
    padding-bottom: 20px;
  }

  .btn-add-special-product-action {
    font-size: 12px;
    line-height: 16px;
  }

  .btn-add-special-product-action svg {
    height: 14px;
  }

  .catalog-product-body-text {
    flex: 1 1;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .table-column-item {
    font-size: 10px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .btn-custom-table-text {
    padding: 2px 10px;
    font-size: 11px;
  }

  .catalog-banner-product-content {
    flex-direction: column;
    height: auto;
  }

  .catalog-product-image {
    text-align: center;
  }

  .img-product-action {
    border: none;
  }

  .catalog-product-image img {
    width: auto;
    height: 500px;
  }

  .catalog-product-body {
    padding-bottom: 20px;
  }

  .btn-add-special-product-action {
    font-size: 14px;
    line-height: 20px;
  }

  .btn-add-special-product-action svg {
    height: 16px;
  }

  .catalog-product-body-text {
    flex: 1 1;
  }
}

@media (max-width: 768px) and (min-width: 480px) {
  .table-column-item {
    font-size: 9px;
    padding-left: 1px;
    padding-right: 1px;
  }

  .btn-custom-table-text {
    padding: 2px 10px;
    font-size: 10px;
  }

  .btn-custom-editing-cart {
    width: 150px;
  }

  .invoice-table-button-column {
    display: block;
  }

  .catalog-banner-product-content {
    flex-direction: column;
    height: auto;
  }

  .catalog-product-image {
    text-align: center;
  }

  .img-product-action {
    border: none;
  }

  .catalog-product-image img {
    width: auto;
    height: 280px;
  }

  .catalog-product-body {
    padding-bottom: 20px;
  }

  .btn-add-special-product-action {
    font-size: 14px;
    line-height: 20px;
  }

  .btn-add-special-product-action svg {
    height: 16px;
  }

  .catalog-product-body-text {
    flex: 1 1;
  }

  .catalog-product-body-content-text {
    flex-direction: column;
  }

  .catalog-product-body-price {
    padding: 0;
  }
}

.invoice-overflow-hidden {
  overflow: hidden;
}

@media (max-width: 1020px) {
  .top-margin-navbar-escape {
    margin-top: 50px;
  }

  .cart-editing-mobile-button {
    display: block;
    width: 100%;
    height: 40px;
  }

  .settings-cart-button {
    color: #000;
    border-color: #000;
    font-size: 15px;
    padding: 2px 6px;
    border-radius: 10px;
  }
}

@media (max-width: 1024px) {
  .homepage-slider-special-action-image {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {

  .icons-holder-product-detail {
    padding-left: 0;
  }

  .document-holder-items {
    padding-left: 0;
  }
}

@media (max-width: 768px) {
  .global-info-user {
    text-align: center;
  }

  .dashboard-item-radio-buttons {
    font-size: 14px;
  }

  .dashboard-value-price {
    padding-right: 15px;
    padding-left: 15px;
  }

  .user-info-select-input {
    max-width: none;
  }

  .user-info-margin {
    margin: 0 0;
  }

  .block-profile-data-content-price-value .dashboard-value-price {
    text-align: left;
  }

  .block-profile-data-content-price-value {
    margin: 0;
  }

  .delivery-img-preview {
    margin-top: 20px;
    margin-left: 20px;
    max-width: 120px;
  }

  .news-box-item-image2 img {
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .status-order-filter-items {
    display: block;
  }
}

.analytic-box-export-icons {
  display: flex;
}

.analytic-box-top-margin {
  margin-top: 10px;
}

.modal-top-filter-holder {
  font-size: 0.9rem;
}

.btn-call {
  color: #62c900;
  background-color: #fff;
  border-color: #62c900;
  font-weight: 400;
  width: 20px;
}

.call {
  width: 20px;
  padding-bottom: 3px;
}

.catalog-item-contact-button {
  width: 20px;
  padding-bottom: 3px;
}

.product-item-call img {
  margin-left: -12px;
  margin-right: -4px;
  width: 16px;
  padding-bottom: 3px;
}

.catalog-block-item-contact img {
  width: 16px;
  padding-bottom: 3px;
}

.catalog-sort-box-view-icon {
  background: #fff;
  color: #111;
  width: 24px;
  padding: 3px;
  cursor: pointer;
}

.catalog-sort-box-view-icon:hover {
  background: #fff;
}

.catalog-sort-box-view-icon svg {
  fill: #111;
  width: 23px;
  margin-bottom: 6px;
  margin-right: -3px;
  height: 30px;

}

.catalog-sort-box-view-icon:hover svg {
  fill: grey;
}

.product-detail-page-create-price img {
  width: 16px;
  padding-bottom: 3px;
}

.pdf-view svg {
  width: 15px;
  fill: #fff;
  margin-bottom: 3px;
}

.catalog-table-item-contact-icon {
  width: 16px;
  padding-bottom: 3px;
  margin-left: -19px;

}

.sort-box {
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-right: 20px;
  cursor: pointer;
}

.sort-box svg {
  fill: #2d2d2d;
  height: 33px;
  margin-top: -7px;
  padding: 2px;
}

.sort-box svg:hover {
  fill: grey;
}

.btn-call:hover {
  color: #54ae00;
  border-color: #54ae00;
}

.profile-page {
  overflow: hidden;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #f8f8f8;
}

.customer-care-left {
  background: #fff;
  padding: 15px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0 10px rgb(0 0 0 / 7%);
  margin-bottom: 45px;
  height: 120px;
}

.presentation-page-title {
  margin: 50px 0 30px 10px;
}

.action-block-special-banner-background-wrapper {
  position: relative;
  /* display: table; */
  width: 100%;
  /* this is an example */
  height: 100%;
  /* this is an example */
  overflow: hidden;
  border-radius: 15px;
}

.action-block-special-banner-bacground {

  filter: blur(5px);
  -webkit-filter: blur(5px);
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
  /* filter: blur(10px);
  background: url(http://localhost:3000/akcija-baner-2.jpg) no-repeat; */
  /* z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover; */
}

.action-block-special-banner-background-wrapper img {
  z-index: 9999;
  /* display: table-cell;
  vertical-align: middle; */
}

@media (max-width: 1024px) {
  .action-block-special-banner-bacground {
    display: block;
  }

  .homepage-content-special-image-block img {
    border-radius: 0;
  }
}

.modal-double-pupup-holder {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  height: auto;
}

.modal-popup-item-holder {
  flex: 60% 1;
}

.modal-popup-item-holder-left {
  flex: 40% 1;
}

.modal-double-popoup {
  max-width: 1650px;
  /* max-width: 1600px; */
}

/* @media (min-width: 1367px) {
  .action-modal-popup .action-block {
    height: 100%;
  }
} */

@media (max-width: 1366px) and (min-width: 1280px) {
  .modal-double-pupup-holder {
    flex-direction: column;
  }

  .modal-popup-item-holder {
    height: 400px;
  }

  .modal-double-popoup {
    max-width: 70%;
    margin-top: 100px;
  }

  .catalog-sort-box-export {
    margin-right: 20px;
  }
}

@media (max-width: 1280px) and (min-width: 768px) {
  /* .action-block {
    height: 720px;
  } */

  .modal-double-pupup-holder {
    flex-direction: column;
  }

  .modal-popup-item-holder {
    height: 400px;
  }

  .modal-double-popoup {
    max-width: 80%;
    margin-top: 100px;
  }

  /* .action-block-content {
    margin-left: 0;
    padding: 25px 15px;
    flex-direction: column;
  }

  .action-block-content-body {
    margin-left: 0px;
    margin-top: 10px;
  } */

  /* .timer-action-block {
    bottom: -8px;
  }

  .timer-action-block .timer-item div {
    height: 50px;
    width: 50px;
    font-size: 32px;
  }

  .shop-item-btn-action {
    font-size: 16px !important;
    height: 55px;
    width: 85px;
  } */
}

@media (max-width: 768px) and (min-width: 360px) {
  .action-block {
    height: 720px;
  }

  .action-block-no-content {
    height: auto;
  }

  .action-block-content-no-content img {
    margin: 0;
  }

  .modal-double-pupup-holder {
    flex-direction: column;
  }

  .modal-popup-item-holder {
    height: 400px;
  }

  .modal-double-popoup {
    max-width: 95%;
    margin-top: 100px;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .action-block {
    width: 100%;
    height: 720px;
  }

  .action-block-no-content {
    height: auto;
  }

  .action-block-content-no-content img {
    margin: 0;
  }

  .action-block-content {
    margin-left: 0;
    padding: 25px 15px;
    flex-direction: column;
  }

  .action-block-content-body {
    margin-left: 0px;
    margin-top: 10px;
  }

  .timer-action-block {
    bottom: -8px;
  }

  .timer-action-block .timer-item div {
    height: 50px;
    width: 50px;
    font-size: 32px;
  }

  .shop-item-btn-action {
    font-size: 16px !important;
    height: 55px;
    width: 85px;
  }

  .slider-banner {
    width: 100%;
  }

  .block-slider-home-banner {
    width: 500px;
    margin: 0 auto;
    height: 500px;
  }
}

.react-pdf__Document {
  max-width: 70px;
  text-align: center;
}

canvas.react-pdf__Page__canvas {
  max-width: 70px;
  display: initial !important;
}

.cart-finish-order-button-secondary {
  /* display: flex;
  flex-direction: column; */
  width: 260px;
  margin-left: 16px;
  margin-right: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.button-title {
  font-weight: 600;
  font-size: 15px;
}

.button-paragraph {
  font-size: 13px;
}

.ivanzz {
  break-inside: avoid-column;
  margin-bottom: 1rem;
}

.masonry-wrapper {
  column-count: 2;
  -moz-column-count: 2;
  -webkit-column-count: 2;
  grid-column-gap: 20px;
  column-gap: 20px;
  margin: 50px;
}


.figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  page-break-inside: avoid;
  break-inside: avoid;
}

@media (max-width: 768px) {
  .masonry-wrapper {
    column-count: 1;
    -moz-column-count: 1;
    -webkit-column-count: 1;
    grid-column-gap: 10px;
    column-gap: 10px;
    margin: 20px;
  }

}

/* /////////////////////////////////// */
/* ////////ODREJDENE REZOLUCIJE/////// */
/* /////////////////////////////////// */

@media (max-width: 2048px) and (min-width: 2048px) {
  .col-sm-4-cust {
    width: 275px;
  }

  .catalog-block-list-item {
    width: 464px;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 374px;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 304px;
  }

  .catalog-block-item-value-table-td {
    width: 100px;
  }
}

@media (max-width: 1760px) and (min-width: 1760px) {

  .col-sm-4-cust,
  .col-sm-4-cust:nth-child(5n) {
    width: 280px;
    margin-right: 25px;
  }

  .col-sm-4-cust:nth-child(4n) {
    margin-right: 0;
  }

  .catalog-block-list-item {
    width: 400px;
    margin-right: 10px;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 433px;
    margin-right: 20px;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 315px;
  }

  .news-items-list {
    overflow-y: auto;
    height: 330px;
  }

  .special-block-items {
    flex: 1.9 1;
    padding: 0px 30px;
    height: 455px;
  }

  .homepage-content-special-image-block {
    height: 450px;
    width: 480px;
    border-radius: 15px;
    position: relative;
  }
}

@media (max-width: 1680px) and (min-width: 1536px) {
  .shop-item-btn-action {
    font-size: 15px !important;
    height: 45px;
    width: 85px;
  }

  .price-item-action,
  .price-item-action .price-item-value-action {
    font-size: 16px;
  }

}

@media (max-width: 1680px) and (min-width: 1024px) {
  .product-detail-document-holder {
    margin-bottom: 20px;
  }

  .disclaimer {
    text-align: left;
  }

  .news-content-center {
    flex-direction: column;
  }

  .news-box-item-image2 {
    align-self: auto;
  }
}

@media (max-width: 1536px) and (min-width: 1281px) {
  .product-detail-content-box .mini-images-slider-product {
    height: 55px;
  }

  .btn-add-special-product-action {
    font-size: 12px;
    line-height: 16px;
  }

  .btn-add-special-product-action svg {
    height: 16px
  }
}

@media (max-width: 1279px) and (min-width: 481px) {
  .product-detail-content-box .mini-images-slider-product {
    height: 43px;
  }
}

@media (max-width: 1200px) and (min-width: 1200px) {
  .price-item-action .price-item-value-action {
    font-size: 19px;
    top: 9px;
  }

  .timer-action-block .timer-item div {
    height: 50px;
    width: 50px;
    font-size: 32px;
  }

  .shop-item-btn-action {
    font-size: 16px !important;
    height: 50px;
    width: 85px;
  }

  .editing-cart-items-table {
    white-space: nowrap;
  }

  .container-editing-cart-content {
    overflow: hidden;
  }

  .editing-cart-items-table-custom {
    overflow: visible;
    overflow-x: scroll;
  }

  .table-row-editing-cart-item {
    padding: 0 10px;
  }

  .catalog-block-list-item {
    width: 373px;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 415px;
    margin-right: 20px;
  }

  .col-sm-4-cust {
    width: 300px;
    margin-right: 25px;
  }

  .catalog-content-list-box.active {
    justify-content: center;
  }

  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 280px;
    margin-right: 10px;
  }
}

@media (max-width: 992px) and (min-width: 451px) {
  .editing-cart-items-table {
    white-space: nowrap;
  }

  .container-editing-cart-content {
    overflow: hidden;
  }

  .editing-cart-items-table-custom {
    overflow: visible;
    overflow-x: scroll;
  }

  .table-row-editing-cart-item {
    padding: 0 10px;
  }
}

@media (max-width: 853px) and (min-width: 800px) {
  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 335px;
    margin-right: 25px;
  }

  .news-item-list {
    overflow: auto;
    height: 350px;
  }

  .homepage-content-body-special-block {
    box-shadow: 0px 3px 35px 0px #cecece;
    border-radius: 15px;
    padding: 20px 15px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 800px;
  }

  .catalog-content-list-box.active {
    justify-content: center;
  }

  .col-sm-4-cust:nth-child(3n) {
    margin-right: 25px;
  }

  .col-sm-4-cust:nth-child(2n) {
    margin-right: 0;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 360px;
    margin-right: 10px;
  }

  .catalog-block-list-item:nth-child(2n) {
    margin-right: 0;
  }

}

@media (max-width: 853px) and (min-width: 800px) {
  .catalog-block-list-item {
    width: 47%;
  }

  .catalog-block-item-value-table-td {
    width: 80px;
  }
}

@media (max-width: 768px) and (min-width: 768px) {
  .col-sm-4-cust.collapsed-sidebar-catalog {
    width: 335px;
    margin-right: 25px;
  }

  .catalog-content-list-box.active,
  .catalog-content-list-block.active {
    justify-content: center;
  }

  .col-sm-4-cust:nth-child(3n) {
    margin-right: 25px;
  }

  .col-sm-4-cust:nth-child(2n) {
    margin-right: 0;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 580px;
    margin-right: 0;
  }

  .catalog-block-item-value-table-td {
    width: 240px;
  }

  .catalog-block-list-item {
    width: 90%;
  }

}

@media (max-width: 800px) {
  .customer-care-left {
    margin-bottom: 30px;
  }
}

.value-radio-items {
  display: flex;
  padding: 24px;
}

.icon-position {
  justify-content: center;
}

/* @media (min-width: 2561px) {
  .container-content {
    width: 2063px;
    margin: 0 auto;
  }
} */
@media (min-width: 2400px) {
  .news-items-list {
    overflow-y: auto;
    height: 360px;
  }
}

.cart-shop-icon svg {
  fill: #000;
  margin-bottom: 5px;
}

.cart-shop-icon-search-mobile img {
  margin-bottom: 5px;
}

.currency-vat-switcher-mobile {
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (min-width:1440px) and (max-width:1440px) {
  .news-items-list {
    overflow-y: auto;
    height: 300px;
  }

  .call-us-button {
    width: 100% !important;
  }

  .icon-position {
    margin-right: -4px;
  }

  .catalog-sort-box-export {
    margin-right: 20px;
  }
}

@media (min-width:1360px) and (max-width:1360px) {
  .btn-add-product-detail {
    height: 31px;
    padding-bottom: 2px;
  }

  .call-us-button {
    width: 100% !important;
  }

  .product-detail-content-box .content-body-special-block {
    height: 450px;
  }
}

@media (min-width:1920px) and (max-width:1920px) {
  .catalog-sort-box-export {
    margin-right: 20px;
  }

  .news-items-list {
    overflow-y: auto;
    height: 366px;
  }

  .logout-page-products-info h2,
  .logout-page-products-info p {
    font-size: 14px;
  }
}

.empty-cart-button {
  margin-top: 20px;
}

@media (min-width:1680px) and (max-width:1680px) {
  .catalog-sort-box-export {
    margin-right: 20px;
  }

  .catalog-block-list-item {
    width: 30%;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 32%;
  }

  .catalog-block-item-value-table-td {
    width: 80px;
  }
}

@media (min-width:1600px) and (max-width:1600px) {
  .catalog-sort-box-export {
    margin-right: 20px;
  }

  .block-action-slider-cart-button svg {
    margin-left: 0px;
    margin-top: 2px;
    margin-right: -3px;
    margin-bottom: 4px;
  }

  .btn-add-product-detail svg {
    fill: #ffff;
    width: 20px;
    margin-bottom: 4px;
    margin-right: -3px;
  }

  .catalog-block-item-value-table-td {
    width: 75px;
  }
}

@media (min-width:1536px) and (max-width:1536px) {
  .catalog-sort-box-export {
    margin-right: 20px;
  }

  .block-action-slider-cart-button svg {
    margin-left: 4px;
    margin-top: 2px;
    margin-right: -2px;
    margin-bottom: 4px;
  }

  .catalog-block-item-value-table-td {
    width: 90px;
  }
}

.slider-special-action-button {
  width: 100%;
}

.catalog-sort-box-export {
  margin-right: 10px;
}

.add-all svg {
  fill: #fff;
  width: 23px;
  padding-bottom: 2px;
  margin-right: -4px;
}

.voucher-info-box {
  width: 100%;
  padding: 6px 11px;
  border-radius: 5px;
  border: 1px solid #dddddd;
  background-color: white;
  margin: 22px 0;
}

.voucher-info-box-content {
  display: flex;
  align-items: center;
}

.voucher-info-box-content-text {
  display: block;
  color: #626262;
}

.voucher-info-box-content-text span {
  text-align: center;
}

.voucher-info-box-image {
  /* width: 200px; */
  height: 60px;
  margin-right: 10px;
}

.voucher-info-box-content p {
  font-weight: 800;
  font-size: 15px;
  line-height: 60px;
  color: #A70B0E;
  transform: rotate(-3deg);
  margin: -18px 0 -43px -79px;
  text-shadow: -3px 3px #d4d4d4;
  cursor: pointer;
}

/* .voucher-info-box-content p sup {
  font-size: 18px;
  top: -35px;
  font-weight: 700;
} */

.voucher-info-holder {
  width: 100%;
  max-width: 2560px;
  padding: 40px 40px;
  border-radius: 5px;
  border: 1px solid #dddddd;
  background-color: white;
  margin: 30px 0;
}

.voucher-info-faq-holder {
  display: flex;
  flex-wrap: wrap;
}

.voucher-info-title {
  max-width: 1000px;
  font-size: 18px;
  margin-bottom: 40px;
  color: #111111;
}

.voucher-info-faq {
  width: 100%;
  margin-top: 2px;

  cursor: pointer;
}

.voucher-info-faq h3 {
  position: relative;
  font-size: 28px;
  line-height: 40px;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #EEF1F3;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 30px;
  z-index: 9;
  transition: color .2s ease-in-out;
}

.icon-expand-faq-items {
  margin-right: 30px;
  margin-left: 30px;
}

.voucher-info-faq h3:hover {
  color: #62c900;
}

.voucher-info-faq h3:hover .info-text-chevron {
  color: #62c900;
}

.voucher-info-faq p {
  position: relative;
  font-size: 22px;
  font-weight: 300;
  padding: 0 60px;
  color: #626262;
  transform: translateY(-50px);
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: transform .5s ease-in-out, opacity .5s ease-in-out, max-height .5s ease-in-out, padding .5s ease-in-out;
  z-index: 1;
}

.voucher-info-faq.active p.shown {
  transform: translateY(0);
  opacity: 1;
  max-height: 140px;
  /* Adjust this value to fit your content */
  padding: 30px 50px 20px 50px;
}

.voucher-info-faq p.hidden {
  opacity: 0;
  max-height: 0;
  padding: 0 50px;
}

@keyframes slide {
  0% {
    margin-top: -50px;
    opacity: 0;
  }

  100% {
    margin-top: 0px;
    opacity: 1;
  }
}

/* .voucher-image-on-top {
  width: 100%;
  height: 500px;
}

.voucher-image-on-top img {
  object-fit: cover;
  width: 100%;
  height: 500px;
  border-radius: 15px;
} */

.info-text-chevron-expanded {
  font-size: 28px;
  transition: color .2s ease-in-out;
}

.info-text-chevron {
  font-size: 28px;
  cursor: pointer;
  transition: color .2s ease-in-out;
}

.info-text-chevron:hover {
  color: #62c900;
}

.voucher-info-faq:hover .voucher-info-faq {
  color: #62c900;
}

@media (max-width: 767px) {
  .voucher-info-faq {
    margin-top: 30px;
    margin-right: 0;
  }

  .voucher-info-holder {
    width: 100%;
    padding: 15px 15px;
    margin: 0;
  }

  .voucher-info-faq p {
    font-size: 16px;
    font-weight: 300;
    padding: 5px 20px 5px 20px;
  }

  .voucher-info-faq h3 {
    font-size: 18px;
    line-height: 22px;
    padding: 10px;
  }

  .info-text-chevron-expanded {
    font-size: 18px;
  }

  .info-text-chevron {
    font-size: 18px;
  }
}

@media (min-width: 450px) and (max-width: 1440px) {
  .auction-list-block-table-li {
    font-size: 16px;
  }

  .auction-list-block-table-li span {
    font-size: 18px;
  }

  .auction-list-block-bid-input-box input {
    font-size: 13px;
  }

  .offer-text-auction span {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .voucher-info-faq {
    margin-top: 30px;
    margin-right: 0;
  }

  .voucher-info-faq p {
    font-size: 18px;
    font-weight: 300;
    padding: 10px 40px 5px 40px;
  }

  .voucher-info-faq h3 {
    font-size: 22px;
    line-height: 28px;
    padding: 20px;
  }

  .info-text-chevron-expanded {
    font-size: 22px;
  }

  .info-text-chevron {
    font-size: 22px;
  }
}

.cart-info-box-voucher {
  background: #efefef;
  margin-bottom: 10px;
  padding: 10px 12px;
  color: #666;
  font-weight: 500;
  border-radius: 5px;
  max-width: 500px;
  display: flex;
}

.cart-info-box-voucher img {
  width: 30px;
  text-align: center;
  vertical-align: middle;
  padding-top: 5px;
  margin-right: 10px;
  object-fit: contain;
}

.coins-file-input-label input {
  display: none;
}

.coins-file-input-label {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.coins-file-input {
  padding: 6px 40px;
  color: #c5c5c5;
  border-radius: 20px;
  border: 1px solid #606060;
  cursor: pointer;
  width: 100%;
}

.coins-file-input i {
  margin-left: 10px;
}

.coins-file-input:hover {
  background: rgb(183, 56, 106);
  color: #fff;
}

.coins-image-preview {
  text-align: center;
}

.coins-image-preview img {
  height: 120px;
}

.order-button-info-text {
  font-size: 12px;
  background: #efefef;
  padding: 10px 12px;
  color: #666;
  font-weight: 400;
  border-radius: 5px;
  display: flex;
  height: 100%;
  align-items: center;
}

.order-info-text {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.order-button-info-text .info-icon {
  border: 2px solid orange;
  color: orange;
  padding: 5px;
  flex: none;
}

.order-button-info-text i {
  width: 25px;
  height: 25px;
  font-size: 12px;
  border-radius: 50%;
  margin-right: 5px;
  text-align: center;
}


.product-catalog-badge-coin-value {
  position: absolute;
  color: white;
  left: 0;
  top: 4px;
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  text-shadow: 2px 2px 1px rgba(100, 100, 100, 0.7), 1px 1px #111;
}

.product-catalog-badge-coin-value-2 {
  line-height: 35px;
  color: white;
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  text-shadow: 2px 2px 1px rgba(100, 100, 100, 0.7), 1px 1px #111;
}

.product-badge-coin-holder {
  position: relative;
  max-width: 80px;
  text-align: center;
}

.product-badge-coin-holder-formula {
  position: relative;
  text-align: center;
}

.product-badge-coin-value {
  position: absolute;
  color: white;
  left: 70px;
  top: calc(50% - 38px);
  width: 100%;
  font-size: 27px;
  font-weight: 700;
  text-shadow: 2px 2px 1px rgba(100, 100, 100, 0.7), 1px 1px #111;
}

.product-badge-coin-value-formula {
  position: absolute;
  color: white;
  right: 20px;
  padding-top: 2px;
  line-height: 1;
  font-size: 20px;
  font-weight: 700;
  text-shadow: 2px 2px 1px rgba(100, 100, 100, 0.7), 1px 1px #111;
}

.auction-price-next-offer {
  font-size: 13px;
  font-weight: 500;
}

.auticon-item-next-price {
  text-align: center;
}

.order-change-button {
  width: 300px;
  float: right;
  margin-left: 10px;
}

.order-quick-button {
  width: 300px;
  float: left;
  margin-right: 10px;
}

.buttons-holder {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #ccc;
}

@media (min-width: 1024px) and (max-width: 1920px) {
  .order-change-button {
    margin-bottom: 15px;
  }

  .order-quick-button {
    margin-bottom: 15px;
  }

  .order-button-info-text {
    height: auto;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 1024px) {
  .order-change-button {
    width: 100%;
    float: none;
    margin-left: 0;
  }

  .order-quick-button {
    width: 100%;
    float: none;
    margin-right: 0;
  }

  .order-button-info-text {
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .btn-cart-finish-text span {
    font-size: 11px;
  }
}

.close-auction-body-detail {
  position: absolute;
  right: 87px;
  color: #7C7C7C;
  font-size: 40px;
  cursor: pointer;
}

.btn-grey {
  color: #fff;
  background-color: #697181;
  border-color: #697181;
}

.btn-call-actions:hover {
  color: #bd2946;
}

.btn-call-actions {
  color: #bd2946;
  background-color: #fff;
  border-color: #bd2946;
  font-weight: 400;
  width: 20px;
  display: flex;
  justify-content: center;
  cursor: default !important;
}

.btn-call-actions svg {
  fill: #bd2946;
  width: 20px;
  margin-left: -5px;
  margin-right: -3px;
  margin-bottom: 4px;
}

.logo-coins-image {
  text-align: center;
  margin-bottom: 10px;
}

.logo-coins-image img {
  max-width: 200px;
}

.container-coins-reg-form {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 10%; */
  /* transform: translate(0%, -50%); */
  width: 450px;
  /* border: 1px solid #fafafa; */
  padding: 60px;
  color: white;
  /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 0%), 0 2px 1px -1px rgb(0 0 0 / 0%); */
  background: #000000;
  border-radius: 35px;
  box-shadow: 0 0em 2em rgba(0, 0, 0, 0.1), 0 0 0 0px rgb(255, 255, 255), 0em 0.3em 2em rgba(0, 0, 0, 0.3);
}

@media (max-width: 1366px) and (min-width: 1280px) {

  .container-coins-reg-form {
    padding: 25px 40px;
    width: 350px;
  }

  .logo-coins-image img {
    max-width: 170px;
  }
}

.container-coins-reg-form input {
  border-radius: 20px;
}

.container-coins-reg-form h2 {
  font-family: 'GtestiProRegular';
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}

.container-coins-reg-form p {
  font-family: 'GtestiProLight';
  margin-top: 40px;
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.container-coins-reg-form input::placeholder {
  font-style: italic !important;
}

.custom-button-coins2:active:focus {
  box-shadow: 0 0 0 .2rem rgb(252, 57, 85) !important;
}

.custom-button-coins2 {
  background-color: #2020CB !important;
  color: #fff;
  border-color: #2020CB !important;
  border-radius: 20px;
  font-weight: 600;
  font-size: 18px;
  padding: 8px 24px;
  width: 220px;
}

/* CSS */
.custom-button-coins {
  align-items: center;
  background-color: #2020CB;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-color: #2020CB;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding: 8px 24px;
  width: 220px;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
}

.custom-button-coins:hover,
.custom-button-coins:focus {
  border-color: rgb(183, 56, 106);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: white;
  background-color: rgb(183, 56, 106);
}

.custom-button-coins:hover {
  transform: translateY(-1px);
}

.custom-button-coins:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.coins-container-holder {
  font-family: 'GtestiProRegular';
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #111111;
}

.custom-coins-input {
  background-color: #111111 !important;
  color: #ffffff !important;
  border: 1px solid #4d4d4d !important;
}

.login-holder-block-image2 {
  flex: 3 1;
  text-align: center;
}

.login-holder-block-image2 img {
  object-fit: contain;
  padding: 0 50px;
}

@media (max-width: 1024px) {
  .login-holder-block-image2 img {
    display: none;
  }

  .login-holder-block-image2 {
    flex: none;
  }

  .logo-coins-image img {
    max-width: 170px;
  }

  .news-content-center {
    flex-direction: column;
  }

  .news-box-item-image2 {
    align-self: auto;
  }
}

@media (max-width: 576px) {

  .container-coins-reg-form {
    width: 100%;
    left: 0%;
    box-shadow: none;
    transform: none;
    top: 0;
    /* height: 100vh; */
    /* border-radius: 0px; */
    padding: 30px 40px 30px;
    margin: 0 15px;
  }

  .container-coins-reg-form h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 24px;
  }
}

@media (max-width: 1440px) and (min-width: 1367px) {
  .container-coins-reg-form {
    padding: 25px 40px;
    width: 350px;
  }
}

@media (max-width: 1366px) and (min-width: 1280px) {
  .container-coins-reg-form {
    padding: 20px 30px;
    width: 350px;
  }
}

@media (max-width: 1199px) and (min-width: 1021px) {
  .container-coins-reg-form {
    padding: 30px 40px;
    width: 350px;
  }

  .container-coins-reg-form h2 {
    margin-top: 20px;
  }
}

.action-call-us-link {
  text-decoration: none !important;
}

.your-coins {
  text-align: -webkit-center;
  margin-top: 250px;
}

.your-coins-sencor {
  text-align: -webkit-center;
  margin-top: 250px;
}

.your-coins-sencor1 {
  text-align: -webkit-center;
  width: 285px;
}

.button-my-coin-list {
  flex: 1 1;
  text-align: center;
  background: transparent;
  border-radius: 15px;
  border: 1px solid rgb(183, 56, 106);
  padding: 5px 0px;
  cursor: pointer;
  color: rgb(183, 56, 106);
  width: 500px;
}

.button-my-coin-list-sencor {
  cursor: pointer;
  text-align: center;
  background-color: #b7386a;
  color: #F5E0ED;
  width: 500px;
  padding: 13px;
  margin-top: 20px;
  border-radius: 25px;
}

.button-my-coin-list:hover {
  background: #111;
  color: #fff;
  border: 1px solid #fff;
}

.button-my-coin-list-sencor:hover {
  background-color: #b7386a;
  padding: 13px;
  color: #F5E0ED;
  margin-top: 20px;
  border-radius: 25px;
}

.coins-content-header {
  font-family: 'GtestiProUltraBold';
  color: rgb(183, 56, 106);
  font-size: 45px;
  letter-spacing: 30px;
  /* font-weight: 100; */
}

.coinss-content-header {
  text-align: center;
  margin-bottom: 50px;
  border-bottom: 1px solid rgb(183, 56, 106);
}

.coins-style .MuiTableCell-head {
  background-color: transparent !important;
  color: white;
}

.coins-style span:hover,
.coins-style .MuiTableSortLabel-root.MuiTableSortLabel-active,
.coins-style .MuiTableSortLabel-icon,
.coins-style .MuiInputBase-root,
.coins-style .MuiIconButton-root.Mui-disabled,
.coins-style span {
  color: #ffffff !important;
}

.coins-style {
  /* background-image: url(https://resource.ewe.rs/media/new-year-loyalty/zvezde.png); */
  /* background-image: url(https://resource.ewe.rs/media/Sencor-Akcililili2.jpg);*/
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-color: #f5a6d8;


}

.container-content1 {
  /* background-image: url(https://resource.ewe.rs/media/new-year-loyalty/zvezde.png); */
  /* background-image: url(https://resource.ewe.rs/media/Sencor-Akcililili2.jpg); */
  /* background-size: cover;
  background-repeat: no-repeat; */
  width: 100%;
  background-image: url(https://resource.ewe.rs/media/rang-list-background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.container-content1-sencor {
  background-image: url(https://resource.ewe.rs/media/Sencor-Akcililili2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.container-content-sencor {
  background-image: url(https://resource.ewe.rs/media/rang-list-background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

@media (min-width:1360px) and (max-width:1366px) {

  .container-content,
  .benefit-content {
    overflow: auto;
  }

  .coins-content-header {
    margin-top: 0px;
  }

  .benefit-content-header {
    margin-top: 100px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(183, 56, 106);
  }

  .sencor-content-header {
    margin-top: 100px;
    margin-bottom: 20px;
    border-bottom: 1px solid #F5E0ED;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .button-my-coin-list {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .button-my-coin-list-sencor {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .benefit-user span {
    font-size: 15px;
  }

  .benefit-user-sencor span {
    font-size: 15px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top 18%;
  }
}

@media (min-width:1560px) and (max-width:1560px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .benefit-content-header {
    margin-top: 150px;
    margin-bottom: 70px;
    border-bottom: 1px solid rgb(183, 56, 106);
  }

  .sencor-content-header {
    margin-top: 150px;
    margin-bottom: 70px;
    border-bottom: 1px solid #F5E0ED;
  }

  .benefit-content-row {
    display: flex;
    flex-direction: column;
  }

  .button-my-coin-list {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .button-my-coin-list-sencor {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top 167%;
  }
}

.benefit-content-header {
  border-bottom: 1px solid rgb(183, 56, 106);
}

.sencor-content-header {
  border-bottom: 1px solid #F5E0ED;
}

@media (min-width:1536px) and (max-width:1536px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .benefit-content-header {
    margin-top: 150px;
  }

  .sencor-content-header {
    margin-top: 150px;
  }

  .benefit-content-row {
    display: flex;
    flex-direction: column;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .button-my-coin-list {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .button-my-coin-list-sencor {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top -68%;
  }
}

@media (min-width:1100px) and (max-width:1200px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .benefit-content-header {
    margin-top: 100px;
  }

  .sencor-content-header {
    margin-top: 100px;
  }

  .benefit-content-row {
    display: flex;
    flex-direction: column;
  }

  .button-my-coin-list {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .button-my-coin-list-sencor {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .benefit-user span {
    font-size: 20px;
  }

  .benefit-user-sencor span {
    font-size: 20px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top 35%;
  }
}

@media (min-width:1680px) and (max-width:1680px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .benefit-content-header {
    margin-top: 50px;
  }

  .sencor-content-header {
    margin-top: 50px;
  }

  .benefit-content-row {
    display: flex;
    flex-direction: column;
  }

  .button-my-coin-list {
    margin-top: -200px;
    font-size: 15px;
  }

  .button-my-coin-list-sencor {
    margin-top: -200px;
    font-size: 15px;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top 42%;
  }
}

@media (min-width:1792px) and (max-width:1792px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .benefit-content-header {
    margin-top: 50px;
  }

  .sencor-content-header {
    margin-top: 50px;
  }

  .benefit-content-row {
    display: flex;
    flex-direction: column;
  }

  .button-my-coin-list {
    margin-top: -200px;
    font-size: 15px;
  }

  .button-my-coin-list-sencor {
    margin-top: -200px;
    font-size: 15px;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top 43%;
  }
}

@media (min-width:1400px) and (max-width:1440px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .benefit-content-header {
    margin-top: 100px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(183, 56, 106);
  }

  .sencor-content-header {
    margin-top: 100px;
    margin-bottom: 20px;
    border-bottom: 1px solid #F5E0ED;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .button-my-coin-list {
    margin-top: -200px;
    font-size: 15px;
  }

  .button-my-coin-list-sencor {
    margin-top: -200px;
    font-size: 15px;
  }

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .benefit-content-row {
    display: flex;
    flex-direction: column;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top 17%;
  }

  .catalog-block-list-item {
    width: 48%;
  }

  .catalog-block-list-item.collapsed-sidebar-catalog {
    width: 45%;
  }
}

@media (min-width:2048px) and (max-width: 2048px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .benefit-content-header {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .sencor-content-header {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .benefit-content-row {
    display: flex;
    flex-direction: column;
  }

  .button-my-coin-list {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .button-my-coin-list-sencor {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .benefit-user span {
    font-size: 35px;
  }

  .benefit-user-sencor span {
    font-size: 35px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top 50%;
  }
}

@media (min-width:2560px) and (max-width: 2560px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .benefit-content-header {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .sencor-content-header {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .benefit-content-row {
    display: flex;
    flex-direction: column;
  }

  .button-my-coin-list {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .button-my-coin-list-sencor {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 15px;
  }

  .benefit-user span {
    font-size: 35px;
  }

  .benefit-user-sencor span {
    font-size: 35px;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top 50%;
  }

  .logout-page-products-info h2,
  .logout-page-products-info p {
    font-size: 18px;
  }
}

@media (min-width:3840px) and (max-width: 3840px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .logout-page-products-info h2,
  .logout-page-products-info p {
    font-size: 18px;
  }

  div.logout-page-products-info p {
    margin-bottom: 0 !important;
  }

  .coins-style {
    font-size: 25px;
  }

  .coins-total {
    font-size: 25px;
  }

  .benefit-content-header {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 50px;
  }

  .sencor-content-header {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 50px;
  }

  .benefit-content-row {
    display: flex;
    flex-direction: column;
  }

  .button-my-coin-list {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 20px;
  }

  .button-my-coin-list-sencor {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 20px;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .benefit-user span {
    font-size: 30px;
  }

  .benefit-user-sencor span {
    font-size: 30px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 88% top 49%;
  }
}

@media (min-width:1920px) and (max-width: 1920px) {

  .container-content,
  .benefit-content {
    overflow: auto;
  }

  .benefit-content-header {
    margin-top: 70px;
  }

  .sencor-content-header {
    margin-top: 70px;
  }

  .benefit-content-row {
    display: flex;
    flex-direction: column;
  }

  .button-my-coin-list {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 20px;
  }

  .button-my-coin-list-sencor {
    margin-bottom: 100px;
    margin-top: -200px;
    font-size: 20px;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 120px;
    margin-top: -60px;
  }

  .benefit-user span {
    font-size: 15px;
  }

  .benefit-user-sencor span {
    font-size: 15px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 34% top 17%;
  }
}

@media (min-width:1024px) and (max-width: 1280px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content,
  .container-content-sencor {
    overflow: auto;
  }

  .benefit-content-header {
    margin-top: 70px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(183, 56, 106);
  }

  .sencor-content-header {
    margin-top: 70px;
    margin-bottom: 20px;
    border-bottom: 1px solid #F5E0ED;
  }


  .button-my-coin-list {
    margin-bottom: 100px;
    margin-top: -200px;
  }

  .button-my-coin-list-sencor {
    margin-bottom: 100px;
    margin-top: -200px;
  }


  .benefit-user span {
    font-size: 13px;
  }

  .benefit-user-sencor span {
    font-size: 13px;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 123px;
    margin-top: -3px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 91% top 33%;
  }
}

@media (max-width: 768px) and (min-width: 360px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content {
    overflow: auto;
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/zvezde.png);
  }

  /* .container-content-sencor{
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/zvezde.png);
  } */
  .benefit-content-header {
    margin-top: 20px;
    font-size: 35px;
    text-align: center;
    display: flex;
  }

  .sencor-content-header {
    margin-top: 20px;
    font-size: 35px;
    text-align: center;
    display: flex;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 98px;
    margin-top: 0px;
    width: 102px;
  }

  .benefit-user span {
    font-size: 10px;
  }

  .benefit-user-sencor span {
    font-size: 10px;
  }

  .coins-content-header {
    font-size: 25px;
    text-align: center;
  }

  .coins-total {
    font-size: 25px;
    text-align: center;
  }

  .button-my-coin-list {
    margin-top: -150px;
    margin-bottom: 80px;
  }

  .button-my-coin-list {
    width: 200px;
  }

  .button-my-coin-list-sencor {
    width: 200px;
  }

  .your-coins {
    margin-top: 200px;
  }

  .coins-total-subtitle {
    font-size: 20px;
  }

  .close-auction-body-detail {
    position: absolute;
    right: 22px;
    color: #7C7C7C;
    font-size: 35px;
    cursor: pointer;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top 70%;
  }
}

@media (max-width: 1000px) and (min-width: 768px) {

  .container-content1,
  .container-content1-sencor,
  .benefit-content {
    overflow: auto;
  }

  .coins-content-header {
    margin-top: 1100px;
  }

  .benefit-content-header {
    margin-top: 20px;
    font-size: 35px;
    text-align: center;
  }

  .sencor-content-header {
    margin-top: 20px;
    font-size: 35px;
    text-align: center;
  }


  .benefit-user span {
    font-size: 10px;
  }

  .benefit-user-sencor span {
    font-size: 10px;
  }

  .coins-content-header {
    font-size: 25px;
    text-align: center;

  }

  .coins-total {
    font-size: 25px;
    text-align: center;
  }

  .button-my-coin-list {
    margin-top: -150px;
    margin-bottom: 80px;
  }

  .button-my-coin-list {
    width: 200px;
  }

  .button-my-coin-list-sencor {
    width: 200px;
  }

  .your-coins {
    margin-top: 200px;
  }

  .coins-total-subtitle {
    font-size: 20px;
  }

  .close-auction-body-detail {
    position: absolute;
    right: 22px;
    color: #7C7C7C;
    font-size: 35px;
    cursor: pointer;
  }

  .NewYearLoyaltyLanding_logo-image {
    height: 98px;
    margin-top: 0px;
    width: 102px;
  }

  .header-content-background {
    background-image: url(https://resource.ewe.rs/media/new-year-loyalty/title-shadow.png);
    background-repeat: no-repeat;
    background-position: left 89% top 55%;
  }

  .disclaimer {
    text-align: left;
    margin: 20px 20px 0 20px;
  }
}

.coins-total {
  font-family: 'GtestiProRegular';
  color: white;
  font-size: 28px;
  letter-spacing: 5px;
  margin: 40px 0;
}

.coins-total span {
  font-family: 'GtestiProRegular';
  color: rgb(183, 56, 106);
  font-size: 28px;
}

.coins-total-subtitle {
  font-family: 'GtestiProRegular';
  color: white;
  font-size: 24px;
  letter-spacing: 10px;
}

.coins-total-subtitle-number {
  font-family: 'GtestiProUltraBold';
  color: rgb(183, 56, 106);
  font-size: 3em;
  letter-spacing: 15px;
}

.coins-subtitle-holder {
  text-align: center;
}

.container-content1,
.container-content1-sencor {
  width: 100%;
  /* padding: 25px 30px; */
}

.container-content,
.benefit-content {
  overflow: auto;
}

.active-cart-link {
  color: #626262;
  font-weight: 400;
  width: 20px;
  font-size: 15px;
}

.go-to-cart-link {
  text-align: left;
}

.container-content1,
.container-content1-sencor .benefit-content,
.container-content-sencor {
  overflow: auto;
}

.planet-earth-image {
  height: 450px;
  width: 100%;
  background-image: url("https://resource.ewe.rs/media/new-year-loyalty/planet-earth-from-space.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* position: absolute; */
  bottom: 0;
  /* height: -webkit-fill-available; */
}

.container-content1,
.container-content-sencor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container-content2 {
  background-color: black;
  background-size: cover;
  width: 100%;
}

img.img-header {
  width: 100%;
}

img.img-col1 {
  width: 50%;
}

img.img-col2 {
  width: 50%;
}

.page-body {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 768px) and (min-width: 360px) {
  .container-content2 {
    padding-top: 50px;
    background-color: black;
    background-size: cover;
  }

  .page-body {
    display: flex;
    flex-direction: column;
    background-color: black;
    background-size: cover;
  }

  img.img-header {
    width: 100%;
    margin-top: 15px;
  }

  img.img-col1 {
    width: 100%;
  }

  img.img-col2 {
    width: 100%;
  }

  div.logout-page-products>div.row>div.col-md-3.col-sm-6,
  div.logout-page-products>div.row>div.col-md-4.col-sm-6 {
    margin-bottom: 5px;
  }
}

@media (max-width: 290px) {
  .catalog-block-item-value-table-td {
    width: 110px;
  }
}

@media (max-width: 1921px) {
  .action-block-content-body p {
    font-size: 18px;
    line-height: 20px
  }
}

.we-present-page-item {
  margin-bottom: 30px;
}

.contact-captcha-image-code {
  padding-left: 15px;
  width: 150px;
  margin-bottom: 0px;
  position: relative;
}

.link {
  text-decoration: none !important;
}

.link:hover {
  color: black;
}

.new-year-party-partners {
  background-image: url("https://resource.ewe.rs/media/happening/2022/novogodisnja-zurka-2022-clients-bg.jpg");
  background-size: contain;
  overflow: auto;
  background-color: #000;
  background-repeat: no-repeat;
}

.new-year-party-partners-registered {
  background-image: url("https://resource.ewe.rs/media/happening/2022/hvala-na-prijavi-kupci.jpg");
  background-size: cover;
  height: 100vh;
}

.new-year-party-clients-registered {
  background-image: url("https://resource.ewe.rs/media/happening/2022/hvala-na-prijavi-bg.jpg");
  background-size: cover;
  height: 100vh;
}

.new-year-party-clients {
  background-image: url("https://resource.ewe.rs/media/happening/2022/novogodisnja-zurka-2022-bg.jpg");
  background-size: contain;
  overflow: auto;
  background-color: #000;
  background-repeat: no-repeat;
}

.new-year-party-container-holder {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
}

.ewe-ski-holder-block-image img {
  width: 100%;
  margin-top: 64px;
}

.ewe-ski-registered {
  background-image: url("https://resource.ewe.rs/media/eweSki2020/ewe-ski2023.png");
  background-size: cover;
  height: 100vh;
  overflow: auto;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
}

.success-regisration-ewe-ski {
  font-size: 60px;
  color: #2567ae;
  /* margin-right: 26px; */
  width: 50%;
  /* border: 1px solid black; */
  /* border-radius: 1.75rem; */
  /* height: 500px; */
  /* margin-left: 67%; */
  /* margin-top: 17%; */
}

.success-regisration-ewe-ski p {
  /* margin-top: 170px; */
  /* margin-bottom: 1rem; */
  font-size: 60px;
  font-style: italic;
  color: #04a2cf;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: -webkit-center;
  line-height: 1.1;
}

.new-year-party-container-holder .input-name {
  font-size: 19px;
}

.ewe-ski-holder {
  display: flex;
  width: 100%;
  /* height: 100vh; */
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  bottom: 0;
}

.login-holder-block-form-ewe-ski {
  font-family: 'NetAxiforma';
  /* color: #066796; */
  color: #024982;
  font-size: 14px;
  /* align-self: center; */
  margin-left: 15px;
}

.personal-info1,
.personal-info2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

.new-year-party-container-holder .input-name {
  font-size: 19px;
}

.form-control-ewe-ski {
  display: block;
  width: 100%;
  /* margin-left: 40px; */
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0.75rem;
  /* padding: 7px; */
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  /* border-radius: 7.25rem; */
  border-radius: 14px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background-color: #E4F2FF;
  /* border: 1px solid #135eae; */
  color: #366da8;
  margin-top: 25px;
  box-shadow: #6285a0 3px 3px 3px -1px inset;
  height: 34px !important;
}

.check-boxes-holder .form-group1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.form-check-holder {
  display: flex;
  margin-right: 15px;
}

.form-control-ewe-ski1 {
  display: block;
  width: 100%;
  /* margin-left: 10px; */
  /* height: calc(1.5em + 0.75rem + 2px); */
  /* padding: 0.375rem 0.75rem; */
  /* padding: 7px; */
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  /* border-radius: 7.25rem; */
  border-radius: 14px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background-color: #E4F2FF;
  /* border: 1px solid #135eae; */
  color: #366da8;
  margin-top: 24px;
  box-shadow: #6285a0 3px 3px 3px -1px inset;
  height: 34px !important;
}

.identity-card {
  width: 65%;
}

.form-control-ewe-ski1 custom-ewe-ski-input-input form-control input {
  width: 40%;
}

.container-ewe-ski-reg-form .form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-ewe-ski-reg-form {
  margin: 0 15px;
}

.input-name0 {
  text-align: center;
  margin-bottom: -18px;
  font-size: 14px;
}

.input-name1 {
  text-align: left;
  margin-top: 6px;
  font-size: 14px;
}

.input-name2 {
  margin-right: 5px;
}

.check-boxes-holder {
  margin-bottom: 20px;
}

.check-boxes-holder .form-check {
  position: relative;
  display: block;
  /* padding-left: 6.25rem; */
  padding-left: 4.25rem !important;
}

.check-boxes-holder .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -2.50rem;
  height: 30px;
  width: 30px;
}

.check-boxes-holder .form-check .form-check-input {
  height: 24px !important;
  width: 24px !important;
  border-radius: 9px !important;
  vertical-align: text-bottom;
  box-shadow: #6285a0 3px 3px 3px -1px inset;
  background-color: #E4F2FF;
}

.check-boxes-holder .form-check-label {
  margin-bottom: 0;
  margin-top: 8px;
  position: absolute;
  left: 23px;
}

.check-boxes-holder input[type='radio'] {
  -webkit-appearance: inherit;
          appearance: inherit;
  /* width: 20px; */
  /* height: 20px; */
  background-color: #DEE9F5;
  /* border: 1px solid blue; */
  border-radius: 0.8rem;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  cursor: pointer;
  display: inline-block;
  /* position: relative; */
  position: absolute;
  left: 88px;
  /* top: -10px; */
  /* left: 10px; */
  margin-right: 10px;
  height: 30px;
  width: 30px;
  /* box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px 1px inset; */
}

.check-boxes-holder input[type='radio']:checked {
  background-color: #0b79ef;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
}

.form-check-holder .form-check .form-check-input:checked {
  background-color: #61cee6 !important;
}

.flex-checks {
  display: flex;
  justify-content: center !important;

}

.flex-checks input[type='radio'] {
  -webkit-appearance: inherit;
          appearance: inherit;
  /* width: 20px; */
  /* height: 20px; */
  background-color: #E4F2FF;
  /* border: 1px solid blue; */
  border-radius: 9px;
  /* box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%); */
  cursor: pointer;
  display: inline-block;
  position: relative;
  /* top: -10px; */
  /* left: 10px; */
  margin-right: 10px;
  height: 24px;
  width: 24px;
  box-shadow: #6285a0 3px 3px 3px -1px inset;
}



.flex-checks input[type='radio']:checked {
  background-color: #61cee6;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
}

.flex-checks .form-check {
  position: relative;
  display: block;
  padding-left: 3.25rem;
}

.flex-checks .form-check-label {
  margin-top: 15px !important;
  position: absolute;
  left: 6px;
  top: -8px;
}

/* .date-of-birth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
} */
.identity-card {
  width: 50% !important;
}

.form-date {
  flex-direction: row !important;
  justify-content: space-between;
  align-items: baseline !important;
  margin-top: -24px;
}

.holder-for-img-and-date {
  display: flex;
  flex-direction: column;
}


.img-upload-holder img {
  height: 60px;
  margin-top: -10px;
  cursor: pointer;
}

.img-upload-holder img:hover {
  /* background-color: red; */
  cursor: pointer;
}

.ewe-ski-file-input-label input {
  display: none;
}

.ewe-ski-file-input-label {
  /* width: 100%; */
  text-align: center;
  margin-top: 15px;
}

.SKIPASS-file-input {
  color: #024982;
  width: 100%;
}

.form-group5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.SKIPASS-file-input i {
  margin-left: 10px;
}

/* .SKIPASS-file-input:hover {
  background: #61cee6;
    color: #fff;
    border-radius: 20px;
    border:1px solid #066796;
    padding: 6px 40px; 
} */

.login-form-and-buttom {
  display: flex;
}

.button-login-content-es:hover {
  transform: scale(1.05);
}

.button-login-content-es {
  transition: all .2s ease-in-out;
  /* width: fit-content; */
  align-self: center;
  padding-top: 20px;
  margin-bottom: 40px;
}

.custom-button-ewe-ski {
  align-items: center;
  background-color: #61cee6;
  border-radius: 14px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: 'NetAxiforma', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  width: 60%;
  justify-content: center;
  line-height: 0.25;
  margin: 0;
  height: 34px;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  border: none !important;
  box-shadow: rgba(1, 40, 65, 0.46) 0px 3px 1px;
}

/* .custom-button-ewe-ski .disabled {
  background-color: gray!important;
  color: white!important;
  cursor: not-allowed!important;
} */

.custom-button-new-year {
  align-items: center;
  background-color: #2020CB;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-color: #2020CB;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: 'NetAxiforma', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding: 8px 24px;
  width: 220px;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
}

.container-new-year-reg-form {
  padding: 50px;
  color: white;
  display: flex;
  position: absolute;
  bottom: 30px;
  flex-direction: column;
}

.button-login-content-ny:hover {
  transform: scale(1.05);
}

.button-login-content-ny {
  transition: all .2s ease-in-out;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
}

.login-holder-block-form-new-year {
  justify-content: center;
  display: flex;
}

.custom-new-year-input {
  color: #ffffff !important;
  border: 1px solid #4d4d4d !important;
  margin: 10px;
}

.container-new-year-reg-form-clients {
  padding: 50px;
  color: white;
  display: flex;
  margin-top: 98%;
}

.container-new-year-reg-form- .form-group {
  margin: 10px;
}

.form-control1 {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 7.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background-color: black;
  border: 1px solid #B49C5E;
  color: #fff;
}

.input-name {
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.form-group1 {
  margin-top: 4px;
}

.custom-button-new-year {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  color: black;
  border: 1px solid black;
  border-style: double;
  padding: 1px;
}

.border {
  position: relative;
  border-radius: 27px;
  padding: 4px;
  background-color: black;
  border: 3px solid #B49C5E !important;
  margin-top: 10px;
}

.border:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;

}

.container-new-year-reg-form form {
  display: flex;
}

.container-new-year-reg-form .form-group {
  margin: 10px;
}

.form-control1:focus {
  color: white;
  background-color: black;
  border-color: yellow;
  box-shadow: 0;
}

.valid-registration {
  font-size: 50px;
  color: #FBFA9E;
  margin-top: 20%;
  font-family: 'GtestiProRegular';
}

.clickable-img {
  width: 100%;
  display: inline-block;
}

.news-box-item-image2 img {
  object-fit: cover;
}

@media (max-width: 1024px) {
  .container-new-year-reg-form form {
    display: block;
  }

  .container-new-year-reg-form {
    margin-top: 0;
  }

  .new-year-party-partners {
    margin-top: 60px;
  }

  .container-new-year-reg-form {
    margin-top: 0;
  }

  .new-year-party-partners {
    margin-top: 60px;
  }

  .new-year-party-clients {
    margin-top: 60px;
  }

  .new-year-party-partners-registered {
    background-size: contain;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
    margin-top: 60px;
  }

  .new-year-party-clients-registered {
    background-size: contain;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
    margin-top: 60px;
  }

  .new-year-party-clients {
    background-size: contain;
  }

  .new-year-party-partners {
    background-size: contain;
  }
}

@media(min-width: 1800px) and (max-width:1950px) {
  .new-year-party-clients {
    background-size: cover;
  }

  .new-year-party-partners {
    background-size: cover;
  }
}

@media (min-width: 2048px) and (max-width: 3500px) {
  .new-year-party-partners {
    background-size: cover;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
  }

  .new-year-party-clients {
    background-size: cover;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
  }

  .new-year-party-partners-registered {
    background-size: cover;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
  }

  .new-year-party-clients-registered {
    background-size: cover;
    background-color: #000;
    background-repeat: no-repeat;
    overflow: auto;
  }

  .new-year-party-partners-registered {
    background-size: contain;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
  }

  .new-year-party-clients-registered {
    background-size: contain;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
  }
}

@media (min-width: 3501px) and (max-width: 3900px) {
  .new-year-party-partners {
    background-size: cover;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
  }

  .new-year-party-clients {
    background-size: cover;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
  }

  .new-year-party-partners-registered {
    background-size: cover;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
  }

  .new-year-party-clients-registered {
    background-size: cover;
    background-color: #000;
    background-repeat: no-repeat;
    overflow: auto;
  }

  .new-year-party-partners-registered {
    background-size: contain;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
    margin-top: 60px;
  }

  .new-year-party-clients-registered {
    background-size: contain;
    overflow: auto;
    background-color: #000;
    background-repeat: no-repeat;
    margin-top: 60px;
  }

  .container-new-year-reg-form {
    padding: 50px 0px 25px 550px;
  }
}

@media(min-width: 500px) and (max-width:649px) {
  .login-holder-block-form-ewe-ski {
    max-width: 80%;
  }

  .ewe-ski-holder-block-image img {
    width: 90%;
    margin-top: -20px;
  }
}

@media(min-width: 650px) and (max-width:991px) {
  .form-control-ewe-ski1 {
    width: 290px;
  }

  .ewe-ski-holder-block-image img {
    width: 85%;
    margin-top: -90px;
  }

}


@media(min-width: 992px) and (max-width:1023px) {
  .form-control-ewe-ski1 {
    width: 215px;
  }

  .ewe-ski-holder-block-image img {
    width: 65%;
    margin-top: -10px;
  }

  .login-holder-block-form-ewe-ski {
    margin-left: -24px;
  }

  .container-ewe-ski-reg-form {
    max-width: 430px;
  }
}

@media(min-width: 1024px) and (max-width:1537px) {
  .ewe-ski-holder-block-image img {
    width: 65%;
    margin-top: -122px;
  }

  .form-control-ewe-ski1 {
    width: 300px;
  }

}

@media(min-width: 1366px) and (max-width:1366px) {
  .ewe-ski-holder-block-image {
    background-color: #ffffff;
    opacity: 0.5;
  }

  .ewe-ski-holder-block-image img {
    width: 100%;
  }

  .ewe-ski-holder {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    bottom: 0;
    margin-top: -997px;
    z-index: 1000;

  }
}

@media(min-width: 1538px) and (max-width:1800px) {
  .ewe-ski-holder-block-image img {
    width: 100%;
    margin-top: 0px;
  }

  .login-holder-block-form-ewe-ski {
    width: 405px;
  }

  .eve-ski-container .row {
    flex-wrap: nowrap;
  }

  .ewe-ski-holder {
    margin-top: 60px;
    margin-right: 20px;
  }


}

@media(min-width: 1801px) and (max-width:1920px) {
  .ewe-ski-holder-block-image img {
    display: none;
  }

  .ewe-ski-background-img {
    background-image: url('https://resource.ewe.rs/media/eweSki2020/ewe-ski2023.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: -60px;
    height: 100vh;
  }

  .login-holder-block-form-ewe-ski {
    width: 500px;
    background-size: 2100px 1200px;
    margin-left: auto;
    margin-right: 40px;
  }

  .eve-ski-container .row {
    flex-wrap: nowrap;
  }

  .ewe-ski-holder {
    margin-top: 110px;
    margin-right: 20px;
  }

}

@media(min-width: 1921px) and (max-width:2315px) {
  .ewe-ski-holder-block-image img {
    display: none;
  }

  .ewe-ski-background-img {
    background-image: url('https://resource.ewe.rs/media/eweSki2020/ewe-ski2023.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }

  .login-holder-block-form-ewe-ski {
    width: 500px;
    background-size: 2100px 1200px;
    margin-left: auto;
    margin-right: 60px;
  }

  .eve-ski-container .row {
    flex-wrap: nowrap;
  }

  .ewe-ski-holder {
    margin-top: 90px;
    margin-right: 20px;
  }

}

@media(min-width: 2316px) and (max-width:2560px) {
  .ewe-ski-holder-block-image img {
    display: none;
  }

  .ewe-ski-background-img {
    background-image: url('https://resource.ewe.rs/media/eweSki2020/ewe-ski2023.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }

  .login-holder-block-form-ewe-ski {
    width: 500px;
    margin-left: auto;
    margin-right: 150px;
    margin-top: 100px;
  }

  .eve-ski-container .row {
    flex-wrap: nowrap;
  }

  .ewe-ski-holder {
    margin-top: 170px;
    margin-right: 20px;
  }
}

@media(min-width: 2561px) and (max-width:3840px) {
  .ewe-ski-holder-block-image img {
    display: none;
  }

  .ewe-ski-background-img {
    background-image: url('https://resource.ewe.rs/media/eweSki2020/ewe-ski2023.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }

  .login-holder-block-form-ewe-ski {
    width: 500px;
    margin-left: auto;
    margin-right: 200px;
    margin-top: 100px;
  }

  .eve-ski-container .row {
    flex-wrap: nowrap;
  }

  .ewe-ski-holder {
    margin-top: 170px;
    margin-right: 20px;
  }
}

@media (min-width:280px) and (max-width:600px) {
  .ewe-ski-registered {
    background-image: url("https://resource.ewe.rs/media/eweSki2020/EWEsky.png");
    background-size: contain;
    background-position: top 64px left 0;
  }

  .success-regisration-ewe-ski {
    font-size: 60px;
    color: #2567ae;
    width: 80%;
    position: relative;
    left: 50%;
    bottom: -500px;
    transform: translateX(-50%);
  }

  .success-regisration-ewe-ski p {
    font-size: 40px;
  }

  .disclaimer {
    text-align: left;
    margin: 20px 20px 0 20px;
  }
}

@media (min-width:601px) and (max-width:992px) {

  .ewe-ski-registered {
    background-image: url("https://resource.ewe.rs/media/eweSki2020/EWEsky.png");
    background-size: 100%;
    /* background-position: top 64px left 0; */
  }

  .success-regisration-ewe-ski {
    font-size: 60px;
    color: #2567ae;
    width: 80%;
    position: absolute;
    left: 50%;
    bottom: 8%;
    transform: translateX(-50%);
  }

  .disclaimer {
    text-align: left;
  }
}

@media (min-width:993px) and (max-width:1279px) {
  .ewe-ski-registered {
    background-image: url("https://resource.ewe.rs/media/eweSki2020/EWEsky.png");
    background-size: 80%;
  }

  .success-regisration-ewe-ski {
    font-size: 60px;
    color: #2567ae;
    width: 80%;
    position: relative;
    left: 50%;
    bottom: -612px;
    transform: translateX(-50%);
  }

}

@media (min-width:1280px) and (max-width:1537px) {
  .ewe-ski-registered {
    background-image: url("https://resource.ewe.rs/media/eweSki2020/EWEsky.png");
    background-size: 65%;
  }

  .success-regisration-ewe-ski {
    font-size: 60px;
    color: #2567ae;
    width: 30%;
    position: relative;
    left: 65%;
    bottom: -403px;
  }

}

@media (min-width:1538px) and (max-width:1919px) {
  .ewe-ski-registered {
    background-image: url("https://resource.ewe.rs/media/eweSki2020/EWEsky.png");
    background-size: 70%;
  }

  .success-regisration-ewe-ski {
    font-size: 60px;
    color: #2567ae;
    width: 30%;
    position: relative;
    left: 70%;
    bottom: -476px;
  }

}

@media (min-width:1920px) and (max-width:2560px) {
  .success-regisration-ewe-ski {
    font-size: 60px;
    color: #2567ae;
    width: 30%;
    position: relative;
    left: 65%;
    bottom: -560px;
  }

  .success-regisration-ewe-ski p {
    font-size: 80px;
  }
}

@media (min-width:2561px) and (max-width:3840px) {
  .success-regisration-ewe-ski {
    font-size: 80px;
    color: #2567ae;
    width: 30%;
    position: relative;
    left: 65%;
    bottom: -580px;
  }

  .success-regisration-ewe-ski p {
    font-size: 80px;
  }
}

/* Klase za ulaznu animaciju */
.table-enter {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.table-enter-active {
  opacity: 1;
  transform: translateY(0);
}

/* Klase za izlaznu animaciju */
.table-exit {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.table-exit-active {
  opacity: 0;
  transform: translateY(-10px);
}

.auction-sub-desc {
  position: relative;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  bottom: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.delivery-attachment-label {
  font-weight: 600;
  margin-top: 10px;
  width: -webkit-max-content;
  width: max-content;
}

.delivery-attachment-input {
  display: none;
}

.delivery-attachment-content {
  text-align: center;
  background: #efefef;
  color: #000;
  font-weight: 600;
  padding: 20px 0px;
  margin-top: 30px;
  border-radius: 5px;
}

.remove-attachment {
  font-size: 14px;
  color: #eb0e4c;
  cursor: pointer;
  margin-left: 4px;
  padding: 2px 4px;
}

.remove-attachment:hover {
  color: #bd2946;
}

.remove-attachment:focus {
  box-shadow: 0 0 0 0.1rem rgba(130, 138, 145, .5);
  color: #EA5881;
}

.remove-attachment:active {
  box-shadow: 0 0 0 0.1rem rgba(130, 138, 145, .5);
  color: #EA5881;
}

.delivery-img-link {
  color: #007bff;
}

.delivery-file-label {
  background: #1ea8c5;
  padding: 6px 40px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

@media (min-width: 2560px) {
  .outlet-page-item {
    padding: 25px 25px 0;
    min-height: 150px;
  }

  .content-products-item-holder img {
    max-width: 120px;
  }

  .logout-page-products-info h2,
  .logout-page-products-info p {
    font-size: 17px;
  }
}

@media (max-width: 2560px) and (min-width: 1921px) {
  .outlet-page-item {
    padding: 25px 25px 0;
    min-height: 140px;
  }

  .content-products-item-holder img {
    max-width: 100px;
  }
}

@media (max-width: 1560px) and (min-width: 1441px) {
  .content-products-item-holder img {
    max-width: 65px;
  }
}

@media (max-width: 1440px) and (min-width: 850px) {

  .content-products-item-holder img {
    max-width: 100px;
  }
}

.products-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.specialShopDiv {
  display: flex;
  /* background-image: linear-gradient(180deg, #633791, #462b63, #2c2634); */
  background-image: linear-gradient(180deg, #633791, #36214c, #2c2634);
  border-radius: 8px;
  transition: 0.5s;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.specialShopDiv span {
  position: absolute;
  display: block;
}

.specialShopDiv span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #efb836);
  animation: animate1 1s linear infinite;
}

@keyframes animate1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.specialShopDiv span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #efb836);
  animation: animate2 1s linear infinite;
  animation-delay: .25s;
}

@keyframes animate2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.specialShopDiv span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #efb836);
  animation: animate3 1s linear infinite;
  animation-delay: .5s;
}

@keyframes animate3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.specialShopDiv span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #efb836);
  animation: animate4 1s linear infinite;
  animation-delay: .75s;
}

@keyframes animate4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

.loyalty-special-product-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.loyalty-special {
  /* background-color: #2c2634; */
  border-radius: 15px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.specialShopDiv:hover {
  animation: wiggle 2s linear infinite;
}

@keyframes wiggle {

  0%,
  7% {
    transform: rotateZ(0);
  }

  15% {
    transform: rotateZ(-10deg);
  }

  20% {
    transform: rotateZ(8deg);
  }

  25% {
    transform: rotateZ(-8deg);
  }

  30% {
    transform: rotateZ(6deg);
  }

  35% {
    transform: rotateZ(-4deg);
  }

  40%,
  100% {
    transform: rotateZ(0);
  }
}


.product-special-img {
  position: relative;
}

.product-special-img div {
  color: #fff;
  font-size: 5em;
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(180deg, #b572f5, #16002a, #3F2855);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-special-img img {
  position: absolute;
  top: 10%;
  left: 50%;
}

.special-product-price {
  display: flex;
  justify-content: space-between;
  /* width: calc(100% - 20px); */
}

.loyalty-special-open-product-holder {
  width: 250px;
  height: 250px;
  padding: 10px;
  /* border: 1px solid #62c900; */
  border-radius: 8px;
  position: relative;
  margin-right: 4px;
  background-color: #fff;
  overflow: hidden;
}

.sold-text {
  position: absolute;
  top: 25px;
  left: -55px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  transform: rotate(-45deg);
  background-color: #ed2024;
  padding: 6px 50px;
}

.bought-2 {
  color: #ed2024;
  font-size: 12px;
}

.bought-2 span {
  font-weight: 700;
}

.bought-2 i {
  background-color: #ed2024;
  border-radius: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
  padding-top: 4px;
  margin-right: 6px;
  text-align: center;
}

.open-product-special-img {
  margin-left: auto;
  margin-right: auto;
  width: 130px;
}

.percetange-loyatly-special-product {
  position: absolute;
  top: 6%;
  left: 75%;
  font-size: 18px;
  font-weight: 900;
  background-image: url('https://resource.ewe.rs/media/loyalty/discount.png');
  width: 50px;
  height: 50px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 10px;
  color: #fff;
  transform: rotate(-25deg);
  text-align: center;
  display: inline-block;
}

.special-product-button {
  display: flex;
  position: absolute;
  top: 85%;
  width: calc(100% - 20px);
}

.special-product-button>div {
  flex-grow: 1;
}

.add-special-product {
  margin-left: 6px;
}

.loyaltyOldPriceSpecial {
  position: relative;
}

.loyaltyOldPriceSpecial::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid #ed2024;
}

.special-product-price span:nth-child(2) {
  font-size: 16px;
  font-weight: 700;
}

.special-product-name-click {
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 500;
  /* width: calc(100% - 20px); */
}

.loyalty-special-product-text {
  color: #3d2767;
  line-height: 1.4;
  padding: 40px 20px 5px 20px;
  border-radius: 8px;
  background: linear-gradient(to right, #fbbf24, #f59e0b);
  /* background: linear-gradient(#fbbf24, #fbbf24, #f59e0b); */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.1);
  border: 4px solid #fcd34d;
  text-align: center;
}

.alert-info-loyalty {
  background-color: #ef4444;
  color: #fff;
  font-weight: 500;
}

.loyalty-special-product-text-img {
  padding: 12px 16px 0px 12px;
  border-radius: 8px;
  background: linear-gradient(#ffcd78, #ffe08f, #ffcd78);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.1);
  border: 4px solid #fcd34d;
  border: 4px solid #fff3d5;
  text-align: center;
}

.loyalty-special-product-text-img p {

  color: #534768;
  /* color: #f48919; */
  margin-top: 12px;
  /* font-family: 'Sigmar One'; */
  font-weight: 900;
  font-size: 16px;
}

.loyalty-special-product-text-boxes {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #2c2634;
  border-radius: 15px;
  /* padding: 20px; */
  padding: 20px 20px 0 20px;
  margin-bottom: 20px;
}

.loyalty-detail-name-click.disabled-link {
  pointer-events: none;
  cursor: default;
}

.loyalty-detail-name-click.disabled:hover {
  text-decoration: none;
}

.special-flip-holder {
  position: relative;
  margin: 2px;
  width: 250px;
  height: 250px;
  overflow: hidden;
}

.flip {
  animation-name: flip;
  animation-duration: .5s;
  animation-direction: normal;
  animation-fill-mode: forwards
}

.flip-reverse {
  animation-direction: reverse;
  animation-fill-mode: backwards;
}

@keyframes flip {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.flip-reverse {
  animation-name: flip2;
  animation-fill-mode: both;
  animation-direction: normal;
  animation-duration: 2s;
}

@keyframes flip2 {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.city-column-title {
  color: #888;
  font-size: 18px;
  margin-bottom: 2px;
}

.city-column-data {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.city-tracking-date {
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
}

.city-details-data {
  margin-top: 2px;
}

.city-modal-loader {
  height: 100px;
}

.product-catalog-bedge-go-cart {
  padding: 0 8px;
  height: 35px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to left, #d40114, #a10023, #6c0b23, #380f1a, #2b0b0b);
}

.product-catalog-badge-3 {
  margin-top: 5px;
  cursor: pointer;
  position: relative;
  text-align: center;
  background-image: linear-gradient(to left, #d40114, #a10023, #6c0b23, #380f1a, #2b0b0b);
  height: 35px;
  border-radius: 4px;
  padding: 0 8px;
  margin-right: 2px;
}

.product-catalog-badge-coin-value-3 {
  color: white;
  right: 0;
  top: 6px;
  font-size: 14px;
  font-weight: 700;
  text-shadow: 2px 2px 1px rgba(100, 100, 100, 0.7), 1px 1px #111;
  display: inline-block;
  padding-top: 5px;
}

.re-captcha-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding-right: 14px;
  margin-bottom: 15px;
}

.re-captcha-container>div {
  display: flex;
  justify-content: flex-end;
  width: auto;
}

.closeLoyModal {
  display: none;
}
.recaptcha-error {
  border: 1px solid red;
  border-radius: 4px;
  display: table;
}

.contactFormSubmitBtn {
  margin-top: 8px;
}
.re-captcha-container-contact {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.button-submit-ly-shop {
    text-align: right;
}

.percetange-loyatly-product {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 30px;
    color: #fff;
    /* border: 1px solid #fff; */
    background-color: #f92a5e;
    text-align: center;
    border-radius: 50%;
    position: relative;
    font-size: 13px;
}

.percetange-loyatly-product:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border: 2px solid #3d2767;
    border-radius: 50%;
}

.loyalty-mobile-banner {
    display: none;
}

.unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    user-select: none;
}

.image-loyalty-top-counter img {
    width: 100%;
}

.loyalty-table-content td {
    padding: 0px 10px;
}

.table-row-no-padding-top>td {
    border-top: 0;
}

.loyalty-table-content th {
    font-size: 14px;
    padding: 0px 10px;
    border-top: 0px;
    font-weight: 600;
}

.table-loyalty>tbody>tr>td {
    vertical-align: middle;
    font-size: 13px;
}

.loyaltyOldPrice {
    white-space: nowrap;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    line-height: 1;
    position: relative;
}

.loyaltyOldPrice:after {
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    height: 1px;
    width: 60px;
    background: #efb836;
    transform: rotate(-13deg);
}

.mask-captcha-loyalty {
    background: #fff;
    opacity: 0;
    width: 100%;
    height: 51px;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1;
}

.img-loyalty-product {
    padding: 5px;
}

.count-clock-loyalty {
    position: absolute;
    left: 50%;
    top: 400px;
    transform: translate(-50%, 0);
}

.custom-name-title span {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.custom-name-title td {
    padding: 25px 10px 10px;
    /* border-bottom: 2px solid #ddd; */
}

.special-icon-loyalty-for-company {
    max-width: 60px;
}

.info-icons-action {
    margin-top: 16px;
    border: 1px solid #b4b4b4;
    padding: 5px 25px;
    border-radius: 12px;
    font-weight: 700;
}

.smallBadge {
    max-width: 60px;
}

.body-modal-loyalty-specification {
    max-height: 65vh;
    overflow-y: auto;
    /* padding-right: 0; */
}

.body-modal-loyalty-specification .product-detail-tab-specification {
    padding: 0px;
}

.loyalty-detail-name-click {
    cursor: pointer;
}

.loyalty-detail-name-click:hover {
    text-decoration: underline;
}

.body-modal-loyalty-specification-h3 {
    font-size: 20px;
}

.loyalty-modal-image {
    max-width: 100%;
}

.image-content-loyalty-modal {
    padding: 0px 100px;
}

.modal-loyalty-header-item {
    flex: 0 1 100%;
}

.bottom-divider {
    padding: 0px 60px;
}

.timer-counter-banner-holder {
    display: flex;
    width: 50%;
    text-align: center;
    margin: 0 auto;
}

.timer-holder-item {
    flex: 1 1;
    font-size: 75px;
}

.total-sum-percent {
    text-align: center;
    font-size: 45px;
    color: #000000;
    font-weight: bold;
}

.scala-pointer {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-9px);
    transition: left 600ms ease-out;
    max-width: 14px;
}

.scala-holder .shop-zoom {
    position: relative;
}

.scala-holder {
    padding-top: 17px;
    margin-bottom: 7px;
    position: relative;
}

.stat-percent {
    width: 100%;
    font-weight: 300;
    font-size: 80px;
    line-height: 1;
    color: #c91031;
    font-weight: 500;
}

.timer-item-text {
    font-size: 30px;
    color: #efb836;
}

.timer-holder-item-dots {
    font-weight: bold;
    color: #efb836;
    flex: 0 1;
    line-height: 1.2;
}

.timer-item-number {
    font-weight: bold;
    font-size: 100px;
    line-height: 1;
}

.stat-item span {
    display: inline-block;
    width: 100%;
}

.shop-target-description {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
}

.loyalty-upper-desc {
    margin-top: 10px;
    font-size: 24px;
}

.stat-target.red {
    color: #fff;
}

.stat-target {
    border: 1px solid #fff;
    border-radius: 30px;
    padding: 5px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

.stat-target.green {
    color: #fff;
}

.item-loyalty-top-settings {
    border-left: 1px solid #b4b4b4;
}

.loyalty-table-content {
    padding-bottom: 65px;
}

.modal-header h4 {
    margin-bottom: 0px;
}

.loyalty-captcha-image-code {
    margin-bottom: 0px;
    position: relative;
}

.FlipClock .flipUnitContainer .flipCard {
    width: 120px !important;
    height: 50px !important;
}

.FlipClock .flipUnitContainer {
    width: 120px;
    height: 100px;
}

.FlipClock .flipUnitContainer .lowerCard span,
.FlipClock .flipUnitContainer .upperCard span {
    font-size: 4.8em !important;
}

.FlipClock .flipUnitContainer .flipCard span {
    font-size: 4.8em !important;
}

.FlipClock .flipUnitContainer .ContainerTitle {
    top: -23px;
    font-size: 17px;
}

.FlipClock .flipUnitContainer {
    width: 120px !important;
    height: 100px !important;
}

/* loyalty slider */
.loyalty-slider .slider-special-action-image {
    padding-top: 0px;
}

.loyalty-slider .action-title-name {
    font-size: 20px;
    min-height: 60px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.loyalty-slider .loyalty-bages-holder {
    position: absolute;
    top: -1px;
    left: 0px;
}

.loyalty-slider-content .loyalty-discount-holder {
    position: absolute;
    right: 10px;
    top: 4px;
}

.loyalty-slider .slider-special-action-detail-res {
    font-size: 17px;
}

.loyalty-slider .slider-special-action-detail-price {
    font-size: 17px;
}


.loyalty-slider-content .action-block-special-slider-title-main h2 {
    text-transform: none;
    font-size: 32px;
    padding-bottom: 0px;
    font-weight: bold;
    margin-bottom: 5px;
}

.loyalty-slider .spec-action-qty {
    flex: none;
}

.loyalty-slider-content .loyalty-btn-holder {
    padding-top: 15px;
    text-align: center;
    /* position: absolute; */
    /* bottom: 0px; */
    margin-left: auto;
    margin-right: auto;
    /* left: 15px; */
    /* right: 15px; */
}

.action-block-special-slider-title-main span {
    color: red;
    font-size: 35px;
}

.loyalty-slider-content .loyalty-block-btn {
    border: 2px solid black;
    background: black;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    border-radius: 8px;
}

#form-loyalty-shop {
    overflow-x: auto;
}

.loyalty-header-info-products {
    background: #3d2767;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    padding: 3px 18px;
    border-radius: 20px;
}

.banner-loyalty-image-conter {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    border-radius: 15px;
}

.banner-counter-holder-loyaty {
    flex: 2 1;
}

.banner-logo-holder-loyaty {
    flex: 1 1;
}

.title-counter-banner-main {
    font-weight: bold;
    font-size: 34px;
}

.banner-counter-holder-main {
    text-align: center;
    color: #fff;
    margin-top: 80px;
}

.loyaty-holder-info-user-box {
    margin-top: 20px;
    background: #efb836;
    padding: 20px 35px;
    border-radius: 15px;
    color: #3d2767;
    margin-bottom: 20px;
    position: relative;
    display: flex;
}

.loyalty-info-box-item {
    flex: 1 1;
    text-align: center;
    border-right: 2px solid #3d2767;
    padding: 0px 40px;
}

.scala-holder-loyalty {
    height: 23px;
    width: 100%;
    background: #fff;
    border-radius: 25px;
    position: relative;
}

.loyalty-info-box-item:nth-child(3) {
    border-right: 0;
}

.scala-holder-loyatly-left {
    width: calc(50% + 17px);
    background: #3d2767;
    height: 100%;
    border-radius: 25px;
}

.scala-holder-loyalty-circle {
    background: #efb836;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 50%;
    top: 0;
    border-radius: 23px;
    border: 1px solid #3d2767;
}

.total-sum-percent-loyalty {
    color: #fff;
    font-size: 85px;
    font-weight: bold;
    line-height: 90px;
}

.loyalty-info-box-item h1 {
    margin-bottom: 0px;
}

.scala-holder-numers {
    width: 100%;
    color: #fff;
    position: relative;
}

.scala-holder-number-item {
    position: absolute;
    top: 0%;
    left: 0%;
}

.scala-holder-number-item-25 {
    left: 25%;
}

.scala-holder-number-item-50 {
    left: 50%;
}

.scala-holder-number-item-75 {
    left: 75%;
}

.scala-holder-number-item-100 {
    left: 97%;
}

.shop-target-description-loyalty {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0px;
    font-weight: 600;
}

/* .alert-info-loyalty {
    margin-top: 15px;
} */

.sold-out {
    opacity: 0.5;
    background: #fafafa;
    position: relative;
}

.sold-out .productImage:after {
    content: 'RASPRODATO';
    color: #ff0427;
    position: absolute;
    top: 30%;
    left: 10px;
    font-size: 16px;
    letter-spacing: 5px;
    font-weight: 600;
    transform: rotate(-15deg);
}

.action-block-special-slider-main-slider {
    /* height: 100%; */
}

/* .loyalty-block-content .special-block-title-main {
    height: fit-content;
} */
@media (max-width: 1681px) {

    .loyalty-slider .slider-special-action-detail-res,
    .loyalty-slider .slider-special-action-detail-price {
        font-size: 14px;
    }
}

@media (max-width: 1400px) {
    .loyalty-slider .action-title-name {
        font-size: 15px;
    }

    .loyalty-slider .loyalty-block-btn {
        font-size: 12px;
    }

    .loyalty-slider .loyalty-btn-holder {
        left: 9px;
        right: 9px;
    }
}

@media (max-width: 1920px) {
    .count-clock-loyalty {
        top: 290px !important;
    }
}

@media (max-width: 1680px) {
    .count-clock-loyalty {
        top: 250px !important;
    }

    .bottom-divider {
        padding: 0px 25px;
    }

    .timer-counter-banner-holder {
        width: 60%;
    }
}

@media (max-width: 1600px) {
    .count-clock-loyalty {
        top: 220px !important;
    }

    .bottom-divider {
        padding: 0px 5px;
    }

    /* .action-block-special-banner {
        width: 450px;
        height: 450px;
    } */
}

@media (max-width: 1440px) {
    .count-clock-loyalty {
        top: 185px !important;
    }

    .bottom-divider {
        padding: 0px;
    }

    .loyalty-upper-desc {
        font-size: 22px;
    }

    .total-sum-percent-loyalty {
        font-size: 70px;
    }

    .banner-counter-holder-main {
        margin-top: 60px;
    }
}

@media (max-width: 1366px) {
    .timer-counter-banner-holder {
        width: 70%;
    }

    .loyalty-info-box-item {
        padding: 0px 30px;
    }
}

@media (max-width: 1280px) {
    .loyalty-info-box-item {
        padding: 0px 23px;
    }

    .total-sum-percent-loyalty {
        font-size: 55px;
    }

    .loyalty-info-box-item h1 {
        margin-bottom: 0px;
        font-size: 30px;
    }
}

@media (max-width: 1024px) {
    .count-clock-loyalty {
        top: 130px !important;
    }

    .FlipClock .flipUnitContainer {
        width: 90px !important;
        height: 70px !important;
    }

    .FlipClock .flipUnitContainer .lowerCard span,
    .FlipClock .flipUnitContainer .upperCard span {
        font-size: 3.4em !important;
    }

    .FlipClock .flipUnitContainer .flipCard {
        width: 90px !important;
        height: 40px !important;
    }

    .FlipClock .flipUnitContainer .flipCard span {
        font-size: 3.4em !important;
    }

    .timer-item-number {
        font-size: 60px;
    }

    .logo-image-loyalty-banner img {
        width: 250px;
    }

    .timer-holder-item-dots {
        line-height: 0.6;
    }
}

@media (max-width: 450px) {
    .count-clock-loyalty {
        top: 220px !important;
        color: #fff !important;
    }

    .FlipClock .flipUnitContainer {
        width: 75px !important;
        height: 60px !important;
    }

    .FlipClock .flipUnitContainer .lowerCard span,
    .FlipClock .flipUnitContainer .upperCard span {
        font-size: 3em !important;
    }

    .FlipClock .flipUnitContainer .flipCard span {
        font-size: 3em !important;
    }

    .FlipClock .flipUnitContainer .flipCard {
        width: 75px !important;
        height: 35px !important;
    }

    .FlipClock .flipUnitContainer .ContainerTitle {
        font-size: 14px !important;
        font-weight: 600 !important;
        top: -20px !important;
    }

    .alert-info-loyalty {
        margin-top: 15px;
    }

    .loyalty-mobile-banner {
        display: block;
    }

    .loyalty-desktop-banner {
        display: none;
    }

    .mobile-column-no-padding {
        padding: 0px;
    }

    .img-loyalty-product {
        max-width: 70px;
    }

    .bottom-divider {
        padding: 0px;
    }

    .loyalty-upper-desc {
        margin-top: 15px;
    }

    .loyalty-input-value {
        width: 60px;
    }

    .image-content-loyalty-modal {
        padding: 0px 25px;
    }

    .banner-loyalty-image-conter {
        flex-direction: column-reverse;
        margin-top: 20px;
    }

    .loyaty-holder-info-user-box {
        flex-direction: column;
    }

    .loyalty-info-box-item:nth-child(3) {
        border-bottom: 0;
    }

    .loyalty-info-box-item {
        border-right: 0;
        border-bottom: 2px solid #3d2767;
        padding-bottom: 10px;
    }

    .loyalty-info-box-item {
        padding-left: 0px;
        padding-right: 0px;
    }

    .timer-counter-banner-holder {
        width: 100%;
    }

    .timer-item-number {
        font-size: 34px;
    }

    .timer-holder-item {
        font-size: 27px;
    }

    .timer-item-text {
        font-size: 18px;
    }

    .logo-image-loyalty-banner {
        text-align: center;
    }

    .banner-counter-holder-main {
        margin-top: 10px;
        padding-bottom: 40px;
    }

    .title-counter-banner-main {
        font-size: 23px;
    }

    .shop-target-description-loyalty-second {
        margin-top: 0px;
    }

    .timer-holder-item-dots {
        line-height: 1.2;
    }

}

/* RESPONSIVE - HOMEPAGE LOYALTY SLIDER*/
@media (max-width: 1440px) and (min-width: 1366px) {
    .special-block-image-mask-name {
        font-size: 22px;
        line-height: 22px;
    }

    .action-block-special-banner {
        width: 400px;
        height: 400px;
    }

    .loyalty-slider-content .action-block-special-slider-title-main h2 {
        font-size: 22px;
    }

    .action-block-special-slider-title-main span {
        font-size: 24px;
    }

    .action-block-special-slider-item-image img {
        width: 80%;
    }

    .action-block-special-slider-item-price-content {
        font-size: 12px;
    }

    .price-content-item.price-value {
        font-size: 17px;
        line-height: 17px;
    }

    .action-block-special-slider-main-slider-item {
        height: 350px;
    }
}

@media (max-width: 1366px) and (min-width: 1280px) {
    .special-block-image-mask-name {
        font-size: 20px;
        line-height: 20px;
    }

    .action-block-special-banner {
        width: 380px;
        height: 380px;
    }

    .loyalty-slider-content .action-block-special-slider-title-main h2 {
        font-size: 22px;
    }

    .action-block-special-slider-title-main span {
        font-size: 24px;
    }

    .action-block-special-slider-item-image img {
        width: 80%;
    }

    .action-block-special-slider-item-price-content {
        font-size: 12px;
    }

    .price-content-item.price-value {
        font-size: 14px;
        line-height: 14px;
    }

    .action-block-special-slider-main-slider-item {
        height: 335px;
    }
}

@media (max-width: 1280px) and (min-width: 1024px) {
    .special-block-image-mask-name {
        font-size: 20px;
        line-height: 20px;
    }

    .action-block-special-banner {
        width: 380px;
        height: 380px;
    }

}

@media (max-width: 1024px) {
    .special-block-image-mask-name {
        font-size: 20px;
        line-height: 20px;
    }

    .action-block-special-banner {
        width: 100%;
        height: 266px;
        text-align: center;
    }

    .homepage-content-special-image-block {
        width: 100%;
        height: 266px;
        text-align: center;
    }

    .action-block-special-content {
        grid-template-columns: 100%;
        grid-template-areas: "banner"
            "slider";
    }

    .homepage-content-body-special-block {
        grid-template-columns: 100%;
        grid-template-areas: "banner"
            "slider";
    }

    .action-block-special-banner img {
        height: 100%;
        padding-top: 15px;
    }

    .loyalty-slider-content .action-block-special-slider-title-main h2 {
        font-size: 22px;
        padding-top: 15px;
    }

    .action-block-special-slider-title-main span {
        font-size: 22px;
    }
}
.product-promo-holder {
    padding: 15px;
    width: 100%;
}

.product-promo-holder .slider-item-spec {
    /* max-width: 270px; */
}

.button-export-product-detail a {
    margin-right: 30px;
    text-decoration: none;
}

.compare-page-table tbody:after {
    content: "@";
    display: block;
    line-height: 30px;
    text-indent: -99999px;
}

.modal-double-popoup {
    /* max-width: 1650px; */
    /* max-width: 1600px; */
}

.no-padding-custom {
    padding: 0px;
}
.invalid-feedback {
    display: none;
    width: 100%;
    margin-bottom: 0.25rem !important;
    font-size: 80%;
    color: #dc3545;
    margin-top: 0.25rem !important;
}

.invalid-feedback-absolute {
    position: relative !important;
}

#fullpage-wrapper .form-control {
    display: block;
    width: 100%;
    height: 38px;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.42857143;
    color: #000000;
    background-color: #dddddd;
    /* background-image: none; */
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    box-shadow: inset 0 1px 1px transparent;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    margin-bottom: 10px;
    margin-top: 5px;
}
/*---CSS---*/
@font-face {
	font-family: abuget;
	/* src: url(../../public/css/fonts/abuget/Abuget.ttf); */
}

#fullpage-wrapper body {
	font-family: 'Open Sans', sans-serif;
}

#fullpage-wrapper h1 {
	color: #5a5a5a;
	font-weight: 700;
	font-size: 3.6vmin;
	margin-bottom: 1em;
}

#fullpage-wrapper h2 {
	color: #d90202;
	font-weight: 700;
	font-size: 1.85vw;
	margin-bottom: 25px;
}

#fullpage-wrapper h3 {
	color: #d90202;
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 15px;
}

#fullpage-header header {
	position: fixed;
	z-index: 9;
	width: 100%;
}

#fullpage-header .header-container {
	background: #000;
	color: #fff;
	z-index: 9;
	width: 100%;
	padding-top: 15px;
	padding-bottom: 14px;
}

#fullpage-header .header-logo {
	position: absolute;
	top: 80px;
	right: 50px;
	display: block;
	color: #000;
}

.header-logo.white {
	color: #fff;
}

#fullpage-header .ewe-logo {
	width: 130px;
	fill: currentColor;
}

#fullpage-header .header-logo-m {
	display: block;
	color: #000;
}

#fullpage-header .ewe-logo-m {
	height: 30px;
	fill: #fff;
	margin-right: 25px;
	margin-top: -5px;
	margin-bottom: -9px;
	float: right;
}

#fullpage-header .header-menu {
	list-style: none;
	display: block;
	margin: 0;
	margin-right: 50px;
	float: right;
}

#fullpage-header .header-menu li {
	display: block;
	float: left;
	text-transform: uppercase;
	margin-left: 30px;
	font-size: 15px;
	font-weight: 300;
}

#fullpage-header .header-menu li:last-of-type {
	font-weight: 700;
}

#fullpage-header .header-menu li>a {
	font-size: 14px;
	text-transform: uppercase;
	position: relative;
	display: block;
	/* line-height: 1; */
	color: #c3c3c3;
	text-decoration: none;
}

.header-menu li>a:hover {
	color: #67cd11;
}

.page-holder {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.page-alignment-holder {
	overflow: hidden;
	height: 100%;
	position: relative;
	height: calc(100% - 50px);
	top: 50px;
	/* height: 100%; */
}

.brands-alignment-holder {
	overflow: hidden;
	height: 100%;
	position: relative;
	height: calc(100% - 50px);
	top: 50px;
	/* height: 100%; */
	padding: 20px 40px;
}

/* .map-map img {
	width: 600px;
} */
.alignment-holder-top {}

.alignment-holder-bottom {}

.sato-page {
	background-color: #ebebeb;
	background-image: url(https://resource.ewe.rs/media/intro/shadow.png);
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: 50%;
}

.map-page {
	background: #ebebeb;
}

.map-page::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	background: #bc0d36;
	transform: skewX(160deg);
	left: 50%;
}

.portfolio-page {
	background: #ebebeb;
}

.numbers-page {
	background: #ebebeb;
}





.intro-page {
	opacity: 0;
}

.intro-page-fade-in {
	transition: opacity 1000ms ease-in;
	opacity: 1;
}

.background-mask {
	display: block;
	position: relative;
	height: 100%;
	background-image: url(https://resource.ewe.rs/media/intro/background-mask.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
}

.background-city {
	position: absolute;
	display: block;
	top: 34%;
	height: 32%;
	width: 70%;
	background-image: url(https://resource.ewe.rs/media/intro/city.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.background-city-ani {
	animation-name: city;
	animation-duration: 7000ms;
	animation-fill-mode: forwards;
	animation-delay: 3500ms;
	animation-iteration-count: infinite;
}

@keyframes city {
	0 {
		transform: translateX(0);
	}

	50% {
		transform: translateX(30vw);
	}

	50% {
		transform: translateX(30vw);
	}

	100% {
		transform: translateX(0);
	}
}

.home-text {
	position: absolute;
	display: block;
	overflow: hidden;
	font-size: 2.2vw;
	font-weight: 300;
	white-space: nowrap;
	text-transform: uppercase;
	top: 70%;
	left: 0;
	width: 100%;
	letter-spacing: 3px;
	text-align: center;
}

.home-text b {
	font-weight: 600;
}

.home-text-cover {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	content: '';
	background: #fff;
}

.home-text-ani {
	animation-name: typing;
	animation-duration: 5000ms;
	animation-fill-mode: forwards;
	animation-delay: 1000ms;
}

@keyframes typing {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(100%);
	}
}

.home-scroll {
	position: absolute;
	width: 50px;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	cursor: pointer;
}

.home-scroll-text {
	text-align: center;
	font-size: 10px;
	color: #666;
	margin-bottom: 10px;
}

.home-scroll-line {
	position: relative;
	width: 1px;
	height: 40px;
	left: 50%;
	background-color: #999;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.home-scroll-line::after {
	content: '';
	display: block;
	width: 3px;
	height: 100%;
	position: absolute;
	left: -1px;
	bottom: 0;
	transform: scaleY(0);
	transform-origin: left bottom;
	background-color: #666;
	transition: transform .6s cubic-bezier(.52, .08, .18, 1), opacity .6s cubic-bezier(.52, .08, .18, 1);
	opacity: 0;
}

.home-scroll:hover .home-scroll-line::after {
	transform: scaleY(1);
	opacity: 1;
}

.sato-page-cover {
	position: absolute;
	width: 230%;
	height: 100%;
	top: 0;
	transform: skewX(30deg) translateX(-65vw);
}

.cover-red {
	background-color: #bc0d36;
}

.cover-grey {
	background-color: #e7e7e7;
}

.cover-white {
	background-color: #fff;
}

.cover-red-ani {
	animation-name: sato-cover;
	animation-duration: 1200ms;
	animation-fill-mode: forwards;
}

.cover-grey-ani {
	animation-name: sato-cover;
	animation-duration: 1200ms;
	animation-fill-mode: forwards;
	animation-delay: 400ms;
}

.cover-white-ani {
	animation-name: sato-cover;
	animation-duration: 1200ms;
	animation-fill-mode: forwards;
	animation-delay: 500ms;
}

@keyframes sato-cover {
	from {
		transform: skewX(30deg) translateX(-65vw);
	}

	to {
		transform: skewX(30deg) translateX(160vw);
	}
}

.sato-holder {
	position: absolute;
	bottom: 0;
	height: 85%;
	width: 45%;
	background-image: url(https://resource.ewe.rs/media/intro/roger.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center bottom;
	transform: translateX(-45vw);
}

.sato-holder-ani {
	animation-name: sato-holder;
	animation-duration: 750ms;
	animation-fill-mode: forwards;
	animation-delay: 750ms;
}

@keyframes sato-holder {
	from {
		transform: translateX(-45vw);
	}

	to {
		transform: none;
	}
}

.sato-text-holder {
	position: absolute;
	top: 50%;
	left: 9%;
	width: 45%;
	transform: translateY(-50%) translateX(36vw);
	opacity: 0;
}

.sato-text-holder h2 {
	color: #5a5a5a !important;
	font-weight: 700 !important;
	font-size: 3.6vmin !important;
	margin-bottom: 1em !important;
}

.sato-text-holder-ani {
	animation-name: sato-text;
	animation-duration: 750ms;
	animation-delay: 1250ms;
	animation-fill-mode: forwards;
}

@keyframes sato-text {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.sato-text-holder p {
	font-size: 1.35vw;
	line-height: 1.4;
	font-weight: 300;
}

.partner-signature {
	margin-top: 20px;
	line-height: 1;
	float: right;
}

.partner-signature div:first-of-type {
	font-family: abuget, times;
	font-size: 8vmin;
	text-align: center;
}

.partner-signature div:last-of-type {
	font-size: 1.4vmin;
	text-align: center;
}

.signature {
	margin-top: 10px;
	line-height: 1;
	float: right;
}

.signature div:first-of-type {
	font-size: 1.4vmin;
	line-height: 5vmin;
	float: left;
}

.signature div:last-of-type {
	font-family: abuget, times;
	font-size: 6vmin;
	margin-left: 20px;
	float: right;
}

.page-3 {
	background: #fafafa;
	height: 100%;
	width: 100%;
	padding-top: 4%;
	padding-bottom: 4%;
	padding-left: 4%;
	padding-right: 4%;
}

.about-item {
	position: relative;
	height: 33.33333333%;
	height: 25%;
	width: calc(100% - 30px);
	margin-left: 15px;
	margin-right: 15px;
	padding: 20px;
	float: left;
	/*	border-bottom: 1px solid #959595; */
	transform: none;
	box-shadow: 1px 2px 10px transparent;
	transition: transform 250ms, box-shadow 250ms;
}

.about-item:hover {
	transform: scale(1.01);
	box-shadow: 1px 2px 10px #ccc;
}

.about-item-button-holder {
	position: relative;
	height: 33.33333333%;
	width: calc(100% - 30px);
	margin-left: 15px;
	margin-right: 15px;
	padding: 20px;
	float: left;
	transform: none;
}

.test .about-item {
	position: relative;
	height: 33.33333333%;
	width: 100%;
	float: left;
	/*	border-bottom: 1px solid #959595; */
	transform: none;
	box-shadow: 1px 2px 10px transparent;
	transition: transform 250ms, box-shadow 250ms;
}

.test .about-item:hover {
	transform: translateX(15px);
	box-shadow: 1px 2px 10px #ccc;
}

.about-logo-holder {
	position: relative;
	float: left;
	width: 10%;
	height: 100%;
}

.about-logo-holder div {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	padding: 10px;
}

.about-text-holder {
	float: left;
	width: 80%;
	position: absolute;
	left: 15%;
	top: 50%;
	transform: translateY(-50%);
}

.about-text-holder p {
	font-size: 15px;
	margin-top: auto;
	font-weight: inherit;
	letter-spacing: inherit;
}





.map-text {
	position: absolute;
	width: 40%;
	top: 50%;
	left: 5%;
	transform: translateY(-50%);
	/*	transform: translate(-40vw, -50%);	*/
	opacity: 0;
}

.map-text-ani {
	animation-name: map-text;
	animation-duration: 750ms;
	animation-delay: 750ms;
	animation-fill-mode: forwards;
}

@keyframes map-text {

	/*	from {transform: translate(-40vw, -50%);}
	to {transform: translate(10vw, -50%);}	*/
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.map-text p {
	font-size: 2.2vmin;
	line-height: 1.4;
	font-weight: 300;
	margin-bottom: 1em;
}

.map-text img {
	max-width: 85%;
}

.map-map {
	position: absolute;
	width: 28%;
	left: 60%;
	top: 50%;
	transform: translateY(-50%);
}

.map-gif::after {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: silver;
	top: 0;
	left: 0;
	background-image: url(https://resource.ewe.rs/media/intro/mapa-tackice7.gif);
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

.map-map-ani {
	animation-name: map-map;
	animation-duration: 700ms;
	animation-delay: 2100ms;
	animation-fill-mode: forwards;
}

@keyframes map-map {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.portfolio-circle-holder {
	position: absolute;
	width: 35%;
	left: 12%;
	top: 50%;
	transform: translateY(-50%);
}

.portfolio-title {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 3.8vmin;
	transform: translate(-50%, -50%);
	/* opacity: 0; */
	transition: opacity 750ms ease;
}

.visible {
	opacity: 1;
}

.portfolio-brands-holder {
	position: absolute;
	width: 30%;
	left: 55%;
	height: 100%;
}

.portfolio-brands-holder img {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.logos {
	opacity: 0;
	transition: opacity 750ms ease;
}

.opacity1 {
	opacity: 1;
}

.numbers-text-holder {
	position: absolute;
	width: 50%;
	height: 100%;
}

.numbers-text-holder-m {
	position: absolute;
	width: 100%;
	height: 50%;
}

.numbers-text-m {
	position: absolute;
	width: 80%;
	top: 50%;
	transform: translateY(-50%) translateX(-80vw);
}

.numbers-text-m p {
	text-align: right;
	font-size: 20px;
}

.numbers-text-holder::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100vw;
	background: #ebebeb;
	transform: skewX(160deg);
	left: -100%;
}

.numbers-text {
	position: absolute;
	width: 70%;
	top: 50%;
	left: 10%;
	transform: translateY(-50%);
	opacity: 0;
	/*    transform: translateY(-50%) translateX(-60vw);	*/
}

.numbers-text-ani {
	animation-name: numbers-text;
	animation-delay: 750ms;
	animation-duration: 750ms;
	animation-fill-mode: forwards;
}

@keyframes numbers-text {

	/*	from {transform: translateY(-50%) translateX(-60vw);}
	to {transform: translateY(-50%) translateX(10vw);}	*/
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.numbers-text p {
	font-size: 1.1vw;
	line-height: 1.4;
	font-weight: 300;
	margin-bottom: 25px;
}

.numbers-numbers-holder {
	position: absolute;
	height: 100%;
	width: 65%;
	background-image: url(https://resource.ewe.rs/media/intro/numbers-background.jpg);
	background-position: right center;
	top: 0;
	right: 0;
}

.numbers-numbers {
	font-size: 40px;
	color: #fff;
	position: absolute;
	top: 50%;
	left: 60%;
	transform: translate(-50%, -50%);
	text-align: center;
	opacity: .8;
}

.numbers-holder-mask {
	font-size: 180px;
	font-weight: 700;
	line-height: 200px;
	height: 200px;
	overflow: hidden;
}

.numbers-holder-content {
	white-space: nowrap;
	transform: translateY(200px);
}

.numbers-holder-content-ani {
	animation-name: brojici;
	animation-duration: 3000ms;
	animation-delay: 500ms;
	animation-fill-mode: forwards;
}

@keyframes brojici {
	0% {
		transform: translateY(200px);
	}

	10% {
		transform: translateY(0px);
	}

	45% {
		transform: translateY(0px);
	}

	55% {
		transform: translateY(-200px);
	}

	90% {
		transform: translateY(-200px);
	}

	100% {
		transform: translateY(-400px);
	}
}

.text-holder-mask {
	font-size: 30px;
	font-weight: 300;
	line-height: 40px;
	height: 40px;
	overflow: hidden;
}

.text-holder-content {
	white-space: nowrap;
	transform: translateY(40px);
}

.text-holder-content-ani {
	animation-name: slovca;
	animation-duration: 3000ms;
	animation-delay: 1000ms;
	animation-fill-mode: forwards;
}

@keyframes slovca {
	0% {
		transform: translateY(40px);
	}

	10% {
		transform: translateY(0px);
	}

	45% {
		transform: translateY(0px);
	}

	55% {
		transform: translateY(-40px);
	}

	90% {
		transform: translateY(-40px);
	}

	100% {
		transform: translateY(-80px);
	}
}


.contact-page {
	background: #ebebeb;
}

.contact-info-holder {
	position: absolute;
	width: 30%;
	top: 50%;
	left: 5%;
	transform: translateY(-50%);
}

.contact-info-holder p {
	font-size: 1.2vw;
	font-weight: 700;
	color: #5a5a5a;
	padding-bottom: 30px;
	border-bottom: 2px solid #9d9d9d;
}

.contact-info-item {
	float: left;
	width: 100%;
	font-size: 1vw;
	background: #fff;
	margin-top: 40px;
}

.contact-info-item:last-of-type span {
	/* display: inline-block; */
	/* width: 100%; */
	/* text-align: center; */
}

.contact-info-left {
	background: #af0c32;
	color: #fff;
	float: left;
	width: 58%;
	padding: 20px;
	font-weight: 600;
}

.contact-info-right {
	float: left;
	width: 42%;
	padding: 20px;
}

.contact-info-right span {
	display: inline-block;
	width: 100%;
	text-align: center;
	font-weight: 300;
}


.contact-form-holder {
	position: absolute;
	width: 30%;
	top: 50%;
	left: 40%;
	transform: translateY(-50%);
	padding-left: 4%;
	border-left: 1px solid #898989;
}

.contact-form-holder p {
	font-weight: 300;
	color: #333;
	font-size: 16px;
}


.contact-map-holder {
	position: absolute;
	width: 33%;
	height: 100%;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.contact-map-cover {
	position: absolute;
	background-color: #ebebeb;
	height: 100%;
	width: 20%;
	transform: skewX(163deg);
	right: 25%;
	border-right: 6px solid #af0c32;

}


/*---FULL PAGE MOD START---*/
#fp-nav {
	z-index: 1;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
	height: 6px;
	width: 6px;
	background: #cbcbcb;
	margin: -3px 0 0 -3px;
}

/*---FULL PAGE MOD END---*/

/*---SVG PORTFOLIO START---*/

.element {
	cursor: pointer;
	fill: #afb1b4;
	fill-opacity: 1;
	fill-rule: nonzero;
	stroke: none;
	transition: fill 500ms ease;
}

.element:hover {
	fill: #ed1c24;
}

.mobile:hover .mob {
	color: #bc0d36;
}

/*---SVG PORTFOLIO END---*/

.btn-sato {
	color: #fff;
	background-color: #dc3231;
	border-color: #dc3231;
	border-radius: 0;
	font-size: 16px;
	background-image: url(https://resource.ewe.rs/media/intro/right-arrow.png);
	background-repeat: no-repeat;
	background-position: 92% 50%;
	padding: 8px 70px 8px 20px;
	margin-top: 30px;
}

.btn-sato:hover {
	color: #fff;
	background-color: #bc0d36;
	border-color: #bc0d36;
}

.btn-sato:active,
.btn-sato.active {
	background-image: url(https://resource.ewe.rs/media/intro/right-arrow.png);
	outline: 0;
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}




#fullpage-wrapper .form-control {
	display: block;
	width: 100%;
	height: 38px;
	padding: 6px 12px;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.42857143;
	color: #000000;
	background-color: #dddddd;
	/* background-image: none; */
	border: 1px solid #d7d7d7;
	border-radius: 6px;
	box-shadow: inset 0 1px 1px transparent;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

	margin-bottom: 10px;
}

#fullpage-wrapper textarea.form-control {
	height: 124px;
}

#fullpage-wrapper .form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #2a2a2a;
}

#fullpage-wrapper .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #2a2a2a;
}

#fullpage-wrapper .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #2a2a2a;
}

#fullpage-wrapper .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #2a2a2a;
}

#fullpage-wrapper .btn-form {
	color: #fff;
	background-color: #898989;
	border-color: #898989;
	font-size: 16px;
	font-weight: 300;
	border-radius: 6px;
	padding: 5px 20px;
}

#fullpage-wrapper .btn-form:hover {
	color: #fff;
	background-color: #757575;
	border-color: #757575;
}

#fullpage-wrapper .button-next {
	width: 44px;
	height: 44px;
	padding: 10px;
	border-radius: 50%;
	position: absolute;
	bottom: 40px;
	right: 50px;
	cursor: pointer;
	border: 2px solid;
	border-color: #dc0032;
	color: #dc0032;
	opacity: 1;
	transition: opacity 1s;
}

.button-next.white {
	border-color: #fff;
	color: #fff;
}

.last-slide {
	opacity: 0;
	pointer-events: none;
}

.button-next svg {
	fill: currentColor;
	;
}

.page-top {
	position: absolute;
	bottom: 30px;
	left: 50%;
	cursor: pointer;
	transform: translateX(-50%);
}

.page-top-text {
	text-align: center;
	font-size: 10px;
	color: #000;
	margin-top: 8px;
}

.page-top-arrow {
	height: 16px;
	width: 60px;
	color: #dc0032;
	transition: transform .45s cubic-bezier(.37, .16, .12, 1);
}

.page-top-arrow svg {
	fill: currentColor;
}

.page-top:hover .page-top-arrow {
	transform: translateY(-6px);
}





.up-in {
	transform: translate(25%, -100%) scale(.75);
}

.up-in-ani {
	animation-name: up-in;
	animation-duration: 1200ms;
	animation-delay: 0ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}

@keyframes up-in {
	0% {
		transform: translate(25%, -100%) scale(.75);
	}

	60% {
		transform: translate(0, 0) scale(.75);
	}

	100% {
		transform: translate(0, 0) scale(1);
	}
}

.up-out {
	transform: translate(0, 0) scale(1);
}

.up-out-ani {
	animation-name: up-out;
	animation-duration: 1200ms;
	animation-delay: 0ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}

@keyframes up-out {
	0% {
		transform: translate(0, 0) scale(1);
	}

	25% {
		transform: translate(-25%, 0) scale(.75);
	}

	100% {
		transform: translate(-25%, 100%) scale(.75);
	}
}

.down-in {
	transform: translate(-25%, 100%) scale(.75);
}

.down-in-ani {
	animation-name: down-in;
	animation-duration: 1200ms;
	animation-delay: 0ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}

@keyframes down-in {
	0% {
		transform: translate(-25%, 100%) scale(.75);
	}

	60% {
		transform: translate(0, 0) scale(.75);
	}

	100% {
		transform: translate(0, 0) scale(1);
	}
}

.down-out {
	transform: translate(0, 0) scale(1);
}

.down-out-ani {
	animation-name: down-out;
	animation-duration: 1200ms;
	animation-delay: 0ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}

@keyframes down-out {
	0% {
		transform: translate(0, 0) scale(1);
	}

	25% {
		transform: translate(25%, 0) scale(.75);
	}

	100% {
		transform: translate(25%, 100%) scale(.75);
	}
}


/*---MOBILE MENU START---*/
.hamburger-menu {
	height: 20px;
	width: 20px;
	margin-left: 20px;
	float: left;
	background-position: center;
	background-size: 100%;
	background-color: transparent;
	border: none;
	background-image: url(https://resource.ewe.rs/media/intro/menu.png);
}

.hamburger-menu:focus {
	outline: none;
}

.cross-menu {
	background-image: url(https://resource.ewe.rs/media/intro/cross.png);
}

.mobile-menu {
	display: block;
	position: absolute;
	background: red;

	display: block;
	position: fixed;
	width: 100%;
	top: 50px;
	left: 0px;
	height: calc(100vh - 50px);
	background: #fff;
	z-index: 199;
	padding: 20px;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
}

.mobile-menu ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.mobile-menu ul>li>a {
	display: inline-block;
	color: #666;
	width: 100%;
	line-height: 1.42857143;
	vertical-align: middle;
	padding: 10px 20px;
	border-bottom: 1px dotted #ccc;
}

/*---MOBILE MENU END---*/

.button-holder {
	position: absolute;
	bottom: 20px;
	right: 40px;
}

.section {
	background-color: #fff !important;
}

@media (max-width: 1531px) {
	.about-small-text-item {
		height: 34% !important;
	}

	.about-big-text-item {
		height: 23% !important;
	}

	.brands-alignment-holder .signature {
		margin-top: 0;
	}

	.section {
		background-color: #fff !important;
	}
}

@media (max-width: 1189px) {
	.about-small-text-item {
		height: 34% !important;
	}

	.section {
		background-color: #fff !important;
	}
}

@media (max-width: 1069px) {
	.about-small-text-item {
		height: 35% !important;
	}

	.brands-alignment-holder .about-text-holder p {
		margin-bottom: 0;
	}

	.section {
		background-color: #fff !important;
	}
}

@media (min-width: 991px) {
	.map-text .text-center {
		text-align: unset !important;
	}
}

@media (max-width: 991px) {
	.section {
		background-color: #fff !important;
	}

	.background-mask {
		display: block;
		position: relative;
		height: 100%;
		background-image: url(https://resource.ewe.rs/media/intro/background-mask-mobile.png);
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
	}

	.background-city {
		position: absolute;
		display: block;
		top: 40%;
		height: 20%;
		width: 80%;
		background-image: url(https://resource.ewe.rs/media/intro/city.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	@keyframes city {
		0 {
			transform: translateX(0);
		}

		50% {
			transform: translateX(20vw);
		}

		50% {
			transform: translateX(20vw);
		}

		100% {
			transform: translateX(0);
		}
	}

	.numbers-numbers-holder {
		position: absolute;
		height: 100%;
		width: 100%;
		background-image: url(https://resource.ewe.rs/media/intro/numbers-background.jpg);
		background-position: right center;
		top: 0;
		left: 0;
	}

	.numbers-text-holder {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	.numbers-text {
		position: absolute;
		width: 90%;
		top: 50%;
		left: 5%;
		transform: translateY(-50%);
		opacity: 0;
	}

	.numbers-numbers {
		font-size: 40px;
		color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		opacity: .8;
	}

	/* .numbers-text p {
		font-size: 2.2vmin;
		line-height: 1.4;
		font-weight: 300;
		margin-bottom: 1em;
	} */
	.sato-holder {
		position: absolute;
		bottom: 0;
		height: 45%;
		width: 100%;
		background-image: url(https://resource.ewe.rs/media/intro/roger.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center bottom;
		transform: translateX(-45vw);
	}

	/* .sato-text-holder {
	    position: absolute;
		height: 50%;
	    width: 80%;
		top: 30px;
		left: 10%;
	    transform: translateY(-50%) translateX(45vw);
		transform: none;
	    opacity: 0;
	}
	.sato-text-holder p {
	    font-size: 3.5vmin;
	    line-height: 1.4;
	    font-weight: 300;
	} */
	/* .portfolio-circle-holder {
	    position: absolute;
	    width: 80%;
	    height: 35%;
	    left: 10%;
	    top: 5%;
	    transform: none;
	} */
	/* .portfolio-brands-holder {
	    position: absolute;
	    width: 70%;
	    left: 25%;
	    height: 60%;
		top: 40%;
	} */

	.img-responsive {
		max-height: 100%;
	}

	.map-page:before {
		background: transparent;
	}

	.map-page .alignment-holder-bottom {
		background-color: #bc0d36;
	}

	.map-text {
		position: absolute;
		width: 90%;
		top: 50%;
		left: 5%;
		transform: translateY(-50%);
		/* transform: translate(-40vw, -50%); */
		opacity: 0;
	}

	.map-text img {
		max-width: 100%;
	}

	.map-map {
		position: absolute;
		width: 100%;
		height: 90%;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}


	/* .alignment-holder-top {
		position: absolute;
		height: 60%;
		width: 100%;
		top: 0;
		left: 0;
	}
	.alignment-holder-bottom {
		position: absolute;
		height: 40%;
		width: 100%;
		bottom: 0;
		left: 0;
	} */
	.contact-info-holder {
		position: absolute;
		width: 90%;
		top: 45%;
		left: 5%;
		transform: translateY(-50%);
	}

	.contact-form-holder {
		position: absolute;
		width: 90%;
		top: 35%;
		left: 5%;
		transform: translateY(-50%);
		padding-left: 0;
		border: none;
	}

	.contact-map-holder {
		display: none;
	}

	.contact-map-cover {
		display: none;
	}

	#fullpage-wrapper .form-control {
		display: block;
		width: 100%;
		height: initial;
		padding: 4px 10px;
		font-size: 2vmax;
		font-weight: 300;
		line-height: 1.42857143;
		color: rgb(0, 0, 0);
		background-color: #d7d7d7;
		background-image: none;
		border: 1px solid #d7d7d7;
		border-radius: 6px;
		box-shadow: inset 0 1px 1px transparent;
		transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		margin-bottom: 10px;
	}

	#fullpage-wrapper textarea.form-control {
		height: 48px;
	}

	.btn-form {
		color: #fff;
		background-color: #898989;
		border-color: #898989;
		font-size: 2vmax;
		font-weight: 300;
		border-radius: 6px;
		padding: 2px 16px 4px;
	}

	.contact-info-item {
		float: left;
		width: 100%;
		font-size: 3vmin;
		background: #fff;
		margin-top: 12px;
	}

	.contact-info-left,
	.contact-info-right {
		padding: 10px;
	}

	.contact-info-holder p {
		font-size: 2vmax;
		padding-bottom: 4px;
	}

	.contact-form-holder p {
		font-weight: 300;
		color: #333;
		font-size: 3vmin;
		margin-bottom: 4px;
	}

	.partner-signature div:first-of-type {
		font-size: 10vmin;
	}

	.partner-signature div:last-of-type {
		font-size: 3vmin;
	}

	.btn-sato {
		color: #fff;
		background-color: #dc3231;
		border-color: #dc3231;
		border-radius: 0;
		font-size: 17px;
		background-image: url(https://resource.ewe.rs/media/intro/right-arrow.png);
		background-repeat: no-repeat;
		background-position: 92% 50%;
		padding: 8px 70px 8px 20px;
		margin-top: 20px;
	}

	.button-next {
		bottom: 25px;
		right: 25px;
	}

	.about-text-holder p {
		font-size: 13px;
	}

	.brands-alignment-holder {
		padding: 10px;
	}

	.about-small-text-item {
		height: 32% !important;
	}

	.about-big-text-item {
		height: 23%;
	}

	.about-item {
		position: relative;
		height: 33.33333333%;
		height: 25%;
		width: 100%;
		padding: 0;
		margin: 0;
		float: left;
		transform: none;
		box-shadow: 1px 2px 10px transparent;
		transition: transform 250ms, box-shadow 250ms;
	}

	.about-logo-holder {
		position: relative;
		float: left;
		width: 15%;
		left: 2%;
		height: 100%;
	}

	.about-text-holder {
		float: left;
		width: 78%;
		position: absolute;
		left: 20%;
		top: 50%;
		transform: translateY(-50%);
	}

	.signature {
		margin-top: 0;
		line-height: 1;
		text-align: center;
		float: none;
	}

	.signature div:first-of-type {
		font-size: 13px;
		line-height: 1.5;
		float: none;
	}

	.signature div:last-of-type {
		font-family: abuget, times;
		font-size: 40px;
		margin-left: 0;
		float: none;
	}

	.home-text {
		top: 62%;
	}

	.about-logo-holder div {
		padding: 0;
	}
}

@media (max-width: 784px) {
	.about-small-text-item {
		height: 33% !important;
	}

	.signature div:last-of-type {
		font-size: 30px;
	}

	.section {
		background-color: #fff !important;
	}
}

@media (max-width: 991px) and (min-width: 451px) {
	.numbers-text p {
		font-size: 2.2vmin;
		line-height: 1.4;
		font-weight: 300;
		margin-bottom: 1em;
	}

	.portfolio-circle-holder {
		position: absolute;
		width: 80%;
		height: 35%;
		left: 10%;
		top: 5%;
		transform: none;
	}

	.portfolio-brands-holder {
		position: absolute;
		width: 70%;
		left: 25%;
		height: 60%;
		top: 40%;
	}

	.sato-text-holder {
		position: absolute;
		height: 50%;
		width: 80%;
		top: 30px;
		left: 10%;
		transform: translateY(-50%) translateX(45vw);
		transform: none;
		opacity: 0;
	}

	.sato-text-holder p {
		font-size: 3.5vmin;
		line-height: 1.4;
		font-weight: 300;
	}

	.alignment-holder-top {
		position: relative;
		height: 60%;
		width: 100%;
		top: 0;
		left: 0;
	}

	.alignment-holder-bottom {
		position: absolute;
		height: 40%;
		width: 100%;
		bottom: 0;
		left: 0;
	}
}

@media (max-width: 375px) {
	.contact-form-holder {
		top: 35%!important;
	}
	.contact-info-data {
		display: none;
	}
}
@media (max-width: 450px) {
	.section {
		background-color: #fff !important;
	}

	.about-small-text-item {
		height: 35% !important;
	}

	.about-big-text-item {
		height: 21%;
	}

	.about-text-holder p {
		font-size: 8px !important;
	}

	.signature div:last-of-type,
	.beko-signature {
		font-size: 22px !important;
	}

	.signature div:first-of-type {
		font-size: 9px;
	}

	.signature {
		margin-top: 5px !important;
	}

	#fullpage-wrapper h1 {
		color: #5a5a5a;
		font-weight: 700;
		font-size: 18px;
		margin-bottom: 1em;
	}

	.numbers-text {
		top: 53%;
	}

	.numbers-text p {
		font-size: 13px;
		line-height: 1.3;
		font-weight: 300;
		margin-bottom: 1em;
	}

	.numbers-holder-mask {
		font-size: 100px;
	}

	.text-holder-mask {
		font-size: 25px;
	}

	.map-text {
		top: 63%;
	}

	.map-text p {
		font-size: 13px;
		line-height: 1.3;
	}

	.portfolio-circle-holder {
		position: absolute;
		width: 80%;
		height: 35%;
		left: 10%;
		top: 5%;
		transform: none;
	}

	.portfolio-brands-holder {
		position: absolute;
		width: 70%;
		left: 15%;
		height: 60%;
		top: 40%;
	}

	.sato-text-holder {
		position: absolute;
		height: 50%;
		width: 80%;
		top: 50px;
		left: 10%;
		transform: translateY(-50%) translateX(45vw);
		transform: none;
		opacity: 0;
	}

	.sato-text-holder p {
		font-size: 15px;
		line-height: 1.3;
		font-weight: 300;
	}

	.alignment-holder-top {
		position: relative;
		height: 50%;
		width: 100%;
		top: 0;
		left: 0;
	}

	.alignment-holder-bottom {
		position: absolute;
		height: 50%;
		width: 100%;
		bottom: 0;
		left: 0;
	}

	.map-holder-img {
		height: 40%;
	}

	.btn-sato {
		font-size: 12px;
	}

	.partner-signature div:first-of-type {
		font-size: 50px;
	}

	.partner-signature div:last-of-type {
		font-size: 12px;
	}

	.contact-form-holder p {
		font-size: 13px;
	}

	.contact-info-holder {
		position: relative;
		width: 90%;
		margin-top: 70px;
		left: 5%;
		/* transform: translateY(-50%); */
	}

	.contact-form-holder {
		position: relative;
		width: 90%;
		top: 25%;
		left: 5%;
		/* transform: translateY(-50%); */
		padding-left: 0;
		border: none;
	}

}

@media (min-width: 768px) {
	.header-container {
		padding-top: 18px;
		padding-bottom: 18px;
	}
}

.cookie-holder-block {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, .85);
	color: #fff;
	padding-top: 35px;
	padding-bottom: 35px;
	z-index: 9;
	transform: translateY(100%);
	transition-duration: 1s;
}

.cookie-holder-block p {
	margin: 0;
	line-height: 24px;
}

.cookie-holder-block.show {
	transform: translateY(0);
}

.language-ewe {
	color: #67cd11;
	font-size: 14px;
	/* line-height: 1; */
	position: relative;
	bottom: 2px;
}

.language-ewe>a {
	font-weight: 300;
	padding: 0px;
	color: #c3c3c3;
	font-size: 14px;

}

.language-ewe>a:hover {
	color: #67cd11;
	text-decoration: underline;

}

.mobile-menu ul>li>.language-ewe {
	display: inline-block;
	color: #666;
	width: 100%;
	line-height: 1.42857143;
	vertical-align: middle;
	padding: 10px 20px;
	border-bottom: 1px dotted #ccc;

}

.mobile-menu ul>li>.language-ewe a:hover {
	color: #666;
}
.invalid-feedback-absolute1 {
    /* position: absolute !important; */
}

.delivery-form-input-margin {
    margin-bottom: 25px;
}
.Wishlist_wish-list-table__1-LDq .Wishlist_editing-cart-items-table__hgSMq {
    overflow: visible;
    width: 100%;
    max-height: 100%;
}
.Wishlist_wish-list-table__1-LDq table.Wishlist_editing-cart-table__3jHIR thead th {
    background: #fff;
    text-align: left;
    padding: 8px;
    font-size: 14px;
    border-top: none;
    border-bottom: 0;
    text-transform: uppercase;
}
.Wishlist_wish-list-row-table__vd8H1 {
    font-size: 15px;
    position: relative;

}
.Wishlist_wishlist-page__310uH table.Wishlist_editing-cart-table__3jHIR tbody td {
    padding: 10px 5px;
    vertical-align: middle;
}
.Wishlist_wishlist-page__310uH .Wishlist_product-name__z5z3D {
    max-width: 100%;
    min-width: 195px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.Wishlist_wishlist-page__310uH .Wishlist_price-value__CfyPm {
    white-space: nowrap;
}
.Wishlist_wishlist-buttons__3MSan {
    width: 300px;
}
.Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
}
.Wishlist_wishlist-page__310uH input {
    padding: 3px 20px;
}
.Wishlist_wishlist-page__310uH .Wishlist_catalog-item-add-button__VJAqo {
    width: 100%;
    margin-left: 10px;
}
.Wishlist_wishlist-page__310uH .Wishlist_button-div-item-qty__3FdUA .Wishlist_plus-item-qty__2GNsa {
    top: -1px;
}
.Wishlist_remove-wish-list-item__2Qw-K {
    top: 25px;
}
.Wishlist_wishlist-img__3Ou6w {
    width: 63px;
}
.Wishlist_wishlist-page__310uH td.Wishlist_main-cart-item-name__3MTNa.Wishlist_main-cart-item-value__3FiGs {
    font-weight: 600;
    /* line-height: 21; */
}
.Wishlist_wishlist-page__310uH .Wishlist_remove-wish-list-item__2Qw-K {
    position: absolute;
    color: #eb0e4c;
    font-size: 15px;
    top: 25px;
    opacity: .9;
    cursor: pointer;
}
.Wishlist_wish-list-remove-all__1naKW button {
    width: 250px;
    float: right;
}

@media (max-width: 1983px) and (min-width: 1603px) {
    .Wishlist_wishlist-page__310uH .Wishlist_button-div-item-qty__3FdUA input.Wishlist_input-qty-add-item__2EOO4{
        width: 100%;
    }
}
@media (max-width: 1559px) and (min-width: 1400px) {
    .Wishlist_wishlist-buttons__3MSan {
        width: 250px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J {
        justify-content: normal;
        font-size: 14px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J .Wishlist_catalog-item-add-button__VJAqo {
        width: 150px;
    }
    .Wishlist_wishlist-page__310uH input {
        width: 80px;
    }
}
@media (max-width: 1399px) and (min-width: 1280px) {
    .Wishlist_wishlist-page__310uH .Wishlist_container-catalog-content__ThSHV {
        padding: 30px 20px;
    }
    .Wishlist_wish-list-row-table__vd8H1 {
        font-size: 14px;
    }
    .Wishlist_wishlist-buttons__3MSan {
        width: 250px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J {
        justify-content: normal;
    }
    .Wishlist_wishlist-page__310uH input {
        width: 80px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J .Wishlist_catalog-item-add-button__VJAqo {
        width: 150px;
    }
}
@media (max-width: 1279px) and (min-width: 1166px) {
    .Wishlist_wishlist-page__310uH .Wishlist_container-catalog-content__ThSHV {
        padding: 30px 10px;
    }
    .Wishlist_wish-list-row-table__vd8H1 {
        font-size: 14px;
    }
    .Wishlist_wishlist-img__3Ou6w {
        width: 55px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_product-name__z5z3D {
        min-width: 255px;
    }
    .Wishlist_wishlist-buttons__3MSan {
        width: 100%;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J {
        justify-content: normal;
    }
    .Wishlist_wishlist-page__310uH input {
        width: 70px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J .Wishlist_catalog-item-add-button__VJAqo {
        width: 130px;
    }
    .Wishlist_remove-wish-list-item__2Qw-K {
        top: 22px;
    }
}
@media (max-width: 1165px) and (min-width: 1021px) {
    .Wishlist_wishlist-page__310uH {
        padding: 30px 10px;
        overflow: hidden;
    }
    .Wishlist_wish-list-table__1-LDq .Wishlist_editing-cart-items-table__hgSMq {
        overflow: visible;
        overflow-x: scroll;
        height: calc( 100vh - 130px );
    }
    .Wishlist_wishlist-page__310uH table.Wishlist_editing-cart-table__3jHIR {
        border-collapse: collapse;
        max-width: 100%;
    }
    .Wishlist_wishlist-page__310uH table.Wishlist_editing-cart-table__3jHIR thead {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
    }
    .Wishlist_wish-list-row-table__vd8H1 {
        font-size: 14px;
    }
   .Wishlist_wishlist-img__3Ou6w {
        width: 55px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_product-name__z5z3D {
        min-width: 255px;
    }
    .Wishlist_wishlist-buttons__3MSan {
        width: 100%;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J {
        justify-content: normal;
    }
    .Wishlist_wishlist-page__310uH input {
        width: 70px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J .Wishlist_catalog-item-add-button__VJAqo {
        width: 130px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_remove-wish-list-item__2Qw-K {
        top: 22px;
    }
}
@media (max-width: 1020px) and (min-width: 901px) {
    .Wishlist_wishlist-page__310uH .Wishlist_product-name__z5z3D {
        min-width: 255px;
    }
    .Wishlist_wishlist-buttons__3MSan {
        width: 240px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J {
        justify-content: normal;
    }
    .Wishlist_wishlist-page__310uH input {
        width: 90px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J .Wishlist_catalog-item-add-button__VJAqo {
        width: 130px;
    }

}
@media (max-width: 900px) {
    .Wishlist_wishlist-page__310uH {
        padding: 30px 10px;
        margin-top: 85px;
    }
    .Wishlist_wish-list-row-table__vd8H1 {
        font-size: 14px;
    }
    .Wishlist_wish-list-table__1-LDq .Wishlist_editing-cart-items-table__hgSMq {
        overflow: visible;
        overflow-x: scroll;
        height: calc( 100vh - 210px );
        margin-top: 10px;
    }
    .Wishlist_wishlist-page__310uH table.Wishlist_editing-cart-table__3jHIR {
        border-collapse: collapse;
        max-width: 100%;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_product-name__z5z3D {
        min-width: 255px;
    }
    .Wishlist_wishlist-buttons__3MSan {
        width: 220px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J {
        justify-content: normal;
    }
    .Wishlist_wishlist-page__310uH input {
        width: 70px;
    }
    .Wishlist_wishlist-page__310uH .Wishlist_catalog-item-buttons__2hk6J .Wishlist_catalog-item-add-button__VJAqo {
        width: 130px;
    }
    .Wishlist_wishlist-page__310uH {
        padding: 0px 0px;
        margin-top: 65px;
    }
    
    .Wishlist_wish-list-remove-all__1naKW button {
        width: 100%;
    }

}
.OrderStatus_stats-order-content__1eZxG {
  /* overflow: hidden; */
}

.OrderStatus_stats-order-content-holder__1gdH1 h1 {
  margin-bottom: 30px;
  border-bottom: 1px solid #cacaca;
  padding-bottom: 5px;
  font-size: 28px;
  font-weight: bold;
}

.OrderStatus_btn-custom-editing-cart__8oB8E {
  display: inline-block;
  cursor: pointer;
}

@media (max-width: 450px) {
  .OrderStatus_stats-order-content__1eZxG {
    padding: 5px 0;
    margin-top: 65px;
  }

  .OrderStatus_stats-order-content-holder__1gdH1 h1 {
    margin-bottom: 0;
  }
}

@media (max-width: 360px) {
  .OrderStatus_btn-custom-editing-cart__8oB8E {
    width: 80px;
  }
}
.OrderStatus_cargo-id-item__iDR2w:hover {
  cursor: pointer;
  color: #3498db;
  text-decoration: underline;
}
.Humanity_container-position-header__17zwN {
    padding-top: 57px;
}

.Humanity_container-text-block__31_yD {
    margin: 0 auto;
    text-align: center;
    margin-top: -120px;
}

p.Humanity_title-block-humanity__1XfLF {
    font-size: 65px;
    letter-spacing: 2px;
    margin-bottom: 60px;
}

p.Humanity_title-block-humanity2__3nso4 {
    font-size: 30pt;
    letter-spacing: 1px;
    margin-bottom: 60px;
    font-weight: bold;
}

p.Humanity_body-block-humanity__37cHr {
    font-size: 20px !important;
    margin-bottom: 59px !important;
}

p.Humanity_content-body-block-3__1HMe2,
.Humanity_content3__1btTC {
    float: right;
    padding-top: 100px;
    font-size: 19px !important;
    padding-bottom: 100px;
    color: #000;
    margin-right: 24px;
}

.Humanity_image-block-rotate__37N1n {
    transform: rotate(12deg);
    /* position: absolute; */
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.Humanity_button-content-block-5__28GGI {
    background: radial-gradient(black, transparent);
    background: #00af9e;
    color: #fff;
    padding: 11px 50px;
    border-radius: 20px;
    font-size: 1vw;
}

.Humanity_row-content-background__1JoFG {
    background-image: url("https://resource.ewe.rs/gui/humanity/footer.jpg");
    padding: 300px 0px 100px;
    background-repeat: no-repeat;
    background-size: cover;
}

.Humanity_image-block-rotate-second__2nwnk {
    transform: rotate(-12deg);
    /* position: absolute; */
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    margin-left: 50px;
}

.Humanity_img-style__frRit {
    position: unset !important;
}

.Humanity_title-content-block-5__3dFT5 {
    font-size: 30px !important;
    margin-bottom: 30px !important;
    color: #fff;
}

.Humanity_content-body-block-5__2h_LZ {
    font-size: 1.09vw;
    margin-bottom: 35px;
    color: #fff;
}

p.Humanity_content-body-block-4__3WP-u {
    float: right;
    padding-top: 100px;
    font-size: 19px !important;
    padding-bottom: 100px;
    color: #000;
}

p.Humanity_content-body-block-6__3MbAq {
    float: right;
    padding-top: 120px;
    font-size: 19px !important;
    color: #000;
}

.Humanity_container-block-footer__1flF0 {
    text-align: right;
}

.Humanity_content-body-block-6__3MbAq {
    font-size: 20px !important;
}

@media (max-width: 1920px) and (min-width: 1025px) {
    .Humanity_title-tree__1Ug-h {
        margin-top: 110px !important;
    }
}

@media (max-width: 1920px) and (min-width: 768px) {
    p.Humanity_title-block-humanity2__3nso4 {
        margin-top: 110px !important;
    }
}

@media (max-width: 1536px) and (min-width: 1025px) {
    p.Humanity_title-block-humanity__1XfLF {
        margin-top: 60px;
    }

    .Humanity_title-tree__1Ug-h {
        margin-top: 110px !important;
    }

    .Humanity_content-body-block-6__3MbAq {
        font-size: 19px !important;
    }
}

@media (max-width: 1218px) and (min-width: 1021px) {

    .Humanity_image-block-rotate__37N1n,
    .Humanity_image-block-rotate-second__2nwnk {
        position: unset;
        width: 80%;
    }

    .Humanity_img-style__frRit {
        width: 90%;
        position: unset !important;
        margin-left: 0 !important;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    p.Humanity_title-block-humanity2__3nso4 {
        margin-top: 0 !important;
    }

    .Humanity_container-text-block__31_yD {
        margin-top: 0 !important;
    }

    p.Humanity_title-block-humanity2__3nso4 {
        font-size: 28px;
    }

    p.Humanity_content-body-block-3__1HMe2 {
        font-size: 17px !important;
        padding: 40px 0 !important;
    }

    .Humanity_content3__1btTC {
        padding-top: 0;
        padding-bottom: 20px !important;
        font-size: 17px !important;
    }

    p.Humanity_body-block-humanity__37cHr {
        font-size: 17px !important;
        margin-bottom: 65px;
    }

    p.Humanity_content-body-block-6__3MbAq {
        padding-top: 95px;
        font-size: 17px !important;
    }

    p.Humanity_content-body-block-4__3WP-u {
        font-size: 17px !important;
        margin-left: 35px;
        padding-top: 0;
    }

    .Humanity_image-block-rotate-second__2nwnk {
        margin-left: 0;
    }

    .Humanity_content-body-block-5__2h_LZ,
    .Humanity_button-content-block-5__28GGI {
        font-size: 14px;
    }

    .Humanity_content-body-block-6__3MbAq {
        font-size: 17px !important;
    }

    .Humanity_title-tree__1Ug-h {
        margin-top: 15px !important;
    }

    .Humanity_img-style__frRit {
        position: unset !important;
        margin-left: 0 !important;
    }
}

@media (max-width: 991px) and (min-width:768px) {
    .Humanity_second-row-page__qHCCL {
        margin-top: 0 !important;
    }

    .Humanity_last-block-new__6fkTK {
        padding-top: 20px;
    }

    .Humanity_img-margin__1gN5C {
        margin-top: 45px !important;
    }
}

@media (max-width: 1019px) {
    .Humanity_humanity-page__8Rz7u {
        margin-top: 63px;
    }
}

@media (max-width: 767px) {

    p.Humanity_title-block-humanity2__3nso4,
    p.Humanity_title-block-humanity__1XfLF {
        font-size: 20pt !important;
    }

    p.Humanity_body-block-humanity__37cHr,
    p.Humanity_content-body-block-4__3WP-u,
    p.Humanity_content-body-block-6__3MbAq,
    .Humanity_content3__1btTC {
        font-size: 13pt !important;
    }

    p.Humanity_content-body-block-3__1HMe2 {
        float: none !important;
        margin-right: 0px !important;
        text-align: center !important;
        padding-top: 50px;
        padding-bottom: 50px;
        font-size: 13pt !important;
    }

    .Humanity_content3__1btTC {
        text-align: center !important;
        margin-right: 0 !important;
    }

    .Humanity_image-block-rotate__37N1n {
        transform: rotate(12deg);
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
        width: 80%;
        margin: 0 auto;
        position: relative;
        margin-top: -20px;
    }

    .Humanity_row-content-background__1JoFG {
        padding: 130px 0px 75px;
    }

    .Humanity_title-content-block-5__3dFT5 {
        text-align: center;
    }

    .Humanity_content-body-block-5__2h_LZ {
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
    }

    .Humanity_button-content-block-5__28GGI {
        font-size: 14px;
    }

    .Humanity_container-block-footer__1flF0 {
        text-align: center;
    }

    .Humanity_image-block-rotate-second__2nwnk {
        transform: rotate(-12deg);
        position: relative;
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
        margin-left: 0px;
        width: 80%;
        margin: auto;
    }

    .Humanity_content-body-block-4__3WP-u {
        text-align: center;
        padding-bottom: 0px !important;
    }

    .Humanity_content-body-block-6__3MbAq {
        padding-top: 40px !important;
        font-size: 13pt !important;
    }

    .Humanity_img-top-style__3Yo6p {
        padding-top: 10px;
    }

    .Humanity_container-text-block__31_yD {
        margin-top: 0px !important;
    }

    .Humanity_container-position-header__17zwN {
        padding-top: 49px !important;
    }

    .Humanity_page-style__2Tm0I {
        padding: 20px;
    }

    .Humanity_thumb-img__2Nm3f {
        padding: 20px;
    }

    .Humanity_second-row-page__qHCCL {
        margin-top: 0 !important;
    }

    .Humanity_img-style__frRit {
        margin-left: 0 !important;
        position: unset;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.LoyaltyLanding_loyalty-landing__1mm5m h1,
.LoyaltyLanding_loyalty-landing__1mm5m h2,
.LoyaltyLanding_loyalty-landing__1mm5m h3,
.LoyaltyLanding_loyalty-landing__1mm5m h4 {
	text-align: center;
	text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
}

.LoyaltyLanding_loyalty-landing__1mm5m h1 {
	font-size: 36px;
	font-weight: 700;
	color: #fff;
}

.LoyaltyLanding_loyalty-landing__1mm5m h1 span {
	color: #ff0427;
}

.LoyaltyLanding_loyalty-landing__1mm5m h2 {
	font-size: 34px;
	font-weight: 300;
	color: #ff0427;
	margin-bottom: 40px;
}

.LoyaltyLanding_loyalty-landing__1mm5m h2 strong {
	font-weight: 700;
}

.LoyaltyLanding_loyalty-landing__1mm5m h3 {
	font-size: 22px;
	font-weight: 500;
	color: #fff;
	margin-top: -35px;
	margin-bottom: 50px;
}

.LoyaltyLanding_loyalty-landing__1mm5m h4 {
	font-size: 20px;
	font-weight: 300;
	color: #fff;
	margin-bottom: 75px;
}

.LoyaltyLanding_landing-desc__3cu9h p {
	text-align: center;
	font-size: 18px;
	margin-bottom: 20px;
}

.LoyaltyLanding_loyalty-landing__1mm5m p {
	font-size: 16px;
	line-height: 1.6;
}

.LoyaltyLanding_footer-holder-left__1LUdC,
.LoyaltyLanding_footer-holder-right__2LKrG {
	text-align: center;
}

.LoyaltyLanding_footer-holder-left__1LUdC p {
	font-weight: 500;
}

.LoyaltyLanding_header-content-loyalty__1yugz {
	padding: 25px 0px;
}

.LoyaltyLanding_top-holder-block__30rkg {
	position: relative;
	width: 100%;
	padding-top: 40px;
}

.LoyaltyLanding_top-block-items__4NSwm {
	width: 100%;
	display: flex;
	color: #fff;
	text-align: center;
}

.LoyaltyLanding_top-block-item-logo__2a4PH {
	flex: 1 1;
	border-right: 2px solid #efb836;
	margin-right: 60px;
}

.LoyaltyLanding_top-block-item-logo__2a4PH img {
	max-width: 250px;
}

.LoyaltyLanding_top-block-item-text__3E2OP {
	flex: 2 1;
	padding: 40px 0px;
	text-transform: uppercase;
	text-align: left;
}

.LoyaltyLanding_top-block-item-text__3E2OP h2 {
	font-weight: 800;
	font-size: 35px;
	letter-spacing: 1px;
}

.LoyaltyLanding_top-block-item-text__3E2OP span {
	color: #efb836;
}

.LoyaltyLanding_top-block-item-text__3E2OP p {
	font-size: 20px;
	line-height: 25px;
	font-weight: 500;
	margin-top: 11px;
}

.LoyaltyLanding_banner-image-text-bottom-loyalty-landing__B1bII {
	text-align: center;
	color: #fff;
}

.LoyaltyLanding_ly-landing-banner-title__3fp6H {
	display: flex;
	width: 100%;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.LoyaltyLanding_ly-landing-banner-title__3fp6H h2 {
	font-size: 50px;
	line-height: 45px;
	font-weight: 800;
	letter-spacing: 1px;
}

.LoyaltyLanding_ly-landing-banner-title-color__SEIO8 {
	color: #efb836;
}

.LoyaltyLanding_ly-landing-banner-title-big__1PUC- {
	font-weight: 800;
	font-size: 70px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.LoyaltyLanding_loyalty-image-img-badge__2SGvd {
	margin-left: 10px;
	height: 95px;
}

.LoyaltyLanding_ly-landing-banner-description__3_ICS {
	font-size: 25px;
	line-height: 26px;
	text-transform: uppercase;
	padding-bottom: 40px;
	font-weight: 600;
	margin-top: 15px;
}

.LoyaltyLanding_loyalty-landing-homepage__2s5D0 {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.LoyaltyLanding_landing-second-block-holder__3FkhZ {
	position: relative;
	color: #3d2767;
}

.LoyaltyLanding_landing-second-block__2eBdz {
	background-color: #efb836;
}

.LoyaltyLanding_landing-second-block-items__1gaX8 {
	display: flex;
}

.LoyaltyLanding_landing-second-block-left-side__1UMdo {
	flex: 1 1;
}

.LoyaltyLanding_landing-second-block-right-side__2UvMH {
	flex: 2 1;
	padding-top: 20px;
}

.LoyaltyLanding_landing-second-block-right-side__2UvMH h1 {
	text-transform: uppercase;
	font-size: 34px;
	line-height: 36px;
	font-weight: bold;
	color: #fff;
	margin-bottom: 20px;
}

.LoyaltyLanding_landing-second-block-right-side__2UvMH span {
	color: #3d2767;
}

.LoyaltyLanding_landing-second-block-right-side__2UvMH p {
	font-size: 16px;
	font-weight: 600;
}

.LoyaltyLanding_landing-second-block-image__21kNl {
	transform: scale(1.3);
	max-width: 300px;
}

.LoyaltyLanding_landing-third-block__3DW1K {
	background-color: #1f1538;
	color: #fff;
	padding-top: 50px;
}

.LoyaltyLanding_landing-third-block-holder__2-SJZ {
	position: relative;
}

.LoyaltyLanding_landing-third-block-items__3A_A3 {
	display: flex;
}

.LoyaltyLanding_landing-third-block-left-side__1rd09 {
	flex: 1 1;
}

.LoyaltyLanding_landing-third-block-right-side__2Avtr {
	flex: 1 1;
}

.LoyaltyLanding_landing-third-block-image__2rvrW {
	/* max-width: 350px; */
	margin-top: -140px;
}

.LoyaltyLanding_landing-third-block-left-side__1rd09 {
	text-align: right;
	margin-top: 50px;
	text-transform: uppercase;
}

.LoyaltyLanding_landing-third-block-left-side__1rd09 p1 {
	font-weight: 800;
	font-size: 40px;
	line-height: 40px;
	letter-spacing: 1px;
}

.LoyaltyLanding_landing-third-block-left-side__1rd09 h2 {
	font-weight: 800;
	font-size: 40px;
	line-height: 40px;
	color: #efb836;
	margin-bottom: 40px;
	letter-spacing: 1px;
}

.LoyaltyLanding_landing-third-block-left-side__1rd09 p {
	font-size: 14px;
	font-weight: 400;
	text-transform: none;
	letter-spacing: 1px;
}

.LoyaltyLanding_landing-button-block__300SE {
	text-align: center;
	padding: 40px 0px;
}

.LoyaltyLanding_landing-button-block__300SE h3 {
	color: #1f1538;
	font-weight: bold;
	margin-bottom: 20px;
}

.LoyaltyLanding_landing-button-block-holder__1WD3b {
	cursor: pointer;
}

.LoyaltyLanding_landing-footer-block-holder__j-ooY {
	background-color: #1f1538;
	color: #fff;
	text-align: center;
	padding: 40px 0px;
}

.LoyaltyLanding_landing-footer-block__1t6mo {
	width: 100%;
}

.LoyaltyLanding_landing-footer-block__1t6mo p {
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0px;
	letter-spacing: 1px;
}

.LoyaltyLanding_banner-image-loyalty-landing__147Aa {
	text-align: center;
	width: 100%;
}

.LoyaltyLanding_top-block-items__4NSwm {
	text-align: center;
}

@media (max-width: 1680px) {
	.LoyaltyLanding_loyalty-image-img-banner__2XVoP {
		max-width: 900px;
	}

	.LoyaltyLanding_top-block-item-logo__2a4PH img {
		max-width: 230px;
	}
}

@media (min-width: 1200px) {
	.LoyaltyLanding_loyalty-landing__1mm5m h1 {
		font-size: 46px;
		letter-spacing: 14px;
	}

	.LoyaltyLanding_loyalty-landing__1mm5m h2 {
		font-size: 44px;
	}

	.LoyaltyLanding_loyalty-landing__1mm5m h3 {
		font-size: 30px;
	}

	.LoyaltyLanding_loyalty-landing__1mm5m h4 {
		font-size: 24px;
		letter-spacing: 9px;
	}

	.LoyaltyLanding_landing-desc__3cu9h p {
		text-align: center;
		font-size: 22px;
		margin-bottom: 20px;
	}
}

@media (max-width: 1200px) and (min-width: 768px) {
	.LoyaltyLanding_container-small-style__1ziwC {
		margin-top: -10px !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.LoyaltyLanding_loyalty-landing__1mm5m h1 {
		font-size: 42px;
	}

	.LoyaltyLanding_landing-desc__3cu9h p {
		font-size: 20px;
	}
}

@media (max-width: 901px) {
	.LoyaltyLanding_main-content__2dYcu {
		margin-top: 60px;
	}

	.LoyaltyLanding_header-content-loyalty__1yugz {
		padding: 20px 0px;
	}

	.LoyaltyLanding_loyalty-landing__1mm5m h1 {
		font-size: 30px;
	}

	.LoyaltyLanding_loyalty-landing__1mm5m h2 {
		font-size: 30px;
	}

	.LoyaltyLanding_loyalty-landing__1mm5m h3 {
		font-size: 18px;
	}

	.LoyaltyLanding_loyalty-landing__1mm5m h4 {
		font-size: 18px;
		margin-bottom: 30px;
	}

	.LoyaltyLanding_landing-third-block-image__2rvrW {
		max-width: 450px;
		margin-top: 0px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.LoyaltyLanding_loyalty-landing__1mm5m h1 {
		font-size: 38px;
	}

	.LoyaltyLanding_landing-desc__3cu9h p {
		font-size: 15px;
	}
}

@media (min-width: 768px) {
	.LoyaltyLanding_row-centered__1kUM0 {
		display: flex;
		align-items: center;
	}

	.LoyaltyLanding_footer-holder-left__1LUdC {
		text-align: left;
	}
}

@media (max-width: 767px) {
	.LoyaltyLanding_container-small-style__1ziwC {
		margin-top: -25px !important;
	}

	.LoyaltyLanding_footer-holder-right__2LKrG {
		margin-top: 20px;
	}

	.LoyaltyLanding_landing-third-block-items__3A_A3 {
		display: flex;
		flex-direction: column-reverse;
	}
}

.LoyaltyLanding_center-block-padding__y5sWn {
	padding-top: 80px;
}

@media (min-width: 320px) {
	.LoyaltyLanding_center-block-padding__y5sWn {
		padding-top: 25px;
	}
}

@media (max-width: 450px) {
	.LoyaltyLanding_top-block-items__4NSwm {
		flex-direction: column;
		margin-top: 20px;
	}

	.LoyaltyLanding_top-block-item-logo__2a4PH {
		border-right: 0;
		margin-right: 0px;
	}

	.LoyaltyLanding_top-block-item-text__3E2OP h2 {
		font-weight: bold;
		font-size: 25px;
		text-align: center;
	}

	.LoyaltyLanding_top-block-item-text__3E2OP p {
		font-size: 16px;
		text-align: center;
	}

	.LoyaltyLanding_top-block-item-text__3E2OP {
		padding: 10px 0 0 0;
	}

	.LoyaltyLanding_loyalty-image-img-banner__2XVoP {
		max-width: 100%;
	}

	.LoyaltyLanding_ly-landing-banner-title__3fp6H h2 {
		font-size: 35px;
		line-height: 35px;
		font-weight: bold;
	}

	.LoyaltyLanding_ly-landing-banner-title-big__1PUC- {
		font-size: 40px;
	}

	.LoyaltyLanding_loyalty-image-img-badge__2SGvd {
		margin-left: 10px;
		height: 70px;
	}

	.LoyaltyLanding_ly-landing-banner-description__3_ICS {
		font-size: 16px;
		margin-top: 5px;
	}

	.LoyaltyLanding_landing-second-block-image__21kNl {
		max-width: 120px;
	}

	.LoyaltyLanding_landing-second-block-right-side__2UvMH h1 {
		font-size: 29px;
		line-height: 30px;
	}

	.LoyaltyLanding_landing-second-block-right-side__2UvMH {
		padding-right: 10px;
		margin-left: -30px;
	}

	.LoyaltyLanding_landing-second-block-right-side__2UvMH p {
		font-size: 15px;
	}

	.LoyaltyLanding_landing-third-block-items__3A_A3 {
		flex-direction: column;
	}

	.LoyaltyLanding_landing-third-block-image__2rvrW {
		width: 100%;
		margin-top: -80px;
	}

	.LoyaltyLanding_landing-third-block-left-side__1rd09 {
		text-align: center;
		margin-top: 0px;
	}

	.LoyaltyLanding_landing-third-block-left-side__1rd09 h1 {
		font-size: 34px;
		line-height: 34px;
	}

	.LoyaltyLanding_landing-third-block-left-side__1rd09 h2 {
		font-size: 34px;
		line-height: 34px;
	}

	.LoyaltyLanding_landing-third-block-left-side__1rd09 p {
		padding: 0px 15px;
	}

	.LoyaltyLanding_landing-button-block-holder__1WD3b img {
		width: 100%;
	}

	.LoyaltyLanding_landing-footer-block-holder__j-ooY {
		padding: 40px 15px;
	}
}
@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Bold.179a2453.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Medium.230a7590.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

body,
html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.EweGoCartLanding_container-fluid__2fswF.EweGoCartLanding_main-container__2htsp {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.EweGoCartLanding_wrapper-ewe-go-cart__3I3HI {
    max-width: 1800px;
    width: 100%;
    margin: 0 auto;
}

.EweGoCartLanding_main-container__2htsp {
    position: relative;
    background-color: #fffbed;
    color: #5b5555;
    font-family: 'GtestiProRegular';
    font-weight: bold;
    width: 100%;
    margin: 0 auto;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/landing/TRAGOVI%20OD%20GUMA%20IZA%20NASLOVA.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%;

}

.EweGoCartLanding_custom-container-fluid__1Q02_ {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 45px;
    background-color: #fffbed;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/landing/TRAGOVI%20OD%20GUMA%20IZA%20NASLOVA.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%;
    height: auto;
}

.EweGoCartLanding_ewe-go-cart__k_vdt {
    margin-top: 120px;
    margin-bottom: 20px;
}

.EweGoCartLanding_ewe-go-cart-img__2xHGM {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    height: auto;
}

.EweGoCartLanding_ewe-go-cart-img-top__1HdrH {
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.EweGoCartLanding_ewe-go-cart-img-bottom__2Fj6G {
    align-self: center;
    margin-top: auto;
    /* width: 100%; */
}

.EweGoCartLanding_ewe-go-cart-img-top__1HdrH,
.EweGoCartLanding_ewe-go-cart-img-bottom__2Fj6G {
    width: 100%;
}

.EweGoCartLanding_flex-embrace-text__1v0_T {
    display: flex;
    align-items: center;
    justify-content: center;
}

.EweGoCartLanding_flex-embrace-text__1v0_T img {
    margin-right: 10px;
}

.EweGoCartLanding_flex-embrace-text__1v0_T p {
    margin-left: -45px;
    margin-bottom: 0;
    padding-left: 10px;
}

.EweGoCartLanding_trazimo-sampiona-holder__1NqjP {}

.EweGoCartLanding_trazimo-sampiona__3ITEF {
    font-size: 42px;
    font-weight: 700;
    font-family: 'Axiforma';
    color: #000;
    margin-bottom: 20px;
}

.EweGoCartLanding_pasus1__8xuj3 {
    font-size: 40px;
    font-family: 'Axiforma';
    color: #000;
    padding: 30px;
}

p.EweGoCartLanding_pasus2__1_aIb {
    padding-top: 20px;
    font-size: 40px;
    font-family: 'Axiforma';
    color: #000;
    align-self: center;
    font-size: 25px;
}

.EweGoCartLanding_pehar-container__1fDqQ {
    position: relative;
    width: 100%;
    height: auto;
}

.EweGoCartLanding_pehar__NzvDC {
    position: absolute;
    top: -280px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.EweGoCartLanding_login-title-image__13aIp {
    margin-bottom: 20px;
}

.EweGoCartLanding_braon-prozor-holder__Zcmr0 {
    text-align: center;
    margin-top: 160px;
    position: relative;
    z-index: 2;
}

.EweGoCartLanding_braonProzor-image__XqhXj {
    /* width: 80%;
    height: auto; */
    /* display: inline-block; */
    max-width: 100%;
    height: auto;
}

.EweGoCartLanding_put-image__1MgDE {
    margin-top: -18%;
}

.EweGoCartLanding_race-content-holder__3QEGb {
    font-family: 'Axiforma';
    background-image: url('https://resource.ewe.rs/media/ewe-go-cart/landing/PUT_SA_FORMULAMA.png');
    background-repeat: no-repeat;
    background-position: top;
    min-height: 4400px;
    position: relative;
    top: -70px;
    background-size: 100%;
}

.EweGoCartLanding_race-content-holder__3QEGb h1 {
    position: relative;
    padding: 10px;
}

.EweGoCartLanding_content-right__3TBZ0 {
    display: flex;
    justify-content: flex-end !important;
}

.EweGoCartLanding_content-center__3Uury {
    display: flex;
    justify-content: center !important;
    grid-gap: 10px;
    gap: 10px;
}

.EweGoCartLanding_content-left__1dqw6 {
    display: flex;
    justify-content: flex-start !important;
}

.EweGoCartLanding_put-image__1MgDE {
    width: 100%;
    height: auto;
}

.EweGoCartLanding_red-text__3djK_ {
    color: #DC0000;
    font-size: 2.8rem;
    font-weight: 700;
}

.EweGoCartLanding_black-text__3iQ_Y {
    color: #000;
    font-size: 2.8rem;
    font-weight: 700;
}

.EweGoCartLanding_paragraph-container__19-8H {
    text-align: right;
    width: 100%;
}

.EweGoCartLanding_paragraph-container1__1DaR7 {
    text-align: left;
    width: 100%;
    margin-bottom: 50px;
}

.EweGoCartLanding_login-page-button__1kkuK {
    background-color: #DC0000;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 250px;
}

.EweGoCartLanding_login-page-button__1kkuK span {
    display: block;
    text-align: center;
}

.EweGoCartLanding_ranking-page-button__PaKz0 span {
    display: block;
    text-align: center;
}

.EweGoCartLanding_login-page-button__1kkuK:hover {
    background-color: darkred;
}

.EweGoCartLanding_ranking-page-button__PaKz0 {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 250px;
}

.EweGoCartLanding_ranking-page-button__PaKz0:hover {
    background-color: gray;
}

.EweGoCartLanding_race-content-holder-text2__2bYDs h1 {
    text-align: right;
    font-size: 2.8rem;
    font-weight: 700;
}

.EweGoCartLanding_fuel-image__3Cbfe {
    width: 40%;
    margin-left: 60%;
    margin-top: 10%;
}

.EweGoCartLanding_fuel-image1__1iW3n {
    width: 40%;
    margin-top: 20%;
    margin-left: -80%;
}

.EweGoCartLanding_paragraph-container2__2AC04 {
    width: 100%;

}

.EweGoCartLanding_paragraph-container2__2AC04 h1 {
    margin-bottom: 30px;
}

.EweGoCartLanding_red-bullet__ZSxqh {
    position: relative;
    padding-left: 20px;
}

.EweGoCartLanding_red-bullet__ZSxqh::before {
    content: "•";
    color: #DC0000;
    position: absolute;
    left: 0;
}

.EweGoCartLanding_paragraph-container40__2FsXo {
    width: 40%;
}

.EweGoCartLanding_second-button-holder__1ZHoa {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -70px;
}

.EweGoCartLanding_links-container__3W8XI {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.EweGoCartLanding_ewe-go-cart-img-top__1HdrH,
.EweGoCartLanding_ewe-go-cart-img-bottom__2Fj6G {
    width: 100%;
}

.EweGoCartLanding_ewe-go-cart-img-top__1HdrH {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.EweGoCartLanding_second-button-holder1__3aFGg {
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-size: 100%;
    background-position: top left;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 1000px;
    position: absolute;
    bottom: 5%;
}

.EweGoCartLanding_final-heading-container__2d45P {
    margin-bottom: 100px;
    margin-top: -100px;
}

.EweGoCartLanding_pasus1__8xuj3,
.EweGoCartLanding_pasus2__1_aIb {
    font-size: 25px;
}

.EweGoCartLanding_race-content-holder-text__3CB3X,
.EweGoCartLanding_race-content-holder-text1__30mw_,
.EweGoCartLanding_race-content-holder-text2__2bYDs {
    font-size: 25px;
}

.EweGoCartLanding_race-content-holder-text__3CB3X h1 {
    text-align: right;
}

.EweGoCartLanding_race-content-holder-text1__30mw_ {
    margin-top: 45%;
}

.EweGoCartLanding_race-content-holder-text__3CB3X h1 span {
    font-size: 2.8rem;
    font-weight: 700;
}

.EweGoCartLanding_race-content-holder__3QEGb {
    position: relative;
}

.EweGoCartLanding_race-content-holder-text__3CB3X {
    position: relative;
    top: 0%;
    left: 15%;
    right: 0;
    margin-top: 20%;
}

.EweGoCartLanding_image-container-stoperica__2afgZ {
    position: absolute;
}

.EweGoCartLanding_race-content-holder-text2__2bYDs {
    position: relative;
    top: 5%;
    left: 10%;
}

.EweGoCartLanding_kartodrom__RIrXa {
    width: 100%;
    margin-top: -35%;
    position: relative;
}

.EweGoCartLanding_paragraph-container2__2AC04 {
    font-family: 'Axiforma';
    font-size: 25px;
    color: #000;
    padding-left: 180px;
}

.EweGoCartLanding_paragraph-containe__2qY0L {
    height: 100vh;
}

.EweGoCartLanding_zastavica__2sSDd {
    width: 70%;
    margin-top: -35%;
}

.EweGoCartLanding_ewe-go-cart-img-top__1HdrH {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.EweGoCartLanding_login-page-button__1kkuK,
.EweGoCartLanding_ranking-page-button__PaKz0 {
    width: 300px;
    height: 60px;
}


@media(min-width: 1921px) {

    .EweGoCartLanding_race-content-holder__3QEGb {
        min-height: 4510px;
    }

    .EweGoCartLanding_race-content-holder-text2__2bYDs {
        top: 10%;
    }
}

@media(max-width:1919px) {
    .EweGoCartLanding_wrapper-ewe-go-cart__3I3HI {
        max-width: 1500px;
    }

    .EweGoCartLanding_race-content-holder-text__3CB3X {
        font-size: 20px;
    }

    .EweGoCartLanding_race-content-holder-text1__30mw_ {
        font-size: 20px;
    }

    .EweGoCartLanding_paragraph-container1__1DaR7 {
        width: 90%;
    }

    .EweGoCartLanding_content-center__3Uury {
        width: 100%;
    }

    .EweGoCartLanding_paragraph-container__19-8H {
        width: 90%;
        font-size: 20px;
    }

    .EweGoCartLanding_race-content-holder-text__3CB3X h1 span {
        font-size: 2.4rem;
    }

    .EweGoCartLanding_race-content-holder-text__3CB3X h1 {
        text-align: center;
    }

    .EweGoCartLanding_red-text__3djK_,
    .EweGoCartLanding_black-text__3iQ_Y {
        font-size: 2.4rem;
    }

    .EweGoCartLanding_race-content-holder-text2__2bYDs h1 {
        margin-right: 9%;
    }

    .EweGoCartLanding_race-content-holder-text2__2bYDs {
        top: 10%;
    }

    .EweGoCartLanding_race-content-holder__3QEGb {
        min-height: 3750px;
    }

    .EweGoCartLanding_race-content-holder-text1__30mw_ {
        margin-top: 30%;
    }

    .EweGoCartLanding_paragraph-container2__2AC04 h1 {
        font-size: 2.4rem;
    }

    .EweGoCartLanding_paragraph-container2__2AC04 p {
        font-size: 20px;
    }

    .EweGoCartLanding_ewe-go-cart-img__2xHGM {
        width: 50%;
    }
}

@media(max-width:1535px) {
    .EweGoCartLanding_race-content-holder__3QEGb {
        min-height: 3350px;
    }

    .EweGoCartLanding_stopwatch-image__3Cb4g {
        width: 60%;
    }

    .EweGoCartLanding_pasus2__1_aIb {
        position: relative;
        z-index: 1;
    }

    .EweGoCartLanding_race-content-holder-text1__30mw_ {
        margin-top: 10px;
    }

    .EweGoCartLanding_race-content-holder-text__3CB3X h1 span {
        font-size: 1.8rem;
    }

    .EweGoCartLanding_red-text__3djK_,
    .EweGoCartLanding_black-text__3iQ_Y {
        font-size: 1.8rem;
    }

    .EweGoCartLanding_paragraph-container__19-8H {
        font-size: 18px;
    }

    .EweGoCartLanding_race-content-holder-text1__30mw_ {
        font-size: 18px;
    }

    .EweGoCartLanding_race-content-holder-text1__30mw_ {
        margin-top: 45%;
    }

    .EweGoCartLanding_fuel-image__3Cbfe {
        width: 30%;
    }

    .EweGoCartLanding_race-content-holder-text2__2bYDs {
        top: 0;
    }

    .EweGoCartLanding_pehar-image__1neKP,
    .EweGoCartLanding_login-title-image__13aIp {
        width: 80%;
    }

    .EweGoCartLanding_pehar__NzvDC {
        top: -200px;
    }

    .EweGoCartLanding_race-content-holder-text2__2bYDs {
        top: 10%;
    }
}

@media(max-width:1365px) {
    p.EweGoCartLanding_pasus2__1_aIb {
        font-size: 16px;
    }

    h4.EweGoCartLanding_pasus1__8xuj3 {
        font-size: 18px;
    }

    .EweGoCartLanding_pehar-image__1neKP {
        width: 50%;
    }

    .EweGoCartLanding_pehar__NzvDC {
        top: -400px;
    }

    .EweGoCartLanding_braon-prozor-holder__Zcmr0 {
        margin-top: 30px;
    }

    .EweGoCartLanding_embrace-title-image__3rIsk {
        width: 40%;
    }

    .EweGoCartLanding_paragraph-container__19-8H {
        font-size: 15px;
    }

    .EweGoCartLanding_race-content-holder-text1__30mw_ {
        font-size: 15px;
        margin-top: 10%;
    }

    .EweGoCartLanding_race-content-holder__3QEGb {
        top: -40px;
        min-height: 2800px;
    }

    .EweGoCartLanding_fuel-image__3Cbfe {
        margin-top: 0;
    }

    .EweGoCartLanding_login-page-button__1kkuK,
    .EweGoCartLanding_ranking-page-button__PaKz0 {
        width: 200px;
    }

    .EweGoCartLanding_second-button-holder__1ZHoa {
        top: 0;
    }

    .EweGoCartLanding_final-heading-container__2d45P {
        margin-bottom: 50px;
        margin-top: 0;
    }

    .EweGoCartLanding_race-content-holder-text2__2bYDs {
        top: 0%;
    }

    .EweGoCartLanding_race-content-holder__3QEGb {
        min-height: 3150px;
    }
}

@media(max-width: 1024px) {

    .EweGoCartLanding_race-content-holder-text1__30mw_ {
        margin-top: 30%;
    }

    .EweGoCartLanding_race-content-holder__3QEGb {
        min-height: 2350px;
    }

    .EweGoCartLanding_race-content-holder-text2__2bYDs {
        top: -35%;
    }

    .EweGoCartLanding_zastavica__2sSDd {
        margin-left: 40%;
    }
}

.EweGoCartLanding_first-holder-mobile__IMvVx {
    text-align: center;
}

.EweGoCartLanding_first-container-fluid-mobile__1iZGx {
    background-color: #fffbed;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/landing/TRAGOVI%20OD%20GUMA%20IZA%20NASLOVA.png);
    background-position: top;
    background-repeat: no-repeat;
    margin-top: 66px;
}

.EweGoCartLanding_ewe-go-cart-img-mobile__sE-8J {
    width: 100%;
}

.EweGoCartLanding_login-title-image-mobile__1ErOd {
    width: 100%;
}

.EweGoCartLanding_trazimo-sampiona-mobile__3knfL {
    font-size: 25px;
    font-family: 'Axiforma';
}

.EweGoCartLanding_pasus1-mobile__1Ufdv {
    font-size: 15px;
    font-family: 'Axiforma';
    padding-left: 10px;
    padding-right: 10px;
}

.EweGoCartLanding_embrace-title-image-mobile__3HezN {
    width: 50%;
}

.EweGoCartLanding_first-holder-mobile__IMvVx img {
    margin: 10px 0;
}

.EweGoCartLanding_pehar-image-mobile__1BTdR {
    width: 30%;
}

.EweGoCartLanding_brown-container-fluid-mobile__2BZJ8 {
    background-color: #4A3937;
    font-size: 15px;
    font-family: 'Axiforma';
    color: white;
    padding: 40px 15px;
    text-align: center;

}

.EweGoCartLanding_start-container-fluid-mobile__3pgTg {
    background-image: url(/static/media/add-to-cart.2bc1cdb7.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px;
    height: 960px;
    text-align: center;
    display: flex;
    align-items: flex-start;
}

.EweGoCartLanding_red-text-mobile__2CDsV {
    color: #DC0000;
    font-size: 20px;
    font-family: 'Axiforma';
    display: block;
    font-weight: 700;
}

.EweGoCartLanding_black-text-mobile__3MyPK {
    color: #000;
    font-size: 20px;
    font-family: 'Axiforma';
    display: block;
    font-weight: 700;
}

.EweGoCartLanding_transparent-holder__eKmct {

    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgb(255 255 255 / 55%);
    text-align: center;
    height: auto;
    margin-top: 100px;
    border: 1px solid black;
    color: #000;
    font-size: 15px;
    font-family: 'Axiforma';
}


.EweGoCartLanding_text-button-holder-mobile__HXVDd {
    background-color: #fffbed;
    font-size: 15px;
    font-family: 'Axiforma';
    padding: 25px 0;
}

.EweGoCartLanding_paragraph-container-mobile__24OaQ {
    text-align: left;
    padding: 15px;
    font-size: 15px;
    font-family: 'Axiforma';
}

.EweGoCartLanding_login-page-button-mobile__25u03,
.EweGoCartLanding_login-page-red-button-mobile__3csxq {
    background-color: #DC0000;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 90%;
    margin-bottom: 10px;
}

.EweGoCartLanding_login-page-button-mobile__25u03 span {
    display: block;
    text-align: center;
}

.EweGoCartLanding_login-page-button-mobile__25u03:hover {
    background-color: darkred;
}

.EweGoCartLanding_ranking-page-button-mobile__237M1 {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 90%;
}

.EweGoCartLanding_ranking-page-button-mobile__237M1:hover {
    background-color: gray;
}

.EweGoCartLanding_go-kart-container-fluid-mobile__2ilmn {
    background-image: url(/static/media/go-kart.36fe9dd4.png);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    display: flex;
    align-items: flex-start;
    background-color: #fffbed;
    height: 1300px;
    padding: 15px;

}

.EweGoCartLanding_transparent-holder25__1qgPN {
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgb(255 255 255 / 55%);
    text-align: center;
    height: auto;
    border: 1px solid black;
    color: #000;
    font-size: 15px;
    font-family: 'Axiforma';
}

.EweGoCartLanding_second-text-button-holder-mobile__afWje {
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #fffbed;
    font-family: 'Axiforma';
    color: #000;
    text-align: left;
    padding-bottom: 20px;
}

.EweGoCartLanding_heading-holder-mobile__2_YE9 {
    font-family: 'Axiforma';
    font-size: 15px;
    padding: 15px;
}

.EweGoCartLanding_heading-holder-mobile__2_YE9 p {
    font-family: 'Axiforma';
    font-size: 20px;
    font-weight: bold;
}

.EweGoCartLanding_paragraph-container-button-mobile__3x9bT {
    margin-bottom: 10px
}

.EweGoCartLanding_red-bullet__ZSxqh {
    font-family: 'Axiforma';
}

.EweGoCartLanding_red-inline__3Tvkr {
    color: #DC0000;
}

.EweGoCartLanding_paragraph-container__19-8H,
.EweGoCartLanding_paragraph-container1__1DaR7 p {
    color: #000;
}

.EweGoCartLanding_third-text-button-holder-mobile__1ujdc {
    background-color: #fffbed;
}

.EweGoCartLanding_third-text-button-holder-mobile__1ujdc {
    padding: 15px;
}

.EweGoCartLanding_final-heading-container-mobile__3POje {
    text-align: center;
}

.EweGoCartLanding_paragraph-container40-mobile__JVfrw {
    text-align: center;
}

.EweGoCartLanding_zastavica-mobile__2t4c0 {
    width: 50%;
}

.EweGoCartLanding_ewe-go-cart-img-bottom-mobile__3kSeZ {
    width: 100%;
}
@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Bold.179a2453.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Medium.230a7590.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

.EweGoCartLogin_main-container__2YpXW {
    font-family: 'Axiforma';
    position: relative;
    background-color: #000;
    color: #5b5555;
}

.EweGoCartLogin_custom-container-fluid__20Qt1 {
    position: relative;
    width: 100%;
    height: 100vh;
    margin-right: auto;
    margin-left: auto;
    background-color: #fffbed;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-repeat: no-repeat;
}

.EweGoCartLogin_login-formula__1mkcq {
    width: 25%;
    position: absolute;
    top: 70%;
    right: 5%;

}

.EweGoCartLogin_full-width-image__1VuE2 {
    width: 100%;
    height: auto;
    display: block;
}

.EweGoCartLogin_ewe-go-cart-img-top__1Ebgn {
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.EweGoCartLogin_ewe-go-cart-login-container__3vFno {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.EweGoCartLogin_login-logo-title-holder__2Mvwn {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}

.EweGoCartLogin_login-logo-title__3ktNJ h4 {
    font-family: 'Axiforma';
    font-weight: 700;
    margin-bottom: 10px;
}

.EweGoCartLogin_login-title-image__2vmrc {
    width: 100%;
}

.EweGoCartLogin_login-title__39U7x {
    font-weight: 700;
    font-family: 'Axiforma';
}

.EweGoCartLogin_login-custom-input__2-zy0 {
    border: 3px solid #db0000;
    border-radius: 22px;
    background-color: transparent;
    padding: 0 20px;
    width: 330px;
    height: 50px;
    box-sizing: border-box;
}

.EweGoCartLogin_login-input-label__2Q0Pf {
    display: block;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Axiforma';
    font-weight: 500;
}

.EweGoCartLogin_login-form-button-holder__3teu- {
    margin-top: 70px;
}

.EweGoCartLogin_login-form-button__26nC1 {
    width: 450px;
    height: 70px;
    border-radius: 35px;
    background-color: #db0000;
    color: white;
    border: none;
    cursor: pointer;
    font-family: 'Axiforma';
    font-size: 26px;
    font-weight: 500;
    transition: background-color 0.3s, transform 0.2s;
}

.EweGoCartLogin_login-form-button__26nC1:hover {
    background-color: #b30000;
}

.EweGoCartLogin_login-form-button__26nC1:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(219, 0, 0, 0.5);
}

.EweGoCartLogin_login-form-button__26nC1:active {
    background-color: #990000;
    transform: scale(0.98);
}

.EweGoCartLogin_full-width-image-bottom__1D8VL {
    position: absolute;
    bottom: 0%;
    width: 100%;
    height: auto;
    display: block;
    background-color: #fffbed;
}

.EweGoCartLogin_go-cart-error__1Sowz {
    display: block;
    color: #db0000;
}

/* RESPONSIVE */

/* For screens smaller than 1920px */
@media (max-width: 1920px) {

    .EweGoCartLogin_login-title-image__2vmrc {
        margin-top: 30px;
        width: 700px;
    }

    .EweGoCartLogin_login-form-button-holder__3teu- {
        margin-top: 40px;
    }

    .EweGoCartLogin_login-form-button__26nC1 {
        height: 60px;
        margin-bottom: 40px;
    }
}


/* For screens smaller than 1440px */
@media (max-width: 1440px) {

    .EweGoCartLogin_login-title-image__2vmrc {
        width: 400px;
    }

    .EweGoCartLogin_full-width-image__1VuE2,
    .EweGoCartLogin_full-width-image-bottom__1D8VL {
        display: none;
    }

    .EweGoCartLogin_login-title__39U7x {
        font-size: 22px;
    }

    .EweGoCartLogin_login-custom-input__2-zy0 {
        height: 40px;
    }

    .EweGoCartLogin_login-form-button__26nC1 {
        height: 50px;
        font-size: 22px;
    }
}

@media (max-width: 1024px) {

    .EweGoCartLogin_login-custom-input__2-zy0 {
        height: 40px;
    }

    .EweGoCartLogin_login-form-button__26nC1 {
        width: 400px;
        height: 50px;
        font-size: 22px;
    }

    .EweGoCartLogin_login-formula__1mkcq {
        top: 75%;
        right: 0;
    }

}

/* For screens equal to or smaller than 768px */
@media (max-width: 768px) {

    .EweGoCartLogin_login-form-button__26nC1 {
        width: 60%;
    }

    .EweGoCartLogin_login-formula__1mkcq {
        left: 50%;
        top: 85%;
        width: 35%;
        transform: translateX(-50%);
    }
}

@media (max-width: 767px) {
    .EweGoCartLogin_custom-container-fluid__20Qt1 {
        margin-top: 15px;
    }

    .EweGoCartLogin_login-logo-title-holder__2Mvwn {
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .EweGoCartLogin_login-logo__1ZDFB {
        width: 50%;
    }

    .EweGoCartLogin_login-logo-title__3ktNJ h4 {
        font-size: 14px;
    }

    .EweGoCartLogin_login-title-image__2vmrc {
        width: 100%;
        margin-top: 0px;
    }

    h1.EweGoCartLogin_login-title__39U7x {
        font-size: 20px;
    }

    .EweGoCartLogin_login-form-button__26nC1 {
        width: 100%;
    }

    .EweGoCartLogin_login-formula__1mkcq {
        top: 85%;
        width: 50%;
    }

    .EweGoCartLogin_login-form-button__26nC1 {
        height: 40px;
        font-size: 18px;
    }

    .EweGoCartLogin_login-custom-input__2-zy0 {
        width: 100%;
    }

}
@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Bold.179a2453.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Medium.230a7590.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

.EweGoCartRanking_main-container__2k1qK {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background-color: #000;
    color: #5b5555;
    font-family: 'Axiforma';
    font-weight: bold;
}

.EweGoCartRanking_custom-container-fluid__OFMZJ {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fffbed;
    justify-content: center;
    align-items: center;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-size: 50%;
    background-position: top left;
    background-repeat: no-repeat;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.EweGoCartRanking_ewe-go-cart-img__2jWzs {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.EweGoCartRanking_ewe-go-cart-img-top__3GgYf {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 100%;
    max-width: 100%; */

}

.EweGoCartRanking_ewe-go-cart-img-bottom__2iNX_ {
    align-self: center;
    margin-top: auto;
    /* width: 100%; */
}

.EweGoCartRanking_ewe-go-cart-formula1-img__6svBv {
    width: 65%;
}

.EweGoCartRanking_ewe-go-cart-formula2-img__11b7I {
    margin-top: 10%;
    width: 100%;
}

.EweGoCartRanking_ranking-list__1oEoa p {
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
}

.EweGoCartRanking_ewe-go-carting-your-coins__28WRR {
    text-align: -webkit-center;
}

.EweGoCartRanking_button-go-carting-your-coins__3DxCk {
    flex: 1 1;
    text-align: center;
    background: transparent;
    border-radius: 15px;
    border: 1px solid #DC0000;
    padding: 5px 0px;
    cursor: pointer;
    color: #111111;
    width: 500px;
    font-family: 'Axiforma';
}

.EweGoCartRanking_button-go-carting-your-coins__3DxCk:hover {
    border: 1px solid #111111;
    color: #DC0000;
}

.EweGoCartRanking_ranking-list__1oEoa {
    margin-top: 20px;
    margin-bottom: 40px;
}

.EweGoCartRanking_ranking-item__1PiV3 {
    display: flex;
    margin-bottom: 5px;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
}

.EweGoCartRanking_ranking-number__3-7Mh {
    font-size: 17px;
    font-weight: bold;
    color: #DC0000;
    margin-right: 10px;
    width: 30px;
    text-align: right;
    flex-shrink: 0;
}

.EweGoCartRanking_emphasize__3EjSW {
    background-color: #ff000012
}

/* .ranking-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #DC0000;
    display: inline-block;
    background: white;
    margin-right: 10px;
    margin-left: 10px;
    flex-shrink: 0;
} */

.EweGoCartRanking_ranking-alias__2Qpvo {
    font-size: 16px;
    flex-grow: 1;
    padding: 5px;
}

.EweGoCartRanking_ranking-list-img-holder__c9Y66 {
    display: flex;
    align-items: center;
}

.EweGoCartRanking_ewe-go-brzina1-holder__VGm4u {
    position: absolute;
    top: 20%;
    left: 10%;
}

.EweGoCartRanking_ewe-go-brzina2-holder__3WM0b {
    position: absolute;
    bottom: 10%;
    right: 5%;
}

.EweGoCartRanking_ewe-go-cart-img-top__3GgYf,
.EweGoCartRanking_ewe-go-cart-img-bottom__2iNX_ {
    width: 100%;
}

.EweGoCartRanking_ewe-go-cart-formula1-img__6svBv {
    margin-left: 20%;
    margin-top: 8%;
    margin-bottom: 2%;
}


@media(max-width: 500px) {
    .EweGoCartRanking_ewe-go-cart-formula1-img__6svBv {
        display: none;
    }

    .EweGoCartRanking_ewe-go-cart-formula2-img__11b7I {
        display: none;
    }

    .EweGoCartRanking_ewe-go-brzina2-img__QtYw2 {
        display: none;
    }

    .EweGoCartRanking_ewe-go-brzina1-img__25Cv9 {
        display: none;
    }

    .EweGoCartRanking_ewe-go-brzina2-holder__3WM0b,
    .EweGoCartRanking_ewe-go-brzina1-holder__VGm4u {
        display: none;
    }

    .EweGoCartRanking_ewe-go-cart-img__2jWzs {
        width: 75%;
    }

    .EweGoCartRanking_button-go-carting-your-coins__3DxCk {
        width: 300px;
    }

    .EweGoCartRanking_ewe-go-cart-img-top__3GgYf {
        margin-top: 65px;
    }

    .EweGoCartRanking_ewe-go-carting-your-coins__28WRR {
        margin-bottom: 20px;
    }

    .EweGoCartRanking_ranking-list__1oEoa {
        margin-bottom: 20px;
    }

    .EweGoCartRanking_ewe-go-cart-img__2jWzs {
        margin-top: 10px;
    }
}

@media(min-width: 500px) and (max-width:649px) {
    .EweGoCartRanking_ewe-go-cart-formula1-img__6svBv {
        display: none;
    }

    .EweGoCartRanking_ewe-go-cart-formula2-img__11b7I {
        display: none;
    }

    .EweGoCartRanking_ewe-go-brzina2-img__QtYw2 {
        display: none;
    }

    .EweGoCartRanking_ewe-go-brzina1-img__25Cv9 {
        display: none;
    }

    .EweGoCartRanking_ewe-go-brzina2-holder__3WM0b,
    .EweGoCartRanking_ewe-go-brzina1-holder__VGm4u {
        display: none;
    }

    .EweGoCartRanking_ewe-go-cart-img__2jWzs {
        width: 75%;
    }

    .EweGoCartRanking_button-go-carting-your-coins__3DxCk {
        width: 300px;
    }

    .EweGoCartRanking_ewe-go-carting-your-coins__28WRR {
        margin-bottom: 20px;
    }

    .EweGoCartRanking_ewe-go-cart-img-top__3GgYf {
        margin-top: 65px;
    }

}

@media(min-width: 650px) and (max-width:991px) {
    /* .ewe-go-cart-formula1-img {
        display: none;
    }

    .ewe-go-cart-formula2-img {
        display: none;
    } */

    .EweGoCartRanking_ewe-go-brzina2-img__QtYw2 {
        display: none;
    }

    .EweGoCartRanking_ewe-go-cart-formula2-img__11b7I {
        width: 90%;
    }

    .EweGoCartRanking_ewe-go-brzina1-img__25Cv9 {
        display: none;
    }

    .EweGoCartRanking_ewe-go-brzina2-holder__3WM0b,
    .EweGoCartRanking_ewe-go-brzina1-holder__VGm4u {
        display: none;
    }

    .EweGoCartRanking_ewe-go-cart-img__2jWzs {
        width: 100%;
    }

    .EweGoCartRanking_button-go-carting-your-coins__3DxCk {
        width: 300px;
    }

    .EweGoCartRanking_ewe-go-cart-img-top__3GgYf {
        margin-top: 65px;
    }
}


@media(min-width: 992px) and (max-width:1023px) {

    /* .ewe-go-cart-formula1-img {
        display: none;
    }

    .ewe-go-cart-formula2-img {
        display: none;
    } */

    .EweGoCartRanking_ewe-go-cart-formula2-img__11b7I {
        width: 90%;
    }

    /* .ewe-go-brzina2-img {
        display: none;
    }

    .ewe-go-brzina1-img {
        display: none;
    } */

    .EweGoCartRanking_ewe-go-brzina2-holder__3WM0b,
    .EweGoCartRanking_ewe-go-brzina1-holder__VGm4u {
        display: none;
    }

    .EweGoCartRanking_ewe-go-cart-img__2jWzs {
        width: 100%;
    }

    .EweGoCartRanking_button-go-carting-your-coins__3DxCk {
        width: 300px;
    }

    .EweGoCartRanking_ewe-go-cart-img-top__3GgYf {
        margin-top: 65px;
    }

}

@media(min-width: 1024px) and (max-width:1537px) {

    .EweGoCartRanking_ewe-go-cart-img__2jWzs {
        width: 70%;
    }
}

@media (min-width:1920px) {
    .EweGoCartRanking_ewe-go-cart-img__2jWzs {
        width: 68%;
    }
}


@media(min-width: 1537px) {
    .EweGoCartRanking_ewe-go-cart-img__2jWzs {
        width: 70%;
    }
}
@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Bold.179a2453.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Medium.230a7590.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

.EweGoCartProfile_main-container__2KfAp {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background-color: #000;
    color: #5b5555;
    font-family: 'Axiforma';
    font-weight: bold;
}

.EweGoCartProfile_custom-container-fluid__3XamX {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fffbed;
    justify-content: center;
    align-items: center;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-size: 50%;
    background-position: top left;
    background-repeat: no-repeat;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.EweGoCartProfile_ewe-go-cart-my-coins__3CCkq {
    background-color: #fffbed;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-size: 50%;
    background-position: top left;
    background-repeat: no-repeat;
}

.EweGoCartProfile_ewe-go-cart-content-header__1xF0y {
    margin-top: 30px;
}


@media(min-width: 500px) and (max-width:649px) {
    .EweGoCartProfile_ewe-go-cart-formula1-img__SFbJL {
        display: none;
    }

    .EweGoCartProfile_ewe-go-cart-formula2-img__32zmm {
        display: none;
    }

    .EweGoCartProfile_ewe-go-brzina2-img__Em3G5 {
        display: none;
    }

    .EweGoCartProfile_ewe-go-brzina1-img__2hjv8 {
        display: none;
    }

    .EweGoCartProfile_ewe-go-brzina2-holder__18T6e,
    .EweGoCartProfile_ewe-go-brzina1-holder__2Qgn8 {
        display: none;
    }

    .EweGoCartProfile_ewe-go-cart-img__3AR5x {
        width: 50%;
    }

}

@media(min-width: 650px) and (max-width:991px) {
    .EweGoCartProfile_ewe-go-cart-formula1-img__SFbJL {
        display: none;
    }

    .EweGoCartProfile_ewe-go-cart-formula2-img__32zmm {
        display: none;
    }

    .EweGoCartProfile_ewe-go-brzina2-img__Em3G5 {
        display: none;
    }

    .EweGoCartProfile_ewe-go-brzina1-img__2hjv8 {
        display: none;
    }

    .EweGoCartProfile_ewe-go-brzina2-holder__18T6e,
    .EweGoCartProfile_ewe-go-brzina1-holder__2Qgn8 {
        display: none;
    }

    .EweGoCartProfile_ewe-go-cart-img__3AR5x {
        width: 50%;
    }
}


@media(min-width: 992px) and (max-width:1023px) {
    .EweGoCartProfile_ewe-go-cart-formula1-img__SFbJL {
        display: none;
    }

    .EweGoCartProfile_ewe-go-cart-formula2-img__32zmm {
        display: none;
    }

    .EweGoCartProfile_ewe-go-brzina2-img__Em3G5 {
        display: none;
    }

    .EweGoCartProfile_ewe-go-brzina1-img__2hjv8 {
        display: none;
    }

    .EweGoCartProfile_ewe-go-brzina2-holder__18T6e,
    .EweGoCartProfile_ewe-go-brzina1-holder__2Qgn8 {
        display: none;
    }

    .EweGoCartProfile_ewe-go-cart-img__3AR5x {
        width: 50%;
    }
}

@media(min-width: 1024px) and (max-width:1537px) {

    .EweGoCartProfile_ewe-go-cart-img__3AR5x {
        width: 70%;
    }
}

.EweGoCartProfile_ewe-go-cart-img-top__2ZwlF {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 100%;
    max-width: 100%; */

}

.EweGoCartProfile_ewe-go-cart-img-top__2ZwlF,
.EweGoCartProfile_ewe-go-cart-img-bottom__3lyzz {
    width: 100%;
}

.EweGoCartProfile_ewe-go-cart-content-header__1xF0y {
    font-family: 'Axiforma';
    color: #DC0000;
    font-size: 55px;
    letter-spacing: 30px;
    font-size: 25px;
    text-align: center;
    font-weight: 700;
}

.EweGoCartProfile_ewe-go-cart-total-subtitle__3HZzf {
    font-family: 'Axiforma';
    color: white;
    font-size: 24px;
    letter-spacing: 10px;
    color: #DC0000;
}

.EweGoCartProfile_ewe-go-cart-content-header__1xF0y {
    text-align: center;
    margin-bottom: 50px;
    /* border-bottom: 1px solid #DC0000; */
}

.EweGoCartProfile_ewe-cart-go-style__C9MOK .EweGoCartProfile_MuiTableCell-head__1KAvo {
    background-color: transparent !important;
    color: #DC0000;
}

.EweGoCartProfile_ewe-cart-go-style__C9MOK span:hover,
.EweGoCartProfile_ewe-cart-go-style__C9MOK .EweGoCartProfile_MuiTableSortLabel-root__1vypz.EweGoCartProfile_MuiTableSortLabel-active__Lz4k_,
.EweGoCartProfile_ewe-cart-go-style__C9MOK .EweGoCartProfile_MuiTableSortLabel-icon__2GC3e,
.EweGoCartProfile_ewe-cart-go-style__C9MOK .EweGoCartProfile_MuiInputBase-root__1yl7J,
.EweGoCartProfile_ewe-cart-go-style__C9MOK .EweGoCartProfile_MuiIconButton-root__3qANF.EweGoCartProfile_Mui-disabled__144gH,
.EweGoCartProfile_ewe-cart-go-style__C9MOK span {
    color: #DC0000 !important;
}

.EweGoCartProfile_ewe-cart-go-style__C9MOK {
    /* background-image: url(https://resource.ewe.rs/media/new-year-loyalty/zvezde.png); */
    /* background-image: url(https://resource.ewe.rs/media/Sencor-Akcililili2.jpg);*/
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-color: #DC0000;


}

.EweGoCartProfile_ewe-cart-go-style1__26cNF {
    background-color: black !important;
    color: white !important;
    border: 1px solid red !important;
}

.EweGoCartProfile_ewe-cart-go-total__1ic6u {
    color: #DC0000;
    font-family: 'Axiforma';
    font-size: 28px;
    letter-spacing: 5px;
    margin: 40px 0;
}

@media(max-width:1023px) {
    .EweGoCartProfile_ewe-go-cart-my-coins__3CCkq {
        margin-top: 65px;
    }

    .EweGoCartProfile_ewe-go-cart-total-subtitle__3HZzf,
    .EweGoCartProfile_ewe-cart-go-total__1ic6u {
        font-size: 20px;
    }
}
@font-face {
    font-family: '04b03';
    src: url(/static/media/04B_03__.ed67d9fa.TTF) format('truetype');
    font-weight: normal;
    font-style: normal;
}

.TehnovacijeRegistrationPage_main-container-tehnovacije__1OuIV {
    background-image: url('https://resource.ewe.rs/media/tehnovacije/tehnovacije-prijava-pozadina.png');
    background-size: cover;
    height: 100vh;
    width: 100%;
    background-position: left;
    background-repeat: no-repeat;
    position: relative;
}

.TehnovacijeRegistrationPage_form-container-tehnovacije__3Mnr0,
.TehnovacijeRegistrationPage_form-and-vertical-text-container__35ER7,
.TehnovacijeRegistrationPage_form-tehnovacije__19Zp5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TehnovacijeRegistrationPage_inner-container-tehnovacije__1wE8T {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    max-height: 85%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
}

.TehnovacijeRegistrationPage_tehnovacije-logo__h9xWP {
    position: absolute;
    top: 10%;
    width: 35%;
}

.TehnovacijeRegistrationPage_form-container-tehnovacije__3Mnr0 img {
    max-width: 100%;
    max-height: 100%;
}

.TehnovacijeRegistrationPage_form-and-vertical-text-container__35ER7 {
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.TehnovacijeRegistrationPage_vertical-text__1cXqm {
    font-family: '04b03';
    font-weight: 500;
    color: #b9ff1a;
    writing-mode: vertical-rl;
    letter-spacing: 0.4rem;
    position: absolute;
    font-size: 0.8rem;
    right: 7%;
}

.TehnovacijeRegistrationPage_holder-form-tehnovacije__2vSea {
    width: 56%;
    margin-top: 12%;
}

.TehnovacijeRegistrationPage_input-tehnovacije__2ZBQs {
    background-color: #fff;
    border: 2px solid transparent;
    border-radius: 0.5rem;
    /* margin-bottom: 0.3rem; */
    height: calc(1.2em + 0.35rem + 2px);
}

.TehnovacijeRegistrationPage_holder-form-tehnovacije__2vSea .TehnovacijeRegistrationPage_input-tehnovacije__2ZBQs:focus {
    box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.4)
}

.TehnovacijeRegistrationPage_tehnovacije-title__3UQnH {
    margin-bottom: 2%;
}

.TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h1 {
    font-size: 1.3rem;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0;
    color: #b9ff1a;
}

.TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h2 {
    font-size: 0.9rem;
    color: #fff;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.TehnovacijeRegistrationPage_label-tehnovacije__2m_54 {
    font-size: 0.6rem;
    margin-bottom: 0.2rem;
    color: #b9ff1a;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.TehnovacijeRegistrationPage_btn-holder-tehnovacije__2EwwZ {
    text-align: center;
}

.TehnovacijeRegistrationPage_button-tehnovacije__3Eys2 {
    display: inline-block;
    color: #4c4848;
    background-color: #b9ff1a;
    line-height: 1.6;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 0.8rem;
    padding: 0.35rem 1.2rem;
    margin-top: 7%;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.TehnovacijeRegistrationPage_button-tehnovacije__3Eys2:hover {
    background-color: #4c4848;
    color: #b9ff1a;
}

.TehnovacijeRegistrationPage_mask-captcha-tehnovacije__1iaNC {
    background: #fff;
    opacity: 0;
    width: 100%;
    height: 51px;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1;
}

.TehnovacijeRegistrationPage_captcha-tehnovacije__11tIn {
    top: 30%;
    left: 50%;
    position: absolute;
    transform: translate(-40%, 0);
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

@media(min-width:768px) {
    .TehnovacijeRegistrationPage_inner-container-tehnovacije__1wE8T {
        max-height: 95%;
    }

    .TehnovacijeRegistrationPage_input-tehnovacije__2ZBQs {
        margin-bottom: 6%;
        height: calc(1.8em + 0.75rem + 2px);
        border-radius: 0.8rem;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH {
        margin-bottom: 7%;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h1 {
        font-size: 2.4rem;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h2 {
        font-size: 1.4rem;
    }

    .TehnovacijeRegistrationPage_label-tehnovacije__2m_54 {
        font-size: 1rem;
    }

    .TehnovacijeRegistrationPage_button-tehnovacije__3Eys2 {
        font-size: 1.5rem;
        padding: 0.5rem 1.85rem;
        margin-top: 8%;
    }

    .TehnovacijeRegistrationPage_vertical-text__1cXqm {
        font-size: 2rem;
        right: 3.3%;
    }
}

@media(min-width:992px) {
    .TehnovacijeRegistrationPage_inner-container-tehnovacije__1wE8T {
        max-height: 85%;
    }

    .TehnovacijeRegistrationPage_input-tehnovacije__2ZBQs {
        margin-bottom: -2%;
        height: calc(1.2em + 0.45rem + 2px);
        border-radius: 0.6rem;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH {
        margin-bottom: -2%;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h1 {
        font-size: 1.3em;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h2 {
        font-size: 0.8rem;
    }

    .TehnovacijeRegistrationPage_label-tehnovacije__2m_54 {
        font-size: 0.6rem;
    }

    .TehnovacijeRegistrationPage_button-tehnovacije__3Eys2 {
        font-size: 0.8rem;
        padding: 0.2rem 1.15rem;
        margin-top: 6%;
    }

    .TehnovacijeRegistrationPage_vertical-text__1cXqm {
        font-size: 0.8rem;
        right: 7%;
    }

    .TehnovacijeRegistrationPage_captcha-tehnovacije__11tIn {
        font-size: 1.6rem;
        top: 24%;
    }
}

@media(min-width:1200px) {
    .TehnovacijeRegistrationPage_main-container-tehnovacije__1OuIV {
        background-position: left bottom;
    }

    .TehnovacijeRegistrationPage_inner-container-tehnovacije__1wE8T {
        max-height: 100%;
    }

    .TehnovacijeRegistrationPage_input-tehnovacije__2ZBQs {
        /* margin-bottom: 3.5%; */
        height: calc(1.4rem + 0.25rem + 2px);
        border-radius: 0.6rem;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH {
        margin-bottom: 7%;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h1 {
        font-size: 1.6em;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h2 {
        font-size: 0.9rem;
    }

    .TehnovacijeRegistrationPage_label-tehnovacije__2m_54 {
        font-size: 0.6rem;
    }

    .TehnovacijeRegistrationPage_button-tehnovacije__3Eys2 {
        font-size: 1rem;
        padding: 0.35rem 1.45rem;
        margin-top: 8%;
    }

    .TehnovacijeRegistrationPage_vertical-text__1cXqm {
        font-size: 0.9rem;
        right: 7%;
    }

    .TehnovacijeRegistrationPage_captcha-tehnovacije__11tIn {
        font-size: 1.6rem;
        top: 26%;
    }

}

@media(min-width:1536px) {
    .TehnovacijeRegistrationPage_input-tehnovacije__2ZBQs {
        margin-bottom: 2%;
        height: calc(1.6em + 0.45rem + 2px);
        border-radius: 0.6rem;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH {
        margin-bottom: 2%;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h1 {
        font-size: 1.8em;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h2 {
        font-size: 1.1rem;
    }

    .TehnovacijeRegistrationPage_label-tehnovacije__2m_54 {
        font-size: 0.7rem;
    }

    .TehnovacijeRegistrationPage_button-tehnovacije__3Eys2 {
        font-size: 1.2rem;
        padding: 0.45rem 1.65rem;
        margin-top: 9%;
    }

    .TehnovacijeRegistrationPage_vertical-text__1cXqm {
        font-size: 1.1rem;
        right: 6%;
    }
}


@media(min-width:1680px) {

    .TehnovacijeRegistrationPage_inner-container-tehnovacije__1wE8T {
        max-height: 95%;
    }

    .TehnovacijeRegistrationPage_input-tehnovacije__2ZBQs {
        margin-bottom: 2%;
        height: calc(1.6em + 0.75rem + 2px);
        border-radius: 0.8rem;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH {
        margin-bottom: 7%;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h1 {
        font-size: 2.4rem;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h2 {
        font-size: 1.4rem;
    }

    .TehnovacijeRegistrationPage_label-tehnovacije__2m_54 {
        font-size: 1rem;
    }

    .TehnovacijeRegistrationPage_button-tehnovacije__3Eys2 {
        font-size: 1.5rem;
        padding: 0.5rem 1.85rem;
        margin-top: 8%;
    }

    .TehnovacijeRegistrationPage_vertical-text__1cXqm {
        font-size: 1.5rem;
        right: 4.8%;
    }

    .TehnovacijeRegistrationPage_captcha-tehnovacije__11tIn {
        font-size: 1.8rem;
        top: 24%;
    }

}


@media(min-width:1920px) {

    .TehnovacijeRegistrationPage_input-tehnovacije__2ZBQs {
        margin-bottom: 2%;
        height: calc(2em + 0.55rem + 2px);
        border-radius: 0.8rem;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h1 {
        font-size: 2.6rem;
    }

    .TehnovacijeRegistrationPage_tehnovacije-title__3UQnH h2 {
        font-size: 1.45rem;
    }

    .TehnovacijeRegistrationPage_button-tehnovacije__3Eys2 {
        font-size: 1.6rem;
        padding: 0.65rem 2.2rem;
        margin-top: 8.5%;
    }

    .TehnovacijeRegistrationPage_vertical-text__1cXqm {
        font-size: 1.8rem;
        right: 3.8%;
    }

    .TehnovacijeRegistrationPage_captcha-tehnovacije__11tIn {
        top: 23%;
        font-size: 2rem;
    }
}

@media(min-width:2560px) {
    .TehnovacijeRegistrationPage_vertical-text__1cXqm {
        font-size: 1.8rem;
        right: 4.5%;
    }

    .TehnovacijeRegistrationPage_captcha-tehnovacije__11tIn {
        top: 24%;
    }
}
