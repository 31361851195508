@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: '04b03';
    src: url('./fonts/04B_03/04B_03__.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.main-container-tehnovacije {
    background-image: url('https://resource.ewe.rs/media/tehnovacije/tehnovacije-prijava-pozadina.png');
    background-size: cover;
    height: 100vh;
    width: 100%;
    background-position: left;
    background-repeat: no-repeat;
    position: relative;
}

.form-container-tehnovacije,
.form-and-vertical-text-container,
.form-tehnovacije {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner-container-tehnovacije {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    max-height: 85%;
    width: fit-content;
    position: relative;
}

.tehnovacije-logo {
    position: absolute;
    top: 10%;
    width: 35%;
}

.form-container-tehnovacije img {
    max-width: 100%;
    max-height: 100%;
}

.form-and-vertical-text-container {
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.vertical-text {
    font-family: '04b03';
    font-weight: 500;
    color: #b9ff1a;
    writing-mode: vertical-rl;
    letter-spacing: 0.4rem;
    position: absolute;
    font-size: 0.8rem;
    right: 7%;
}

.holder-form-tehnovacije {
    width: 56%;
    margin-top: 12%;
}

.input-tehnovacije {
    background-color: #fff;
    border: 2px solid transparent;
    border-radius: 0.5rem;
    /* margin-bottom: 0.3rem; */
    height: calc(1.2em + 0.35rem + 2px);
}

.holder-form-tehnovacije .input-tehnovacije:focus {
    box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.4)
}

.tehnovacije-title {
    margin-bottom: 2%;
}

.tehnovacije-title h1 {
    font-size: 1.3rem;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0;
    color: #b9ff1a;
}

.tehnovacije-title h2 {
    font-size: 0.9rem;
    color: #fff;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.label-tehnovacije {
    font-size: 0.6rem;
    margin-bottom: 0.2rem;
    color: #b9ff1a;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.btn-holder-tehnovacije {
    text-align: center;
}

.button-tehnovacije {
    display: inline-block;
    color: #4c4848;
    background-color: #b9ff1a;
    line-height: 1.6;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 0.8rem;
    padding: 0.35rem 1.2rem;
    margin-top: 7%;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.button-tehnovacije:hover {
    background-color: #4c4848;
    color: #b9ff1a;
}

.mask-captcha-tehnovacije {
    background: #fff;
    opacity: 0;
    width: 100%;
    height: 51px;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1;
}

.captcha-tehnovacije {
    top: 30%;
    left: 50%;
    position: absolute;
    transform: translate(-40%, 0);
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

@media(min-width:768px) {
    .inner-container-tehnovacije {
        max-height: 95%;
    }

    .input-tehnovacije {
        margin-bottom: 6%;
        height: calc(1.8em + 0.75rem + 2px);
        border-radius: 0.8rem;
    }

    .tehnovacije-title {
        margin-bottom: 7%;
    }

    .tehnovacije-title h1 {
        font-size: 2.4rem;
    }

    .tehnovacije-title h2 {
        font-size: 1.4rem;
    }

    .label-tehnovacije {
        font-size: 1rem;
    }

    .button-tehnovacije {
        font-size: 1.5rem;
        padding: 0.5rem 1.85rem;
        margin-top: 8%;
    }

    .vertical-text {
        font-size: 2rem;
        right: 3.3%;
    }
}

@media(min-width:992px) {
    .inner-container-tehnovacije {
        max-height: 85%;
    }

    .input-tehnovacije {
        margin-bottom: -2%;
        height: calc(1.2em + 0.45rem + 2px);
        border-radius: 0.6rem;
    }

    .tehnovacije-title {
        margin-bottom: -2%;
    }

    .tehnovacije-title h1 {
        font-size: 1.3em;
    }

    .tehnovacije-title h2 {
        font-size: 0.8rem;
    }

    .label-tehnovacije {
        font-size: 0.6rem;
    }

    .button-tehnovacije {
        font-size: 0.8rem;
        padding: 0.2rem 1.15rem;
        margin-top: 6%;
    }

    .vertical-text {
        font-size: 0.8rem;
        right: 7%;
    }

    .captcha-tehnovacije {
        font-size: 1.6rem;
        top: 24%;
    }
}

@media(min-width:1200px) {
    .main-container-tehnovacije {
        background-position: left bottom;
    }

    .inner-container-tehnovacije {
        max-height: 100%;
    }

    .input-tehnovacije {
        /* margin-bottom: 3.5%; */
        height: calc(1.4rem + 0.25rem + 2px);
        border-radius: 0.6rem;
    }

    .tehnovacije-title {
        margin-bottom: 7%;
    }

    .tehnovacije-title h1 {
        font-size: 1.6em;
    }

    .tehnovacije-title h2 {
        font-size: 0.9rem;
    }

    .label-tehnovacije {
        font-size: 0.6rem;
    }

    .button-tehnovacije {
        font-size: 1rem;
        padding: 0.35rem 1.45rem;
        margin-top: 8%;
    }

    .vertical-text {
        font-size: 0.9rem;
        right: 7%;
    }

    .captcha-tehnovacije {
        font-size: 1.6rem;
        top: 26%;
    }

}

@media(min-width:1536px) {
    .input-tehnovacije {
        margin-bottom: 2%;
        height: calc(1.6em + 0.45rem + 2px);
        border-radius: 0.6rem;
    }

    .tehnovacije-title {
        margin-bottom: 2%;
    }

    .tehnovacije-title h1 {
        font-size: 1.8em;
    }

    .tehnovacije-title h2 {
        font-size: 1.1rem;
    }

    .label-tehnovacije {
        font-size: 0.7rem;
    }

    .button-tehnovacije {
        font-size: 1.2rem;
        padding: 0.45rem 1.65rem;
        margin-top: 9%;
    }

    .vertical-text {
        font-size: 1.1rem;
        right: 6%;
    }
}


@media(min-width:1680px) {

    .inner-container-tehnovacije {
        max-height: 95%;
    }

    .input-tehnovacije {
        margin-bottom: 2%;
        height: calc(1.6em + 0.75rem + 2px);
        border-radius: 0.8rem;
    }

    .tehnovacije-title {
        margin-bottom: 7%;
    }

    .tehnovacije-title h1 {
        font-size: 2.4rem;
    }

    .tehnovacije-title h2 {
        font-size: 1.4rem;
    }

    .label-tehnovacije {
        font-size: 1rem;
    }

    .button-tehnovacije {
        font-size: 1.5rem;
        padding: 0.5rem 1.85rem;
        margin-top: 8%;
    }

    .vertical-text {
        font-size: 1.5rem;
        right: 4.8%;
    }

    .captcha-tehnovacije {
        font-size: 1.8rem;
        top: 24%;
    }

}


@media(min-width:1920px) {

    .input-tehnovacije {
        margin-bottom: 2%;
        height: calc(2em + 0.55rem + 2px);
        border-radius: 0.8rem;
    }

    .tehnovacije-title h1 {
        font-size: 2.6rem;
    }

    .tehnovacije-title h2 {
        font-size: 1.45rem;
    }

    .button-tehnovacije {
        font-size: 1.6rem;
        padding: 0.65rem 2.2rem;
        margin-top: 8.5%;
    }

    .vertical-text {
        font-size: 1.8rem;
        right: 3.8%;
    }

    .captcha-tehnovacije {
        top: 23%;
        font-size: 2rem;
    }
}

@media(min-width:2560px) {
    .vertical-text {
        font-size: 1.8rem;
        right: 4.5%;
    }

    .captcha-tehnovacije {
        top: 24%;
    }
}