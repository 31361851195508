@font-face {
    font-family: 'Axiforma';
    src: url('./fonts/axiforma/Axiforma-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('./fonts/axiforma/Axiforma-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

.main-container {
    font-family: 'Axiforma';
    position: relative;
    background-color: #000;
    color: #5b5555;
}

.custom-container-fluid {
    position: relative;
    width: 100%;
    height: 100vh;
    margin-right: auto;
    margin-left: auto;
    background-color: #fffbed;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-repeat: no-repeat;
}

.login-formula {
    width: 25%;
    position: absolute;
    top: 70%;
    right: 5%;

}

.full-width-image {
    width: 100%;
    height: auto;
    display: block;
}

.ewe-go-cart-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.ewe-go-cart-login-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.login-logo-title-holder {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    gap: 10px;
}

.login-logo-title h4 {
    font-family: 'Axiforma';
    font-weight: 700;
    margin-bottom: 10px;
}

.login-title-image {
    width: 100%;
}

.login-title {
    font-weight: 700;
    font-family: 'Axiforma';
}

.login-custom-input {
    border: 3px solid #db0000;
    border-radius: 22px;
    background-color: transparent;
    padding: 0 20px;
    width: 330px;
    height: 50px;
    box-sizing: border-box;
}

.login-input-label {
    display: block;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Axiforma';
    font-weight: 500;
}

.login-form-button-holder {
    margin-top: 70px;
}

.login-form-button {
    width: 450px;
    height: 70px;
    border-radius: 35px;
    background-color: #db0000;
    color: white;
    border: none;
    cursor: pointer;
    font-family: 'Axiforma';
    font-size: 26px;
    font-weight: 500;
    transition: background-color 0.3s, transform 0.2s;
}

.login-form-button:hover {
    background-color: #b30000;
}

.login-form-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(219, 0, 0, 0.5);
}

.login-form-button:active {
    background-color: #990000;
    transform: scale(0.98);
}

.full-width-image-bottom {
    position: absolute;
    bottom: 0%;
    width: 100%;
    height: auto;
    display: block;
    background-color: #fffbed;
}

.go-cart-error {
    display: block;
    color: #db0000;
}

/* RESPONSIVE */

/* For screens smaller than 1920px */
@media (max-width: 1920px) {

    .login-title-image {
        margin-top: 30px;
        width: 700px;
    }

    .login-form-button-holder {
        margin-top: 40px;
    }

    .login-form-button {
        height: 60px;
        margin-bottom: 40px;
    }
}


/* For screens smaller than 1440px */
@media (max-width: 1440px) {

    .login-title-image {
        width: 400px;
    }

    .full-width-image,
    .full-width-image-bottom {
        display: none;
    }

    .login-title {
        font-size: 22px;
    }

    .login-custom-input {
        height: 40px;
    }

    .login-form-button {
        height: 50px;
        font-size: 22px;
    }
}

@media (max-width: 1024px) {

    .login-custom-input {
        height: 40px;
    }

    .login-form-button {
        width: 400px;
        height: 50px;
        font-size: 22px;
    }

    .login-formula {
        top: 75%;
        right: 0;
    }

}

/* For screens equal to or smaller than 768px */
@media (max-width: 768px) {

    .login-form-button {
        width: 60%;
    }

    .login-formula {
        left: 50%;
        top: 85%;
        width: 35%;
        transform: translateX(-50%);
    }
}

@media (max-width: 767px) {
    .custom-container-fluid {
        margin-top: 15px;
    }

    .login-logo-title-holder {
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .login-logo {
        width: 50%;
    }

    .login-logo-title h4 {
        font-size: 14px;
    }

    .login-title-image {
        width: 100%;
        margin-top: 0px;
    }

    h1.login-title {
        font-size: 20px;
    }

    .login-form-button {
        width: 100%;
    }

    .login-formula {
        top: 85%;
        width: 50%;
    }

    .login-form-button {
        height: 40px;
        font-size: 18px;
    }

    .login-custom-input {
        width: 100%;
    }

}