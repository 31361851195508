.stats-order-content {
  /* overflow: hidden; */
}

.stats-order-content-holder h1 {
  margin-bottom: 30px;
  border-bottom: 1px solid #cacaca;
  padding-bottom: 5px;
  font-size: 28px;
  font-weight: bold;
}

.btn-custom-editing-cart {
  display: inline-block;
  cursor: pointer;
}

@media (max-width: 450px) {
  .stats-order-content {
    padding: 5px 0;
    margin-top: 65px;
  }

  .stats-order-content-holder h1 {
    margin-bottom: 0;
  }
}

@media (max-width: 360px) {
  .btn-custom-editing-cart {
    width: 80px;
  }
}
.cargo-id-item:hover {
  cursor: pointer;
  color: #3498db;
  text-decoration: underline;
}