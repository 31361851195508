.container-position-header {
    padding-top: 57px;
}

.container-text-block {
    margin: 0 auto;
    text-align: center;
    margin-top: -120px;
}

p.title-block-humanity {
    font-size: 65px;
    letter-spacing: 2px;
    margin-bottom: 60px;
}

p.title-block-humanity2 {
    font-size: 30pt;
    letter-spacing: 1px;
    margin-bottom: 60px;
    font-weight: bold;
}

p.body-block-humanity {
    font-size: 20px !important;
    margin-bottom: 59px !important;
}

p.content-body-block-3,
.content3 {
    float: right;
    padding-top: 100px;
    font-size: 19px !important;
    padding-bottom: 100px;
    color: #000;
    margin-right: 24px;
}

.image-block-rotate {
    transform: rotate(12deg);
    /* position: absolute; */
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.button-content-block-5 {
    background: radial-gradient(black, transparent);
    background: #00af9e;
    color: #fff;
    padding: 11px 50px;
    border-radius: 20px;
    font-size: 1vw;
}

.row-content-background {
    background-image: url("https://resource.ewe.rs/gui/humanity/footer.jpg");
    padding: 300px 0px 100px;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-block-rotate-second {
    transform: rotate(-12deg);
    /* position: absolute; */
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    margin-left: 50px;
}

.img-style {
    position: unset !important;
}

.title-content-block-5 {
    font-size: 30px !important;
    margin-bottom: 30px !important;
    color: #fff;
}

.content-body-block-5 {
    font-size: 1.09vw;
    margin-bottom: 35px;
    color: #fff;
}

p.content-body-block-4 {
    float: right;
    padding-top: 100px;
    font-size: 19px !important;
    padding-bottom: 100px;
    color: #000;
}

p.content-body-block-6 {
    float: right;
    padding-top: 120px;
    font-size: 19px !important;
    color: #000;
}

.container-block-footer {
    text-align: right;
}

.content-body-block-6 {
    font-size: 20px !important;
}

@media (max-width: 1920px) and (min-width: 1025px) {
    .title-tree {
        margin-top: 110px !important;
    }
}

@media (max-width: 1920px) and (min-width: 768px) {
    p.title-block-humanity2 {
        margin-top: 110px !important;
    }
}

@media (max-width: 1536px) and (min-width: 1025px) {
    p.title-block-humanity {
        margin-top: 60px;
    }

    .title-tree {
        margin-top: 110px !important;
    }

    .content-body-block-6 {
        font-size: 19px !important;
    }
}

@media (max-width: 1218px) and (min-width: 1021px) {

    .image-block-rotate,
    .image-block-rotate-second {
        position: unset;
        width: 80%;
    }

    .img-style {
        width: 90%;
        position: unset !important;
        margin-left: 0 !important;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    p.title-block-humanity2 {
        margin-top: 0 !important;
    }

    .container-text-block {
        margin-top: 0 !important;
    }

    p.title-block-humanity2 {
        font-size: 28px;
    }

    p.content-body-block-3 {
        font-size: 17px !important;
        padding: 40px 0 !important;
    }

    .content3 {
        padding-top: 0;
        padding-bottom: 20px !important;
        font-size: 17px !important;
    }

    p.body-block-humanity {
        font-size: 17px !important;
        margin-bottom: 65px;
    }

    p.content-body-block-6 {
        padding-top: 95px;
        font-size: 17px !important;
    }

    p.content-body-block-4 {
        font-size: 17px !important;
        margin-left: 35px;
        padding-top: 0;
    }

    .image-block-rotate-second {
        margin-left: 0;
    }

    .content-body-block-5,
    .button-content-block-5 {
        font-size: 14px;
    }

    .content-body-block-6 {
        font-size: 17px !important;
    }

    .title-tree {
        margin-top: 15px !important;
    }

    .img-style {
        position: unset !important;
        margin-left: 0 !important;
    }
}

@media (max-width: 991px) and (min-width:768px) {
    .second-row-page {
        margin-top: 0 !important;
    }

    .last-block-new {
        padding-top: 20px;
    }

    .img-margin {
        margin-top: 45px !important;
    }
}

@media (max-width: 1019px) {
    .humanity-page {
        margin-top: 63px;
    }
}

@media (max-width: 767px) {

    p.title-block-humanity2,
    p.title-block-humanity {
        font-size: 20pt !important;
    }

    p.body-block-humanity,
    p.content-body-block-4,
    p.content-body-block-6,
    .content3 {
        font-size: 13pt !important;
    }

    p.content-body-block-3 {
        float: none !important;
        margin-right: 0px !important;
        text-align: center !important;
        padding-top: 50px;
        padding-bottom: 50px;
        font-size: 13pt !important;
    }

    .content3 {
        text-align: center !important;
        margin-right: 0 !important;
    }

    .image-block-rotate {
        transform: rotate(12deg);
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
        width: 80%;
        margin: 0 auto;
        position: relative;
        margin-top: -20px;
    }

    .row-content-background {
        padding: 130px 0px 75px;
    }

    .title-content-block-5 {
        text-align: center;
    }

    .content-body-block-5 {
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
    }

    .button-content-block-5 {
        font-size: 14px;
    }

    .container-block-footer {
        text-align: center;
    }

    .image-block-rotate-second {
        transform: rotate(-12deg);
        position: relative;
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
        margin-left: 0px;
        width: 80%;
        margin: auto;
    }

    .content-body-block-4 {
        text-align: center;
        padding-bottom: 0px !important;
    }

    .content-body-block-6 {
        padding-top: 40px !important;
        font-size: 13pt !important;
    }

    .img-top-style {
        padding-top: 10px;
    }

    .container-text-block {
        margin-top: 0px !important;
    }

    .container-position-header {
        padding-top: 49px !important;
    }

    .page-style {
        padding: 20px;
    }

    .thumb-img {
        padding: 20px;
    }

    .second-row-page {
        margin-top: 0 !important;
    }

    .img-style {
        margin-left: 0 !important;
        position: unset;
    }
}