.loyalty-landing h1,
.loyalty-landing h2,
.loyalty-landing h3,
.loyalty-landing h4 {
	text-align: center;
	text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
}

.loyalty-landing h1 {
	font-size: 36px;
	font-weight: 700;
	color: #fff;
}

.loyalty-landing h1 span {
	color: #ff0427;
}

.loyalty-landing h2 {
	font-size: 34px;
	font-weight: 300;
	color: #ff0427;
	margin-bottom: 40px;
}

.loyalty-landing h2 strong {
	font-weight: 700;
}

.loyalty-landing h3 {
	font-size: 22px;
	font-weight: 500;
	color: #fff;
	margin-top: -35px;
	margin-bottom: 50px;
}

.loyalty-landing h4 {
	font-size: 20px;
	font-weight: 300;
	color: #fff;
	margin-bottom: 75px;
}

.landing-desc p {
	text-align: center;
	font-size: 18px;
	margin-bottom: 20px;
}

.loyalty-landing p {
	font-size: 16px;
	line-height: 1.6;
}

.footer-holder-left,
.footer-holder-right {
	text-align: center;
}

.footer-holder-left p {
	font-weight: 500;
}

.header-content-loyalty {
	padding: 25px 0px;
}

.top-holder-block {
	position: relative;
	width: 100%;
	padding-top: 40px;
}

.top-block-items {
	width: 100%;
	display: flex;
	color: #fff;
	text-align: center;
}

.top-block-item-logo {
	flex: 1;
	border-right: 2px solid #efb836;
	margin-right: 60px;
}

.top-block-item-logo img {
	max-width: 250px;
}

.top-block-item-text {
	flex: 2;
	padding: 40px 0px;
	text-transform: uppercase;
	text-align: left;
}

.top-block-item-text h2 {
	font-weight: 800;
	font-size: 35px;
	letter-spacing: 1px;
}

.top-block-item-text span {
	color: #efb836;
}

.top-block-item-text p {
	font-size: 20px;
	line-height: 25px;
	font-weight: 500;
	margin-top: 11px;
}

.banner-image-text-bottom-loyalty-landing {
	text-align: center;
	color: #fff;
}

.ly-landing-banner-title {
	display: flex;
	width: 100%;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.ly-landing-banner-title h2 {
	font-size: 50px;
	line-height: 45px;
	font-weight: 800;
	letter-spacing: 1px;
}

.ly-landing-banner-title-color {
	color: #efb836;
}

.ly-landing-banner-title-big {
	font-weight: 800;
	font-size: 70px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.loyalty-image-img-badge {
	margin-left: 10px;
	height: 95px;
}

.ly-landing-banner-description {
	font-size: 25px;
	line-height: 26px;
	text-transform: uppercase;
	padding-bottom: 40px;
	font-weight: 600;
	margin-top: 15px;
}

.loyalty-landing-homepage {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.landing-second-block-holder {
	position: relative;
	color: #3d2767;
}

.landing-second-block {
	background-color: #efb836;
}

.landing-second-block-items {
	display: flex;
}

.landing-second-block-left-side {
	flex: 1;
}

.landing-second-block-right-side {
	flex: 2;
	padding-top: 20px;
}

.landing-second-block-right-side h1 {
	text-transform: uppercase;
	font-size: 34px;
	line-height: 36px;
	font-weight: bold;
	color: #fff;
	margin-bottom: 20px;
}

.landing-second-block-right-side span {
	color: #3d2767;
}

.landing-second-block-right-side p {
	font-size: 16px;
	font-weight: 600;
}

.landing-second-block-image {
	transform: scale(1.3);
	max-width: 300px;
}

.landing-third-block {
	background-color: #1f1538;
	color: #fff;
	padding-top: 50px;
}

.landing-third-block-holder {
	position: relative;
}

.landing-third-block-items {
	display: flex;
}

.landing-third-block-left-side {
	flex: 1;
}

.landing-third-block-right-side {
	flex: 1;
}

.landing-third-block-image {
	/* max-width: 350px; */
	margin-top: -140px;
}

.landing-third-block-left-side {
	text-align: right;
	margin-top: 50px;
	text-transform: uppercase;
}

.landing-third-block-left-side p1 {
	font-weight: 800;
	font-size: 40px;
	line-height: 40px;
	letter-spacing: 1px;
}

.landing-third-block-left-side h2 {
	font-weight: 800;
	font-size: 40px;
	line-height: 40px;
	color: #efb836;
	margin-bottom: 40px;
	letter-spacing: 1px;
}

.landing-third-block-left-side p {
	font-size: 14px;
	font-weight: 400;
	text-transform: none;
	letter-spacing: 1px;
}

.landing-button-block {
	text-align: center;
	padding: 40px 0px;
}

.landing-button-block h3 {
	color: #1f1538;
	font-weight: bold;
	margin-bottom: 20px;
}

.landing-button-block-holder {
	cursor: pointer;
}

.landing-footer-block-holder {
	background-color: #1f1538;
	color: #fff;
	text-align: center;
	padding: 40px 0px;
}

.landing-footer-block {
	width: 100%;
}

.landing-footer-block p {
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0px;
	letter-spacing: 1px;
}

.banner-image-loyalty-landing {
	text-align: center;
	width: 100%;
}

.top-block-items {
	text-align: center;
}

@media (max-width: 1680px) {
	.loyalty-image-img-banner {
		max-width: 900px;
	}

	.top-block-item-logo img {
		max-width: 230px;
	}
}

@media (min-width: 1200px) {
	.loyalty-landing h1 {
		font-size: 46px;
		letter-spacing: 14px;
	}

	.loyalty-landing h2 {
		font-size: 44px;
	}

	.loyalty-landing h3 {
		font-size: 30px;
	}

	.loyalty-landing h4 {
		font-size: 24px;
		letter-spacing: 9px;
	}

	.landing-desc p {
		text-align: center;
		font-size: 22px;
		margin-bottom: 20px;
	}
}

@media (max-width: 1200px) and (min-width: 768px) {
	.container-small-style {
		margin-top: -10px !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.loyalty-landing h1 {
		font-size: 42px;
	}

	.landing-desc p {
		font-size: 20px;
	}
}

@media (max-width: 901px) {
	.main-content {
		margin-top: 60px;
	}

	.header-content-loyalty {
		padding: 20px 0px;
	}

	.loyalty-landing h1 {
		font-size: 30px;
	}

	.loyalty-landing h2 {
		font-size: 30px;
	}

	.loyalty-landing h3 {
		font-size: 18px;
	}

	.loyalty-landing h4 {
		font-size: 18px;
		margin-bottom: 30px;
	}

	.landing-third-block-image {
		max-width: 450px;
		margin-top: 0px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.loyalty-landing h1 {
		font-size: 38px;
	}

	.landing-desc p {
		font-size: 15px;
	}
}

@media (min-width: 768px) {
	.row-centered {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-holder-left {
		text-align: left;
	}
}

@media (max-width: 767px) {
	.container-small-style {
		margin-top: -25px !important;
	}

	.footer-holder-right {
		margin-top: 20px;
	}

	.landing-third-block-items {
		display: flex;
		flex-direction: column-reverse;
	}
}

.center-block-padding {
	padding-top: 80px;
}

@media (min-width: 320px) {
	.center-block-padding {
		padding-top: 25px;
	}
}

@media (max-width: 450px) {
	.top-block-items {
		flex-direction: column;
		margin-top: 20px;
	}

	.top-block-item-logo {
		border-right: 0;
		margin-right: 0px;
	}

	.top-block-item-text h2 {
		font-weight: bold;
		font-size: 25px;
		text-align: center;
	}

	.top-block-item-text p {
		font-size: 16px;
		text-align: center;
	}

	.top-block-item-text {
		padding: 10px 0 0 0;
	}

	.loyalty-image-img-banner {
		max-width: 100%;
	}

	.ly-landing-banner-title h2 {
		font-size: 35px;
		line-height: 35px;
		font-weight: bold;
	}

	.ly-landing-banner-title-big {
		font-size: 40px;
	}

	.loyalty-image-img-badge {
		margin-left: 10px;
		height: 70px;
	}

	.ly-landing-banner-description {
		font-size: 16px;
		margin-top: 5px;
	}

	.landing-second-block-image {
		max-width: 120px;
	}

	.landing-second-block-right-side h1 {
		font-size: 29px;
		line-height: 30px;
	}

	.landing-second-block-right-side {
		padding-right: 10px;
		margin-left: -30px;
	}

	.landing-second-block-right-side p {
		font-size: 15px;
	}

	.landing-third-block-items {
		flex-direction: column;
	}

	.landing-third-block-image {
		width: 100%;
		margin-top: -80px;
	}

	.landing-third-block-left-side {
		text-align: center;
		margin-top: 0px;
	}

	.landing-third-block-left-side h1 {
		font-size: 34px;
		line-height: 34px;
	}

	.landing-third-block-left-side h2 {
		font-size: 34px;
		line-height: 34px;
	}

	.landing-third-block-left-side p {
		padding: 0px 15px;
	}

	.landing-button-block-holder img {
		width: 100%;
	}

	.landing-footer-block-holder {
		padding: 40px 15px;
	}
}