.wish-list-table .editing-cart-items-table {
    overflow: visible;
    width: 100%;
    max-height: 100%;
}
.wish-list-table table.editing-cart-table thead th {
    background: #fff;
    text-align: left;
    padding: 8px;
    font-size: 14px;
    border-top: none;
    border-bottom: 0;
    text-transform: uppercase;
}
.wish-list-row-table {
    font-size: 15px;
    position: relative;

}
.wishlist-page table.editing-cart-table tbody td {
    padding: 10px 5px;
    vertical-align: middle;
}
.wishlist-page .product-name {
    max-width: 100%;
    min-width: 195px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.wishlist-page .price-value {
    white-space: nowrap;
}
.wishlist-buttons {
    width: 300px;
}
.wishlist-page .catalog-item-buttons {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
}
.wishlist-page input {
    padding: 3px 20px;
}
.wishlist-page .catalog-item-add-button {
    width: 100%;
    margin-left: 10px;
}
.wishlist-page .button-div-item-qty .plus-item-qty {
    top: -1px;
}
.remove-wish-list-item {
    top: 25px;
}
.wishlist-img {
    width: 63px;
}
.wishlist-page td.main-cart-item-name.main-cart-item-value {
    font-weight: 600;
    /* line-height: 21; */
}
.wishlist-page .remove-wish-list-item {
    position: absolute;
    color: #eb0e4c;
    font-size: 15px;
    top: 25px;
    opacity: .9;
    cursor: pointer;
}
.wish-list-remove-all button {
    width: 250px;
    float: right;
}

@media (max-width: 1983px) and (min-width: 1603px) {
    .wishlist-page .button-div-item-qty input.input-qty-add-item{
        width: 100%;
    }
}
@media (max-width: 1559px) and (min-width: 1400px) {
    .wishlist-buttons {
        width: 250px;
    }
    .wishlist-page .catalog-item-buttons {
        justify-content: normal;
        font-size: 14px;
    }
    .wishlist-page .catalog-item-buttons .catalog-item-add-button {
        width: 150px;
    }
    .wishlist-page input {
        width: 80px;
    }
}
@media (max-width: 1399px) and (min-width: 1280px) {
    .wishlist-page .container-catalog-content {
        padding: 30px 20px;
    }
    .wish-list-row-table {
        font-size: 14px;
    }
    .wishlist-buttons {
        width: 250px;
    }
    .wishlist-page .catalog-item-buttons {
        justify-content: normal;
    }
    .wishlist-page input {
        width: 80px;
    }
    .wishlist-page .catalog-item-buttons .catalog-item-add-button {
        width: 150px;
    }
}
@media (max-width: 1279px) and (min-width: 1166px) {
    .wishlist-page .container-catalog-content {
        padding: 30px 10px;
    }
    .wish-list-row-table {
        font-size: 14px;
    }
    .wishlist-img {
        width: 55px;
    }
    .wishlist-page .product-name {
        min-width: 255px;
    }
    .wishlist-buttons {
        width: 100%;
    }
    .wishlist-page .catalog-item-buttons {
        justify-content: normal;
    }
    .wishlist-page input {
        width: 70px;
    }
    .wishlist-page .catalog-item-buttons .catalog-item-add-button {
        width: 130px;
    }
    .remove-wish-list-item {
        top: 22px;
    }
}
@media (max-width: 1165px) and (min-width: 1021px) {
    .wishlist-page {
        padding: 30px 10px;
        overflow: hidden;
    }
    .wish-list-table .editing-cart-items-table {
        overflow: visible;
        overflow-x: scroll;
        height: calc( 100vh - 130px );
    }
    .wishlist-page table.editing-cart-table {
        border-collapse: collapse;
        max-width: 100%;
    }
    .wishlist-page table.editing-cart-table thead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
    }
    .wish-list-row-table {
        font-size: 14px;
    }
   .wishlist-img {
        width: 55px;
    }
    .wishlist-page .product-name {
        min-width: 255px;
    }
    .wishlist-buttons {
        width: 100%;
    }
    .wishlist-page .catalog-item-buttons {
        justify-content: normal;
    }
    .wishlist-page input {
        width: 70px;
    }
    .wishlist-page .catalog-item-buttons .catalog-item-add-button {
        width: 130px;
    }
    .wishlist-page .remove-wish-list-item {
        top: 22px;
    }
}
@media (max-width: 1020px) and (min-width: 901px) {
    .wishlist-page .product-name {
        min-width: 255px;
    }
    .wishlist-buttons {
        width: 240px;
    }
    .wishlist-page .catalog-item-buttons {
        justify-content: normal;
    }
    .wishlist-page input {
        width: 90px;
    }
    .wishlist-page .catalog-item-buttons .catalog-item-add-button {
        width: 130px;
    }

}
@media (max-width: 900px) {
    .wishlist-page {
        padding: 30px 10px;
        margin-top: 85px;
    }
    .wish-list-row-table {
        font-size: 14px;
    }
    .wish-list-table .editing-cart-items-table {
        overflow: visible;
        overflow-x: scroll;
        height: calc( 100vh - 210px );
        margin-top: 10px;
    }
    .wishlist-page table.editing-cart-table {
        border-collapse: collapse;
        max-width: 100%;
    }
    .wishlist-page .product-name {
        min-width: 255px;
    }
    .wishlist-buttons {
        width: 220px;
    }
    .wishlist-page .catalog-item-buttons {
        justify-content: normal;
    }
    .wishlist-page input {
        width: 70px;
    }
    .wishlist-page .catalog-item-buttons .catalog-item-add-button {
        width: 130px;
    }
    .wishlist-page {
        padding: 0px 0px;
        margin-top: 65px;
    }
    
    .wish-list-remove-all button {
        width: 100%;
    }

}