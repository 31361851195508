@font-face {
    font-family: 'Axiforma';
    src: url('./fonts/axiforma/Axiforma-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('./fonts/axiforma/Axiforma-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

body,
html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.container-fluid.main-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.wrapper-ewe-go-cart {
    max-width: 1800px;
    width: 100%;
    margin: 0 auto;
}

.main-container {
    position: relative;
    background-color: #fffbed;
    color: #5b5555;
    font-family: 'GtestiProRegular';
    font-weight: bold;
    width: 100%;
    margin: 0 auto;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/landing/TRAGOVI%20OD%20GUMA%20IZA%20NASLOVA.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%;

}

.custom-container-fluid {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 45px;
    background-color: #fffbed;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/landing/TRAGOVI%20OD%20GUMA%20IZA%20NASLOVA.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%;
    height: auto;
}

.ewe-go-cart {
    margin-top: 120px;
    margin-bottom: 20px;
}

.ewe-go-cart-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    height: auto;
}

.ewe-go-cart-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.ewe-go-cart-img-bottom {
    align-self: center;
    margin-top: auto;
    /* width: 100%; */
}

.ewe-go-cart-img-top,
.ewe-go-cart-img-bottom {
    width: 100%;
}

.flex-embrace-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-embrace-text img {
    margin-right: 10px;
}

.flex-embrace-text p {
    margin-left: -45px;
    margin-bottom: 0;
    padding-left: 10px;
}

.trazimo-sampiona-holder {}

.trazimo-sampiona {
    font-size: 42px;
    font-weight: 700;
    font-family: 'Axiforma';
    color: #000;
    margin-bottom: 20px;
}

.pasus1 {
    font-size: 40px;
    font-family: 'Axiforma';
    color: #000;
    padding: 30px;
}

p.pasus2 {
    padding-top: 20px;
    font-size: 40px;
    font-family: 'Axiforma';
    color: #000;
    align-self: center;
    font-size: 25px;
}

.pehar-container {
    position: relative;
    width: 100%;
    height: auto;
}

.pehar {
    position: absolute;
    top: -280px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.login-title-image {
    margin-bottom: 20px;
}

.braon-prozor-holder {
    text-align: center;
    margin-top: 160px;
    position: relative;
    z-index: 2;
}

.braonProzor-image {
    /* width: 80%;
    height: auto; */
    /* display: inline-block; */
    max-width: 100%;
    height: auto;
}

.put-image {
    margin-top: -18%;
}

.race-content-holder {
    font-family: 'Axiforma';
    background-image: url('https://resource.ewe.rs/media/ewe-go-cart/landing/PUT_SA_FORMULAMA.png');
    background-repeat: no-repeat;
    background-position: top;
    min-height: 4400px;
    position: relative;
    top: -70px;
    background-size: 100%;
}

.race-content-holder h1 {
    position: relative;
    padding: 10px;
}

.content-right {
    display: flex;
    justify-content: flex-end !important;
}

.content-center {
    display: flex;
    justify-content: center !important;
    gap: 10px;
}

.content-left {
    display: flex;
    justify-content: flex-start !important;
}

.put-image {
    width: 100%;
    height: auto;
}

.red-text {
    color: #DC0000;
    font-size: 2.8rem;
    font-weight: 700;
}

.black-text {
    color: #000;
    font-size: 2.8rem;
    font-weight: 700;
}

.paragraph-container {
    text-align: right;
    width: 100%;
}

.paragraph-container1 {
    text-align: left;
    width: 100%;
    margin-bottom: 50px;
}

.login-page-button {
    background-color: #DC0000;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 250px;
}

.login-page-button span {
    display: block;
    text-align: center;
}

.ranking-page-button span {
    display: block;
    text-align: center;
}

.login-page-button:hover {
    background-color: darkred;
}

.ranking-page-button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 250px;
}

.ranking-page-button:hover {
    background-color: gray;
}

.race-content-holder-text2 h1 {
    text-align: right;
    font-size: 2.8rem;
    font-weight: 700;
}

.fuel-image {
    width: 40%;
    margin-left: 60%;
    margin-top: 10%;
}

.fuel-image1 {
    width: 40%;
    margin-top: 20%;
    margin-left: -80%;
}

.paragraph-container2 {
    width: 100%;

}

.paragraph-container2 h1 {
    margin-bottom: 30px;
}

.red-bullet {
    position: relative;
    padding-left: 20px;
}

.red-bullet::before {
    content: "•";
    color: #DC0000;
    position: absolute;
    left: 0;
}

.paragraph-container40 {
    width: 40%;
}

.second-button-holder {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -70px;
}

.links-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.ewe-go-cart-img-top,
.ewe-go-cart-img-bottom {
    width: 100%;
}

.ewe-go-cart-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.second-button-holder1 {
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-size: 100%;
    background-position: top left;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 1000px;
    position: absolute;
    bottom: 5%;
}

.final-heading-container {
    margin-bottom: 100px;
    margin-top: -100px;
}

.pasus1,
.pasus2 {
    font-size: 25px;
}

.race-content-holder-text,
.race-content-holder-text1,
.race-content-holder-text2 {
    font-size: 25px;
}

.race-content-holder-text h1 {
    text-align: right;
}

.race-content-holder-text1 {
    margin-top: 45%;
}

.race-content-holder-text h1 span {
    font-size: 2.8rem;
    font-weight: 700;
}

.race-content-holder {
    position: relative;
}

.race-content-holder-text {
    position: relative;
    top: 0%;
    left: 15%;
    right: 0;
    margin-top: 20%;
}

.image-container-stoperica {
    position: absolute;
}

.race-content-holder-text2 {
    position: relative;
    top: 5%;
    left: 10%;
}

.kartodrom {
    width: 100%;
    margin-top: -35%;
    position: relative;
}

.paragraph-container2 {
    font-family: 'Axiforma';
    font-size: 25px;
    color: #000;
    padding-left: 180px;
}

.paragraph-containe {
    height: 100vh;
}

.zastavica {
    width: 70%;
    margin-top: -35%;
}

.ewe-go-cart-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.login-page-button,
.ranking-page-button {
    width: 300px;
    height: 60px;
}


@media(min-width: 1921px) {

    .race-content-holder {
        min-height: 4510px;
    }

    .race-content-holder-text2 {
        top: 10%;
    }
}

@media(max-width:1919px) {
    .wrapper-ewe-go-cart {
        max-width: 1500px;
    }

    .race-content-holder-text {
        font-size: 20px;
    }

    .race-content-holder-text1 {
        font-size: 20px;
    }

    .paragraph-container1 {
        width: 90%;
    }

    .content-center {
        width: 100%;
    }

    .paragraph-container {
        width: 90%;
        font-size: 20px;
    }

    .race-content-holder-text h1 span {
        font-size: 2.4rem;
    }

    .race-content-holder-text h1 {
        text-align: center;
    }

    .red-text,
    .black-text {
        font-size: 2.4rem;
    }

    .race-content-holder-text2 h1 {
        margin-right: 9%;
    }

    .race-content-holder-text2 {
        top: 10%;
    }

    .race-content-holder {
        min-height: 3750px;
    }

    .race-content-holder-text1 {
        margin-top: 30%;
    }

    .paragraph-container2 h1 {
        font-size: 2.4rem;
    }

    .paragraph-container2 p {
        font-size: 20px;
    }

    .ewe-go-cart-img {
        width: 50%;
    }
}

@media(max-width:1535px) {
    .race-content-holder {
        min-height: 3350px;
    }

    .stopwatch-image {
        width: 60%;
    }

    .pasus2 {
        position: relative;
        z-index: 1;
    }

    .race-content-holder-text1 {
        margin-top: 10px;
    }

    .race-content-holder-text h1 span {
        font-size: 1.8rem;
    }

    .red-text,
    .black-text {
        font-size: 1.8rem;
    }

    .paragraph-container {
        font-size: 18px;
    }

    .race-content-holder-text1 {
        font-size: 18px;
    }

    .race-content-holder-text1 {
        margin-top: 45%;
    }

    .fuel-image {
        width: 30%;
    }

    .race-content-holder-text2 {
        top: 0;
    }

    .pehar-image,
    .login-title-image {
        width: 80%;
    }

    .pehar {
        top: -200px;
    }

    .race-content-holder-text2 {
        top: 10%;
    }
}

@media(max-width:1365px) {
    p.pasus2 {
        font-size: 16px;
    }

    h4.pasus1 {
        font-size: 18px;
    }

    .pehar-image {
        width: 50%;
    }

    .pehar {
        top: -400px;
    }

    .braon-prozor-holder {
        margin-top: 30px;
    }

    .embrace-title-image {
        width: 40%;
    }

    .paragraph-container {
        font-size: 15px;
    }

    .race-content-holder-text1 {
        font-size: 15px;
        margin-top: 10%;
    }

    .race-content-holder {
        top: -40px;
        min-height: 2800px;
    }

    .fuel-image {
        margin-top: 0;
    }

    .login-page-button,
    .ranking-page-button {
        width: 200px;
    }

    .second-button-holder {
        top: 0;
    }

    .final-heading-container {
        margin-bottom: 50px;
        margin-top: 0;
    }

    .race-content-holder-text2 {
        top: 0%;
    }

    .race-content-holder {
        min-height: 3150px;
    }
}

@media(max-width: 1024px) {

    .race-content-holder-text1 {
        margin-top: 30%;
    }

    .race-content-holder {
        min-height: 2350px;
    }

    .race-content-holder-text2 {
        top: -35%;
    }

    .zastavica {
        margin-left: 40%;
    }
}

.first-holder-mobile {
    text-align: center;
}

.first-container-fluid-mobile {
    background-color: #fffbed;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/landing/TRAGOVI%20OD%20GUMA%20IZA%20NASLOVA.png);
    background-position: top;
    background-repeat: no-repeat;
    margin-top: 66px;
}

.ewe-go-cart-img-mobile {
    width: 100%;
}

.login-title-image-mobile {
    width: 100%;
}

.trazimo-sampiona-mobile {
    font-size: 25px;
    font-family: 'Axiforma';
}

.pasus1-mobile {
    font-size: 15px;
    font-family: 'Axiforma';
    padding-left: 10px;
    padding-right: 10px;
}

.embrace-title-image-mobile {
    width: 50%;
}

.first-holder-mobile img {
    margin: 10px 0;
}

.pehar-image-mobile {
    width: 30%;
}

.brown-container-fluid-mobile {
    background-color: #4A3937;
    font-size: 15px;
    font-family: 'Axiforma';
    color: white;
    padding: 40px 15px;
    text-align: center;

}

.start-container-fluid-mobile {
    background-image: url('../img/ewe-cart-go/add-to-cart.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px;
    height: 960px;
    text-align: center;
    display: flex;
    align-items: flex-start;
}

.red-text-mobile {
    color: #DC0000;
    font-size: 20px;
    font-family: 'Axiforma';
    display: block;
    font-weight: 700;
}

.black-text-mobile {
    color: #000;
    font-size: 20px;
    font-family: 'Axiforma';
    display: block;
    font-weight: 700;
}

.transparent-holder {

    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgb(255 255 255 / 55%);
    text-align: center;
    height: auto;
    margin-top: 100px;
    border: 1px solid black;
    color: #000;
    font-size: 15px;
    font-family: 'Axiforma';
}


.text-button-holder-mobile {
    background-color: #fffbed;
    font-size: 15px;
    font-family: 'Axiforma';
    padding: 25px 0;
}

.paragraph-container-mobile {
    text-align: left;
    padding: 15px;
    font-size: 15px;
    font-family: 'Axiforma';
}

.login-page-button-mobile,
.login-page-red-button-mobile {
    background-color: #DC0000;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 90%;
    margin-bottom: 10px;
}

.login-page-button-mobile span {
    display: block;
    text-align: center;
}

.login-page-button-mobile:hover {
    background-color: darkred;
}

.ranking-page-button-mobile {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 90%;
}

.ranking-page-button-mobile:hover {
    background-color: gray;
}

.go-kart-container-fluid-mobile {
    background-image: url('../img/ewe-cart-go/go-kart.png');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    display: flex;
    align-items: flex-start;
    background-color: #fffbed;
    height: 1300px;
    padding: 15px;

}

.transparent-holder25 {
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgb(255 255 255 / 55%);
    text-align: center;
    height: auto;
    border: 1px solid black;
    color: #000;
    font-size: 15px;
    font-family: 'Axiforma';
}

.second-text-button-holder-mobile {
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #fffbed;
    font-family: 'Axiforma';
    color: #000;
    text-align: left;
    padding-bottom: 20px;
}

.heading-holder-mobile {
    font-family: 'Axiforma';
    font-size: 15px;
    padding: 15px;
}

.heading-holder-mobile p {
    font-family: 'Axiforma';
    font-size: 20px;
    font-weight: bold;
}

.paragraph-container-button-mobile {
    margin-bottom: 10px
}

.red-bullet {
    font-family: 'Axiforma';
}

.red-inline {
    color: #DC0000;
}

.paragraph-container,
.paragraph-container1 p {
    color: #000;
}

.third-text-button-holder-mobile {
    background-color: #fffbed;
}

.third-text-button-holder-mobile {
    padding: 15px;
}

.final-heading-container-mobile {
    text-align: center;
}

.paragraph-container40-mobile {
    text-align: center;
}

.zastavica-mobile {
    width: 50%;
}

.ewe-go-cart-img-bottom-mobile {
    width: 100%;
}