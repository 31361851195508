.product-promo-holder {
    padding: 15px;
    width: 100%;
}

.product-promo-holder .slider-item-spec {
    /* max-width: 270px; */
}

.button-export-product-detail a {
    margin-right: 30px;
    text-decoration: none;
}

.compare-page-table tbody:after {
    content: "@";
    display: block;
    line-height: 30px;
    text-indent: -99999px;
}

.modal-double-popoup {
    /* max-width: 1650px; */
    /* max-width: 1600px; */
}

.no-padding-custom {
    padding: 0px;
}