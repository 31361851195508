@font-face {
    font-family: 'Axiforma';
    src: url('./fonts/axiforma/Axiforma-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url('./fonts/axiforma/Axiforma-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

.main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background-color: #000;
    color: #5b5555;
    font-family: 'Axiforma';
    font-weight: bold;
}

.custom-container-fluid {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fffbed;
    justify-content: center;
    align-items: center;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-size: 50%;
    background-position: top left;
    background-repeat: no-repeat;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ewe-go-cart-my-coins {
    background-color: #fffbed;
    background-image: url(https://resource.ewe.rs/media/ewe-go-cart/login/login-trag-gume.png);
    background-size: 50%;
    background-position: top left;
    background-repeat: no-repeat;
}

.ewe-go-cart-content-header {
    margin-top: 30px;
}


@media(min-width: 500px) and (max-width:649px) {
    .ewe-go-cart-formula1-img {
        display: none;
    }

    .ewe-go-cart-formula2-img {
        display: none;
    }

    .ewe-go-brzina2-img {
        display: none;
    }

    .ewe-go-brzina1-img {
        display: none;
    }

    .ewe-go-brzina2-holder,
    .ewe-go-brzina1-holder {
        display: none;
    }

    .ewe-go-cart-img {
        width: 50%;
    }

}

@media(min-width: 650px) and (max-width:991px) {
    .ewe-go-cart-formula1-img {
        display: none;
    }

    .ewe-go-cart-formula2-img {
        display: none;
    }

    .ewe-go-brzina2-img {
        display: none;
    }

    .ewe-go-brzina1-img {
        display: none;
    }

    .ewe-go-brzina2-holder,
    .ewe-go-brzina1-holder {
        display: none;
    }

    .ewe-go-cart-img {
        width: 50%;
    }
}


@media(min-width: 992px) and (max-width:1023px) {
    .ewe-go-cart-formula1-img {
        display: none;
    }

    .ewe-go-cart-formula2-img {
        display: none;
    }

    .ewe-go-brzina2-img {
        display: none;
    }

    .ewe-go-brzina1-img {
        display: none;
    }

    .ewe-go-brzina2-holder,
    .ewe-go-brzina1-holder {
        display: none;
    }

    .ewe-go-cart-img {
        width: 50%;
    }
}

@media(min-width: 1024px) and (max-width:1537px) {

    .ewe-go-cart-img {
        width: 70%;
    }
}

.ewe-go-cart-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 100%;
    max-width: 100%; */

}

.ewe-go-cart-img-top,
.ewe-go-cart-img-bottom {
    width: 100%;
}

.ewe-go-cart-content-header {
    font-family: 'Axiforma';
    color: #DC0000;
    font-size: 55px;
    letter-spacing: 30px;
    font-size: 25px;
    text-align: center;
    font-weight: 700;
}

.ewe-go-cart-total-subtitle {
    font-family: 'Axiforma';
    color: white;
    font-size: 24px;
    letter-spacing: 10px;
    color: #DC0000;
}

.ewe-go-cart-content-header {
    text-align: center;
    margin-bottom: 50px;
    /* border-bottom: 1px solid #DC0000; */
}

.ewe-cart-go-style .MuiTableCell-head {
    background-color: transparent !important;
    color: #DC0000;
}

.ewe-cart-go-style span:hover,
.ewe-cart-go-style .MuiTableSortLabel-root.MuiTableSortLabel-active,
.ewe-cart-go-style .MuiTableSortLabel-icon,
.ewe-cart-go-style .MuiInputBase-root,
.ewe-cart-go-style .MuiIconButton-root.Mui-disabled,
.ewe-cart-go-style span {
    color: #DC0000 !important;
}

.ewe-cart-go-style {
    /* background-image: url(https://resource.ewe.rs/media/new-year-loyalty/zvezde.png); */
    /* background-image: url(https://resource.ewe.rs/media/Sencor-Akcililili2.jpg);*/
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-color: #DC0000;


}

.ewe-cart-go-style1 {
    background-color: black !important;
    color: white !important;
    border: 1px solid red !important;
}

.ewe-cart-go-total {
    color: #DC0000;
    font-family: 'Axiforma';
    font-size: 28px;
    letter-spacing: 5px;
    margin: 40px 0;
}

@media(max-width:1023px) {
    .ewe-go-cart-my-coins {
        margin-top: 65px;
    }

    .ewe-go-cart-total-subtitle,
    .ewe-cart-go-total {
        font-size: 20px;
    }
}